import { useQuery } from 'react-query';

import { UserCertifications } from 'Certificate/model/UserCertifications';
import { certificateApi } from 'Certificate/service/certificate.api';
import { UserHistory } from 'Certificate/model/UserHistory';
import { UserTrainingSet } from 'Certificate/model/UserTraningSet';


export const myCertificationsKey = 'my-certifications';
export const useMyCertificationsQuery = () =>
  useQuery<UserCertifications>(
    myCertificationsKey,
    certificateApi.getMyCertificates
  );

export const userCertificationsKey = 'user-certifications';
export const useUserCertificationsQuery = (userId: string) =>
  useQuery<UserCertifications>([userCertificationsKey, userId], () =>
    certificateApi.getUserCertifications(userId)
  );

export const userHistoryKey = 'user-history';
export const useUserHistoryQuery = (userId: string) =>
  useQuery<UserHistory|null>([userHistoryKey, userId], () =>
    certificateApi.getUserHistory(userId)
  );

export const userTraningSets = 'user-traning-sets';
export const useUserTraningSetsQuery = (userId: number) =>
  useQuery<UserTrainingSet[]>([userHistoryKey, userId], () =>
    certificateApi.getUserTraningSets(userId)
  );
