import { FC } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ManageRoleIcon from '@material-ui/icons/Security';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import RoleListIcon from '@material-ui/icons/Policy';

import { useCollapseRowActions } from 'hooks';
import { addInternalUsersRoute } from 'route';
import { Card, Content } from 'components/Page';
import {
  SearchUsers,
  SearchUsersRowActions,
} from 'MyProfile/component/SearchUsers/SearchUsers';
import { managementColumns } from 'MyProfile/component/UserTable';
import { IsExternalType } from 'Dictionary/component';
import { RoundButton } from 'components';
import { EditInternalUserForm } from 'MyProfile/component/EditInternalUserForm';
import { UserRolesList } from 'MyProfile/component/UserRolesList';
import { useMyProfileQuery } from 'MyProfile/query/myProfile.query';
import { ManageUserRole } from 'MyProfile/component/ManageUserRole/ManageUserRole';
import { ManageUserTabs } from 'MyProfile/component/ManageUserTabs';

const pageSize = 20;

export const InternalUsersPage: FC = () => {
  const history = useHistory();
  const { data: myProfile } = useMyProfileQuery();
  const handleOpenAddUsers = () => history.push(addInternalUsersRoute.path);
  const {
    openRow,
    closeRow,
    collapseRecords,
  } = useCollapseRowActions<SearchUsersRowActions>();
  return (
    <Content
      title="Internal users"
      subtitle="Here you can manage internal users, change their roles or archive them"
      renderActions={() => (
        <RoundButton onClick={handleOpenAddUsers}>Add User</RoundButton>
      )}
    >
      <SearchUsers
        initialPageSize={pageSize}
        SearchFiltersFormProps={{ isExternalType: IsExternalType.Abb, isForAction: true }}
        DataTableProps={{
          columns: managementColumns,
          checkboxSelection: true,
          renderRowActions: (row, collapseRow, isExpanded) =>
            isExpanded ? (
              <IconButton
                onClick={() => {
                  closeRow(row.id);
                  collapseRow();
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <>
                <IconButton
                  onClick={() => {
                    openRow(row.id, SearchUsersRowActions.RoleList);
                    collapseRow();
                  }}
                >
                  <RoleListIcon />
                </IconButton>
                {myProfile?.isAdmin && (
                  <IconButton
                    onClick={() => {
                      openRow(row.id, SearchUsersRowActions.ManageRole);
                      collapseRow();
                    }}
                  >
                    <ManageRoleIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    openRow(row.id, SearchUsersRowActions.Edit);
                    collapseRow();
                  }}
                >
                  <EditIcon />
                </IconButton>
              </>
            ),
          renderCollapseRow: (row) => {
            if (collapseRecords[row.id] === SearchUsersRowActions.RoleList) {
              return <UserRolesList userId={row.id} removable={false} />;
            }
            if (collapseRecords[row.id] === SearchUsersRowActions.ManageRole) {
              return (
                <ManageUserRole
                  user={row}
                  isExternalType={IsExternalType.Abb}
                />
              );
            }
            if (collapseRecords[row.id] === SearchUsersRowActions.Edit) {
              return (
                <Card transparent>
                  <EditInternalUserForm user={row} />
                </Card>
              );
            }
            return null;
          },
        }}
        DataObjectListProps={{
          renderDetails: (item) => (
            <ManageUserTabs
              user={item}
              isAdmin={myProfile?.isAdmin ?? false}
              isExternalType={IsExternalType.Abb}
            />
          ),
        }}
        displaySearchResults
      />
    </Content>
  );
};
