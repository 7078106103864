import { FC } from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';

import { myRoleMatchToRoles, RoleType } from 'MyProfile/enum/RoleType';
import { useMyProfileQuery } from 'MyProfile/query';
import { getRoles } from 'MyProfile/model/MyProfile';
import { notFoundRoute } from 'route';

export interface ProtectedRouteProps extends RouteProps {
  roles: RoleType[];
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  roles,
  ...routeProps
}) => {
  const { data } = useMyProfileQuery();
  const myRoles: RoleType[] = data ? getRoles(data) : [];

  return myRoleMatchToRoles(myRoles, roles) ? (
    <Route {...routeProps} />
  ) : (
    <Redirect to={notFoundRoute} />
  );
};
