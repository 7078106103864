import { useMemo } from 'react';
import { Grid, GridSize, Typography, useTheme } from '@material-ui/core';
import { ColumnDefinition, FilterScope, ReportPageTypes } from 'Reports/model';
import { FilterFieldFactory } from './FilterFieldFactory';
import { FilterChangeHandler, ReportingFilters } from './@types';

export type FiltersScopeGridSize = boolean | GridSize | undefined;

export interface FiltersScopeProps {
  filters: ReportingFilters;
  reportType: ReportPageTypes;
  onFilterChange: FilterChangeHandler;
  filterScope: FilterScope;
  columnsDefinition: ColumnDefinition[];
  lg?: FiltersScopeGridSize;
  md?: FiltersScopeGridSize;
  sm?: FiltersScopeGridSize;
  xl?: FiltersScopeGridSize;
  xs?: FiltersScopeGridSize;
}

export const FiltersScope = (props: FiltersScopeProps) => {
  const {
    filters,
    reportType,
    onFilterChange,
    filterScope,
    columnsDefinition,
    lg = 4,
    md = 4,
    sm = 6,
    xl = 4,
    xs = 12,
  } = props;
  const theme = useTheme();
  const columnsScoped = useMemo(
    () =>
      columnsDefinition.filter(
        (x) => x.scopeId === filterScope.id && x.filter === 'basic'
      ),
    [filterScope, columnsDefinition]
  );
  return filterScope?.visible ? (
    <Grid item lg={lg} md={md} sm={sm} xl={xl} xs={xs}>
      <Typography
        variant="body2"
        style={{ marginBottom: theme.spacing(2) }}
      >{`${filterScope.name} Attributes`}</Typography>
      {columnsScoped.map((f) => (
        <FilterFieldFactory
          key={f.cid}
          columnDefinition={f}
          filters={filters}
          onFilterChange={onFilterChange}
          reportType={reportType}
        />
      ))}
    </Grid>
  ) : null;
};
