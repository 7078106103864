import { createContext } from 'react';
import { ColumnDefinition, ReportPageTypes } from '../../model';

export interface GridContextProps {
  reportType: ReportPageTypes;
  columnsDefinitions: ColumnDefinition[];
  onGroupByChange?: ((cid: string | null) => void) | null;
  onVisibleColumnsChange?:
    | ((columns: string[], allColumns: boolean) => void)
    | null;
}

export const GridContext = createContext<GridContextProps>({
  reportType: ReportPageTypes.StatusOfCertifications,
  columnsDefinitions: [],
  onGroupByChange: null,
  onVisibleColumnsChange: null,
});
