import { makeStyles, Typography } from '@material-ui/core';
import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { ArrowForward } from '@material-ui/icons';
import { Alternatives } from '../model/CertificationPlansDetails';
import { AbbTheme } from '../../theme/createAbbTheme';

const columns: DataTableColumn<Alternatives>[] = [
  {
    field: 'source',
    headerName: 'Source',
    renderField: ({ source }) => `Completing requirement - ${source}`
  },
  {
    field: 'source',
    headerName: '',
    renderField: () => <ArrowForward />,
    width: 40,
  },
  {
    field: 'alternative',
    headerName: 'Alternative',
    renderField: ({ alternative }) =>
      `Approves also requirement - ${alternative}`,
  },
  // empty column for spacing
  {
    field: undefined,
    headerName: '',
    renderField: () => '',
  },
];

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    title: {},
  }),
  {
    name: 'AlternativesCertificatesTable',
  }
);

export interface AlternativesCertificatesTableProps {
  alternativesCertificates: Alternatives[];
  DataTableProps?: Omit<DataTableProps<Alternatives>, 'rows' | 'columns'>;
}

export const AlternativesCertificatesTable = (
  props: AlternativesCertificatesTableProps
) => {
  const { alternativesCertificates, DataTableProps } = props;
  const classes = useStyles();
  return Array.isArray(alternativesCertificates) &&
    alternativesCertificates.length ? (
    <>
      <Typography className={classes.title} variant="h6">
        Alternatives
      </Typography>

      <DataTable
        columns={columns}
        rows={alternativesCertificates}
        {...DataTableProps}
      />
    </>
  ) : null;
};

export default AlternativesCertificatesTable;
