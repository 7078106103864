import { useQuery } from 'react-query';

import { CertificationPlanHistory } from '../model/CertificationPlanHistory';
import { certificationPlanApi } from '../service/certificationPlans.api';

const certificationPlansHistoryKey = 'certification-plans-history';
export const useCertificationPlansHistoryQuery = (certificatePlanId: number) =>
  useQuery<CertificationPlanHistory[]>(certificationPlansHistoryKey, () =>
    certificationPlanApi.getCertificationPlanHistory(certificatePlanId)
  );
