import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Chip,
  useTheme,
  Tooltip,
} from '@material-ui/core';
import { Close, Save, Clear } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { deepCopy, getColDefName } from 'utils';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { ColumnDefinition, ReportSetting } from '../../model';
import { FiltersDateRange, ReportingFilters } from '../@types';
import { ActionsPanel } from './ActionsPanel';

export interface ReportSettingsDetailsProps {
  selectedIndex: number | null;
  selected: ReportSetting | null;
  inEditMode?: boolean;
  chosenFilters: ReportingFilters;
  columnDefinitions: ColumnDefinition[];
  onFiltersRestore: (filters: Partial<ReportingFilters>) => void;
  onColumnsRestore: (columns: string[]) => void;
  onSettingsChange: (settings: ReportSetting, index: number | null) => void;
  onSettingNameChange: (settings: ReportSetting, index: number | null) => void;
}

export const ReportSettingsDetails = (props: ReportSettingsDetailsProps) => {
  const {
    selectedIndex,
    columnDefinitions,
    selected,
    chosenFilters,
    inEditMode,
    onFiltersRestore,
    onColumnsRestore,
    onSettingsChange,
    onSettingNameChange,
  } = props;
  const theme = useTheme();
  const [selectedColDef, setSelectedColDef] = useState<ColumnDefinition[]>([]);
  const [settingName, setSettingName] = useState<string>('');
  const filters: {
    name: string;
    cid: string;
    tooltip: string | null;
  }[] = useMemo(() => {
    const f = selected?.filters ?? {};
    if (f) {
      return Object.keys(f).map((k) => {
        const colDef = columnDefinitions.find((c) => c.cid === k) ?? null;
        const name = getColDefName(colDef);
        const values = selected?.filters[k];
        let tooltip = typeof values === 'string' ? values || null : null;
        if (colDef?.type?.date && values) {
          const v = values as FiltersDateRange;
          tooltip = v?.start ? `Start date: ${v?.start ?? 'no date'},` : '';
          tooltip =
            tooltip + v?.start ? ` End date: ${v?.start ?? 'no date'},` : '';
        }
        if (Array.isArray(values) && Array.isArray(colDef?.values)) {
          tooltip = values.reduce((prev, value) => {
            const label = colDef?.values?.find((v) => v.vid === value)?.label;
            return label ? `${prev} ${label},` : prev;
          }, '');
        }
        tooltip = tooltip?.trim() ?? null;
        tooltip = tooltip?.replace(/(^,)|(,$)/g, '') ?? null;
        return { name, cid: colDef?.cid ?? '', tooltip };
      });
    }
    return [];
  }, [selected]);
  useEffect(() => {
    setSettingName(selected?.name ?? '');
  }, [selected]);
  const colDefFiltred = useMemo(() => {
    const length = selected?.columns?.length ?? 0;
    if (length <= 0) {
      return columnDefinitions;
    }

    return columnDefinitions.filter((c) =>
      selected?.columns.some((cid) => c.cid !== cid)
    );
  }, [columnDefinitions]);
  const handleItemsChange = (
    e: ChangeEvent<unknown>,
    value: (string | ColumnDefinition)[]
  ) => {
    setSelectedColDef(value as ColumnDefinition[]);
  };
  const handleAddColumns = () => {
    if (selectedColDef.length <= 0) {
      return;
    }
    const currentCols = selected?.columns ?? [];
    const next = deepCopy(selected) as ReportSetting;
    next.columns = [...selectedColDef.map((c) => c.cid), ...currentCols];
    onSettingsChange(next, selectedIndex);
    setSelectedColDef([]);
  };
  const handleRemoveColumn = (cid: string) => {
    const next = deepCopy(selected) as ReportSetting;
    next.columns = next.columns.filter((c) => c !== cid);
    onSettingsChange(next, selectedIndex);
  };
  const handleColumnDragEnd = (results: DropResult) => {
    if (
      !Number.isInteger(results.destination?.index) ||
      !results?.destination
    ) {
      return;
    }
    const next = deepCopy(selected) as ReportSetting;

    const element = next.columns[results.source.index];
    next.columns.splice(results.source.index, 1);
    next.columns.splice(results.destination.index, 0, element);

    onSettingsChange(next, selectedIndex);
  };
  const handleCollectFilters = () => {
    const next = deepCopy(selected) as ReportSetting;
    next.filters = { ...chosenFilters };
    onSettingsChange(next, selectedIndex);
  };
  const handleDeleteFilter = (cid: string) => {
    const next = deepCopy(selected) as ReportSetting;
    const filters = next?.filters;
    if (filters && Object.keys(filters).some((k) => k === cid)) {
      delete next.filters[cid];
      onSettingsChange(next, selectedIndex);
    }
  };

  const onNameChange = (newName: string) => {
    setSettingName(newName)
    selected!.name = newName
  }
  return selected ? (
    <>

      <Typography variant="subtitle2">
        Setting details
      </Typography>
      <TextField
        label="Setting name"
        value={selected.name}
        required={true}
        disabled={!props.inEditMode}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onNameChange(e.target?.value)}
      />

      {Array.isArray(filters) && (
        <div>
          <Typography
            variant="caption"
            component="div"
            style={{
              fontSize: 10,
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            Filters
          </Typography>
          {filters.map((f) => (
            <Tooltip key={f.cid} title={f.tooltip ?? 'No value'}>
              <span>
                <Chip
                  disabled={!props.inEditMode}
                  label={f.name}
                  style={{ margin: theme.spacing(0.5) }}
                  onDelete={() => handleDeleteFilter(f.cid)}
                />
              </span>
            </Tooltip>
          ))}
          <ActionsPanel hidden={!inEditMode}>
            <Button color="secondary" onClick={handleCollectFilters} disabled={!props.inEditMode}>
              Get filters
            </Button>
          </ActionsPanel>
        </div>
      )}
      <Typography
        variant="caption"
        component="div"
        style={{
          fontSize: 10,
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        Columns
      </Typography>

      <Autocomplete
        value={selectedColDef}
        options={colDefFiltred.filter(c => c.cid !== "CgACAQYDDAMEDQkGBAwTSK")}
        multiple={true}
        disabled={!props.inEditMode} hidden={!inEditMode}
        onChange={handleItemsChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        getOptionLabel={(option) => getColDefName(option)}
        getOptionSelected={(option, value) => option?.cid === value?.cid}
      />
      <ActionsPanel hidden={!inEditMode}>
        <Button
          color="secondary"
          onClick={handleAddColumns}
          disabled={!props.inEditMode || selectedColDef.length <= 0}
        >
          Add Columns
        </Button>
      </ActionsPanel>
      <DragDropContext onDragEnd={handleColumnDragEnd} >
        <Droppable droppableId="columnSettings">
          {(provided) => (
            <List {...provided.droppableProps} ref={provided.innerRef} >
              {selected?.columns?.map((c, index) => {
                const name = getColDefName(
                  columnDefinitions.find((d) => d.cid === c) ?? null
                );
                return (
                  <Draggable key={c} draggableId={c} index={index} isDragDisabled={!props.inEditMode}>
                    {(provided) => (
                      <ListItem disabled={!props.inEditMode}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                      >
                        <ListItemText primary={name} />
                        <ListItemSecondaryAction>
                          <IconButton disabled={!props.inEditMode} onClick={() => handleRemoveColumn(c)}>
                            <Close />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </>
  ) : (
    <Typography>Select settings to display details...</Typography>
  );
};
