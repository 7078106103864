import { FC, useState } from 'react';
import { useMutation } from 'react-query';

import { FieldRoot } from 'components/SearchFiltersForm/SearchFiltersForm.style';
import { userAuthorizationsKey } from 'MyProfile/query/myProfile.query';
import {
  AutocompleteField,
  AutocompleteFieldItem,
} from 'components/Field/AutocompleteField/AutocompleteField';
import { autocompleteInputStyles } from 'HelpAndSupport/component/BusinessUnitContacts/BusinessUnitContacts.style';
import { RoleType } from 'MyProfile/enum/RoleType';
import { MyProfile } from 'MyProfile/model/MyProfile';
import { myProfileApi } from 'MyProfile/service/myProfile.api';
import { ApiError } from 'model';
import { useToasts } from 'components/ToastProvider';
import { UpdateAuthorizationsDto } from 'MyProfile/model/UpdateAuthorizationsDto';
import { queryClient } from 'service';
import { RoleDetailsForm } from 'MyProfile/component/RoleDetailsForm';
import { RoleDetailsFormData } from 'MyProfile/component/RoleDetailsForm/RoleDetailsForm';

export const addInternalUserRoleOptions: AutocompleteFieldItem[] = [
  { id: RoleType.Administrator, name: 'Administrator' },
  { id: RoleType.CertificationAuthority, name: 'Certification Authority' },
  { id: RoleType.CertificateBuilder, name: 'Certificate Builder' },
  { id: RoleType.Contact, name: 'Contact' },
  { id: RoleType.Reader, name: 'Reader' },
  { id: RoleType.Supervisor, name: 'Supervisor' },
  { id: RoleType.ChannelPartnerManager, name: 'Int/Ext Company Coordinator' },
];

export const addExternalUserRoleOptions: AutocompleteFieldItem[] = [
  { id: RoleType.Reader, name: 'Reader' },
  { id: RoleType.PartnerSupervisor, name: 'External Company Responsible' },
];

export const mapUpdateAuthorizationsDto = (
  formData: RoleDetailsFormData,
  role: RoleType,
  userId: number
): UpdateAuthorizationsDto => {
  const partialUpdateDto: UpdateAuthorizationsDto = {
    userId,
    roleType: role,
  };
  switch (role) {
    case RoleType.CertificationAuthority:
    case RoleType.CertificateBuilder:
      return {
        ...partialUpdateDto,
        businessUnitId: Number(formData.businessUnit?.id),
      };
    case RoleType.Contact:
      return {
        ...partialUpdateDto,
        businessUnitId: Number(formData.businessUnit?.id),
        scopeId: Number(formData.scope?.id),
      };
    case RoleType.Reader:
      return {
        ...partialUpdateDto,
        businessUnitId: Number(formData.businessUnit?.id),
        countryId: Number(formData.country?.id),
      };
    case RoleType.Supervisor:
      return {
        ...partialUpdateDto,
        businessUnitId: Number(formData.businessUnit?.id),
        countryId: Number(formData.country?.id),
        scopeId: Number(formData.scope?.id),
      };
    case RoleType.ABBTrainingManager:
    case RoleType.ChannelPartnerManager:
    case RoleType.PartnerSupervisor:
      return {
        ...partialUpdateDto,
        businessUnitId: Number(formData.businessUnit?.id),
        companyId: Number(formData.company?.id),
        countryId: Number(formData.country?.id),
      };
    case RoleType.Administrator:
    default:
      return partialUpdateDto;
  }
};

export interface AddUserRoleProps {
  user: MyProfile;
  roleOptions: AutocompleteFieldItem[];
}

export const AddUserRole: FC<AddUserRoleProps> = ({ user, roleOptions }) => {
  const { showError, showSuccess } = useToasts();
  const [selectedRole, setRole] = useState<AutocompleteFieldItem>({});
  const { mutate: addUserRole, isLoading } = useMutation<
    unknown,
    ApiError,
    RoleDetailsFormData
  >(
    (formData) =>
      myProfileApi.updateAuthorizations(
        mapUpdateAuthorizationsDto(
          formData,
          selectedRole.id as RoleType,
          user.id
        )
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([userAuthorizationsKey, user.id]);
        showSuccess('Add user role action success');
      },
      onError: (error) => showError(error.message),
    }
  );

  return (
    <>
      <FieldRoot>
        <AutocompleteField
          label="Select role..."
          value={selectedRole}
          options={roleOptions}
          InputProps={{
            style: autocompleteInputStyles,
          }}
          onChange={(event, value) => setRole(value as AutocompleteFieldItem)}
          disabled={isLoading}
        />
      </FieldRoot>
      {selectedRole && (
        <RoleDetailsForm
          role={selectedRole?.id as RoleType}
          loading={isLoading}
          onSubmit={(values) => addUserRole(values)}
        />
      )}
    </>
  );
};
