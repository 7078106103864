import { FC } from 'react';
import { Checkbox, FormControlLabel, TextFieldProps, Tooltip } from '@material-ui/core';
import { useField } from 'formik';
import { FieldRoot } from 'components/SearchFiltersForm/SearchFiltersForm.style';
import UsedIcons from 'components/Trivial/UsedIcons';

export type FormikCheckboxFieldProps = TextFieldProps & {
  name: string;
};

export const FormikCheckboxField: FC<FormikCheckboxFieldProps> = (props) => {
  const [field, meta, helpers] = useField({ name: props.name });
  const helperText = meta.error ?? props.helperText

  const render = () => <>
    {props.label}
    {!helperText ? <></> : (<>&nbsp;<Tooltip title={helperText}><UsedIcons.HelperIcon /></Tooltip></>)}
  </>

  return (<FieldRoot>
    <FormControlLabel
      control={<Checkbox checked={field.value} />}

      label={render()}
      name={props.name}
      labelPlacement="end"
      onChange={field.onChange} />

  </FieldRoot>);
};
