import { ColumnDefinition } from 'Reports/model';

export const formatBoolean = (bool: Boolean): string => (bool ? 'Yes' : 'No');

export const capitalize = (s: string): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getColDefName = (columnDefinition: ColumnDefinition | null) => {
  if(columnDefinition != null)
  {
    if(columnDefinition.name.toLowerCase().includes((columnDefinition.scopeId as string).toLowerCase()))
      return  `${columnDefinition.name}`;      
    
    return `${capitalize(columnDefinition.scopeId as string)} ${columnDefinition.name}`;
  } 
  
  return '';
}

export const formatExternalFlag = (isExternal: boolean): string =>
  isExternal ? 'External only' : 'ABB';
