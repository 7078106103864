import React, { FC } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { AbbTheme } from 'theme/createAbbTheme';

export type Props = {};

export const MobileSection: FC<Props> = ({ children }) => {
  const matches = useMediaQuery<AbbTheme>((theme) =>
    theme.breakpoints.down('md')
  );

  if (matches) {
    return <>{children}</>;
  }

  return null;
};
