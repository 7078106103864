import styled from 'styled-components';
import ListSubheader, {
  ListSubheaderProps,
} from '@material-ui/core/ListSubheader';

export const StyledListSubheader = styled(ListSubheader)<ListSubheaderProps>`
  text-transform: uppercase;
  && {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.grey[300]};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    max-height: ${({ theme }) => theme.spacing(5)}px;
  }
`;
