import { useState } from 'react';
import BusinessUnitIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import SearchIcon from '@material-ui/icons/Search';

import { List, ListItem } from 'components/List';
import { AutocompleteField } from 'components/Field';
import { formatUserName } from 'utils/formatUserName';
import { Loader } from 'components/Loader/Loader';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { useBusinessUnitContactsQuery } from 'Dictionary/query/dictionaryQuery';
import { BusinessUnitContact } from 'Dictionary/model/BusinessUnitContact';
import {
  autocompleteInputStyles,
  StyledCard,
} from './BusinessUnitContacts.style';

export const getUniqBusinessUnits = (
  buContacts: BusinessUnitContact[]
): AutocompleteFieldItem[] =>
  buContacts.filter((bu, i, arr) => arr.findIndex(t => t.businessUnit.id === bu.businessUnit.id) === i)
    .map((buContact) => ({
    id: buContact.businessUnit.id,
    name: buContact.businessUnit.description,
  }));

export const filterBUContacts = (
  filter: AutocompleteFieldItem | null,
  list: BusinessUnitContact[]
): BusinessUnitContact[] =>
  filter
    ? list.filter((buContact) => buContact.businessUnit.id === filter.id)
    : list;

export const BusinessUnitContacts = () => {
  const { data, isLoading } = useBusinessUnitContactsQuery();
  const [selectedBU, setSelectedBU] = useState<AutocompleteFieldItem | null>(
    null
  );

  if (isLoading) {
    return <Loader />;
  }

  const buContactsToDisplay = filterBUContacts(selectedBU, data ?? []);

  return (
    <>
      <AutocompleteField
        label="Search..."
        value={selectedBU as AutocompleteFieldItem}
        options={getUniqBusinessUnits(data ?? [])}
        InputProps={{
          style: autocompleteInputStyles,
          startAdornment: <SearchIcon />,
        }}
        onChange={(event, value) =>
          setSelectedBU(value as AutocompleteFieldItem)
        }
      />
      {buContactsToDisplay.map((unit, index) => {
        const fullName = formatUserName(`${unit.firstName} ${unit.lastName}`);
        return (
          <StyledCard key={index} header={fullName} listContent>
            <List
              items={[
                {
                  Icon: BusinessUnitIcon,
                  primaryText: `${unit.businessUnit.description} - ${unit.scope !== null ? unit.scope.description : 'All Scopes'}`,
                },
                { Icon: PhoneIcon, primaryText: unit.phoneNumber ?? '...' },
                { Icon: EmailIcon, primaryText: unit.email.toLowerCase() },
              ]}
              renderItem={ListItem}
            />
          </StyledCard>
        );
      })}
    </>
  );
};
