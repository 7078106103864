import styled from 'styled-components';

export const PageActionsRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(1, 0, 0)};
`;

export const FieldRoot = styled.div<{ $hintMargin?: boolean, $topbottomPadding?: number }>`
  ${({ theme, $hintMargin, $topbottomPadding }) => ({
    padding: theme.spacing($topbottomPadding ?? 0, 0.5),
    [theme.breakpoints.up('lg')]: {
      ...($hintMargin && { marginBottom: theme.spacing(2.5) }),
    },
  })}
`;
