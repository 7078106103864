import { AutocompleteFieldItem } from '../../components/Field/AutocompleteField/AutocompleteField';

export interface Approver {
  id: number;
  geid: string;
  firstName: string;
  lastName: string;
  email: string;
}

export const getFullName = (user?: Partial<Approver>): string =>
  user ? `${user.firstName} ${user.lastName}` : '...';

export const getFullNameWithGeid = (user?: Partial<Approver>): string =>
  user ? `${getFullName(user)} ${user?.geid ? `(${user.geid})` : ''}` : '...';

export const mapToAutocompleteFieldItems = (
  users: Partial<Approver>[]
): AutocompleteFieldItem[] =>
  users.map((user) => ({
    id: user.email,
    name: getFullNameWithGeid(user),
  }));
