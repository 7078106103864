import { Accordion, AccordionProps } from './Accordion';
import { SpaceTop } from './AccordionList.style';

export interface AccordionListProps {
  accordions: AccordionProps[];
  spaceBetween?: boolean;
  defaultExpandedFirst?: boolean;
}

export const AccordionList = ({
  accordions,
  spaceBetween = false,
  defaultExpandedFirst = false,
}: AccordionListProps) => {
  const isExpanded = (index: number): boolean =>
    defaultExpandedFirst && index === 0;

  return (
    <>
      {accordions.map((accordion, i) =>
        spaceBetween ? (
          <SpaceTop key={i} $marginTop={i !== 0}>
            <Accordion key={i} defaultExpanded={isExpanded(i)} {...accordion} />
          </SpaceTop>
        ) : (
          <Accordion key={i} defaultExpanded={isExpanded(i)} {...accordion} />
        )
      )}
    </>
  );
};
