import { CSSProperties } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionActions,
  AccordionActionsProps,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
} from '@material-ui/core';

import { Card, CardProps } from 'components/Page/Card/Card';

export const StyledCard = styled(Card)<CardProps>`
  width: 100%;
  box-sizing: border-box;
`;

export const Header = styled.div<{ $rightGap?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 56px;
  margin: ${({ theme, $rightGap }) =>
    theme.spacing(0, $rightGap ? 6 : 2, 0, 2)};
`;

export const Actions = styled(Header)`
  justify-content: flex-end;
`;

export const StyledAccordion = styled(Accordion)<AccordionProps>`
  margin: 0;
  padding: 0;
`;

export const StyledAccordionSummary = styled(
  AccordionSummary
)<AccordionSummaryProps>`
  margin: 0;
  padding: 0;
  .MuiAccordionSummary-content {
    margin: 0;
    padding: 0;
  }
`;

export const StyledAccordionDetails = styled(
  AccordionDetails
)<AccordionDetailsProps>`
  flex-direction: column;
`;

export const StyledAccordionActions = styled(
  AccordionActions
)<AccordionActionsProps>`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const inputButtonStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '12px',
};
