import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { Company, CompanyFindQuery, CompanyInfoDTO } from 'Dictionary/model/Company';
import { BusinessUnit } from 'Dictionary/model/BusinessUnit';
import { Language } from 'Dictionary/model/Language';
import { PGUnit } from 'Dictionary/model/PGUnit';
import { Division } from 'Dictionary/model/Division';
import { EmployeeJob, EmployeeJobFamily } from 'Dictionary/model/EmployeeJob';
import { BusinessUnitContact } from 'Dictionary/model/BusinessUnitContact';
import { CertificateFunction } from 'Dictionary/model/CertificateFunction';
import { WorkType } from 'Dictionary/model/WorkType';
import { CompanyChannel } from 'Dictionary/model/CompanyChannel';
import { Country } from 'Dictionary/model/Country';
import { CompanyFilterParams } from 'Dictionary/model/CompanyFilterParams';
import { MyProfile } from 'MyProfile/model/MyProfile';
import { TrainingManagersFiltersDto } from 'Dictionary/model/TrainingManagersFiltersDto';
import { Approver } from 'Dictionary/model/Approver';
import { ScopeDetailsParams } from 'Dictionary/model/ScopeDetailsParams';
import { ScopeDetail, ScopeKeyword, ScopeDetailFindQuery } from 'Dictionary/model/ScopeDetail';
import { PrintingConfigurationDTO } from 'page/PrintingCertificates/PrintingHeaders/model';

import { PaginatedList, UserDetails, PaginationDto } from 'model';
import fileDownload from 'js-file-download';
import { DigitalSignatureDto, DigitalSignatureFindQuery, PrintingStandardTextDTO, PrintingStandardTextsFindQuery } from 'page/PrintingCertificates/model';

const domainPath = '/management-structure';

const getCompanies = async (): Promise<Company[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/user-companies`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getCompanyChannels = async (): Promise<CompanyChannel[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/company-channels`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getManagers = async (query: string): Promise<Partial<MyProfile>[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/managers`, {
      params: { query },
    });
    return response.data.result;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getTrainingManagers = async (
  dto: TrainingManagersFiltersDto
): Promise<Partial<MyProfile>[]> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/training-managers`,
      dto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getEmployeeJobs = async (): Promise<EmployeeJob[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/functional-jobs`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getEmployeeJobFamilies = async (): Promise<EmployeeJobFamily[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/functional-job-families`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getBusinessUnits = async (
  divisionid: Division['id']
): Promise<BusinessUnit[]> => {
  try {
    const response = await apiClient.get(
      `${domainPath}/business-units/published?divisionId=${divisionid}`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getActiveBusinessUnits = async (
  divisionid: Division['id']
): Promise<BusinessUnit[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/business-units?divisionId=${divisionid}`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getPgUnits = async (
  businessUnitId: BusinessUnit['id']
): Promise<PGUnit[]> => {
  try {
    const response = await apiClient.get(
      `${domainPath}/pg-units?businessUnitId=${businessUnitId}`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getPreferredLanguages = async (): Promise<Language[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/languages`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getLanguagesByBusinessUnit = async (
  buId: number
): Promise<Language[]> => {
  try {
    const response = await apiClient.get(
      `${domainPath}/business-units/${buId}/languages`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getBusinessUnitContacts = async (): Promise<BusinessUnitContact[]> => {
  try {
    const response = await apiClient.get(
      `${domainPath}/business-units/contacts`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getCertificateFunctions = async (): Promise<CertificateFunction[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/certificate-functions`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getWorkTypes = async (): Promise<WorkType[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/work-types`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getDivisions = async (): Promise<Division[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/divisions`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getCountries = async (): Promise<Country[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/countries`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getCompaniesWithFilter = async (
  params: CompanyFilterParams
): Promise<Company[]> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/companies/filter`,
      params
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const companyFind = async (
  query: CompanyFindQuery
): Promise<PaginatedList<CompanyInfoDTO>> => {
  try {
    const response = await apiClient.post(
      `admin/company/find`,
      query
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const companyDelete = async (
  companyId: number
): Promise<unknown> => {
  try {
    const response = await apiClient.delete(
      `admin/company/${companyId}`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};
const companyAdd = async (
  command: CompanyInfoDTO
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `admin/company/`,
      command
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};
const companyGet = async (
  companyId: number
): Promise<CompanyInfoDTO> => {
  try {
    const response = await apiClient.get(
      `admin/company/${companyId}`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const companyUpdate = async (
  command: CompanyInfoDTO
): Promise<unknown> => {
  try {
    const response = await apiClient.put(
      `admin/company/${command.id}`,
      command
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getScopeDetails = async (
  params: ScopeDetailsParams
): Promise<ScopeDetail[]> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/scope-details`,
      params
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getApprovers = async (): Promise<Approver[]> => {
  try {
    const response = await apiClient.get('/pending-approvals/approvers');
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const dictionaryApi = {
  getCompanies,
  getManagers,
  getTrainingManagers,
  getEmployeeJobs,
  getEmployeeJobFamilies,
  getBusinessUnits,
  getActiveBusinessUnits,
  getPgUnits,
  getDivisions,
  getPreferredLanguages,
  getLanguagesByBusinessUnit,
  getBusinessUnitContacts,
  getCertificateFunctions,
  getWorkTypes,
  getCompanyChannels,
  getCountries,
  getCompaniesWithFilter,
  companyFind,
  getApprovers,
  getScopeDetails,


  company: {
    ...makeDefaultApi<CompanyInfoDTO, CompanyFindQuery>({ basePath: "admin/company/" }),

    deleteBatch: async (ids: (string | number)[]): Promise<unknown> => {
      try {

        const prms = new URLSearchParams(ids.map(s => ['ids', s.toString()])).toString()
        const fullUrl = `admin/company/delete/?${prms}`
        const response = await apiClient.delete(fullUrl);
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },

    validateCompanyName: async (command: CompanyInfoDTO) => {
      try {
        const response = await apiClient.post(
          `admin/company/find`,
          command
        );
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },
  },

  scopeKeyword: {
    ...makeDefaultApi<ScopeKeyword, ScopeDetailFindQuery>({ basePath: "admin/scope-keyword/" }),
  },

  other: {
    findDateFormats: async (): Promise<{ id: number, value: string }[]> => {
      try {
        const result = [
          { id: 20000, value: "M/d/yy" },
          { id: 20001, value: "MM/dd/yy" },
          { id: 20002, value: "M/d/yyyy" },
          { id: 20003, value: "MM/dd/yyyy" },
          { id: 20004, value: "MMM d, yyyy" },
          { id: 20005, value: "MMMM dd, yyyy" },
          { id: 20006, value: "dddd, MMMM d, yyyy" },
        ]
        return await Promise.resolve(result);

      } catch (e) {
        throw new ApiError(e);
      }
    },
  },

  digitalSignature: {
    ...makeDefaultApi<DigitalSignatureDto, DigitalSignatureFindQuery>({ basePath: "admin/signature/" }),

    findSigner: async (query: { byText: string }): Promise<UserDetails[]> => {
      try {
        const response = await apiClient.post(
          `admin/signature/find-signer`,
          query
        );
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },

  },

  IPrintingCertificatesContract: {
    ...makeDefaultApi<PrintingStandardTextDTO, PrintingStandardTextsFindQuery>({ basePath: "admin/printing-standard/" }),

    preview: async (query: { standard: PrintingStandardTextDTO, forExternal: boolean }): Promise<unknown> => {
      try {

        const response = await apiClient.get(
          `admin/printing-standard/${query.standard.id}/preview?forExternal=${query.forExternal ? "true" : "false"}`,
          { responseType: 'blob' }
        );
        fileDownload(response.data, 'preview.pdf');
        return undefined;
      } catch (e) {
        throw new ApiError(e);
      }
    },

    getSettings: async (query: { division: DictionaryItemByCodeDTO }): Promise<PrintingConfigurationDTO> => {
      try {
        const response = await apiClient.get(
          `admin/printing-standard/settings/${query.division.id}`
        );
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },

    updateSettings: async (command: PrintingConfigurationDTO): Promise<unknown> => {
      try {
        const response = await apiClient.put(
          `admin/printing-standard/settings/${command.division?.id}`,
          command
        );
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },
  }
};

export const defaultApiClient = apiClient
export function makeDefaultApi<
  TModelDTO extends { id: number },
  TQueryDTO extends PaginationDto
>(p: { basePath: string }): ApiContract<TModelDTO, TQueryDTO> {
  return {
    get: async (id: string | number): Promise<TModelDTO> => {

      try {
        const response = await apiClient.get(
          `${p.basePath}${id}`
        );
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },

    find: async (query: TQueryDTO): Promise<PaginatedList<TModelDTO>> => {
      try {
        const response = await apiClient.post(
          `${p.basePath}find`,
          query
        );
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },

    add: async (command: TModelDTO) => {
      try {
        const response = await apiClient.post(
          `${p.basePath}`,
          command
        );
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },
    update: async (command: TModelDTO): Promise<unknown> => {
      try {
        const response = await apiClient.put(
          `${p.basePath}${command.id}`,
          command
        );
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },
    delete: async (id: string | number | TModelDTO): Promise<unknown> => {
      try {
        const itemId = (typeof id === "string" || typeof id === "number") ? id : id.id;
        const response = await apiClient.delete(
          `${p.basePath}${itemId}`
        );
        return response.data;
      } catch (e) {
        throw new ApiError(e);
      }
    },
  }
}