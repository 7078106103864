import { CSSProperties } from 'react';
import { fade, darken } from '@material-ui/core/styles';
import { createAbbTheme, Shades } from './createAbbTheme';

declare global {
  interface ButtonProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
}

const fontFamilyBase: string = ['abbvoice', 'sans-serif'].join(', ');

const dividerColor: CSSProperties['borderColor'] = 'rgba(0, 0, 0, 0.12)';

const greys = {
  50: '#fafafa',
  100: '#f0f0f0',
  200: '#d2d2d2',
  300: '#a9a9a9',
  400: '#6e6e6e',
  500: '#9e9e9e',
  600: '#4a4a4a',
  700: '#262626',
};

const shades: Shades = {
  gray06_t: 'rgba(0, 0, 0, .06)',
  gray05_t: 'rgba(0, 0, 0, .02)',
  red05_t: 'rgba(255, 0, 15, .05)',
  red10_t: 'rgba(255, 0, 15, .1)',
  red80_t: 'rgba(255, 0, 15, .8)',
  red1_10: '#e5000d',
};

type FontWeightsType = {
  light: CSSProperties['fontWeight'];
  regular: CSSProperties['fontWeight'];
  medium: CSSProperties['fontWeight'];
  bold: CSSProperties['fontWeight'];
};
 // eslint-disable-next-line
const fontWeights: any = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

const mainColor: string = '#ff000f';
const whiteBase: string = '#fff';

const green = {
  main: '#007A33',
  light: '#E5F1EA',
};

const yellow = {
  main: '#FFD100',
  light: '#FFFBE5',
};

const orange = {
  main: '#FF7300',
  light: '#FFEDDB',
};

const red = {
  main: mainColor,
  light: '#FFE5E6',
};

const blue = {
  main: '#004C97',
  light: '#E5EDF4',
};

const spacing = (x: number): number => 8 * x;
const createBorder = (
  color: CSSProperties['borderColor'],
  size = 1
): CSSProperties['border'] => `${size}px solid ${color}`;

//@ts-ignore
export const theme = createAbbTheme({
  palette: {
    primary: { main: greys[600] },
    secondary: {
      light: fade(mainColor, 0.64),
      main: mainColor,
      dark: darken(mainColor, 0.34),
    },
    background: { paper: whiteBase, default: whiteBase },
    error: {
      light: fade(mainColor, 0.12),
      main: mainColor,
      dark: darken(mainColor, 0.34),
      contrastText: mainColor,
    },
    blue,
    green,
    yellow,
    orange,
    red,
    grey: { ...greys },
    shades: { ...shades },
    text: {
      primary: '#000',
      secondary: '#4a4a4a',
      disabled: '#a9a9a9',
      hint: '#a9a9a9',
    },
  },
  typography: {
    fontFamily: fontFamilyBase,
    fontSize: 16,
    fontWeightLight: fontWeights.light,
    fontWeightRegular: fontWeights.regular,
    fontWeightMedium: fontWeights.medium,
    fontWeightBold: fontWeights.bold,
    subtitle1: {
      fontWeight: fontWeights.medium,
    },
    subtitle2: {
      fontWeight: fontWeights.medium,
    },
    h1: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      fontWeight: fontWeights.medium,
    },
    h2: {
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
      fontWeight: fontWeights.medium,
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: fontWeights.medium,
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: fontWeights.medium,
    },
    h5: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: fontWeights.medium,
    },
    h6: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: fontWeights.medium,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    button: {
      fontSize: '0.875rem',
      lineHeight: '1rem',
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#000',
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        marginTop: 16,
      },
      dayLabel: {
        fontWeight: fontWeights.medium,
        color: greys[400],
      },
      switchHeader: {
        borderBottom: createBorder(greys[100]),
      },
    },
    MuiPickersDay: {
      day: {
        color: greys[400],
      },
      daySelected: {
        backgroundColor: mainColor,
      },
      current: {
        border: createBorder(greys[200]),
        color: greys[400],
      },
    },
    MuiTypography: {
      h4: {
        fontFamily: fontFamilyBase,
      },
      body2: {
        fontFamily: fontFamilyBase,
      },
    },
    MuiButton: {
      root: {
        fontFamily: fontFamilyBase,
        fontSize: 14,
        height: spacing(4.5),
      },
      sizeLarge: {
        height: spacing(5),
      },
      sizeSmall: {
        height: spacing(3.5),
      },
    },
    MuiIconButton: {
      root: {
        color: greys[700],
      },
    },
    MuiSvgIcon: {
      root: {
        width: '1.5rem',
        height: '1.5rem',
      },
      colorPrimary: {
        color: greys[700],
      },
      colorSecondary: {
        color: mainColor,
      },
      fontSizeSmall: {
        width: '1rem',
        height: '1rem',
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: 16,
        color: greys[700],
        fontWeight: fontWeights.medium,
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
    },
    MuiDialogContent: {
      root: {
        fontFamily: fontFamilyBase,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
    },
    MuiDialogContentText: {
      root: {
        fontSize: 14,
        color: greys[600],
        fontWeight: fontWeights.regular,
        marginBottom: spacing(3),
      },
    },
    MuiTableSortLabel: {
      icon: {
        width: '1rem',
        height: '1rem',
      },
    },
    MuiDialogActions: {
      root: {
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
      },
    },
    MuiSnackbar: {
      root: {
        backgroundColor: greys[700],
        borderRadius: 0,
      },
    },

    MuiTextField: {
      root: {
        marginBottom: spacing(2),
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: fontFamilyBase,
        fontWeight: 500,
        transform: 'translate(0, 1.5px) scale(0.75) !important',
      },
      asterisk: {
        color: mainColor,
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: whiteBase,
        maxHeight: 64,
      },
      colorDefault: {
        backgroundColor: whiteBase,
      },
    },
    MuiAccordion: {
      root: {
        fontFamily: fontFamilyBase,
        border: createBorder(dividerColor, 1),
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 2,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: shades.gray06_t,
        height: spacing(3.5),
        padding: spacing(0.5),
      },
      label: {
        fontSize: spacing(1.5),
        fontWeight: fontWeights.medium,
      },
      deleteIcon: {
        height: spacing(2.5),
        width: spacing(2.5),
      },
    },
    MuiDropzoneArea: {
      root: {
        color: greys[600],
      },
      icon: {
        color: greys[600],
      },
    },
    MuiDropzonePreviewList: {
      removeButton: {
        color: greys[600],
        backgroundColor: shades.gray05_t,
        '&:hover': {
          backgroundColor: shades.gray05_t,
        },
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 0,
      },
      standardSuccess: {
        border: createBorder(green.main),
      },
      standardInfo: {
        border: createBorder(blue.main),
      },
      standardWarning: {
        border: createBorder(yellow.main),
      },
      standardError: {
        border: createBorder(red.main),
      },
    },
    MuiAutocomplete: {
      groupLabel: {
        color: greys[400],
        fontWeight: fontWeights.regular,
        fontSize: 12,
      },
    },
    MuiCheckbox: {
      root: {
        color: greys[400],
        '&$checked': {
          color: `${mainColor} !important`,
        },
        '&$disabled': {
          color: `${greys[200]} !important`,
        },
      },
      checked: {},
      disabled: {},
    },
    MuiTableRow: {
      root: {
        border: `1px solid ${greys[200]}`,
      },
    },
    MuiTableCell: {
      root: {
        border: 'none',
      },
      head: {
        fontSize: 12,
        color: greys[600],
        width: '100%',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          backgroundColor: greys[50],
        },
        '&$focused': {
          backgroundColor: greys[50],
        },
        '&$disabled': {
          backgroundColor: 'transparent',
          color: greys[300],
        },
        marginTop: 20,
        fontSize: 16,
        lineHeight: '22px',
        padding: '8px 12px',
        border: `1px solid ${greys[100]}`,
        borderRadius: '2px',
        backgroundColor: 'transparent',
      },
      notchedOutline: {
        border: 0,
      },
      input: {
        backgroundColor: 'transparent',
        height: 22,
        padding: 0,
      },
    },
    MuiSelect: {
      icon: {
        right: spacing(2),
      },
      select: {
        backgroundColor: 'inherit !important',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: fontWeights.bold,
        '&$disabled': {
          color: greys[600],
        },
      },
      asterisk: {
        color: mainColor,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 0,
      },
      label: {
        '@media (max-width: 480px)': {
          fontSize: 14,
        },
        '&$disabled': {
          color: greys[300],
        },
      },
      disabled: {},
    },
    MuiFormHelperText: {
      root: {
        display: 'flex',
        position: 'relative',
        fontWeight: fontWeights.medium,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 14,
        fontFamily: fontFamilyBase,
      },
      multiline: {
        marginTop: spacing(0.25),
        marginBottom: spacing(0.25),
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: spacing(5),
      },
    },
    MuiDrawer: {
      paper: {
        width: 256,
      },
      root: {
        zIndex: 1000,
      },
    },
    MuiFilledInput: {
      root: {
        minHeight: 40,
        marginTop: 20,
        borderRadius: '2px !important',
        paddingTop: '0 !important',
        border: `1px solid rgba(0,0,0,0.34)`,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: greys[50],
        },
        '&$focused': {
          backgroundColor: greys[50],
        },
        '&$disabled': {
          backgroundColor: 'transparent',
          color: greys[300],
        },
        '&$error': {
          border: `1px solid ${mainColor}`,
        },
      },
      multiline: {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
      },
      input: {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
      },
      underline: {
        '&::before': {
          display: 'none',
        },
        '&::after': {
          display: 'none',
        },
      },
      adornedStart: {
        paddingLeft: 0,
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
  },
  props: {
    MuiTextField: {
      fullWidth: true,
      variant: 'filled',
    },
    MuiCard: {},
  },
});
