import { useState } from 'react';
import { ActionsRoot, Button, Card, Stack } from 'components';
import { CertificationListForSelectionPart } from './CertificationListForSelectionPart';
import { CertificateLookupForField } from './CertificateLookupForField';

export function RecommendedCardPart() {

    const [show, setShow] = useState(false);
    const togleShow = () => setShow(prev => !prev)

    return (
        <Stack>
            <Card header="Recommended Certificates">
                <CertificateLookupForField name='recommended'
                    noDataMessage={<Stack>
                        <span>User will be automatically registered for recommended Certificates</span>
                        <span>Currently there are no recommended Certificates set up. Click “Add recommended” button to add one.</span>
                    </Stack>} />
            </Card>
            {show ? <CertificationListForSelectionPart name='recommended' /> : <ActionsRoot>
                <Button color="secondary" onClick={togleShow}>ADD RECOMMENDED</Button>
            </ActionsRoot>}
        </Stack>
    );
}
