import { useState } from 'react';

export interface UseCollapseRowActions<Actions> {
  collapseRecords: Record<number, Actions>;
  closeRow: (rowId: number) => void;
  openRow: (rowId: number, action: Actions) => void;
}
export function useCollapseRowActions<
  Actions
>(): UseCollapseRowActions<Actions> {
  const [collapseRecords, setCollapseRecords] = useState<
    Record<number, Actions>
  >({});

  const closeRow = (rowId: number) =>
    setCollapseRecords((prevCollapseRecords) =>
      Object.keys(prevCollapseRecords)
        .filter((key) => key !== rowId.toString())
        .reduce(
          (record, key) => ({
            ...record,
            [Number(key)]: (prevCollapseRecords as Record<number, Actions>)[
              Number(key)
            ],
          }),
          {}
        )
    );

  const openRow = (rowId: number, action: Actions) =>
    setCollapseRecords((prevState) => ({ ...prevState, [rowId]: action }));

  return { closeRow, openRow, collapseRecords };
}
