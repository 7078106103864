import { FC, useState } from 'react';

import { Card } from 'components/Page';
import { IsExternalType } from 'Dictionary/component';
import { EditInternalUserForm } from 'MyProfile/component/EditInternalUserForm';
import { UserRolesList } from 'MyProfile/component/UserRolesList';
import { ManageUserRole } from 'MyProfile/component/ManageUserRole/ManageUserRole';
import { TabsView, TabsViewProps } from 'components/TabsView';
import { MyProfile } from 'MyProfile/model/MyProfile';
import { EditExternalUserForm } from 'MyProfile/component/EditExternalUserForm';

const labels = ['Edit', 'Show roles'];
const adminLabels = [...labels, 'Manage Roles'];

export interface ManageUserTabsProps extends Omit<TabsViewProps, 'labels'> {
  isAdmin: boolean;
  user: MyProfile;
  isExternalType: IsExternalType;
}

export const ManageUserTabs: FC<ManageUserTabsProps> = ({
  user,
  isAdmin,
  isExternalType,
  ...tabViewProps
}) => {
  const [tab, setTab] = useState(0);
  return (
    <TabsView
      labels={isAdmin ? adminLabels : labels}
      tabIndex={tab}
      onChange={setTab}
      animateHeight={true}
      {...tabViewProps}
    >
      <Card transparent>
        {isExternalType === IsExternalType.External ? (
          <EditExternalUserForm user={user} />
        ) : (
          <EditInternalUserForm user={user} />
        )}
      </Card>
      <UserRolesList userId={user.id} removable={false} />
      <ManageUserRole user={user} isExternalType={isExternalType} />
    </TabsView>
  );
};
