import { FC, HtmlHTMLAttributes } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useOverlayLoaderStyles } from './OverlayLoader.style';

export interface OverlayLoaderProps extends HtmlHTMLAttributes<HTMLDivElement> {
  mutating?: boolean;
  label?: string;
}

export const OverlayLoader: FC<OverlayLoaderProps> = (props) => {
  const { className, mutating = false, label, children, ...other } = props;
  const classes = useOverlayLoaderStyles(props);
  return (
    <div className={clsx(className, classes.root)} {...other}>
      {mutating && (
        <div className={classes.overlay}>
          <div className={classes.content}>
            <CircularProgress size={48} color="secondary" />
            <Typography className={classes.label} variant="body1">
              {label}
            </Typography>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default OverlayLoader;
