import { MyProfile } from 'MyProfile/model/MyProfile';
import { EditUserDto } from 'MyProfile/model/EditUserDto';

export interface AddInternalDto extends EditUserDto {
  email: string;
  geid: string;
}

export const mapUsersToDto = (users: MyProfile[]): AddInternalDto[] =>
  users.map((user) => ({
    email: user.email,
    geid: user.geid,
    notes: user.notes,
    city: user.city,
    state: user.state,
    managerGeid: user.manager.geid,
    managerEmail: user.manager.email,
    functionalmanagerGeid: user.functionalManager.geid,
    functionalmanagerEmail: user.functionalManager.email,
    divisionId: user.division?.id,
    businessUnitId: user.businessUnit.id,
    pgUnitId: user.pgUnit?.id ?? null,
    companyId: user.company?.id ?? null,
    employeeJobsIds: (user.employeeJobs ?? []).map((job) => job.id),
    employeeJobFamiliesIds: (user.employeeJobFamilies ?? []).map((job) => job.id),
    preferredLanguagesIds: (user.preferredLanguages ?? []).map(
      (lang) => lang.id
    ),
  }));
