import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Content, Card } from 'components/Page';
import { Anchor } from 'components/Page/Anchor/Anchor';
import { TrainingMoviesList } from 'HelpAndSupport/component/TrainingMoviesList/TrainingMoviesList';
import { RolesInPcsTable } from 'HelpAndSupport/component/RolesInPcsTable/RolesInPcsTable';
import { BusinessUnitContacts } from 'HelpAndSupport/component/BusinessUnitContacts/BusinessUnitContacts';
import { StyledSubheaderTypography } from './HelpAndSupportPage.style';

const submitIncidentUrl = 'https://abb.service-now.com/myservices?id=myis';

export const HelpAndSupportPage: FC = () => (
  <Content
    title="Help and support"
    renderSubheader={() => (
      <Anchor href={submitIncidentUrl}>
        <ArrowForwardIcon />
        <StyledSubheaderTypography variant="body1">
          Submit incident/request
        </StyledSubheaderTypography>
      </Anchor>
    )}
  >
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={8}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={12} lg={6}>
            <Card header="How to...?">
              <HowToList />
            </Card>
          </Grid> */}
          <Grid item md={12}>
            <Card header="How to...?">
              <TrainingMoviesList />
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Card header="Roles in PCS2">
              <RolesInPcsTable />
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <Card header="Division contacts" transparent>
          <BusinessUnitContacts />
        </Card>
      </Grid>
    </Grid>
  </Content>
);
