import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { Content, Card } from 'components/Page';
import { SearchCertificates } from 'Catalog/component/SearchCertificates';
import { catalogColumns } from 'Catalog/component/CatalogCertificateTable';
import { catalogDetailsRoute } from 'route';

const catalogTablePageSize = 20;
const getDetailsRoute = (certificationId: number): string =>
  catalogDetailsRoute.createRoute({ certificationId: String(certificationId) });

export const CatalogPage = () => {
  const handleOpenDetails = (certificationId: number) => () =>
    window.open(
      `${window.location.origin}${getDetailsRoute(certificationId)}`,
      '_blank'
    );

  return (
    <SearchCertificates
      initialPageSize={catalogTablePageSize}
      DataTableProps={{
        columns: catalogColumns,
        renderRowActions: (row) => (
          <IconButton onClick={handleOpenDetails(row.id)}>
            <NavigateNextIcon />
          </IconButton>
        ),
      }}
    >
      {(renderSearchFilters, renderTable, data) => (
        <Content
          title="Catalog"
          subtitle="Here you can find all certificates available for you"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card transparent>{renderSearchFilters()}</Card>
            </Grid>
            <Grid item xs={12}>
              <Card header={`Search results: ${data?.total ?? '...' }`} transparent>
                {renderTable()}
              </Card>
            </Grid>
          </Grid>
        </Content>
      )}
    </SearchCertificates>
  );
};
