import { Dispatch } from 'react'
import { FluidForEditors, SearchFiltersFormSmart } from 'components'
import { FormikTextField, DictionaryFieldByCode } from 'components/FormikField';

import * as Model from './model'

export default function SearchForm(p: {
  defaultQuery: Model.CompanyFindQuery
  onQuery: Dispatch<Model.CompanyFindQuery>
}) {

  return (
    <SearchFiltersFormSmart
      searchBarField='name'
      placeholder="Search by company name and hit enter..."
      initialValues={p.defaultQuery}
      onSubmit={p.onQuery}
    >
      {(fp) => (
        <>
          <FluidForEditors inRow={2}>
            <DictionaryFieldByCode name="businessArea" label="Business Area" query={{ key: "BusinessArea" }} dependandFieldName="divisions" />
            <DictionaryFieldByCode name="divisions" label="Division" multiple query={{ key: 'Division', businessArea: fp.values.businessArea }} />

            <DictionaryFieldByCode name="country" label="Country/Territory" query={{ key: "Country" }} />
            <FormikTextField name="city" label="City" autoComplete="off" />

            <DictionaryFieldByCode name="channels" label="Channel type" multiple query={{ key: "Channel" }} />
            <DictionaryFieldByCode name="isAbb" label="ABB / External" query={{ key: "UserType" }} />
          </FluidForEditors>
        </>

      )
      }
    </SearchFiltersFormSmart>
  )
}
