import {
  Typography,
  CardContent,
  Grid,
  GridSize,
  useTheme,
} from '@material-ui/core';
import { DataField } from 'components/DataField';
import { CardProps } from 'components/Page/Card/Card';
import { memo, ReactNode, useMemo } from 'react';
import { StyledCard, Header, Actions } from './DataObjectView.style';

export type DataObjectGridSize = boolean | GridSize | undefined;

export interface DataObjectViewProps<O> extends CardProps {
  title?: string;
  item: O;
  dataSetMaps?: DataObjectMap<O>[];
  renderExtraContent?: () => ReactNode;
  renderActions?: () => ReactNode;
  lg?: DataObjectGridSize;
  md?: DataObjectGridSize;
  sm?: DataObjectGridSize;
  xl?: DataObjectGridSize;
  xs?: DataObjectGridSize;
}

export interface DataObjectMap<O> {
  field: keyof O;
  headerName?: string;
  width?: number;
  renderField?: (set: O) => ReactNode;
}

const camelCaseToText = (camel: string): string => {
  let result = camel.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`);
  result = result.charAt(0).toUpperCase() + result.slice(1);
  return result;
};

export function DataObjectView<O = {}>(props: DataObjectViewProps<O>) {
  const {
    title,
    item,
    dataSetMaps = null,
    renderActions,
    renderExtraContent,
    lg = 4,
    md = 4,
    sm = 6,
    xl = 4,
    xs = 12,
    ...other
  } = props;
  const theme = useTheme();
  const map = useMemo<DataObjectMap<O>[]>(
    () =>
      dataSetMaps ??
     //@ts-ignore
      Object.keys(item).map(
        (key) =>
          ({
            field: key,
            headerName: camelCaseToText(key),
          } as DataObjectMap<O>)
      ),
    [dataSetMaps]
  );
  const actions = renderActions ? renderActions() : null;
  return (
    <StyledCard variant="outlined" {...other}>
      {title && (
        <Header $rightGap>
          <Typography style={{ fontWeight: theme.typography.fontWeightBold }}>
            {title}
          </Typography>
        </Header>
      )}
      <CardContent>
        <Grid container spacing={3}>
          {map.map((s) => {
            const key = String(s.field ?? s.headerName);
            const value = s.renderField
              ? s.renderField(item) ?? '...'
              : item[s.field];
            return (
              <Grid key={key} item lg={lg} md={md} sm={sm} xl={xl} xs={xs}>
                <DataField label={s?.headerName ?? ''} value={value} />
              </Grid>
            );
          })}
          {renderExtraContent && (
            <Grid item xs={12}>
              {renderExtraContent()}
            </Grid>
          )}
        </Grid>
      </CardContent>
      {actions && <Actions>{actions}</Actions>}
    </StyledCard>
  );
}

export default memo(DataObjectView);
