import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ColumnDefinition, ColumnValue, ReportPageTypes } from 'Reports/model';
import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress, TextFieldProps } from '@material-ui/core';
import { useToasts } from 'components/ToastProvider';
import { FilterChangeHandler, ReportingFilters } from './@types';
import { useReportingFieldDisabled } from '../hooks';
import { reportsApi } from '../service/reports.api';

export interface ReportingAutocomplateProps {
  columnDefinition: ColumnDefinition;
  fieldProps: TextFieldProps;
  onFilterChange: FilterChangeHandler;
  filters: ReportingFilters;
  reportType: ReportPageTypes;
}

export const ReportingAutocomplate = (props: ReportingAutocomplateProps) => {
  const {
    columnDefinition,
    fieldProps,
    onFilterChange,
    filters,
    reportType,
  } = props;
  const multiple = Boolean(columnDefinition.type.select?.multiple);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<ColumnValue[]>([]);
  const [options, setOptions] = useState<ColumnValue[]>([]);
  useEffect(() => {
    const values = filters[columnDefinition?.cid] as number[];
    if (values) {
      setValues(options.filter((o) => values.some((x) => o.vid === x)));
    } else {
      setValues([]);
    }
  }, [filters]);
  const disabled = useReportingFieldDisabled(columnDefinition, filters);
  const { showError } = useToasts();
  const loadValues = useCallback(async () => {
    try {
      setLoading(true);
      const qs: ReportingFilters = {};
      columnDefinition.dependsOn?.forEach((dep) => {
        const filter = filters[dep];
        if (filter) {
          qs[dep] = filter;
        }
      });
      if (Object.keys(qs).length > 0 || columnDefinition.cid === "AA0GBAMEAgIPCgICBAsFBQ" || columnDefinition.cid === "CAgOCg0KAQQLDQUIBAQIDg") {
        const values = await reportsApi.getValues(
          reportType,
          columnDefinition.cid,
          qs
        );
        setOptions(values);
      }
    } catch (error) {
      showError(`Cannot load values for ${columnDefinition.name}`);
    } finally {
      setLoading(false);
    }
  }, [columnDefinition, reportType, filters]);
  useEffect(() => {
    if (Array.isArray(columnDefinition.values)) {
      setOptions(columnDefinition.values);
      return;
    }
    if (columnDefinition.valuesApi) {
      loadValues();
    }
  }, [columnDefinition, filters]);
  const handleItemsChange = (
    e: ChangeEvent<{}>,
    value: ColumnValue | ColumnValue[] | null
  ) => {
    if (Array.isArray(value)) {
      onFilterChange(
        columnDefinition.cid,
        value.map((x) => x.vid)
      );
      setValues(values);
      return;
    }
    if (value) {
      onFilterChange(columnDefinition.cid, [value.vid]);
    } else {
      onFilterChange(columnDefinition.cid, null);
    }
  };
  return (
    <Autocomplete
      disabled={disabled || loading}
      value={values}
      options={options}
      multiple={multiple}
      onChange={handleItemsChange}
      renderInput={(params) => (
        <TextField
          {...params}
          {...fieldProps}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="secondary" size={16} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      getOptionLabel={(option) => option?.label ?? ''}
      getOptionSelected={(option, value) => option?.vid === value?.vid}
    />
  );
};
