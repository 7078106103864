import {
  makeStyles,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Edit, Group, Delete } from '@material-ui/icons';
import { ApprovalGroup } from 'ApprovalGroupsPage/model/ApprovalGroup';
import { DataTable, DataTableColumn } from 'components/DataTable/DataTable';
import { useEffect, useState } from 'react';
import { AbbTheme } from 'theme/createAbbTheme';
import {
  BusinessUnit,
  toAutocomplateField,
} from 'Dictionary/model/BusinessUnit';
import { useMutation } from 'react-query';
import { useToasts } from 'components/ToastProvider';
import { useHistory } from 'react-router-dom';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import { DataObjectView } from 'components';
import { ApiError } from 'model';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import { useApprovalGroupsQuery } from '../query/approvalGroup.query';
import {
  addApprovalGroup,
  updateApprovalGroup,
  deleteApprovalGroup,
} from '../service/approvalGroup.api';
import { ApprovalGroupEdit, ApprovalGroupFormData } from './ApprovalGroupEdit';
import { ReactComponent as NotFoundSvg } from '../../components/QueryErrorResetBoundary/svg/NotFound.svg';
import { approvalGroupsDetailsRoute } from '../../route';

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    header: {
      display: 'flex',
      flexDirection: 'row',
    },
    empty: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    addGroup: {
      margin: theme.spacing(2, 0),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
  }),
  { name: 'ApprovalGroupsTable' }
);

export interface ApprovalGroupsTableProps {
  businessUnit: BusinessUnit | null;
}

const approvalGroupsColumns: DataTableColumn<ApprovalGroup>[] = [
  {
    field: 'businessUnit',
    headerName: 'Division',
    renderField: ({ businessUnit }) => businessUnit?.description ?? '',
    width: 240,
  },
  {
    field: 'description',
    headerName: 'Description',
  },
];

export const editApprovalGroupMutationKey: string =
  'edit-approval-group-mutation';

const mapFormDataToApprovalGroup = (
  values: ApprovalGroupFormData,
  approvalGroup: ApprovalGroup = {
    id: -1,
    isUsed: true,
    sequence: 0,
    description: '',
    businessUnit: null,
  }
): ApprovalGroup => ({
  ...approvalGroup,
  businessUnit: {
    id: Number(values?.businessUnit?.id ?? -1),
    description: values?.businessUnit?.name ?? '',
  },
  description: values?.description ?? approvalGroup?.description ?? '',
});

export const ApprovalGroupsTable = (props: ApprovalGroupsTableProps) => {
  const { businessUnit = null } = props;
  const [addMode, setAddMode] = useState(false);
  const classes = useStyles(props);
  const history = useHistory();
  const [rowToDelete, setRowToDelete] = useState<ApprovalGroup | null>(null);
  const { showSuccess, showError } = useToasts();
  const onError = async (error: ApiError) => showError(error.message);
  const {
    data: approvalGroups = [],
    isLoading,
    refetch,
  } = useApprovalGroupsQuery(businessUnit?.id ?? 0);
  useEffect(() => {
    if (businessUnit) {
      refetch();
    }
  }, [businessUnit]);
  const { mutate: addApprovalGroupMutation } = useMutation({
    mutationKey: editApprovalGroupMutationKey,
    mutationFn: addApprovalGroup,
    onSuccess: () => {
      refetch();
      showSuccess('Approval group was added');
    },    
    onError: () => {
      showError('Cannot add approval group');      
    },
  });
  const { mutate: updateApprovalGroupMutation } = useMutation({
    mutationKey: editApprovalGroupMutationKey,
    mutationFn: updateApprovalGroup,
    onSuccess: () => {
      refetch();
      showSuccess('Approval group was updated');
    },
    onError: () => {
      showError('Cannot update approval group');
    },
  });
  const {
    mutate: deleteApprovalGroupMutation,
    isLoading: isDeleteLoading,
  } = useMutation({
    mutationKey: editApprovalGroupMutationKey,
    mutationFn: deleteApprovalGroup,
    onSuccess: () => {
      refetch();
      showSuccess('Approval group was deleted');
    },
    onError
    // onError: () => {
    //   showError('Cannot delete approval group');
    // },
  });
  const handleOpenGroup = (row: ApprovalGroup) =>
    history.push(
      approvalGroupsDetailsRoute.createRoute({ groupId: String(row.id) })
    );

  return (
    <>
      {businessUnit && (
        <>
        {addMode && (
          <ApprovalGroupEdit
            initialValues={{
              businessUnit: toAutocomplateField(businessUnit),
              description: '',
            }}
            onSubmit={(values) =>
              addApprovalGroupMutation(mapFormDataToApprovalGroup(values))
            }
            className={classes.addGroup}
            title="Add approval group"
            onClose={() => setAddMode(false)}
          />
        )}
        <div className={classes.header}>
          <Typography variant="h6">{`Search results: ${
            approvalGroups?.length ?? 0
          }`}</Typography>
          <span style={{ flexGrow: 1 }} />
          {!addMode && (
            <Button color="secondary" onClick={() => setAddMode(true)}>
              + Add approval group
            </Button>
          )}
        </div>
        </>
      )      
      }
      {((businessUnit && approvalGroups.length) || isLoading) > 0 ? (
        <>
          <DesktopSection>
            <DataTable
              columns={approvalGroupsColumns}
              loading={isLoading}
              rows={approvalGroups ?? []}
              rowSelector={(row) => row.id}
              enableSort
              onSortRequest={(direction, fieldName) => 
                  approvalGroups.sort((a,b) => {                    
                    const left = a[fieldName];
                    const right = b[fieldName]; 
                    
                    if(left === null && right === null)  
                      return 0;
                    if(left === null)  
                      return direction === "asc" ? -1 : 1;
                    if(right === null)  
                      return direction === "asc" ? 1 : -1;

                    if(left > right)
                      return direction === "asc" ? 1 : -1;
                    if(left < right)
                      return direction === "asc" ? -1 : 1;
                    return 0;
                  })
              }
              renderCollapseRow={(row, collapseRow) => (
                <ApprovalGroupEdit
                  title="Edit group"
                  actionLabel="Save changes"
                  initialValues={{
                    businessUnit: toAutocomplateField(
                      row?.businessUnit ?? { id: 0, description: 'All' }
                    ),
                    description: row.description,
                  }}
                  onSubmit={(values) =>
                    updateApprovalGroupMutation(
                      mapFormDataToApprovalGroup(values, row)
                    )
                  }
                  onClose={collapseRow}
                />
              )}
              renderRowActions={(row, collapseRow) => (
                <>
                  <IconButton onClick={() => handleOpenGroup(row)}>
                    <Group />
                  </IconButton>
                  <IconButton onClick={() => setRowToDelete(row)}>
                    <Delete />
                  </IconButton>
                  <IconButton onClick={collapseRow}>
                    <Edit />
                  </IconButton>
                </>
              )}
              hideCollapseColumn
            />
          </DesktopSection>
          <MobileSection>
            <DataObjectList<ApprovalGroup>
              loading={isLoading}
              items={approvalGroups ?? []}
              renderDataObjectView={(item) => (
                <DataObjectView
                  title={item.businessUnit?.description ?? ''}
                  item={item}
                  dataSetMaps={mapToDataSetMap(approvalGroupsColumns, [
                    'businessUnit',
                  ])}
                  variant="elevation"
                  transparent
                />
              )}
              renderActions={(item) => (
                <>
                  <Button
                    onClick={() => deleteApprovalGroupMutation(item)}
                    disabled={isDeleteLoading}
                  >
                    Remove group
                  </Button>
                  <Button color="secondary" onClick={() => handleOpenGroup(item)}>
                    Details
                  </Button>
                </>
              )}
              renderDetails={(item) => (
                <ApprovalGroupEdit
                  title="Edit group"
                  actionLabel="Save changes"
                  initialValues={{
                    businessUnit: toAutocomplateField(
                      item?.businessUnit ?? { id: 0, description: 'All' }
                    ),
                    description: item.description,
                  }}
                  onSubmit={(values) =>
                    updateApprovalGroupMutation(
                      mapFormDataToApprovalGroup(values, item)
                    )
                  }
                  onClose={() => null}
                />
              )}
            />
          </MobileSection>
          <Dialog open={Boolean(rowToDelete)} onClose={() => setRowToDelete(null)}>
            <DialogTitle id="form-dialog-title">Delete approval group</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure to delete {rowToDelete?.description}{' '}
                {rowToDelete?.businessUnit
                  ? `(${rowToDelete.businessUnit.description})`
                  : ''}{' '}
                approval group?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setRowToDelete(null)} color="primary">
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  rowToDelete && deleteApprovalGroupMutation(rowToDelete);
                  setRowToDelete(null);
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <div className={classes.empty}>
          <NotFoundSvg />
          <Typography variant="h6">
            {businessUnit
              ? 'No approval groups for selected Division...'
              : 'Select Division first...'}
          </Typography>
        </div>
      )}
    </>    
  );
};
