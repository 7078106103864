import { apiClient } from '../../service';
import { ApiError } from '../../model/ApiError';
import {
  PendingApprovals,
  PendingApprovalsQuery,
  PendingApprovalsResults,
} from '../model';

const getPendingApprovals = async (
  query: Partial<PendingApprovalsQuery>
): Promise<PendingApprovals> => {
  try {
    const response = await apiClient.post('/pending-approvals', query);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const approvePendingApprovals = async (
  taskIds: number[], expirationDate?: string 
): Promise<PendingApprovalsResults> => {
  try {
    const response = await apiClient.post('/pending-approvals/mass-approve', {
      taskIds,
      expirationDate
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const rejectPendingApprovals = async (
  taskId: number
): Promise<PendingApprovalsResults> => {
  try {
    const response = await apiClient.post(
      `/pending-approvals/${taskId}/reject`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export { getPendingApprovals, approvePendingApprovals, rejectPendingApprovals };
