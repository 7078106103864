import { FC, ElementType } from 'react';
import { ListItemProps as MUIListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import {
  StyledListItem,
  ListItemTextTitle,
  ListItemTextRoot,
  StyledSecondaryText,
  StyledListItemText,
  StyledListItemIcon,
} from './ListItem.style';

export interface ListItemType {
  primaryText: string;
  secondaryText?: string;
  iconPlaceholder?: boolean;
  Icon?: ElementType;
  RightIcon?: ElementType;
  iconAsText?: boolean;
}

export interface ListItemProps extends ListItemType, MUIListItemProps {
  leftPadding?: 'tiny' | 'default';
}

export const ListItem: FC<ListItemProps> = ({
  primaryText,
  secondaryText,
  iconPlaceholder,
  Icon,
  RightIcon,
  iconAsText,
  leftPadding = 'tiny',
  ...listItemProps
}) => (
  <StyledListItem
    // issue: https://github.com/mui-org/material-ui/issues/14971
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {...(listItemProps as MUIListItemProps<any, any>)}
    $tinyLeftPadding={leftPadding === 'tiny'}
  >
    {Icon && !iconAsText && (
      <ListItemIcon>
        <Icon color={listItemProps.selected ? 'secondary' : 'primary'} />
      </ListItemIcon>
    )}
    {iconPlaceholder && <ListItemIcon />}
    {iconAsText ? (
      <StyledListItemText>
        <ListItemTextRoot>
          <ListItemTextTitle>{primaryText}</ListItemTextTitle>
          <StyledListItemIcon>{Icon && <Icon color="primary" />}</StyledListItemIcon>
        </ListItemTextRoot>
      </StyledListItemText>
    ) : (
      <StyledListItemText
        primary={primaryText}
        secondary={
          <StyledSecondaryText variant="caption">
            {secondaryText}
          </StyledSecondaryText>
        }
      />
    )}
    {RightIcon && (
      <ListItemIcon>
        <RightIcon color="primary" />
      </ListItemIcon>
    )}
  </StyledListItem>
);
