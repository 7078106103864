import { useToasts } from 'components/ToastProvider';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ColumnDefinition,
  ReportQuery,
  ReportQueryResponseFlat,
  ReportSettings,
} from 'Reports/model';
import { FilterScope } from '../model/FilterScope';
import { ReportPageTypes } from '../model/ReportPageTypes';
import { reportsApi } from '../service/reports.api';

export const filterKey = 'filterKey';

export const useFilterSettingsQuery = (
  options: UseQueryOptions<ReportSettings> = {}
) => {
  const { showError } = useToasts();
  return useQuery<ReportSettings>(filterKey, () => reportsApi.getFilters(), {
    ...options,
    onError: () => showError('Cannot load settings'),
  });
};

export const filterScopesKey = 'filterScopesKey';

export const useFilterScopesQuery = (pageId: ReportPageTypes) =>
  useQuery<FilterScope[]>([filterScopesKey, pageId], () =>
    reportsApi.getFilterScopes(pageId)
  );

export const reportColumnDefinitionsKey = 'reportColumnDefinitionsKey';

export const useReportColumnDefinitionsQuery = (pageId: ReportPageTypes) =>
  useQuery<ColumnDefinition[]>([reportColumnDefinitionsKey, pageId], () =>
    reportsApi.getColumns(pageId)
  );

export const filterCertificationPlansQueryKey =
  'reportFilterCertificationPlansKey';

export const useReportDataQuery = (
  query: ReportQuery,
  reportType: ReportPageTypes = ReportPageTypes.StatusOfCertifications,
  options = {}
) => {
  let api = null;
  switch (reportType) {
    case ReportPageTypes.StatusOfCertifications:
      api = () => reportsApi.getFilterCertificationPlans(query);
      break;
    case ReportPageTypes.UncompletedRequirements:
      api = () => reportsApi.getFilterCertificationPlans(query);
      break;
    case ReportPageTypes.KpiReports:
      api = () => reportsApi.getFilterCertificationPlans(query);
      break;
    case ReportPageTypes.CertificationsByPerson:
      api = () => reportsApi.getFilterCertificationPlans(query);
      break;
  }
  return useQuery<ReportQueryResponseFlat>(
    filterCertificationPlansQueryKey,
    api,
    { ...options }
  );
};
