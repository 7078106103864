import { FC, ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import { StatusCodes } from 'http-status-codes';

import { myCertificationsRoute } from 'route';
import { RoundButton } from 'components/Button';
import { DetailsAppShell } from 'components/AppShell';
import { ReactComponent as UnathorizedSvg } from 'components/QueryErrorResetBoundary/svg/Unathorized.svg';
import { ReactComponent as InternalServerErrorSvg } from 'components/QueryErrorResetBoundary/svg/InternalServerError.svg';
import { ReactComponent as NotFoundSvg } from 'components/QueryErrorResetBoundary/svg/NotFound.svg';
import { ReactComponent as ForbiddenSvg } from 'components/QueryErrorResetBoundary/svg/Forbidden.svg';
import { Root, MessageRoot } from './QueryErrorResetBoundary.style';

const handleGoBack = (): void =>
  window.location.replace(myCertificationsRoute.path);

const getErrorSvg = (errorCode: number): ReactNode => {
  switch (errorCode) {
    case StatusCodes.UNAUTHORIZED:
      return <UnathorizedSvg />;
    case StatusCodes.FORBIDDEN:
      return <ForbiddenSvg />;
    case StatusCodes.NOT_FOUND:
      return <NotFoundSvg />;
    case StatusCodes.INTERNAL_SERVER_ERROR:
    default:
      return <InternalServerErrorSvg />;
  }
};

export interface ErrorDetailsAppShellProps {
  errorCode: number;
  errorMsg: string;
  handleOnTryAgain?: () => void;
}

export const ErrorDetailsAppShell: FC<ErrorDetailsAppShellProps> = ({
  errorCode,
  errorMsg,
  handleOnTryAgain,
}) => (
  <DetailsAppShell title="Go back to homepage" goBack={handleGoBack}>
    <Root>
      {getErrorSvg(errorCode)}
      <MessageRoot>
        <Typography variant="h4">{errorMsg}</Typography>
      </MessageRoot>
      {handleOnTryAgain && (
        <RoundButton
          color="secondary"
          variant="contained"
          onClick={handleOnTryAgain}
        >
          Try again
        </RoundButton>
      )}
    </Root>
  </DetailsAppShell>
);
