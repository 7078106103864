import { FC } from 'react';
import { Formik, FormikConfig, FormikState } from 'formik';

import { FieldRoot } from 'components/SearchFiltersForm/SearchFiltersForm.style';
import {
  BusinessUnitSelectField,
  CompanyWithFiltersField,
  CountriesSelectWithAllField,
  ScopeSelectField,
} from 'Dictionary/component';
import { Button } from 'components';
import {
  AutocompleteField,
  AutocompleteFieldItem,
} from 'components/Field/AutocompleteField/AutocompleteField';
import { RoleType } from 'MyProfile/enum/RoleType';
import { ActionsRoot } from 'components/Page/ModalContent/ModalContent.style';
import { mapCompanyFilterParams } from 'Dictionary/component/CompanyWithFiltersField/CompanyWithFiltersField';
import { Loader } from 'components/Loader/Loader';

export interface RoleDetailsFormData {
  businessUnit: AutocompleteFieldItem;
  country: AutocompleteFieldItem;
  company: AutocompleteFieldItem;
  scope: AutocompleteFieldItem;
}

const buRoles: RoleType[] = [
  RoleType.CertificationAuthority,
  RoleType.CertificateBuilder,
  RoleType.Contact,
  RoleType.Reader,
  RoleType.Supervisor,
  RoleType.ABBTrainingManager,
  RoleType.ChannelPartnerManager,
  RoleType.PartnerSupervisor,
];
const countryRoles: RoleType[] = [
  RoleType.Reader,
  RoleType.Supervisor,
  RoleType.ABBTrainingManager,
  RoleType.ChannelPartnerManager,
  RoleType.PartnerSupervisor,
];
const companyRoles: RoleType[] = [
  RoleType.ABBTrainingManager,
  RoleType.ChannelPartnerManager,
  RoleType.PartnerSupervisor,
];
const scopeRoles: RoleType[] = [
  RoleType.Contact,
  RoleType.Supervisor,
];
const isValueCorrect = (
  value: AutocompleteFieldItem,
  role: RoleType,
  fieldRoles: RoleType[]
): boolean => (fieldRoles.includes(role) ? typeof value.id === 'number' : true);
const isAddRoleEnabled = (
  values: FormikState<RoleDetailsFormData>['values'],
  role: RoleType
): boolean =>
  isValueCorrect(values.businessUnit, role, buRoles) &&
  isValueCorrect(values.country, role, countryRoles) &&
  isValueCorrect(values.company, role, companyRoles) &&
  isValueCorrect(values.scope, role, scopeRoles);

export interface RoleDetailsFormProps {
  role: RoleType;
  loading: boolean;
  onSubmit: FormikConfig<RoleDetailsFormData>['onSubmit'];
}

export const RoleDetailsForm: FC<RoleDetailsFormProps> = ({
  role,
  loading,
  onSubmit,
}) => (
  <Formik
    initialValues={{
      businessUnit: {} as AutocompleteFieldItem,
      country: {} as AutocompleteFieldItem,
      company: {} as AutocompleteFieldItem,
      scope: {} as AutocompleteFieldItem,
    }}
    onSubmit={onSubmit}
  >
    {({ values, submitForm, setFieldValue }) => (
      <>
        {buRoles.includes(role) && (
          <FieldRoot>
            <BusinessUnitSelectField
              name="businessUnit"
              disableClearable
              disabled={loading}
              onFieldChange={() => {
                setFieldValue('country', []);
                setFieldValue('company', []);
                setFieldValue('scope', []);
              }}
            />
          </FieldRoot>
        )}
        {countryRoles.includes(role) && (
          <FieldRoot>
            <CountriesSelectWithAllField
              name="country"
              label="Country/Territory"
              multiple={false}
              disableClearable
              required
              disabled={loading}
              onFieldChange={() => {
                setFieldValue('company', []);
                setFieldValue('scope', []);
              }} 
            />
          </FieldRoot>
        )}
        {companyRoles.includes(role) && (
          <FieldRoot>
            {values.country?.id && values.businessUnit?.id ? (
              <CompanyWithFiltersField
                name="company"
                label="Company"
                filterParams={mapCompanyFilterParams(values.businessUnit, [
                  values.country,
                ], null)}
                multiple={false}
                disableClearable
                disabled={loading}
                addAlloption={role!==RoleType.PartnerSupervisor}
                required
              />
            ) : (
              <AutocompleteField
                label="Company"
                options={[]}
                disabled
                required
              />
            )}
          </FieldRoot>
        )}
        {scopeRoles.includes(role) && (
          <FieldRoot>
            <ScopeSelectField
              name="scope"
              multiple={false}
              disableClearable
              required
              disabled={loading}
            />
          </FieldRoot>
        )}
        <ActionsRoot>
          <Button
            type="submit"
            color="secondary"
            onClick={submitForm}
            disabled={loading || !isAddRoleEnabled(values, role)}
          >
            {loading ? <Loader size={24} /> : 'Add role'}
          </Button>
        </ActionsRoot>
      </>
    )}
  </Formik>
);
