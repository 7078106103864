import { FC } from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

import { myCertificationsRoute } from 'route';
import { AppShellBaseProps } from './@types';
import { Menu } from './Menu/Menu';
import {
  DetailsContentRoot,
  DetailsTitleRoot,
  StyledTypography,
  Grow,
} from './AppShell.style';

const defaultAppBackPath = myCertificationsRoute.path;

export interface DetailsAppShellProps extends Partial<AppShellBaseProps> {
  title: string;
  subtitle?: string;
  loading?: boolean;
  defaultGoBackPath?: string;
  goBack?: () => void;
}

export const DetailsAppShell: FC<DetailsAppShellProps> = (props) => {
  const {
    logout,
    title,
    subtitle,
    loading = false,
    goBack,
    defaultGoBackPath = defaultAppBackPath,
    children,
  } = props;
  const history = useHistory();
  const handleBack = () => {
    if (goBack) {
      goBack();
      return;
    }
    if (window.history.state !== null) {
      history.goBack();
    } else {
      history.push(defaultGoBackPath);
    }
  };

  return (
    <>
      <AppBar position="fixed" color="default">
        <Toolbar>
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <DetailsTitleRoot>
            {loading ? (
              <Skeleton width={200} height={32} />
            ) : (
              <>
                <StyledTypography>{title}</StyledTypography>
                {subtitle && (
                  <Typography variant="caption">{subtitle}</Typography>
                )}
              </>
            )}
          </DetailsTitleRoot>
          <Grow />
          {logout && <Menu logout={logout} />}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <DetailsContentRoot>{children}</DetailsContentRoot>
    </>
  );
};

export default DetailsAppShell;
