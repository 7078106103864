import { FC } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import { useRoundButtonStyles } from './RoundButton.style';

export const RoundButton: FC<ButtonProps> = (props) => {
  const {
    className,
    disabled,
    variant = 'outlined',
    children,
    ...other
  } = props;
  const classes = useRoundButtonStyles(props);
  return (
    <Button
      className={clsx(className, classes.root, disabled && classes.disabled)}
      classes={{
        outlined: classes.outlined,
      }}
      variant={variant}
      disabled={disabled}
      {...other}
    >
      {children}
    </Button>
  );
};

export default RoundButton;
