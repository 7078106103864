import { MyProfile } from 'MyProfile/model/MyProfile';
import { EditUserDto } from 'MyProfile/model/EditUserDto';

export interface AddExternalDto extends Omit<EditUserDto, 'managerGeid' | 'managerEmail' | 'functionalmanagerEmail' | 'functionalmanagerGeid'> {
  firstName: string;
  lastName: string;
  countryId: number;
  email: string;
  trainingManagerGeid: string;
  trainingManagerEmail: string;
}

export const mapUsersToDto = (users: MyProfile[]): AddExternalDto[] =>
  users.map((user) => ({
    firstName: user.firstName,
    lastName: user.lastName,
    notes: user.notes,
    city: user.city,
    state: user.state,
    countryId: user.country.id,
    email: user.email,
    geid: user.geid,
    trainingManagerGeid: user.manager.geid,
    trainingManagerEmail: user.manager.email,
    divisionId: user.division?.id,
    businessUnitId: user.businessUnit.id,
    companyId: user.company?.id ?? null,
    pgUnitId: user.pgUnit?.id ?? null,
    employeeJobsIds: (user.employeeJobs ?? []).map((job) => job.id),
    employeeJobFamiliesIds: (user.employeeJobFamilies ?? []).map((job) => job.id),
    preferredLanguagesIds: (user.preferredLanguages ?? []).map(
      (lang) => lang.id
    ),
  }));
