import { useMemo } from 'react';
import { ObjectType } from '../../Dictionary/model/TaskType';
import { getTaskTypeItems } from '../../Dictionary/component/TaskTypeSelectField/TaskTypeSelectField';
import { StatusChip } from '../../components/StatusChip';

export interface ObjectTypeChipProps {
  type: ObjectType;
}

export const ObjectTypeChip = (props: ObjectTypeChipProps) => {
  const { type } = props;
  const label = useMemo(
    () => getTaskTypeItems().find((x) => x.id === type)?.name ?? null,
    [type]
  );
  return label ? <StatusChip label={label} color="grey" /> : null;
};
