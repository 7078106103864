import { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { useMutation } from 'react-query';

import { useToasts } from 'components/ToastProvider';
import { Button } from 'components';
import { Card } from 'components/Page';
import { DataTable, DataTableColumn } from 'components/DataTable/DataTable';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import {
  ActionsWrapper,
  StyledForm,
} from 'Print/component/PrintOutForm/PrintOutForm.style';
import { LanguagesByBusinessUnitSelectField } from 'Dictionary/component/LanguagesByBusinessUnitSelectField/LanguagesByBusinessUnitSelectField';
import { PrintOutFormData } from 'Print/model/PrintOutFormData';
import { PrintCertificate } from 'Print/model/PrintCertificate';
import {
  getFirstSignersAutocompleteFields,
  getSecondSignersAutocompleteFields,
  getSigners,
  Print,
} from 'Print/model/Print';
import { FormikAutocompleteField } from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { printApi } from 'Print/service/print.api';
import { SubmitPrintDto } from 'Print/model/SubmitPrintDto';
import { Signer } from 'Print/model/Signer';
import { ApiError } from 'model';

export interface PrintOutFormProps {
  print: Print;
  userId: string;
}

const columns: DataTableColumn<PrintCertificate>[] = [
  { field: 'title', headerName: 'Certificate name' },
];

const mapOtherSigners = (singers?: Signer[]): AutocompleteFieldItem[] =>
  (singers ?? []).filter((signer) => signer.userId > 0).map((signer) => ({
    id: signer.userId,
    name: signer.userName,
    group: 'Other (If Authorised in Digital Signature)',
  }));

const initialValues: PrintOutFormData = {
  printingLanguage: null,
  firstSigner: null,
  secondSigner: null,
};

export const PrintOutForm: FC<PrintOutFormProps> = ({ print, userId }) => {
  const { showError, showSuccess } = useToasts();
  const [selectedCertificates, setSelectedCertificates] = useState<string[]>(
    []
  );
  const {
    mutate: fetchSigners,
    data: signers,
    isLoading: signersLoading,
  } = useMutation<Signer[], ApiError, number>((id) =>
    printApi.getSigners(print.businessUnit.id, id)
  );
  const { mutate: printPdf, isLoading: printPdfLoading } = useMutation<
    unknown,
    ApiError,
    SubmitPrintDto
  >((dto) => printApi.print(userId, dto));

  const firstSignerOptions = [
    ...getFirstSignersAutocompleteFields(print),
    ...mapOtherSigners(signers),
  ];
  const secondSignerOptions = [
    ...getSecondSignersAutocompleteFields(print),
    ...mapOtherSigners(signers),
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(
        { firstSigner = {}, printingLanguage = {}, secondSigner = {} },
        formikHelpers
      ) => {
        const selectedSigners: Signer[] = [
          print.firstSignerDisabled ? print.signers.find((signer) => signer.userId === 0) : getSigners(print, 'isDefaultFirst', signers).find(
            (signer) => signer.userId === firstSigner?.id
          ),
          getSigners(print, 'isDefaultSecond', signers).find(
            (signer) => signer.userId === secondSigner?.id
          ),
        ].filter(
          (signerOrUndefined) => signerOrUndefined !== undefined
        ) as Signer[];

        printPdf(
          {
            languageId: Number(printingLanguage?.id),
            printItems: [
              {
                businessUnit: print.businessUnit,
                signers: selectedSigners,
                certificates: print.certificates.filter((certificate) =>
                  selectedCertificates.includes(
                    String(certificate.certificateListId)
                  )
                ),
              },
            ],
          },
          {
            onSuccess: () => {
              showSuccess('Print certificates success');
              setSelectedCertificates([]);
              formikHelpers.resetForm();
            },
            onError: () => showError('Print certificates error'),
          }
        );
      }}
    >
      {({ values, setFieldValue }) => {
        const signersHelperText = !values.printingLanguage?.id
          ? 'Select printing language first'
          : undefined;
        const isPrintDisabled =
          !selectedCertificates.length ||
          !values.printingLanguage?.id ||
          (!values.firstSigner?.id && !print.firstSignerDisabled) ||
          printPdfLoading;
        const isSignerDisabled = !values.printingLanguage?.id;

        return (
          <StyledForm>
            <Grid container>
              <Grid item xs={12} lg={4}>
                <Card transparent>
                  <LanguagesByBusinessUnitSelectField
                    name="printingLanguage"
                    label="Printing language"
                    buId={print.businessUnit.id}
                    multiple={false}
                    onFieldChange={(field) => {
                      fetchSigners(Number((field as AutocompleteFieldItem).id));
                    }}
                    disableClearable
                  />
                </Card>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Card transparent>
                  <FormikAutocompleteField
                    name="firstSigner"
                    label="First Signer"
                    options={firstSignerOptions}
                    disabled={print.firstSignerDisabled || isSignerDisabled}
                    inputValue={print.firstSignerDisabled ? "Certificate Approver" : undefined}
                    helperText={print.firstSignerDisabled ? undefined : signersHelperText}
                    groupBy={(field) => String(field.group)}
                    loading={signersLoading && !print.firstSignerDisabled}
                    required
                  />
                </Card>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Card transparent>
                  <FormikAutocompleteField
                    name="secondSigner"
                    label="Second Signer (Optional)"
                    options={secondSignerOptions}
                    disabled={isSignerDisabled}
                    helperText={signersHelperText}
                    groupBy={(field) => String(field.group)}
                    loading={signersLoading}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card header="Certificates" transparent>
                  <DataTable
                    columns={columns}
                    rows={print.certificates}
                    enableSort
                    onSortRequest={(direction, fieldName) =>
                      print.certificates.sort((a, b) => {
                        if ((a[fieldName] > b[fieldName]))
                          return direction === "asc" ? 1 : -1;
                        if ((a[fieldName] < b[fieldName]))
                          return direction === "asc" ? -1 : 1;
                        return 0;
                      })
                    }
                    checkboxSelection
                    rowSelector={(row) => row.certificateListId}
                    noDataMessage="No certificates to display"
                    outerSelectedRows={selectedCertificates}
                    onCheckboxChange={setSelectedCertificates}
                  />
                  <ActionsWrapper>
                    <Button
                      type="submit"
                      color="secondary"
                      disabled={isPrintDisabled}
                    >
                      Print selected certificates
                    </Button>
                  </ActionsWrapper>
                </Card>
              </Grid>
            </Grid>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
