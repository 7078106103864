import styled from 'styled-components';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';

export type Size = 'tiny' | 'small' | 'regular' | 'large';

export const StyledAvatar = styled(Avatar)<AvatarProps & { size: Size }>`
  && {
    background-color: ${({ theme }) => theme.palette.grey[100]};
    color: ${({ theme }) => theme.palette.grey[600]};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    width: 40px;
    width: ${({ size }) => size === 'tiny' && '24px'};
    width: ${({ size }) => size === 'small' && '32px'};
    width: ${({ size }) => size === 'large' && '48px'};
    height: 40px;
    height: ${({ size }) => size === 'tiny' && '24px'};
    height: ${({ size }) => size === 'small' && '32px'};
    height: ${({ size }) => size === 'large' && '48px'};
  }
`;

export const StyledInitials = styled.span<{ size: Size }>`
  font-size: 0.875rem;
  font-size: ${({ size }) => size === 'tiny' && '.625rem'};
  font-size: ${({ size }) => size === 'small' && '.75rem'};
  font-size: ${({ size }) => size === 'large' && '1rem'};
`;
