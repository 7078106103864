import React, { ReactNode, Ref, useEffect, useRef, useState } from 'react';
import { Form, Formik, FormikConfig, FormikProps, FormikValues } from 'formik';

import { Button } from 'components/Button';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { useToasts } from 'components/ToastProvider';
import { UserFilterList, UserFilterSaver, ListFiltersSettings, FilterSetting, UserFilterNameEditor, userFilterLogic } from './UserFilersList';
import { PageActionsRoot } from './SearchFiltersForm.style';

export interface SearchFiltersDefaultValues {
  searchText: string;
}


export interface SearchFiltersFormProps<Values> extends FormikConfig<Values> {
  loading?: boolean;
  placeholder?: string;
  renderPageActions?: () => ReactNode;
  children: (formikProps: FormikProps<Values>) => ReactNode;

  withFilterSaveKey?: string

  searchBarField?: keyof Values | "searchText"
}

export const getFormmikFormCounter = (values: FormikValues): number =>
  Object.keys(values).reduce((counter, key) => {
    const currentValue = values[key];
    if (Array.isArray(currentValue)) {
      return counter + currentValue.length;
    }
    if (!Object.keys(values[key] ?? {}).length || currentValue === null) {
      return counter;
    }
    return counter + 1;
  }, 0);

export function SearchFiltersForm<Values extends SearchFiltersDefaultValues>(p: SearchFiltersFormProps<Values>) {
  return SearchFiltersFormSmart(p)
}

export function SearchFiltersFormSmart<Values>({
  renderPageActions,
  children,
  placeholder,
  loading = false,
  searchBarField = "searchText",
  initialValues,
  withFilterSaveKey,
  ...otherProps
}: SearchFiltersFormProps<Values>) {

  if (typeof searchBarField !== "string") throw new Error("Invalid searchBarField type. Expected string.")

  const disableFilterSave = !withFilterSaveKey

  const [state, setState] = useState<{
    valuesToUse: Values
    filters: ListFiltersSettings,
    selectedFilter?: FilterSetting
    savingCurrent: boolean,
    prevInfo?: ListFiltersSettings
  }>({ valuesToUse: initialValues, filters: {}, savingCurrent: false });
  const { showSuccess, showError } = useToasts();


  useEffect(() => {
    if (disableFilterSave) return;
    const fetchData = async () => {
      const settings = await userFilterLogic.load(withFilterSaveKey!)
      const defaultValues = userFilterLogic.getDefault(settings)
      if (defaultValues != undefined) //@ts-ignore >>HACK?
        otherProps?.onSubmit(defaultValues.filter as Values, null)
      setState({ ...state, selectedFilter: defaultValues, valuesToUse: defaultValues?.filter as Values ?? initialValues, filters: { ...settings } })
    }
    fetchData().catch(console.error);
  }, [])

  useEffect(() => {
      const divisionFilter = state.filters?.filters?.find(e => e.title == 'division');
      if(divisionFilter){
        userFilterLogic.delete(state.filters, divisionFilter)
        userFilterLogic.persist(withFilterSaveKey!, state.filters)
        setState({ ...state, filters: { ...state.filters } })
      }
  }, [state.filters, state.selectedFilter]);

  const onUserFilterDelete = (filter: FilterSetting) => {
    userFilterLogic.delete(state.filters, filter)
    userFilterLogic.persist(withFilterSaveKey!, state.filters)
    setState({ ...state, filters: { ...state.filters } })
  }

  const onUserFilterEdit = (filter: FilterSetting | undefined, formikProps: FormikProps<Values>) => {
    const isNew = formikProps.dirty && !state.savingCurrent;
    const selected = isNew ? userFilterLogic.add(state.filters, formikProps.values) : state.selectedFilter!
    setState({ ...state, savingCurrent: true, selectedFilter: selected, prevInfo: JSON.parse(JSON.stringify(state.filters)) })
  }

  const onUserFilterSave = (filter: FilterSetting, formikProps: FormikProps<Values>) => {
    if (state.filters?.filters?.find((e => e.title == filter.title && e != filter)) != null) {
      showError('There are duplicates in the settings names. Settings names must be unique.')
      return
    }
    Object.assign(filter.filter, formikProps.values)
    formikProps.resetForm({ values: filter.filter as Values });
    userFilterLogic.persist(withFilterSaveKey!, state.filters);
    setState({ ...state, savingCurrent: false, prevInfo: undefined })
  }

  const onUserFilterCancelEdit = () => {
    setState({ ...state, savingCurrent: false, selectedFilter: undefined, filters: state.prevInfo!, prevInfo: undefined })
  }

  const onUserFilterSelected = (filter: FilterSetting, formikProps: FormikProps<Values>) => {
    setState({ ...state, selectedFilter: filter });
    formikProps.resetForm({ values: filter.filter as Values })
  }

  const onFiltersReset = (formikProps: FormikProps<Values>) => {
    setState({ ...state, savingCurrent: false, selectedFilter: undefined })
    formikProps.resetForm({ values: initialValues })
  }


  const renderActions = (formikProps: FormikProps<Values>): React.ReactNode =>
  (<>
    {
      disableFilterSave ?
        <></> :
        <>
          <UserFilterSaver
            savingCurrent={state.savingCurrent}
            current={state.selectedFilter}
            dirty={formikProps.dirty}
            onEdit={i => onUserFilterEdit(i, formikProps)}
            onSaved={i => onUserFilterSave(i, formikProps)}
            onCancel={() => onUserFilterCancelEdit()}
          />
        </>
    }
    {
      state.savingCurrent ?
        <></> :
        <>
          <Button
            onClick={() => onFiltersReset(formikProps)}
            disabled={loading}
          >
            Clear filters
          </Button>
          <Button type="submit" color="secondary" disabled={loading}>
            Apply
          </Button>
        </>
    }
  </>)

  return (
    <Formik initialValues={state.valuesToUse}{...otherProps} enableReinitialize={true} >
      {(formikProps: FormikProps<Values>) => (
        <Form>
          <SearchBar
            placeholder={placeholder}
            searchText={(formikProps.values as Record<string, unknown>)[searchBarField] as string}
            selectedFiltersCount={getFormmikFormCounter(formikProps.values)}
            renderActions={() => renderActions(formikProps)}
            onClearText={() => {
              formikProps.setFieldValue(searchBarField, '');
              formikProps.submitForm();
            }}
            onChange={(text) => formikProps.setFieldValue(searchBarField, text)}
            onEnter={() => formikProps.submitForm()}
          >
            {
              disableFilterSave ?
                <></> :
                <>
                  <UserFilterList
                    savingCurrent={state.savingCurrent}
                    items={state.filters}
                    current={state.selectedFilter}
                    onDeleted={filter => onUserFilterDelete(filter)}
                    onSelected={filter => onUserFilterSelected(filter, formikProps)} />
                </>
            }
            {
              disableFilterSave || state.selectedFilter == undefined ?
                <></> :
                <>
                  <UserFilterNameEditor savingCurrent={state.savingCurrent} items={state.filters} current={state.selectedFilter} />
                </>
            }
            {children(formikProps)}
          </SearchBar>
          {renderPageActions && (
            <PageActionsRoot>{renderPageActions()}</PageActionsRoot>
          )}
        </Form>
      )
      }
    </Formik >
  );
}
