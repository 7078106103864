import { FC, ReactNode } from 'react';

import { OverlayLoader } from 'components/OverlayLoader/OverlayLoader';
import {
  Root,
  HeaderTypography,
  HeaderRoot,
  ActionsRoot,
  ContentRoot,
} from './ModalContent.style';

export interface ModalContentProps {
  header: string;
  renderActions?: () => ReactNode;
}

export const ModalContent: FC<ModalContentProps> = ({
  header,
  renderActions,
  children,
}) => (
  <Root>
    <OverlayLoader>
      <HeaderRoot>
        <HeaderTypography variant="h6">{header}</HeaderTypography>
      </HeaderRoot>
      <ContentRoot>{children}</ContentRoot>
      {renderActions && <ActionsRoot>{renderActions()}</ActionsRoot>}
    </OverlayLoader>
  </Root>
);
