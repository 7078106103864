import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';

import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { CPStatusChip } from 'components/StatusChip';
import { formatDate } from 'utils';
import { certificationPlansDetailsRoute } from 'route';
import { Certificate } from 'Certificate/model/Certificate';
import { PlanRequirementsTable } from 'Requirement/component/PlanRequirementsTable/PlanRequirementsTable';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import { TitleTypography } from 'Certificate/component/UserCertificateTable/UserCertificateTable.style';
import { Button, DataObjectView } from 'components';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';

export const expiredColumns: DataTableColumn<Certificate>[] = [
  {
    field: 'title',
    headerName: 'Certificate',
    width: 400,
    renderField: ({ title }) => (
      <TitleTypography variant="body2">{title}</TitleTypography>
    ),
  },
  { field: 'release', headerName: 'Release' },
  { field: 'levelDescription', headerName: 'Level' },
  {
    field: 'approvedDate',
    headerName: 'Approved on',
    renderField: ({ approvedDate }) => formatDate(approvedDate),
  },
  {
    field: 'expirationDate',
    headerName: 'Expiring date',
    renderField: ({ expirationDate }) => formatDate(expirationDate),
  },
  {
    field: 'status',
    headerName: 'Status',
    notSortable: true,
    renderField: ({ status }) => <CPStatusChip status={status} />,
  },
];

export const pendingColumns: DataTableColumn<Certificate>[] = [
  {
    field: 'title',
    headerName: 'Certificate',
    width: 400,
    renderField: ({ title }) => (
      <TitleTypography variant="body2">{title}</TitleTypography>
    ),
  },
  { field: 'release', headerName: 'Release' },
  { field: 'levelDescription', headerName: 'Level' },
  {
    field: 'enrollDate',
    headerName: 'Enrolled on',
    renderField: ({ enrollDate }) => formatDate(enrollDate),
  },
  {
    field: 'requirementsCount',
    headerName: 'Req',
    notSortable: true,
    renderField: ({ requirementsCount, requirementsDone }) =>
      `${requirementsDone}/${requirementsCount}`,
  },
  {
    field: 'status',
    headerName: 'Status',
    notSortable: true,
    renderField: ({ status }) => <CPStatusChip status={status} />,
  },
];

export const completedColumns: DataTableColumn<Certificate>[] = [
  {
    field: 'title',
    headerName: 'Certificate',
    width: 400,
    renderField: ({ title }) => (
      <TitleTypography variant="body2">{title}</TitleTypography>
    ),
  },
  { field: 'release', headerName: 'Release' },
  { field: 'levelDescription', headerName: 'Level' },
  {
    field: 'approvedDate',
    headerName: 'Approved on',
    renderField: ({ approvedDate }) => formatDate(approvedDate),
  },
  {
    field: 'expirationDate',
    headerName: 'Expiration date',
    renderField: ({ expirationDate }) => formatDate(expirationDate),
  },
  {
    field: 'status',
    headerName: 'Status',
    notSortable: true,
    renderField: ({ status }) => <CPStatusChip status={status} />,
  },
];

export interface UserCertificateTableProps
  extends DataTableProps<Certificate> {}

export const UserCertificateTable: FC<UserCertificateTableProps> = ({
  columns,
  ...otherProps
}) => {
  const history = useHistory();
  const handleOpenDetails = (certificateId: number) => () =>
    history.push(
      certificationPlansDetailsRoute.createRoute({
        certificationPlanId: String(certificateId),
      })
    );

  return (
    <>
      <DesktopSection>
        <DataTable
          columns={columns}
          enableSort
          onSortRequest={(direction, fieldName) => 
            otherProps.rows.sort((a,b) => {
              if(a[fieldName] === null && b[fieldName] === null)  
                return 0;
              if(a[fieldName] === null)  
                return direction === "asc" ? -1 : 1;
              if(b[fieldName] === null)  
                return direction === "asc" ? 1 : -1;
              
                if((a[fieldName] > b[fieldName]))
                  return direction === "asc" ? 1 : -1;
                if((a[fieldName] < b[fieldName]))
                  return direction === "asc" ? -1 : 1;
                return 0;
              })
          }
          rowSelector={(row) => row.id}
          renderRowActions={(row, collapseRow, isExpanded) => (
            <>
              <IconButton onClick={collapseRow}>
                {isExpanded ? <CloseIcon /> : <ListIcon />}
              </IconButton>
              <IconButton onClick={handleOpenDetails(row.id)}>
                <NavigateNextIcon />
              </IconButton>
            </>
          )}
          renderCollapseRow={(row) => (
            <PlanRequirementsTable
              certificateId={row.id}
              DataTableProps={{ checkboxSelection: true }}
            />
          )}
          hideCollapseColumn
          {...otherProps}
        />
      </DesktopSection>
      <MobileSection>
        <DataObjectList
          loading={otherProps.loading}
          items={otherProps.rows}
          renderDataObjectView={(certificate) => (
            <DataObjectView
              title={certificate.title}
              item={certificate}
              dataSetMaps={mapToDataSetMap(columns, ['title'])}
              renderActions={() => (
                <Button
                  color="secondary"
                  onClick={handleOpenDetails(certificate.id)}
                >
                  Details
                </Button>
              )}
              variant="elevation"
              transparent
            />
          )}
          renderDetails={(certificate) => (
            <PlanRequirementsTable
              certificateId={certificate.id}
              DataTableProps={{ checkboxSelection: true }}
            />
          )}
          noDataMessage={otherProps.noDataMessage}
        />
      </MobileSection>
    </>
  );
};
