import { useState, useEffect } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography, Tooltip,
  useTheme,
  Button,
  AccordionActions,
} from '@material-ui/core';
import { useToasts } from 'components/ToastProvider';
import { ExpandMore, Settings } from '@material-ui/icons';
import {
  ColumnDefinition,
  ReportPageTypes,
  ReportSetting,
} from 'Reports/model';
import {
  useFilterSettingsQuery,
  useFilterSettingsMutation,
} from 'Reports/query';
import { ReportSettingsList } from './ReportSettingsList';
import { ReportSettingsDetails } from './ReportSettingsDetails';
import { ReportingFilters } from '../@types';
import { ScheduleSettings } from './ScheduleSettings';

export interface ReportSettingsProps {
  chosenFilters: ReportingFilters;
  reportType: ReportPageTypes;
  columnDefinitions: ColumnDefinition[];
  onFiltersRestore: (filters: Partial<ReportingFilters>) => void;
  onColumnsRestore: (columns: string[]) => void;
}

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
}

export const ReportSettingsPanel = (props: ReportSettingsProps) => {
  const {
    reportType,
    columnDefinitions,
    chosenFilters,
    onFiltersRestore,
    onColumnsRestore,
  } = props;
  const forceUpdate = useForceUpdate();
  const theme = useTheme();
  const [selectedSetting, setSelectedSetting] = useState<ReportSetting | null>(null);
  const [orgValues, setOrgValues] = useState<ReportSetting | null>(null);
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [settings, setSettings] = useState<ReportSetting[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { isLoading, data, dataUpdatedAt, refetch } = useFilterSettingsQuery();
  useEffect(() => {
    const settingsSet = (data ? data[reportType] : null);

    setSettings(settingsSet ?? [])
    setSelectedSetting(null)
    setSelectedIndex(null)

    forceUpdate();
  }, [dataUpdatedAt]);
  const { isLoading: saving, mutate } = useFilterSettingsMutation();

  const handleAddNewSettings = (name: string) => {
    const newSetting = { name, filters: {}, columns: [] }
    setSettings((prev) => [newSetting, ...prev])

    setSelectedSetting(newSetting)
    setSelectedIndex(0)
    edit()
  };
  const handleSelect = (name: string, index: number) => {
    setSelectedSetting(settings[index] ?? null);
    setSelectedIndex(index);
  };
  const handleSettingsChnage = (
    newSet: ReportSetting,
    index: number | null
  ) => {
    if (!newSet && !index) {
      return;
    }
    setSettings((prev) =>
      prev.map((s, i) =>
        s.name === newSet.name && i === selectedIndex ? newSet : s
      )
    );
    setSelectedSetting(newSet);
  };
  const handleSettingNameChange = (
    newSet: ReportSetting,
    index: number | null
  ) => {
    if (!Number.isInteger(index)) {
      return;
    }
    setSettings((prev) =>
      prev.map((s, i) => (i === selectedIndex ? newSet : s))
    );
  };

  const { showSuccess, showError } = useToasts();
  const handleSaveSettings = () => {
    const settingNames = settings.map(x => x.name)
    const duplicatedNames = settingNames.filter((item, index) => settingNames.indexOf(item) != index)
    if (duplicatedNames.length > 0) {
      showError('There are duplicates in the settings names. Settings names must be unique.')
      return
    }

    if ((selectedSetting?.name ?? "") == "") {
      showError('Setting name is required!')
      return
    }

    if (selectedSetting?.schedule) {

      if (!(selectedSetting?.schedule.startDate)) {
        showError('Setting schedule start date is required!');
        return
      }

      if ((selectedSetting?.schedule.sendTo ?? []).length == 0) {
        showError('Scheduled report receiver is required!')
        return
      }

    }
    doPersist()
  };

  const doPersist = (toSave?: ReportSetting[]) => {
    if (data) {
      const next = { ...data };
      next[reportType] = [...(toSave ?? settings)];
      mutate(next);
      setInEditMode(false)
    }
  }

  const handleAccept = (name: string, index: number) => {
    const selectedSetting = settings[index];
    if (selectedSetting) {
      onColumnsRestore(selectedSetting.columns);
      onFiltersRestore(selectedSetting.filters);
    }
  };
  const handleDelete = (name: string, index: number) => {
    const array = [...settings]
    if (index >= 0) {
      array.splice(index, 1)
      setSettings(array)
      setSelectedSetting(null)
      setSelectedIndex(null)
      doPersist(array)
    }
  };
  const edit = () => {
    setInEditMode(true)
    forceUpdate()
  }

  const cancelEdit = () => {
    setInEditMode(false)
    setSelectedSetting(null)
    refetch()
  }

  const doRunsetting = () => {
    if (selectedSetting) {
      onColumnsRestore(selectedSetting.columns);
      onFiltersRestore(selectedSetting.filters);
    }
  }

  const onSchedulerActiveChange = (isActive: boolean) => {
    handleSaveSettings()
  }

  const renderActions = () => {

    if (inEditMode)
      return (<>
        <Button
          disabled={isLoading || saving || !selectedSetting}
          onClick={() => doRunsetting()}
          color="secondary"
        >
          Run current settings
        </Button>

        <Button
          disabled={isLoading || saving}
          onClick={cancelEdit}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading || saving}
          onClick={handleSaveSettings}
          color="secondary"
        >
          Save settings
        </Button>
      </>)

    if (selectedSetting)
      return (<>
        <Tooltip title={selectedSetting?.schedule?.active ? "Suspend the schedule to edit setting" : ""} >
          <div>
            <Button
              disabled={isLoading || saving || !selectedSetting || selectedSetting?.schedule?.active}
              onClick={() => edit()}
              color="primary"
            >
              Edit
            </Button>
          </div>
        </Tooltip>
        <Button
          disabled={isLoading || saving || !selectedSetting}
          onClick={() => doRunsetting()}
          color="secondary"
        >
          Run settings
        </Button>

      </>)


    return (<></>)
  }

  return (
    <Accordion
      elevation={0}
      style={{
        marginTop: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
      }}
    >

      <AccordionSummary expandIcon={<ExpandMore />}>
        <Settings style={{ marginRight: theme.spacing(2) }} />
        <Typography variant="h6">Report settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          'Loaidng...'
        ) : (
          <Grid spacing={3} container>
            <Grid lg={12} item>
              <ReportSettingsList
                selectedSetting={{
                  name: selectedSetting?.name ?? '',
                  index: selectedIndex ?? -1,
                }}
                inEditMode={inEditMode}
                settings={settings}
                onAddNewSetting={handleAddNewSettings}
                onAccept={handleAccept}
                onDelete={handleDelete}
                onSelect={handleSelect}
              />
            </Grid>

            <Grid lg={6} sm={12} item className="reportSettings">
              <ReportSettingsDetails
                selectedIndex={selectedIndex}
                onFiltersRestore={onFiltersRestore}
                onColumnsRestore={onColumnsRestore}
                onSettingsChange={handleSettingsChnage}
                onSettingNameChange={handleSettingNameChange}
                chosenFilters={chosenFilters}
                columnDefinitions={columnDefinitions}
                selected={selectedSetting}
                inEditMode={inEditMode}
              />
            </Grid>
            <Grid lg={6} sm={12} item className="reportSettings">
              <ScheduleSettings setting={selectedSetting} inEditMode={inEditMode} onActiveChange={onSchedulerActiveChange} />
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
      <AccordionActions>
        {renderActions()}
      </AccordionActions>
    </Accordion>
  );
};
