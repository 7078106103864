import { ChangeEvent, useState, useEffect, ReactNode } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Fade from '@material-ui/core/Fade';
import TableSortLabel, {
  TableSortLabelProps,
} from '@material-ui/core/TableSortLabel';
import { Skeleton } from '@material-ui/lab';
import { formatDate } from 'utils';

import { Sort } from 'model';
import { Table } from 'components/Table';
import {
  CollapseTableCell,
  CollapseTableRow,
  DataTableRow,
  RowActionsRoot,
  StyledToolbar,
  ToolbarActionsRoot,
  HeaderTableRow,
  ToolbarTypography,
  DataTableCell,
  NoDataMessageWrapper,
} from './DataTable.style';

export interface DataTableColumn<Row> {
  field: keyof Row | undefined;
  headerName: string;
  width?: number | string;
  renderField?: (row: Row) => ReactNode;
  notSortable?: boolean;
}

export interface DataTableProps<Row> {
  loading?: boolean;
  columns: DataTableColumn<Row>[];
  rows: Row[];
  noDataMessage?: string | JSX.Element;
  rowSelector?: (row: Row) => string | number;
  selectIsRowSelectable?: (row: Row) => boolean;
  enableSort?: boolean;
  checkboxSelection?: boolean;
  headerRowAlwaysOnTop?: boolean;
  showRowActionsOnHover?: boolean;
  hideCollapseColumn?: boolean;
  outerBorder?: boolean;
  outerSelectedRows?: string[];
  outerSelectedRowsRaw?: Row[];
  defaultSort?: Sort<Row>;
  onCheckboxChange?: (selectedRows: string[]) => void;
  onRowsSelection?: (selectedRows: Row[]) => void;
  onSortRequest?: (
    direction: TableSortLabelProps['direction'],
    field: keyof Row
  ) => void;
  renderRowCells?: (row: Row) => ReactNode;
  renderSelectedActions?: (
    selectedRows: string[],
    clearSelected: () => void
  ) => ReactNode;
  renderRowActions?: (
    row: Row,
    collapseRow: () => void,
    isExpanded: boolean
  ) => ReactNode;
  renderCollapseRow?: (row: Row, collapseRow: () => void) => ReactNode;
}

export function DataTable<Row = {}>({
  loading = false,
  columns,
  rows,
  rowSelector,
  selectIsRowSelectable,
  onSortRequest,
  renderRowCells,
  renderSelectedActions,
  renderRowActions,
  renderCollapseRow,
  noDataMessage,
  defaultSort,
  onCheckboxChange,
  onRowsSelection,
  outerSelectedRows,
  outerSelectedRowsRaw,
  outerBorder = true,
  enableSort = false,
  checkboxSelection = false,
  showRowActionsOnHover = false,
  hideCollapseColumn = false,
  headerRowAlwaysOnTop = false,
}: DataTableProps<Row>) {
  const [selectedRowsData, setselectedRowsData] = useState<Row[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [openedRows, setOpenedRows] = useState<string[]>([]);
  const [direction, setDirection] = useState<TableSortLabelProps['direction']>(
    defaultSort?.sortDescending ? 'desc' : 'asc'
  );
  const [orderByField, setOrderByField] = useState<keyof Row | null>(
    defaultSort?.sortExpression ?? null
  );
  const [fadeInRow, setFadeInRow] = useState<string>('');
  const properSelectedRows = outerSelectedRows || selectedRows;

  useEffect(() => {
    if (properSelectedRows.length !== 0 )return;
    setselectedRowsData([])
    setSelectedRows([])
    setOpenedRows([])
  }, [outerSelectedRows]);

  if (loading) {
    return <Skeleton width={320} height={32} />;
  }

  if (noDataMessage && !rows.length) {
    return (
      <NoDataMessageWrapper>
        <Typography variant="body2">{noDataMessage}</Typography>
      </NoDataMessageWrapper>
    );
  }

  const getRowSelector = (row: Row) =>
    rowSelector ? rowSelector(row).toString() : '';

  const handleSetSelectedRows = (newSelectedRows: string[]): void => {
    if (onCheckboxChange) {
      onCheckboxChange(newSelectedRows);
    } else {
      setSelectedRows(newSelectedRows);
    }
  };
  const handleSelectedRow = (rowId: string, selected: boolean): void => {
    if (!onRowsSelection) return;
    let newSelectedRows: Row[] = [];
    if (selected){
      const rawrows = rows.filter((row) => rowId === getRowSelector(row))[0];
      newSelectedRows = [...selectedRowsData, rawrows];
    }
    else{
      const rawrows = selectedRowsData.filter((row) => rowId !== getRowSelector(row));
      newSelectedRows =rawrows;
    }
    setselectedRowsData(newSelectedRows);
    onRowsSelection(newSelectedRows);
  };

  const handleOnCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newSelectedRows: string[] = [];
    if (event.target.checked) {
      newSelectedRows = [...properSelectedRows, event.target.name];
      handleSelectedRow(event.target.name, true);
    }
    if (!event.target.checked) {
      newSelectedRows = properSelectedRows.filter(
        (selectedRow) => selectedRow !== event.target.name
      );
      handleSelectedRow(event.target.name, false);
    }
    handleSetSelectedRows(newSelectedRows);
  };

  const handleOnHeaderCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    let newSelectedRows: string[] = [];
    let newSelectedRowsRow: Row[] = [];
    if (event.target.checked && !properSelectedRows.length) {
      if (selectIsRowSelectable) {
        newSelectedRows = rows
          .filter((row) => selectIsRowSelectable(row))
          .map(getRowSelector);
      } else {
        newSelectedRows = rows.map(getRowSelector);
      }
      newSelectedRowsRow = rows;
    }
    if (!event.target.checked) {
      newSelectedRows = [];
    }
    handleSetSelectedRows(newSelectedRows);

    setselectedRowsData(newSelectedRowsRow);
    if (onRowsSelection) onRowsSelection(newSelectedRowsRow);
  };

  const handleOnCollapseRow = (row: Row) => () => {
    const rowId = getRowSelector(row);
    if (openedRows.includes(rowId)) {
      setOpenedRows((openedRows) => openedRows.filter((row) => row !== rowId));
    } else {
      setOpenedRows((openedRows) => [...openedRows, getRowSelector(row)]);
    }
  };

  const handleOnShowRowActions = (row: Row) => () =>
    setFadeInRow(getRowSelector(row));

  const handleOnHideRowActions = () => setFadeInRow('');

  const handleOnSort = (field: keyof Row | undefined) => () => {
    if (!field) {
      return;
    }

    let newDirection: TableSortLabelProps['direction'];

    if (field === orderByField) {
      newDirection = direction === 'asc' ? 'desc' : 'asc';
    }
    if (field !== orderByField) {
      newDirection = 'asc';
    }

    setDirection(newDirection);
    setOrderByField(field);

    if (onSortRequest) {
      onSortRequest(newDirection, field);
    }
  };

  const getHeaderCheckbox = () => {
    const isChecked = properSelectedRows.length === rows.length;
    return (
      <Checkbox
        name="header-checkbox"
        checked={isChecked}
        indeterminate={
          !!properSelectedRows.length &&
          properSelectedRows.length !== rows.length
        }
        color={isChecked ? 'secondary' : 'primary'}
        onChange={handleOnHeaderCheckboxChange}
        disabled={
          selectIsRowSelectable &&
          !rows.find((row) => selectIsRowSelectable(row))
        }
      />
    );
  };

  const getSelectedToolbar = () => (
    <StyledToolbar $outerBorder={outerBorder} $isSelected={!!properSelectedRows.length}>
      {!headerRowAlwaysOnTop && getHeaderCheckbox()}
      <ToolbarTypography variant="body2">
        {properSelectedRows.length ? 
        `${properSelectedRows.length} item${properSelectedRows.length > 1 ? 's' : ''} selected`
        :
        'Use checkboxes to act on requirements' 
        }        
      </ToolbarTypography>
      {renderSelectedActions && (
        <ToolbarActionsRoot>
          {renderSelectedActions(properSelectedRows, () =>
            handleSetSelectedRows([])
          )}
        </ToolbarActionsRoot>
      )}
    </StyledToolbar>
  );

  const getHeaderRow = () =>
    (!properSelectedRows.length || headerRowAlwaysOnTop) && (
      <HeaderTableRow $outerBorder={outerBorder} $headerOnTop={headerRowAlwaysOnTop}>
        {checkboxSelection && (
          <TableCell padding="checkbox">{getHeaderCheckbox()}</TableCell>
        )}
        {columns.map((column, index) => (
          <TableCell key={index} style={{ width: column?.width ?? '100%' }}>
            {enableSort && !column.notSortable ? (
              <TableSortLabel
                active={column.field === orderByField}
                direction={column.field === orderByField ? direction : 'asc'}
                onClick={handleOnSort(column.field)}
              >
                {column.headerName}
              </TableSortLabel>
            ) : (
              column.headerName
            )}
          </TableCell>
        ))}
        {renderRowActions && <TableCell width="auto" />}
        {renderCollapseRow && !hideCollapseColumn && <TableCell />}
      </HeaderTableRow>
    );

  let columnsCount = columns.length + 1;
  if (checkboxSelection) columnsCount += 1;
  if (renderCollapseRow) columnsCount += 1;
  if (hideCollapseColumn) columnsCount -= 1;

  const getDataRow = (row: Row) => {
    const isRowSelected = properSelectedRows.some(
      (selectedRow) => selectedRow === getRowSelector(row)
    );
    const isExpanded = openedRows.includes(getRowSelector(row));
    return (
      <>
        <DataTableRow
          $outerBorder={outerBorder}
          $isSelected={isRowSelected}
          $isExpanded={isExpanded}
          onMouseEnter={handleOnShowRowActions(row)}
          onMouseLeave={handleOnHideRowActions}
        >
          {checkboxSelection && (
            <DataTableCell padding="checkbox" $isExpanded={isExpanded}>
              <Checkbox
                name={getRowSelector(row)}
                checked={isRowSelected}
                onChange={handleOnCheckboxChange}
                disabled={selectIsRowSelectable && !selectIsRowSelectable(row)}
              />
            </DataTableCell>
          )}
          {renderRowCells
            ? renderRowCells(row)
            : columns.map((column, index) => {
                const getValue = () => (column.field ? row[column.field] : '');
                return (
                  <DataTableCell
                    key={index}
                    width={column.width}
                    $isExpanded={isExpanded}
                  >
                    {column.renderField ? column.renderField(row) : getValue()}
                  </DataTableCell>
                );
              })}

          {renderRowActions && (
            <DataTableCell align="right" $isExpanded={isExpanded}>
              {!showRowActionsOnHover && (
                <RowActionsRoot>
                  {renderRowActions(row, handleOnCollapseRow(row), isExpanded)}
                </RowActionsRoot>
              )}
              {showRowActionsOnHover && !properSelectedRows.length && (
                <Fade in={fadeInRow === getRowSelector(row)}>
                  <RowActionsRoot>
                    {renderRowActions(
                      row,
                      handleOnCollapseRow(row),
                      isExpanded
                    )}
                  </RowActionsRoot>
                </Fade>
              )}
            </DataTableCell>
          )}
          {renderCollapseRow && !hideCollapseColumn && (
            <DataTableCell $isExpanded={isExpanded}>
              <RowActionsRoot>
                <IconButton onClick={handleOnCollapseRow(row)}>
                  {isExpanded ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </RowActionsRoot>
            </DataTableCell>
          )}
        </DataTableRow>
        {renderCollapseRow && (
          <CollapseTableRow $outerBorder={outerBorder} $isExpanded={isExpanded}>
            <CollapseTableCell colSpan={columnsCount}>
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                {renderCollapseRow(row, handleOnCollapseRow(row))}
              </Collapse>
            </CollapseTableCell>
          </CollapseTableRow>
        )}
      </>
    );
  };

  return (
    <>
      {(!!properSelectedRows.length || headerRowAlwaysOnTop) && getSelectedToolbar()}
      <Table
        rows={rows}
        renderRow={getDataRow}
        renderHeaderRow={getHeaderRow}
      />
    </>
  );
}

DataTable.formatDate = formatDate