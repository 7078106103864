import styled from 'styled-components';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';

export const DeleteIconButton = styled(IconButton)<IconButtonProps>`
  margin-right: ${({ theme }) => theme.spacing(2.5)}px;
`;

export const AdminListItemRoot = styled.div`
  display: flex;
`;
