import { FC } from 'react';
import { useMutation } from 'react-query';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';

import { getFullName, MyProfile } from 'MyProfile/model/MyProfile';
import { myProfileApi } from 'MyProfile/service/myProfile.api';
import { ApiError } from 'model/ApiError';
import { EditUserForm } from 'MyProfile/component/EditUserForm';
import { useToasts } from 'components/ToastProvider';
import {
  EditUserFormData,
  mapEditUserDto,
} from 'MyProfile/component/EditUserForm/EditUserForm';
import { FieldRoot } from 'components/SearchFiltersForm/SearchFiltersForm.style';
import { DataField } from 'components';

export interface ProfileInformationFormProps {
  myProfile: MyProfile;
  onChange: () => void;
}

const formValidationSchema = Yup.object().shape({
  manager: Yup.object().typeError('Select at least one Manager'),
  employeeJobs: Yup.array().min(1, 'Select at least one Employee job'),
  company: Yup.object().typeError('Select at least one Company'),
  businessUnit: Yup.object().typeError('Select Division'),
  preferredLanguages: Yup.array().min(
    1,
    'Select at least one Preferred language'
  ),
});

export const ProfileInformationForm: FC<ProfileInformationFormProps> = ({
  myProfile,
  onChange,
}) => {
  const { showError, showSuccess } = useToasts();
  const { mutate, isLoading } = useMutation<
    unknown,
    ApiError,
    EditUserFormData
  >((formData) => myProfileApi.post(mapEditUserDto(formData)), {
    onSuccess: () => {
        showSuccess('My profile has been updated');
      //if (myProfile.userNeedToUpdateProfile) {
        onChange();
      //}
    },
    onError: (error) => showError(error.message),
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <FieldRoot $topbottomPadding={1}>
            {myProfile && (
              <DataField label="Full name" value={getFullName(myProfile)} />
            )}
          </FieldRoot>
          <FieldRoot $topbottomPadding={1}>
            <DataField label="Country/Territory" value={myProfile?.country?.name} />
          </FieldRoot>
          <FieldRoot $topbottomPadding={1}>
            <DataField label="State" value={myProfile?.state} />
          </FieldRoot>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FieldRoot $topbottomPadding={1}>
            <DataField label="Email address" value={myProfile?.email} />
          </FieldRoot>
          <FieldRoot $topbottomPadding={1}>
            <DataField label="GEID" value={myProfile?.geid} />
          </FieldRoot>
          <FieldRoot $topbottomPadding={1}>
            <DataField label="City" value={myProfile?.city} />
          </FieldRoot>
        </Grid>
      </Grid>
      <EditUserForm
        user={myProfile}
        countryId={myProfile.country.id}
        loading={isLoading}
        validationSchema={formValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            await mutate(values);
            resetForm({ values });
          } catch (e) {
            resetForm();
          }
        }}
        showUserCompanies
      />
    </>
  );
};
