import { FC } from 'react';
import { createPortal } from 'react-dom';
import MUIModal, { ModalProps } from '@material-ui/core/Modal';

const modalRoot = document.getElementById('modal-root') as HTMLElement;

export const Modal: FC<ModalProps> = ({ children, ...modalProps }) =>
  createPortal(
    <MUIModal {...modalProps} disableAutoFocus disableEnforceFocus>
      {children}
    </MUIModal>,
    modalRoot
  );
