import { AutocompleteFieldItem as Model } from 'components/Field/AutocompleteField/AutocompleteField';
import { PaginationDto, Sort } from 'model';

export interface Company {
  id: number;
  description: string;
  location: string;
}

export const getCompanyName = (company?: Company): string =>
  company && company?.description
    ? `${company?.description}${company?.location && ` - ${company?.location}`}`
    : '...';

export const mapToAutocompleteFieldItems = (
  companies: Company[]
): Model[] =>
  companies.map((company) => ({
    id: company.id,
    name: getCompanyName(company),
  }));



export interface CompanyInfoDTO {
  id: number
  name: string

  country: DictionaryItemByCodeDTO
  divisions: DictionaryItemByCodeDTO[]
  channels: DictionaryItemByCodeDTO[]

  city: string

  isAbb: boolean
}

export interface CompanyFindQuery extends PaginationDto, Sort<CompanyInfoDTO> {
  name?: string
  country?: number
  city?: string
  divisions?: number[]
  channels?: number[]
  isAbb?: DictionaryItemByCodeDTO
  businessArea?: DictionaryItemByCodeDTO
}