export enum CertificationPlanWorkFlowActions {
  Submit,
  Approve,
  Reset,
  ResetToApproval,
  Reject,
  SubmitForRenew,
  Renew,
  Remove,
  EditDates,
  FillinQuestionnaire,
  Enroll,
  AlreadyEnrolled,
  AlreadyEnrolledOtherRelease,
  ClApprove,
  ClReject,
  NoLongerAvailable,
  NewerReleaseAvailable,
  ClApproveDisabled,
  ClRejectDisabled,
  ClPublish,
  ClRejectPublish,
  SubmitWithDate,
  SubmitForRenewWithDate,
  ApproveWithDate,
  RenewWithDate,
  ApproveRenewalEnrollment,
  RejectRenewalEnrollment
}
