import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from '@material-ui/core';

import { formatTemplate } from '../../utils';
import { Button } from '../../components';

interface Props {
    submitAction: React.Dispatch<string>,
    onClose: React.DispatchWithoutAction
}
export const RenderSubmitWithDateDialog = (props: Props) => {

    const [value, setValue] = useState<Dayjs | null>(null);
    const getUpdateDate = (dateOrNull: Dayjs | null): string => dateOrNull ? dayjs(dateOrNull).format() : "";
    return (
        <Dialog open={true} onClose={props.onClose}>
            <DialogTitle id="form-dialog-title">Provide Certification Expiration Date</DialogTitle>
            <DialogContent>
                <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    label="Expiration date"
                    value={value}
                    onChange={setValue}
                    format={formatTemplate}
                    placeholder='yyyy/mm/dd'
                    //disabled={isLoading}
                    autoOk
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Close
                </Button>
                <Button
                    color="secondary"
                    onClick={() => {
                        props.submitAction(getUpdateDate(value));
                        props.onClose();
                    }}
                    disabled={!value?.isValid()}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
