import { UserDetails } from '../../model';

export interface QuestionnarieDetails {
  id: number;
  submittedDate: string | null;
  submittedBy: UserDetails | null;
  question1: PeriodQuestionnaireQuestionAnswer;
  question2: Question2Choices;
  question3: Question3Choices;
}

export interface SaveQuestionnarieDetails {
  questionnaireId: number;
  question1AnswerId: PeriodQuestionnaireQuestionAnswer;
  question2AnswerId: Question2Choices;
  question3AnswerId: Question3Choices;
}

export enum PeriodQuestionnaireQuestionAnswer {
  Never = 1,
  Occasionally = 2,
  OnContinuousBasis = 3,
  NotSelected,
}

export enum Question2Choices {
  Yes = 4,
  No = 5,
  NotSelected,
}

export enum Question3Choices {
  Yes = 6,
  No = 7,
  NotSelected,
}
