import { useMutation } from 'react-query';

import { useToasts } from 'components/ToastProvider';
import { queryClient } from 'service/queryClient';
import {
  userRequirementsAttachmentsKey,
  useUserRequirementAttachmentsQuery,
} from 'Attachment/query/attachment.query';
import { attachmentApi } from 'Attachment/service/attachment.api';
import { AttachmentList } from 'Attachment/component/AttachmentList/AttachmentList';
import { FC } from 'react';

export interface UserRequirementAttachmentListProps {
  userId: string;
  requirementId: string;
  onAttachmentSuccess:  {() : void} | null;
}

export const RequirementAttachmentList: FC<UserRequirementAttachmentListProps> = ({ userId, requirementId, onAttachmentSuccess }) => {
  const { showError, showSuccess } = useToasts();
  const { data, isLoading } = useUserRequirementAttachmentsQuery(userId, requirementId);
  const { mutate: uploadAttachment } = useMutation(
    (files: File[]) => attachmentApi.uploadFilesByUserAndRequirementId(files, userId, requirementId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(userRequirementsAttachmentsKey);
        onAttachmentSuccess && onAttachmentSuccess();
        showSuccess('Upload attachment success');
      },
      onError: () => showError('Upload attachment failed'),
    }
  );

  return (
    <>
      <AttachmentList
        items={data?.result ?? []}
        loading={isLoading}
        allowRemoveAttachment={data?.canCurrentUserRemoveAttachments?? false}
        disableAddAttachment={data?.result?.length! >= 1 ?? false }
        onDropzoneSave={(files: File[]) => uploadAttachment(files)}
        userId={userId}
        requirementId={requirementId}
        filesLimit={1}
        noDataMessage="There are no attachments yet..."
        onRemoveRequirementAttachmentSuccess={onAttachmentSuccess}
      />
    </>
  );
};
