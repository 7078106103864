import React, { FC } from 'react';

import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { formatDate } from 'utils';
import { CertificationPlansDetails } from 'CertificationPlansPage/model/CertificationPlansDetails';
import { BoldTypography } from 'components/Page';
import {
  TitleTypography,
  DescriptionCaptionTypography,
} from './CatalogCertificateTable.style';

export const catalogColumns: DataTableColumn<CertificationPlansDetails>[] = [
  {
    field: 'title',
    headerName: 'Certificate',
    width: 450,
    renderField: ({ title, purpose }) => (
      <div>
        <TitleTypography variant="body2">{title}</TitleTypography>
        <DescriptionCaptionTypography variant="caption">
          {purpose}
        </DescriptionCaptionTypography>
      </div>
    ),
  },
  {
    field: 'businessUnit',
    headerName: 'Division',
    renderField: ({ businessUnit }) => <>{businessUnit.description}</>,
  },
  {
    field: 'level',
    headerName: 'Level',
    renderField: ({ level }) => level.description,
  },
  {
    field: 'scope',
    headerName: 'Scope',
    renderField: ({ scope }) => scope.description,
  },
  { field: 'release', headerName: 'Release' },
  {
    field: 'publishDate',
    headerName: 'Published on',
    renderField: ({ publishDate }) => formatDate(publishDate),
  },
  { field: 'expirationFrame', headerName: 'Expiration period' },
];

export const massEnrollmentColumns: DataTableColumn<
  CertificationPlansDetails
>[] = [
  {
    field: 'title',
    headerName: 'Certificate',
    width: 300,
    renderField: ({ title }) => (
      <BoldTypography variant="body2">{title}</BoldTypography>
    ),
  },
  {
    field: 'level',
    headerName: 'Level',
    renderField: ({ level }) => level.description,
  },
];

export interface CatalogCertificateTableProps
  extends DataTableProps<CertificationPlansDetails> {}

export const CatalogCertificateTable: FC<CatalogCertificateTableProps> = ({
  rows,
  columns,
  loading,
  ...otherProps
}) => (
  <DataTable
    {...otherProps}
    columns={columns}
    rows={rows}
    loading={loading}
    rowSelector={(row) => row.id}
    hideCollapseColumn
    noDataMessage="No certifications to display"
  />
);
