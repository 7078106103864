import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { ApprovalGroupUser } from '../model';

export interface ApprovalGroupUserListProps {
  items: ApprovalGroupUser[] | null;
  onDelete: (user: ApprovalGroupUser) => void;
}

export const ApprovalGroupUserList = (props: ApprovalGroupUserListProps) => {
  const { items, onDelete } = props;
  if (!Array.isArray(items)) {
    return null;
  }
  return items.length > 0 ? (
    <Paper elevation={0} variant="outlined">
      <List>
        {items.map((u) => (
          <ListItem key={u.userAuthorizationId} divider>
            <ListItemText primary={u.displayName} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => onDelete(u)}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  ) : (
    <Typography variant="body2">No users in approval group...</Typography>
  );
};
