import React, { FC } from 'react';

import { FormikAutocompleteField } from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { CertificationPlanLevelType } from 'enums/CertificationPlanLevelType';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface LevelSelectFieldProps {
  name: string;
  disabled?: boolean;
}

export const getLevelAutocompleteFieldItems = (): AutocompleteFieldItem[] =>
  Object.entries(CertificationPlanLevelType)
    .filter((value) => isNaN(parseInt(value.toString(), 10)))
    .map(([key, value]) => ({ id: value, name: key }));

export const LevelSelectField: FC<LevelSelectFieldProps> = ({
  name,
  disabled = false,
}) => (
  <FormikAutocompleteField
    name={name}
    label="Level"
    disabled={disabled}
    options={getLevelAutocompleteFieldItems()}
    multiple
  />
);
