import { FC, FormEvent, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useMutation, useQueryClient } from 'react-query';
import { ApiError } from 'model';
import { formatTemplate } from 'utils';
import { ModalContent } from 'components/Page';
import { Button } from 'components';
import { useToasts } from 'components/ToastProvider';
import { certificationPlansKey } from 'CertificationPlansPage/query/certificationPlansQuery';
import {
  certificationPlanWorkflowApi,
  UpdateDatesDto,
} from 'CertificationPlansPage/service/certificationPlanWorkflow.api';
import { requirementApi, UpdateRequirementsDatesDto } from 'Requirement/service/requirement.api';
import { StyledForm } from './EditDatesModal.style';
import { Loader } from '../../../components/Loader/Loader';

const formId = 'certification-plan-details-edit-dates';

export interface EditDatesModalProps {
  certificatePlanId?: number;
  requirementsIds?: number[];
  initialEnrollmentDate: string | null;
  initialSubmitDate: string | null;
  initialExpirationDate: string | null;
  initialApprovedDate: string | null;
  initialRenewalDate: string | null;
  initialDueDate: string | null;
  onClose: () => void;
  onSuccess?: () => void;
}
const getPickersDate = (dateOrNull: string | null): Dayjs | null =>
  dateOrNull ? dayjs(dateOrNull) : null;

const getUpdateDate = (dateOrNull: Dayjs | null): string | null =>
  dateOrNull ? dayjs(dateOrNull).toISOString() : null;

export const EditDatesModal: FC<EditDatesModalProps> = ({
  certificatePlanId,
  requirementsIds,
  initialEnrollmentDate,
  initialSubmitDate,
  initialExpirationDate,
  initialApprovedDate,
  initialRenewalDate,
  initialDueDate,
  onClose,
  onSuccess,
}) => {
  
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useToasts();
  const { mutate, isLoading } = useMutation<unknown, ApiError>(
    () => {
      if(requirementsIds) 
      return requirementApi.updateDates(
        getUpdateRequirementsDateDto(),
        certificatePlanId!
      );

      if(certificatePlanId)
         return certificationPlanWorkflowApi.updateDates(
            getUpdateDateDto(),
            certificatePlanId
          );      
      
      return Promise.resolve();
      },
    {
      onSuccess: () => {
        showSuccess('Update dates success');
        !requirementsIds && queryClient.invalidateQueries(certificationPlansKey);
        onSuccess && onSuccess();
        onClose();
      },
      onError: (error) => showError(error.message),
    }
  );

  const [enrollmentDate, setEnrollmentDate] = useState<Dayjs | null>(
    getPickersDate(initialEnrollmentDate)
  );
  const [submitDate, setSubmitDate] = useState<Dayjs | null>(
    getPickersDate(initialSubmitDate)
  );
  const [expirationDate, setExpirationDate] = useState<Dayjs | null>(
    getPickersDate(initialExpirationDate)
  );
  const [approvedDate, setApprovedDate] = useState<Dayjs | null>(
    getPickersDate(initialApprovedDate)
  );
  const [renewalDate, setRenewalDate] = useState<Dayjs | null>(
    getPickersDate(initialRenewalDate)
  );
  const [dueDate, setDueDate] = useState<Dayjs | null>(
    getPickersDate(initialDueDate)
  );

  const getUpdateDateDto = (): UpdateDatesDto => ({
    enrollmentDate: getUpdateDate(enrollmentDate),
    expirationDate: getUpdateDate(expirationDate),
    approvedDate: getUpdateDate(approvedDate),
    renewalDate: getUpdateDate(renewalDate),
    dueDate: getUpdateDate(dueDate),
  });

  const getUpdateRequirementsDateDto = (): UpdateRequirementsDatesDto => ({
    submitDate: getUpdateDate(submitDate),
    expirationDate: getUpdateDate(expirationDate),
    approvedDate: getUpdateDate(approvedDate),
    requirementsIds: requirementsIds!
  });

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate();
  };

  return (
    <ModalContent
      header="Change dates"
      renderActions={() => (
        <>
          <Button onClick={onClose}>Close</Button>
          <Button
            disabled={isLoading}
            type="submit"
            form={formId}
            color="secondary"
          >
            {isLoading ? <Loader /> : 'Save'}
          </Button>
        </>
      )}
    >
      <StyledForm id={formId} onSubmit={handleOnSubmit}>
        {enrollmentDate && (
          <KeyboardDatePicker
            variant="inline"
            inputVariant="outlined"
            label="Enrollment date"
            value={enrollmentDate}
            onChange={(date) => { date && setEnrollmentDate(date.set('hour',12))} }
            format={formatTemplate}
            disabled={isLoading}
            autoOk
          />
        )}
        {submitDate && (
          <KeyboardDatePicker
            variant="inline"
            inputVariant="outlined"
            label="Submit date"
            value={submitDate}
            onChange={(date) => { date && setSubmitDate(date.set('hour',12))} }
            format={formatTemplate}
            disabled={isLoading}
            autoOk
          />
        )}
        {approvedDate && (
          <KeyboardDatePicker
            variant="inline"
            inputVariant="outlined"
            label="Approval date"
            value={approvedDate}
            onChange={(date) => { date && setApprovedDate(date.set('hour',12))} }
            format={formatTemplate}
            disabled={isLoading}
            autoOk
          />
        )}
        {expirationDate && (
          <KeyboardDatePicker
            variant="inline"
            inputVariant="outlined"
            label="Expiration date"
            value={expirationDate}
            onChange={(date) => { date && setExpirationDate(date.set('hour',12))} }
            format={formatTemplate}
            disabled={isLoading}
            autoOk
          />
        )}        
        {renewalDate && (
          <KeyboardDatePicker
            variant="inline"
            inputVariant="outlined"
            label="Renewal date"
            value={renewalDate}
            onChange={(date) => { date && setRenewalDate(date.set('hour',12))} }
            format={formatTemplate}
            disabled={isLoading}
            autoOk
          />
        )}
        {dueDate && (
          <KeyboardDatePicker
            variant="inline"
            inputVariant="outlined"
            label="Due date"
            value={dueDate}
            onChange={(date) => { date && setDueDate(date.set('hour',12))} }
            format={formatTemplate}
            disabled={isLoading}
            autoOk
          />
        )}
      </StyledForm>
    </ModalContent>
  );
};
