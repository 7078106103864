import { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'components/ToastProvider';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { ListItem, ListItemType } from 'components/List';
import { Button } from 'components/Button';
import { userRequirementsAttachmentsKey, userAttachmentsKey, attachmentsKey } from 'Attachment/query/attachment.query';
import { attachmentApi } from 'Attachment/service/attachment.api';
import { Attachment } from 'Attachment/model/Attachment';
import { ListItemRoot, IconButtonRoot } from './AttachmentListItem.style';

export interface AttachmentListItemType extends ListItemType {
  attachment: Attachment;
}

export interface AttachmentListItemProps {
  item: AttachmentListItemType;
  removable: boolean;
  attachmentOwnerId?: string;
  attachmentRequirementId?: string;
  onRemoveRequirementAttachmentSuccess: {() : void} | null;
}

export const AttachmentListItem: FC<AttachmentListItemProps> = ({
  item,
  removable,
  attachmentOwnerId,
  attachmentRequirementId,
  onRemoveRequirementAttachmentSuccess
}) => {
  const { mutate: downloadAttachment } = useMutation((attachment: Attachment) =>
    attachmentApi.get(attachment.id, attachment.fileName)
  );
  const { showSuccess, showError } = useToasts();
  const queryClient = useQueryClient();
  const { mutate: removeAttachment } = useMutation(
    (id: Attachment['id']) => attachmentApi.remove(id),
    { onSuccess: () => {  
      if(attachmentRequirementId && attachmentOwnerId)
        queryClient.invalidateQueries([userRequirementsAttachmentsKey, attachmentOwnerId, attachmentRequirementId]);
      else if(attachmentOwnerId)    
        queryClient.invalidateQueries([userAttachmentsKey, attachmentOwnerId]);
      else
        queryClient.invalidateQueries(attachmentsKey);
        onRemoveRequirementAttachmentSuccess && onRemoveRequirementAttachmentSuccess();
      showSuccess('Attachment removed');
      },
      onError: () => showError('You are not authorized')
    }
  );

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleOnRemoveFile = (id: Attachment['id']) => {
    removeAttachment(id);
    setIsDialogOpen(false);
  };

  return (
    <ListItemRoot>
      <ListItem
        primaryText={item.primaryText}
        secondaryText={item.attachment.description}
        Icon={item.Icon}
        onClick={() => downloadAttachment(item.attachment)}
        button
      />
      {removable && (
        <>
          <IconButtonRoot>
            <IconButton onClick={() => setIsDialogOpen(true)}>
              <DeleteIcon />
            </IconButton>
          </IconButtonRoot>
          <Dialog open={isDialogOpen}>
            <DialogTitle>Are you sure to delete file?</DialogTitle>
            <DialogActions>
              <Button color="default" onClick={() => setIsDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={() => handleOnRemoveFile(item.attachment.id)}
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </ListItemRoot>
  );
};
