import { HtmlHTMLAttributes, memo } from 'react';
import { EnrollmentRequestStatus } from 'enums/EnrollmentRequestStatus';
import { AbbColor, StatusChip } from './StatusChip';

export interface EnrollmentRequestStatusChipProps
  extends Omit<HtmlHTMLAttributes<HTMLSpanElement>, 'children' | 'color'> {
  status: EnrollmentRequestStatus;
}

interface StatusTypeMap {
  label: string;
  color: AbbColor;
}

const mapCPStatusType = (status: EnrollmentRequestStatus): StatusTypeMap => {
  switch (status) {
    case EnrollmentRequestStatus.Pending:
      return {
        label: 'Pending',
        color: 'yellow',
      };
    case EnrollmentRequestStatus.Approved:
      return {
        label: 'Approved',
        color: 'green',
      };
    case EnrollmentRequestStatus.Rejected:
      return {
        label: 'Rejected',
        color: 'red',
      };
    default:
      return {
        label: 'Unknown',
        color: 'grey',
      };
  }
};

export const EnrollmentRequestStatusChip = (
  props: EnrollmentRequestStatusChipProps
) => {
  const { className, status, ...other } = props;
  const map = mapCPStatusType(status);
  return (
    <StatusChip
      className={className}
      label={map.label}
      color={map.color}
      variant="light"
      {...other}
    />
  );
};

export default memo(
  EnrollmentRequestStatusChip,
  (prev, next) => prev.status === next.status
);
