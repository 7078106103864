import { useState } from 'react';
import {
    Card,
    FluidForEditors,
    Typography,
    RoundButton,
    ActionsRoot,
    EditorHelper
} from 'components'

import * as Model from './model';
import { CertificationListForSelectionPart, CertificateLookupForField } from '../Certificate';

type DTOModel = Model.TrainingSetDTO

interface State {
    isAddingCertificate: boolean
}
export default function Editor(p: { helper: EditorHelper<DTOModel> }) {

    const Helper = p.helper

    const [state, setState] = useState<State>({ isAddingCertificate: false })

    function onStartAddingCertificates() {
        setState(c => ({ ...c, isAddingCertificate: true }))
    }

    function render() {
        return (<>
            <FluidForEditors inRow={1}>
                <Card header='Training set information'>
                    <FluidForEditors inRow={1}>
                        {Helper.TextField({ name: "name", label: "Title" })}
                    </FluidForEditors>
                    <FluidForEditors inRow={2}>
                        {Helper.DropDownField({ name: "forDivision", label: "Division", multiple: true, query: Model.API.dictionaryQuery({ key: 'Division' }) })}
                        {Helper.DropDownField({ name: "forCountry", label: "Country/Territory", multiple: true, query: Model.API.dictionaryQuery({ key: 'Country' }) })}
                        {Helper.DropDownField({ name: "forFunctionalJob", label: "Employee Job", multiple: true, query: Model.API.dictionaryQuery({ key: 'FunctionalJob' }) })}
                        {Helper.DropDownField({ name: "forFunctionalJobFamily", label: "Employee Job Family", multiple: true, query: Model.API.dictionaryQuery({ key: 'FunctionalJobFamily' }) })}
                        {Helper.DropDownField({ name: "forUserType", label: "Abb / External", query: Model.API.dictionaryQuery({ key: 'UserType' }) })}
                    </FluidForEditors>
                    <FluidForEditors inRow={1}>

                        {Helper.CheckboxField({
                            name: "reagisterAllUsers",
                            label: "Register all users with selecetd criteria",
                            helperText: "Users with selected attributes that already exist in the system will be automatically enrolled in all Certificates under the Training Set. Please ensure that the correct selections are made to avoid undesired mass enrollments."
                        })}

                        {Helper.CheckboxField({
                            name: "automaticRenewal",
                            label: "Automatic submission for renewal",
                            helperText: "If the Certificate under Training Set is not submitted for renewal during expiring period, the system will automatically submit it when the expiration date is passed."
                        })}


                    </FluidForEditors>
                </Card>

                <Card header='Manage current certificates'>
                    {!p.helper.values.certificates?.length ?
                        <Typography variant="body2" color='textSecondary'>Currently there are no certificates added. Click "Add certificate" button to add one.</Typography> :
                        <CertificateLookupForField name='certificates' />
                    }
                </Card>

                {!state.isAddingCertificate ?
                    <ActionsRoot><RoundButton color="secondary" variant="text" onClick={onStartAddingCertificates}>Add certificate</RoundButton></ActionsRoot> :
                    <Card header='Add existing certificate to the training set'>
                        <CertificationListForSelectionPart name='certificates' />
                    </Card>
                }
            </FluidForEditors>
        </>)
    }

    return render()
}

