import React, { FC, ElementType } from 'react';
import MUITablePagination, {
  TablePaginationProps as MUITablePaginationProps,
} from '@material-ui/core/TablePagination';

import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from 'model';

export type TablePaginationProps = Omit<
  MUITablePaginationProps,
  'count' | 'page' | 'rowsPerPage'
> & {
  pagination: Pagination;
};

export const TablePagination: FC<TablePaginationProps> = ({
  pagination,
  ...tablePaginationProps
}) => {

  // INFO:
  //  Poprawia stzałke do rozwijania rozmiaru strony. 
  //
  const useStyles = makeStyles({
    selectIcon: {
      position: "relative"
    }
  });
  const classes = useStyles();

  return (
    <MUITablePagination
      rowsPerPageOptions={[10, 20, 50]}
      classes={{ selectIcon: classes.selectIcon }}
      {...tablePaginationProps}
      page={pagination.currentPageIndex}
      count={pagination.total}
      rowsPerPage={pagination.pageSize}
      component={'div' as ElementType}
    />
  )
}
