import { FC } from 'react';

import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { formatDate } from 'utils';
import { MyLearningCourseHistory } from 'Certificate/model/MyLearningCourseHistory';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import { DataObjectView } from 'components';

export const columns: DataTableColumn<MyLearningCourseHistory>[] = [
  {
    field: 'code',
    headerName: 'Code',
  },
  {
    field: 'title',
    headerName: 'Title',
  },
  { 
    field: 'deliveryType', 
    headerName: 'Delivery Type' 
  },
  {
    field: 'updateDate',
    headerName: 'Date',
    renderField: ({ updateDate }) => formatDate(updateDate),
  },
  {
    field: 'regStatus',
    headerName: 'Status',
  },
];

export interface MyLearningCourseHistoryTableProps
  extends Omit<DataTableProps<MyLearningCourseHistory>, 'columns'> {}

// eslint-disable-next-line max-len
export const MyLearningCourseHistoryTable: FC<MyLearningCourseHistoryTableProps> = ({
  rows,
  ...otherProps
}) => (
  <>
    <MobileSection>
      <DataObjectList
        loading={otherProps.loading}
        items={rows}
        renderDataObjectView={(item) => (
          <DataObjectView
            title={item.code}
            item={item}
            dataSetMaps={mapToDataSetMap(columns, ['code'])}
          />
        )}
      />
    </MobileSection>
    <DesktopSection>
      <DataTable
        {...otherProps}
        columns={columns}
        rows={rows}
        rowSelector={(row) => row.code}
      />
    </DesktopSection>
  </>
);
