import React, { FC } from 'react';

import { StyledA } from './Anchor.style';

export interface SubheaderLinkProps {
  href: string;
  hover?: boolean;
}

export const Anchor: FC<SubheaderLinkProps> = ({
  href,
  hover = false,
  children,
}) => (
  <StyledA href={href} target="_blank" rel="noreferrer" $hover={hover}>
    {children}
  </StyledA>
);
