import { FC } from 'react';

import { Card } from 'components/Page';
import { MyProfile } from 'MyProfile/model/MyProfile';
import { AddUserRole } from 'MyProfile/component/AddUserRole';
import { UserRolesList } from 'MyProfile/component/UserRolesList';
import { IsExternalType } from 'Dictionary/component';
import {
  addExternalUserRoleOptions,
  addInternalUserRoleOptions,
} from 'MyProfile/component/AddUserRole/AddUserRole';

export interface ManageUserRoleProps {
  user: MyProfile;
  isExternalType: IsExternalType;
}

export const ManageUserRole: FC<ManageUserRoleProps> = ({
  user,
  isExternalType,
}) => (
  <div>
    <Card header="Add user role" transparent>
      <AddUserRole
        user={user}
        roleOptions={
          isExternalType === IsExternalType.External
            ? addExternalUserRoleOptions
            : addInternalUserRoleOptions
        }
      />
    </Card>
    <UserRolesList userId={user.id} removable />
  </div>
);
