import { makeStyles } from '@material-ui/core';

import { AbbTheme } from 'theme/createAbbTheme';

export const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    title:{
      margin: theme.spacing(0, 2),
    },
    commentHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 56,
      margin: theme.spacing(0, 2),
    },
    commentField: {
      flexGrow: 1,
      marginRight: theme.spacing(4),
    },
  }),
  { name: 'CertificationPlansRequirementsForm' }
);
