import React, { FC } from 'react';

import { FormikAutocompleteField } from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface ValiditySelectFieldProps {
  name: string;
}

export const getValidityAutocompleteFieldItems = (): AutocompleteFieldItem[] => [
  { id: 0, name: 'Not expire'},
  { id: 12, name: '12 months'},
  { id: 18, name: '18 months'},
  { id: 24, name: '24 months'},
  { id: 30, name: '30 months'},
  { id: 36, name: '3 years'},
  { id: 48, name: '4 years'},
  { id: 60, name: '5 years'},
  { id: 72, name: '6 years'},
  { id: 120, name: '10 years'},
  { id: 300, name: '25 years'},
];

export const ValiditySelectField: FC<ValiditySelectFieldProps> = ({
  name,
}) => (
  <FormikAutocompleteField
    name={name}
    label="Expiration period"
    options={getValidityAutocompleteFieldItems()}
    multiple
  />
);
