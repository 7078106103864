import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

export const TitleTypography = styled(Typography)<TypographyProps>`
  && {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`;

export const DescriptionCaptionTypography = styled(Typography)<TypographyProps>`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
`;
