import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { AppShellBaseProps } from 'components/AppShell';
import { PrintOutCertificatesPage } from 'page/PrintOutCertificatesPage/PrintOutCertificatesPage';
import { CertificationsByPersonDetailsParams } from 'route';

export const PrintOutUserCertificatesPage: FC<AppShellBaseProps> = (props) => {
  const { userId } = useParams<CertificationsByPersonDetailsParams>();

  return <PrintOutCertificatesPage userId={userId.toString()} {...props} />;
};
