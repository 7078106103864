import { BusinessUnit } from 'Dictionary/model/BusinessUnit';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface TrainingSet {
  id: number;
  name: string;
  businessUnit: BusinessUnit;
}

export const mapToAutocompleteFieldItems = (
  trainingSets: TrainingSet[] = []
): AutocompleteFieldItem[] =>
  trainingSets.map((set) => ({
    id: set.id,
    name: set.name,
  }));
