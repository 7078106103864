import { FC } from 'react';
import { useField } from 'formik';

import {
  AutocompleteField,
  AutocompleteFieldItem,
  AutocompleteFieldProps,
} from 'components/Field/AutocompleteField/AutocompleteField';

export type { AutocompleteFieldItem }
export interface FormikAutocompleteFieldProps extends AutocompleteFieldProps {
  name: string;
  onFieldChange?: (
    value?: AutocompleteFieldItem | AutocompleteFieldItem[]
  ) => void;
}

export const FormikAutocompleteField: FC<FormikAutocompleteFieldProps> = ({
  multiple = false,
  onFieldChange,
  ...props
}) => {
  const [field, meta, helpers] = useField({ name: props.name });

  const getDefaultOptions = (): AutocompleteFieldProps['options'] => {
    if (props.options.length) {
      return props.options;
    }
    if (multiple && !!field.value) {
      return field.value;
    }
    if (!multiple && field.value) {
      return [field.value];
    }
    return [];
  };

  const handleChange: AutocompleteFieldProps['onChange'] = (
    event,
    value,
    reason,
    details?
  ) => {
    helpers.setValue(value);
    if (onFieldChange) {
      onFieldChange(value);
    }
  };

  const getHelperText = () => {
    if (meta.error) {
      return meta.error;
    }
    if (props.helperText) {
      return props.helperText;
    }
    return undefined;
  };

  return (
    <AutocompleteField
      {...props}
      id={field.name}
      label={props.label}
      value={field.value}
      options={getDefaultOptions()}
      multiple={multiple}
      error={!!meta.error}
      helperText={getHelperText()}
      onChange={handleChange}
    />
  );
};
