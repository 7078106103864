import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

interface AxiosResponseData {
  ReferenceId: string;
  Url: string;
  RequestContent?: string;
  Error: {
    Type: string;
    Message: string;
  };
  errors: [
    {
      field: string;
      message: string;
    }
  ]
}

const getDefaultAxiosResponseData: () => AxiosResponseData['Error'] = () => ({
  Type: '',
  Message: '',
});
const getDefaultAxiosResponseValidationData: () => AxiosResponseData['errors'] = () => ([{
  field: '',
  message: '',
}]);

export class ApiError extends Error {
  code: number;

  referenceId: string = '';

  url: string = '';

  requestContent: string = '';

  error: AxiosResponseData['Error'] = getDefaultAxiosResponseData();

  errors: AxiosResponseData['errors'] = getDefaultAxiosResponseValidationData();

  constructor(_o: AxiosError<AxiosResponseData> | unknown) {
    super();

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
    this.name = 'ApiError';

    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const axiosError = _o as any

    if (Array.isArray(axiosError.response?.data?.errors))
      this.message = axiosError.response?.data?.errors[0]?.message ?? 'An error occurs';
    else
      this.message = axiosError.response?.data?.Error?.Message ?? 'An error occurs';
    this.code = axiosError?.code
      ? parseInt(axiosError?.code, 10)
      : StatusCodes.INTERNAL_SERVER_ERROR;
    if (axiosError?.response) {
      const response = axiosError?.response;
      this.referenceId = response?.data?.ReferenceId ?? '';
      this.url = axiosError.response?.data?.Url ?? '';
      this.requestContent = response?.data.RequestContent ?? '';
      this.error = response?.data?.Error ?? getDefaultAxiosResponseData();
    }
  }
}
