import { ReactNode, useEffect } from 'react';
import { Loader } from 'components/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { myProfileRoute } from 'route';
import { Typography, useTheme } from '@material-ui/core';
import { useMyProfileQuery } from '../MyProfile/query';

export interface ProfileLoaderProps {
  renderContent: (userNeedToUpdateProfile: boolean) => ReactNode;
}

export const ProfileLoader = (props: ProfileLoaderProps) => {
  const { renderContent } = props;
  const theme = useTheme();
  const { isLoading, data: profile } = useMyProfileQuery();
  const history = useHistory();
  useEffect(() => {
    if (profile && profile.userNeedToUpdateProfile) {
      history.replace(myProfileRoute.path);
    }
  }, [profile]);
  return (
    <div>
      {isLoading ? (
        <Loader style={{ marginTop: theme.spacing(8) }}>
          <Typography style={{ marginTop: theme.spacing(3) }}>
            Loading user profile...
          </Typography>
        </Loader>
      ) : (
        renderContent(profile?.userNeedToUpdateProfile ?? true)
      )}
    </div>
  );
};
