import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik'

import { usePCSContext } from 'Router/PCSContext';
import * as route from 'route';
import { useMyProfileQuery } from 'MyProfile/query';

import {
    TabsView,
    Loader,
    Content,
    useToasts,
    RoundButton,
    DetailsAppShell,
    EditorHelper,
    EditorBase,
    Yup
} from 'components'

import { AlternativesCardPart } from './AlternativesCardPart';
import { RequirementsFormPart } from './RequirementsCardPart';
import { PrintOptionsCardPart } from './PrintOptionsCardPart';
import { RecommendedCardPart } from './RecommendedCardPart';
import { PrerequisitesCardPart } from './PrerequisitesCardPart';
import { AvailabilityCardPart } from './AvailabilityCardPart';
import { HeaderDataCardPart } from './HeaderDataCardPart';
import { LifecycleCardPart } from './LifecycleCardPart';

import { API, KnownCertificateListStatus } from './model';
import * as Model from './model';
import { AutomaticEnrollmentCardPart } from './AutomaticEnrollmentCardPart';

export interface FromRouteParams {
    itemId: string;
}

type DTOModel = Model.CertificateListDefinitionDTO
type TDataModel = DTOModel
type TQueryModel = Model.CertificateListDefinitionFindQuery


export function EditorPage() {
    const { itemId } = useParams<FromRouteParams>();
    const itemIdParsed = parseInt(itemId, 10)
    return <EditorPagePart itemId={itemIdParsed} />
}

function EditorPagePart(p: { itemId: number }) {

    const [state, setState] = useState<{
        item: TDataModel
    } | undefined>()

    const history = useHistory();
    const feedback = useToasts();
    const pcsContext = usePCSContext();
    const currentUser = useMyProfileQuery().data;

    const itemIdParsed = p.itemId

    const settings: DetailsPageSettings<TDataModel, TQueryModel> = {
        contract: API.ICertificateListContract,
        newItem: () => ({
            id: 0,
            release: '1.0',
            status: { id: 0, display: "Draft", code: KnownCertificateListStatus.Temporary },
            forChannels: [],
            forCountries: [],
            forDivisions: [],
            validFor: [],
            workType: [],
            owner:{
                email: currentUser!.email,
                firstName: currentUser!.firstName,
                fullDisplayName: `${currentUser!.firstName} ${currentUser!.lastName} (${currentUser!.email})`,
                geid: currentUser!.geid,
                id: currentUser!.id,
                lastName: currentUser!.lastName,
            },
            prerequisites: [],
            scopeDetail: [],
            recommended: [],
            automaticEnrollment: [],
            alternatives: [],
            requirements: [],
            identificators: [],
            history: [],
            expirationDate: { id: 1, code: "FIXED", display: "Fixed for all enrolled users" },
            renewalApproverType:  { id: 0, code: "NONE", display: "Not needed" }
        }),
        validationSchema: Yup.object<Partial<Record<keyof TDataModel, Yup.AnySchema>>>({
            division: Yup.object().nullable().required(),
            businessArea: Yup.object().nullable().required(),
            scope: Yup.object().nullable().required(),
            scopeDetail: Yup.array().min(1).required(),
            level: Yup.object().nullable().required(),
            mainFunction: Yup.object().nullable().required(),
            owner: Yup.object().nullable().required(),
            //purpose: Yup.string().required().min(2),
            title: Yup.string().required().min(2),
            duration: Yup.string().nullable().test("checkIsFormatCorrect", "Incorrect duration format", 
                (value) => new Promise((resolve) => {
                    if(value){
                        const regex=/^[0-9:]*$/;
                        resolve(regex.test(value!));
                    }
                    resolve(true);
                })          
            ),

            validFor: Yup.array().required(),
            workType: Yup.array().required(),

            completionApproverType: Yup.object().nullable().required(),
            //completionApproverType: Yup.object().nullable().required(),
            behavior: Yup.object().nullable().required(),

            forUserType: Yup.object().nullable().required(),
        }),
        pageSettings: {
            title: (item: DTOModel) => item.id > 0 ? item.title : "Define new Certificate",
            subtitle: (item: DTOModel) => "Certificate Certificate Certificate",
            details: {
                routeToNew: (itemId: number) => route.managTrainingSetDetailsRoute.createRoute({ itemId })
            },
        },
    }

    function loadDataBase() {
        if (itemIdParsed === 0)
            setState(s => ({ item: settings.newItem() as TDataModel }))
        else
            settings.contract
                .get(itemIdParsed)
                .then(x => ({ ...{ alternatives: [], requirements: [], identificators: [], history: [] }, ...x } as TDataModel))
                .then(x => setState(s => ({ item: x })))
    }

    async function doUpdate(row: TDataModel) {
        const updateResult = await API.ICertificateListContract.update(row) as { itemID: number };

        try {
            if (row.id === 0) {
                feedback.showSuccess('Item added ...page will refresh')
                const targetRoute = route.manageCertificatesDetailsRoute.createRoute({ itemId: updateResult.itemID.toString() })
                history.replace(targetRoute)
                window.location.reload()

            }
            else {
                feedback.showSuccess('Item updated')
                loadDataBase()
            }
        } catch (e) {
            //@ts-ignore checked
            feedback.showError((e && e.message) ? `Cannot update item. Additional message: ${e.message}` : "Cannot update item.")
        }
    }
    function doRemoveDraft(row: TDataModel) { API.ICertificateListContract.RemoveDraft(row) }
    function doSaveAsDraft(row: TDataModel) { doUpdate({ ...row, status: { id: 0, code: KnownCertificateListStatus.Draft, display: "" } }) }
    function doSubmitForApproval(row: TDataModel) { doUpdate({ ...row, status: { id: 0, code: KnownCertificateListStatus.WaitingApproval, display: "" } }) }
    function doRePublish(row: TDataModel) { doUpdate({ ...row, status: { id: 0, code: KnownCertificateListStatus.Published, display: "" } }) }

    ///
    ///
    ///
    const onCommit = doUpdate

    ///
    /// Effectss
    React.useEffect(loadDataBase, [])

    ///
    /// Render
    function renderActions(helper: EditorHelper<TDataModel>) {

        // eslint-disable-next-line

        const onRemoveDraft = () => helper.isValid && doRemoveDraft(state!.item)
        const onSaveAsDraft = () => helper.isValid && doSaveAsDraft(helper.values)
        const onSubmitForApproval = () => helper.isValid && doSubmitForApproval(helper.values)
        const onRePublish = () => helper.isValid && doRePublish(helper.values)

        return (<>
            {(helper.values.status.code !== KnownCertificateListStatus.Temporary &&
                helper.values.status.code !== KnownCertificateListStatus.Removed &&
                helper.values.status.code !== KnownCertificateListStatus.Imported &&
                helper.values.status.code !== KnownCertificateListStatus.Draft) ? <></> :
                <RoundButton variant="outlined" onClick={onSaveAsDraft}>
                    Save as draft
                </RoundButton>
            }
            {helper.values.status.code !== KnownCertificateListStatus.Draft ? <></> :
                <RoundButton variant="outlined" onClick={onRemoveDraft}>
                    Delete Draft
                </RoundButton>}
            {(helper.values.status.code !== KnownCertificateListStatus.Draft &&
                helper.values.status.code !== KnownCertificateListStatus.Imported) ? <></> :
                <RoundButton color="secondary" variant="contained" onClick={onSubmitForApproval}>
                    Submit for Approval
                </RoundButton>}
            {(helper.values.status.code !== KnownCertificateListStatus.Published &&
                helper.values.status.code !== KnownCertificateListStatus.Withdrawn) ? <></> :
                <RoundButton color="secondary" variant="contained" onClick={onRePublish}>
                    RePublish
                </RoundButton>}
        </>)
    }

    function PageContent(p: { item: DTOModel }) {
        return <>
            <Formik
                initialValues={p.item}
                validationSchema={settings.validationSchema}
                validateOnMount={false}
                validateOnBlur={true}
                onSubmit={values => onCommit(values)}
            >
                {formikProps => {
                    const helper = EditorBase.getHelper<TDataModel>(formikProps, () => { }, onCommit)
                    const renderActionsPart = () => renderActions(helper)
                    return (<Content
                        loading={!state}
                        title={(p.item.id === 0) ? "Define new Certificate" : helper.values.title}
                        subtitle={`Release ${helper.values.release} | ${helper.values.status.display}`}
                        renderActions={renderActionsPart}>
                        <Editor helper={helper as EditorHelper<Partial<TDataModel>>} />
                    </Content>)
                }}
            </Formik>
        </>
    }

    const toEdit = state?.item

    return (
        <DetailsAppShell
            title={toEdit ? settings.pageSettings.title(toEdit) : ""}
            subtitle={toEdit ? settings.pageSettings.subtitle(toEdit) : ""}
            loading={!state}
            defaultGoBackPath=".."
            logout={pcsContext?.onLogout}
        >
            {!state ? <Loader /> : <PageContent item={state.item} />}
        </DetailsAppShell>
    )
}
function Editor(p: { helper: EditorHelper<Partial<TDataModel>> }) {
    const [tab, setTab] = useState(0);
    return (<TabsView

        tabIndex={tab}
        onChange={setTab}
        labels={[
            "Header Data",
            "Lifecycle",
            "Availability",
            "Prerequisites",
            "Requirements",
            "Alternatives",
            "Print Options",
            "Recommended",
            "Automatic Enrollment"]} >

        {tab === 0 ? <HeaderDataCardPart helper={p.helper} /> : <></>}
        {tab === 1 ? <LifecycleCardPart helper={p.helper} /> : <></>}
        {tab === 2 ? <AvailabilityCardPart /> : <></>}
        {tab === 3 ? <PrerequisitesCardPart /> : <></>}
        {tab === 4 ? <RequirementsFormPart /> : <></>}
        {tab === 5 ? <AlternativesCardPart /> : <></>}
        {tab === 6 ? <PrintOptionsCardPart /> : <></>}
        {tab === 7 ? <RecommendedCardPart /> : <></>}
        {tab === 8 ? <AutomaticEnrollmentCardPart /> : <></>}

    </TabsView>)
}