import { FC } from 'react';
import MovieIcon from '@material-ui/icons/Movie';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { List, ListItem, ListItemType } from 'components/List';
import { Anchor } from 'components/Page/Anchor/Anchor';

interface MoviesListItem extends ListItemType {
  href: string;
}

const items: MoviesListItem[] = [
  {
    primaryText: 'All PCS2 files',
    Icon: ArrowForwardIcon,
    href:
      'https://library.abb.com/r?cid=9AAC819821',
  },
  {
    primaryText: 'New UI demo',
    secondaryText: 'Duration: 1h 3min',
    Icon: MovieIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK108466A2801&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'Basics - PCS2 Cheat Sheet',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A6371&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'Learner - manage your Certificates and Requirements',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4858&LanguageCode=en&DocumentPartId=&Action=Launch',
  },{
    primaryText: 'Learner - print your Certificates',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4603&LanguageCode=en&DocumentPartId=&Action=Launch',
  },{
    primaryText: 'Learner - search and enroll for a Certificate',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4856&LanguageCode=en&DocumentPartId=&Action=Launch',
  },{
    primaryText: 'Learner - update My Profile',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4714&LanguageCode=en&DocumentPartId=&Action=Launch',
  },{
    primaryText: 'Manager - approve Pending Requests',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4731&LanguageCode=en&DocumentPartId=&Action=Launch',
  },{
    primaryText: 'Manager - check user\'s MyLearning history',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4727&LanguageCode=en&DocumentPartId=&Action=Launch',
  },{
    primaryText: 'Manager - manage your Employees by using User Management',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4891&LanguageCode=en&DocumentPartId=&Action=Launch',
  },{
    primaryText: 'Manager - Manual Enrollment',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4726&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'Manager - Bulk upload',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK108468A0289&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'Manager - use Report Settings',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4870&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'Manager - Use Reporting to track Learner progress',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4866&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'Manager - Int/Ext Company Coordinator role',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK108469A0925&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'System Admin - manage Certificates',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4909&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'System Admin - manage Companies',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4746&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'System Admin - manage Old Enrollments',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK108468A6999&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'System Admin - manage Training Sets',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK107992A4914&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'Notifications in PCS2',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK108466A5287&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
  {
    primaryText: 'PCS2 Roles',    
    Icon: ArrowForwardIcon,
    href:
      'https://search.abb.com/library/Download.aspx?DocumentID=9AKK108469A3654&LanguageCode=en&DocumentPartId=&Action=Launch',
  },
];

export const TrainingMoviesList: FC = () => (
  <List
    items={items}
    renderItem={(item) => (
      <Anchor href={item.href}>
        <ListItem {...item} button />
      </Anchor>
    )}
  />
);
