import { useMutation } from 'react-query';

import { useToasts } from 'components/ToastProvider';
import { queryClient } from 'service/queryClient';
import {
  userAttachmentsKey,
  useUserAttachmentsQuery,
} from 'Attachment/query/attachment.query';
import { attachmentApi } from 'Attachment/service/attachment.api';
import { AttachmentList } from 'Attachment/component/AttachmentList/AttachmentList';
import { FC } from 'react';

export interface UserAttachmentListProps {
  userId: string;
}

export const UserAttachmentList: FC<UserAttachmentListProps> = ({ userId }) => {
  const { showError, showSuccess } = useToasts();
  const { data, isLoading } = useUserAttachmentsQuery(userId);  
  const { mutate: uploadAttachment } = useMutation(
    (files: File[]) => attachmentApi.uploadFilesByUserId(files, userId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([userAttachmentsKey, userId]);
        showSuccess('Upload attachment success');
      },
      onError: () => showError('Upload attachment failed'),
    }
  );

  return (
    <AttachmentList
      items={data?.result ?? []}
      loading={isLoading}
      onDropzoneSave={(files: File[]) => uploadAttachment(files)}
      allowRemoveAttachment={data?.canCurrentUserRemoveAttachments?? false}
      userId={userId}
      onRemoveRequirementAttachmentSuccess={null}
    />
  );
};
