import { FC } from 'react';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from '../../../components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { AutocompleteFieldItem } from '../../../components/Field/AutocompleteField/AutocompleteField';
import { TaskStatus } from '../../model/TaskStatus';

export interface TaskStatusSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {}

export const getTaskStatusItems = (): AutocompleteFieldItem[] => [
  { id: TaskStatus.All, name: 'All' },
  { id: TaskStatus.Open, name: 'Open' },
  { id: TaskStatus.Close, name: 'Close' },
];

export const TaskStatusSelectField: FC<TaskStatusSelectFieldProps> = (
  props
) => {
  const items = getTaskStatusItems();
  return (
    <FormikAutocompleteField label="Task status" options={items} {...props} />
  );
};
