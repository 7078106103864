import { useState } from 'react';
import { Grid } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/VerticalAlignBottom';
import { useMutation } from 'react-query';

import { Content } from 'components/Page';
import { ByPersonFiltersForm, ByPersonTable } from 'ByPerson/component';
import { useByPersonQuery } from 'ByPerson/query/byPersonQuery';
import { ByPerson } from 'ByPerson/model/ByPerson';
import { getPagination, Pagination, Sort } from 'model';
import { TablePagination } from 'components/Table';
import { Button } from 'components';
import { Loader } from 'components/Loader/Loader';
import {
  ByPersonSearchFilters,
  defaultSearchFilters,
} from 'ByPerson/model/ByPersonSearchFilters';
import { useToasts } from 'components/ToastProvider';
import { byPersonApi } from 'ByPerson/service/byPerson.api';

const byPersonTablePageSize = 20;
const initialPagination: Pagination = {
  total: 0,
  pageSize: byPersonTablePageSize,
  currentPageIndex: 0,
};

const initialSort: Sort<ByPerson> = {
  sortExpression: 'displayName',
  sortDescending: false,
};

export const CertificationsByPersonPage = () => {
  const { showSuccess, showError } = useToasts();
  const [pagination, setPagination] = useState<Pagination>(
    getPagination(byPersonTablePageSize)
  );
  const [sort, setSort] = useState<Sort<ByPerson>>(
    initialSort
  );
  const [filters, setFilters] = useState<ByPersonSearchFilters>(
    defaultSearchFilters
  );
  const { data, isLoading, isFetching } = useByPersonQuery(
    pagination,
    sort,
    filters,
    {
      onSuccess: (newData) =>
        setPagination(getPagination(byPersonTablePageSize, newData)),
    }
  );
  const {
    mutate: exportToExcel,
    isLoading: isExportToExcelLoading,
  } = useMutation(() => byPersonApi.exportToExcel(filters), {
    onSuccess: () => showSuccess('Export to excel success'),
    onError: () => showError('Export to excel failed. Too many records on search. Must be less than 10000.'),
  });

  return (
    <Content
      title="Certifications by person"
      subtitle="You will find on the list all people you have been authorized to plus all people defined into the system that report hierarchically to you."
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ByPersonFiltersForm
            renderPageActions={() => (
              <Button
                onClick={() => exportToExcel()}
                disabled={isExportToExcelLoading}
              >
                {isExportToExcelLoading ? (
                  <Loader size={24} />
                ) : (
                  <>
                    <ExportIcon /> &nbsp;Export to excel
                  </>
                )}
              </Button>
            )}
            onFiltersSubmit={(searchFilters) => {
              setFilters(searchFilters);
              setPagination(initialPagination);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ByPersonTable
            rows={data?.result ?? []}
            loading={isLoading || isFetching}
            onSortRequest={(direction, sortExpression) =>
              setSort({
                sortExpression,
                sortDescending: direction === 'desc',
              })
            }
            defaultSort={initialSort}
            enableSort
          />
          {!isFetching && (
            <TablePagination
              rowsPerPageOptions={[]}
              pagination={pagination}
              onChangePage={(event, currentPageIndex) =>
                setPagination((prevPagination) => ({
                  ...prevPagination,
                  currentPageIndex,
                }))
              }
            />
          )}
        </Grid>
      </Grid>
    </Content>
  );
};
