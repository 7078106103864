import { FC, useState } from 'react';

import { AppShellBaseProps, DetailsAppShell } from 'components/AppShell';
import { Content } from 'components/Page';
import { RoundButton } from 'components';
import { MyProfile } from 'MyProfile/model/MyProfile';
import { IsExternalType } from 'Dictionary/component';
import { AddUsers } from 'MyProfile/component/AddUsers';
import { useToasts } from 'components/ToastProvider';
import { useMutation } from 'react-query';
import { AddUserResponse } from 'MyProfile/model/AddUserResponse';
import { ApiError } from 'model';
import { myProfileApi } from 'MyProfile/service/myProfile.api';
import { AddExternalDto, mapUsersToDto } from 'MyProfile/model/AddExternalDto';
import { externalUsersRoute } from 'route';

export interface AddExternalUsersPageProps extends AppShellBaseProps { }

export const AddExternalUsersPage: FC<AddExternalUsersPageProps> = ({
  logout,
}) => {
  const { showError, showWarning, showMany } = useToasts();
  const [selectedUsers, setSelectedUsers] = useState<MyProfile[]>([]);
  const { mutate: addUsersToPcs, isLoading: addingUsersLoading } = useMutation<
    AddUserResponse,
    ApiError,
    AddExternalDto[]
  >((usersToAdd) => myProfileApi.addExternals(usersToAdd), {
    onSuccess: (response) => {
      if (response.failed.length && !response.success.length) {
        showMany("Action failed for all users", response.failed.map(response => response.message), true);
      }
      if (!response.failed.length && response.success.length) {
        showMany("Users were added successfully", response.success.map(response => response.userEmail), false);        
        setSelectedUsers([]);
      }
      if (response.failed.length && response.success.length) {
        const addedUserEmails: string[] = response.success.map(
          (userResponse) => userResponse.userEmail
        );
        showWarning('Some users were not added to PCS2');        
        showMany("Added users", response.success.map(response => response.userEmail), false);
        showMany("Failed users", response.failed.map(response => response.message), true);        
        setSelectedUsers((prevState) =>
          prevState.filter((user) => !addedUserEmails.includes(user.email))
        );
      }
    },
    onError: () => showError('Add users to PCS action failed'),
  });
  const handleOnAddUsers = () => addUsersToPcs(mapUsersToDto(selectedUsers));
  const handleSetSelectedUsers = (users: MyProfile[]) =>
    setSelectedUsers(users);
  return (
    <DetailsAppShell
      title="Add external user"
      subtitle="External users list"
      logout={logout}
      defaultGoBackPath={externalUsersRoute.path}
    >
      <Content
        mutating={addingUsersLoading}
        mutatingLabel="Adding users to PCS2..."
        title="Add external user"
        subtitle="Here you can define new external users to be added to PCS2"
        renderActions={() => (
          <RoundButton
            color="secondary"
            variant="contained"
            disabled={!selectedUsers.length}
            onClick={handleOnAddUsers}
          >
            Add users to PCS2
          </RoundButton>
        )}
      >
        <AddUsers
          isExternalType={IsExternalType.External}
          selectedUsers={selectedUsers}
          setSelectedUsers={handleSetSelectedUsers}
        />
      </Content>
    </DetailsAppShell>
  );
};
