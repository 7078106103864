import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { Form, Formik } from 'formik';
import { AccordionActions, Grid, Button } from '@material-ui/core';
import { ApprovalGroupUser } from '../model';
import { CountriesSelectWithAllField } from '../../Dictionary/component';
import { HrgtUserSelectField } from '../../Dictionary/component/HrgtUserSelectField/HrgtUserSelectField';
import { Card } from '../../components/Page/Card/Card';
import { uuid } from '../../utils';

interface UserAutocompleteFieldItem extends AutocompleteFieldItem {
  geid?: string;
  email?: string;
}

interface ApprovalGroupUserAddFormData {
  country: AutocompleteFieldItem;
  user: UserAutocompleteFieldItem;
}

const useApprovalGroupUserAddFormInitialValues: ApprovalGroupUserAddFormData = {
  country: {},
  user: {},
};

export interface ApprovalGroupUserAddFormProps {
  className?: string;
  onAddUser: (user: ApprovalGroupUser) => void;
}

export const ApprovalGroupUserAddForm = (
  props: ApprovalGroupUserAddFormProps
) => {
  const { className, onAddUser } = props;
  return (
    <Formik
      initialValues={useApprovalGroupUserAddFormInitialValues}
      onSubmit={(values, helpers) => {
        helpers.resetForm();
        const countryId = Number(values?.country?.id);
        const countryName = String(values?.country?.name);
        const userID = Number(values?.user?.id === values?.user?.geid ? 0 : values?.user?.id);
        const userGeid = String(values?.user?.geid);
        const user: ApprovalGroupUser = {
          country: { id: countryId, code: '', name: countryName },
          userId: userID,
          displayName: `${values?.user?.name} - ${countryName}`,
          userName: values?.user?.email ?? '',
          userAuthorizationId: -1,
          geid: userGeid,
          id: uuid(),
        };
        onAddUser(user);
      }}
    >
      {({ resetForm, values }) => (
        <Form>
          <Card
            className={className}
            header="Add user to the group"
            elevation={0}
          >
            <Grid container spacing={3}>
              <Grid item xl={2} lg={3} md={5} sm={12} xs={12}>
                <CountriesSelectWithAllField
                  name="country"
                  label="Country/Territory"
                  required
                  multiple={false}
                  disableClearable
                />
              </Grid>
              <Grid item xl={10} lg={9} md={7} sm={12} xs={12}>
                <HrgtUserSelectField name="user" />
              </Grid>
            </Grid>
            <AccordionActions>
              <Button onClick={() => resetForm()}>Clear</Button>
              <Button disabled={!values.user.name || !values.country.name} color="secondary" type="submit">
                Add
              </Button>
            </AccordionActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
