import { useState } from 'react';
import { Content } from 'components/Page';
import {
  ApprovalGroupsTable,
  BusinessUnitSearchBar,
  editApprovalGroupMutationKey,
} from 'ApprovalGroupsPage/components';
import { BusinessUnit } from 'Dictionary/model/BusinessUnit';
import { useIsMutating } from 'react-query';

export const ApprovalGroupsPage = () => {
  const [businessUnit, setBusinessUnit] = useState<BusinessUnit | null>(null);
  const mutating = useIsMutating({ mutationKey: editApprovalGroupMutationKey });
  return (
    <Content
      title="Approval groups"
      subtitle="Here you can add new or manage existing approval groups."
      mutating={Boolean(mutating)}
    >
      <BusinessUnitSearchBar onBuChange={setBusinessUnit} />
      <ApprovalGroupsTable businessUnit={businessUnit} />
    </Content>
  );
};
