import { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { ColumnDefinition, ReportPageTypes } from 'Reports/model';
import { FilterFieldFactory } from './FilterFieldFactory';
import { FilterChangeHandler, ReportingFilters } from './@types';

export interface AdvancedFiltersScopeProps {
  columnsDefinition: ColumnDefinition[];
  onFilterChange: FilterChangeHandler;
  filters: ReportingFilters;
  reportType: ReportPageTypes;
}

export const AdvancedFiltersScope = (props: AdvancedFiltersScopeProps) => {
  const { columnsDefinition, onFilterChange, filters, reportType } = props;
  const columnsScoped = useMemo(
    () => columnsDefinition.filter((x) => x.filter === 'advanced'),
    [columnsDefinition]
  );
  return (
    <Grid container spacing={3}>
      {columnsScoped.map((f) => (
        <Grid key={f.cid} item lg={4} md={4} sm={6} xl={4} xs={12}>
          <FilterFieldFactory
            columnDefinition={f}
            filters={filters}
            onFilterChange={onFilterChange}
            reportType={reportType}
          />
        </Grid>
      ))}
    </Grid>
  );
};
