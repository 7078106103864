import { FC } from 'react';

import { useCountriesQuery } from 'Dictionary/query/dictionaryQuery';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface CountriesSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {
  label?: string
}

export const CountriesSelectField: FC<CountriesSelectFieldProps> = (props) => {
  const { data = [], isLoading, refetch } = useCountriesQuery();

  return (
    <FormikAutocompleteField
      options={data} 
      label={props.label ?? "Country/Territory"}
      loading={isLoading}
      onOpen={() => refetch()}
      multiple
      {...props}
    />
  );
};
