import React from 'react';
import {Button, makeStyles } from '@material-ui/core';
import { AuthError } from 'msal';
import Typography from '@material-ui/core/Typography';

import { AbbLogo } from 'components/AbbLogo/AbbLogo';

const useStyles = makeStyles(
  //@ts-ignore
  (theme: AbbTheme) => ({
    root: {
      display: 'block',
      boxSizing: 'border-box',
      paddingBottom: theme.spacing(4),
      position: 'relative',
      width: '100%',
      maxWidth: 720,
      minHeight: 360,
      marginTop: theme.spacing(4),
      marginRight: 'auto',
      marginLeft: 'auto',
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[6],
      backgroundImage: `url(${process.env.PUBLIC_URL}/img/splash.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    version: {
      position: 'absolute',
      top: theme.spacing(2),
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: 12,
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.grey[900],
    },
    logo: {
      position: 'absolute',
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
    rights: {
      position: 'absolute',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      fontSize: 12,
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.grey[900],
    },
    content: {
      display: 'block',
      width: '100%',
      boxSizing: 'border-box',
      padding: theme.spacing(31.25, 4, 4, 4),
    },
    login: {
      minWidth: 164,
      marginTop: theme.spacing(4),
    },
    error: {
      marginTop: theme.spacing(3),
      color: theme.palette.error.main,
    },
  }),
  { name: 'Login' }
);

export interface LoginProps {
  login: () => void;
  error?: AuthError;
}

export const Login = (props: LoginProps) => {
  const { login, error } = props;
  error &&
    // eslint-disable-next-line no-console
    console.warn(error.errorCode || '', error.name || '', error.message || '');
  const classes = useStyles(props);
  const version = process.env.REACT_APP_VERSION;
  const currentYear = new Date().getUTCFullYear();

  return (
    <section className={classes.root}>
      <Typography component="span" className={classes.version}>
        People Certification System {version}
      </Typography>
      <Typography component="span" className={classes.rights}>
        All rights reserved {currentYear}
      </Typography>
      <div className={classes.logo}>
        <AbbLogo variant="regular" />
      </div>
      <div className={classes.content}>
        <Typography variant="h2">PCS2</Typography>
        <Button
          variant="contained"
          color="secondary"
          className={classes.login}
          onClick={login}
          size="large"
        >
          Login
        </Button>
      </div>
    </section>
  );
};

Login.defaultProps = {
  error: false,
};
