import { FC } from 'react';

import { DataTable, DataTableColumn } from 'components/DataTable/DataTable';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import { DataObjectView } from 'components';

interface RoleInPcsRow {
  role: string;
  definedFor: string;
  enroll: string;
  approveObjective: string;
  certificationPlan: string;
  clBuilder: string;
  clApprover: string;
  externalUserManagement: string;
  browseReports: string;
}

const rows: RoleInPcsRow[] = [
  {
    role: 'Administrator',
    definedFor: 'All Division',
    enroll: 'Yes',
    approveObjective: 'Yes',
    certificationPlan: 'Yes',
    clBuilder: 'Yes',
    clApprover: 'Yes',
    externalUserManagement: 'Yes',
    browseReports: 'Yes',
  },
  {
    role: 'Approver (Group)',
    definedFor: 'Division',
    enroll: '-',
    approveObjective: 'Yes',
    certificationPlan: 'Yes',
    clBuilder: '-',
    clApprover: '-',
    externalUserManagement: '-',
    browseReports: '-',
  },
  {
    role: 'Certificate Builder',
    definedFor: 'Division',
    enroll: '-',
    approveObjective: '-',
    certificationPlan: '-',
    clBuilder: 'Yes',
    clApprover: '-',
    externalUserManagement: '-',
    browseReports: '-',
  },
  {
    role: 'Certification Authority',
    definedFor: 'Division',
    enroll: '-',
    approveObjective: 'Yes',
    certificationPlan: 'Yes',
    clBuilder: 'Yes',
    clApprover: 'Yes',
    externalUserManagement: '-',
    browseReports: 'Yes (authorised Division)',
  },
  {
    role: 'Int/Ext Company Coordinator',
    definedFor: 'Division / company',
    enroll: 'Yes',
    approveObjective: 'Yes',
    certificationPlan: 'Yes',
    clBuilder: '-',
    clApprover: '-',
    externalUserManagement: 'Yes',
    browseReports: 'Yes (authorised Division / company)',
  },
  {
    role: 'Not ABB employee',
    definedFor: 'External company',
    enroll: '-',
    approveObjective: '-',
    certificationPlan: '-',
    clBuilder: '-',
    clApprover: '-',
    externalUserManagement: '-',
    browseReports: '-',
  },
  {
    role: 'Hierarchical Manager',
    definedFor: 'ABB employee',
    enroll: 'Yes',
    approveObjective: 'Yes',
    certificationPlan: 'Yes',
    clBuilder: '-',
    clApprover: '-',
    externalUserManagement: '-',
    browseReports: 'Yes (only reporting people)',
  },
  {
    role: 'Reader',
    definedFor: 'Division / Country',
    enroll: '-',
    approveObjective: '-',
    certificationPlan: '-',
    clBuilder: '-',
    clApprover: '-',
    externalUserManagement: '-',
    browseReports: 'Yes (authorised Division / country)',
  },
  {
    role: 'Supervisor',
    definedFor: 'Division / Country',
    enroll: 'Yes',
    approveObjective: 'Yes',
    certificationPlan: 'Yes',
    clBuilder: '-',
    clApprover: '-',
    externalUserManagement: 'Yes',
    browseReports: 'Yes (authorised Division / country)',
  },
  {
    role: 'External Company Responsible',
    definedFor: 'External company',
    enroll: 'Yes',
    approveObjective: '-',
    certificationPlan: '-',
    clBuilder: '-',
    clApprover: '-',
    externalUserManagement: 'Yes',
    browseReports: 'Yes (authorised external company)',
  },
];
const columns: DataTableColumn<RoleInPcsRow>[] = [
  { field: 'role', headerName: 'Role', width: 100 },
  { field: 'definedFor', headerName: 'Defined for', width: 100 },
  { field: 'enroll', headerName: 'Enroll on behalf', width: 68 },
  { field: 'approveObjective', headerName: 'Approve objective', width: 68 },
  { field: 'certificationPlan', headerName: 'Certification Plan', width: 72 },
  { field: 'clBuilder', headerName: 'CL builder', width: 68 },
  { field: 'clApprover', headerName: 'CL approver', width: 68 },
  {
    field: 'externalUserManagement',
    headerName: 'External user management',
    width: 68,
  },
  { field: 'browseReports', headerName: 'Browse reports', width: 100 },
];

export const RolesInPcsTable: FC = () => (
  <>
    <MobileSection>
      <DataObjectList
        items={rows}
        renderDataObjectView={(item) => (
          <DataObjectView
            title={item.role}
            item={item}
            dataSetMaps={mapToDataSetMap(columns, ['role'])}
          />
        )}
      />
    </MobileSection>
    <DesktopSection>
      <DataTable columns={columns} rows={rows} />
    </DesktopSection>
  </>
);
