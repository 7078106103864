import { useState } from 'react';
import { Content, Card } from 'components/Page';
import { DropzoneDialog, DropzoneDialogProps } from 'material-ui-dropzone';
import ExportIcon from '@material-ui/icons/VerticalAlignBottom';
import { useMutation } from 'react-query';
import { useToasts } from 'components/ToastProvider';
import { Button } from 'components';
import { Loader } from 'components/Loader/Loader';
import { Anchor } from 'components/Page/Anchor/Anchor';
import { Grid, Typography } from '@material-ui/core';
import { attachmentApi } from 'Attachment/service/attachment.api';
import { ActionsRoot } from './BulkUploadPage.style';

const filesLimit = 3;
const acceptedFiles = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const BulkUploadPage = () => {

  const { showInfo, showError, showSuccess } = useToasts();

  const {
    mutate: downloadBulkTemplateFile,
    isLoading: isTemplateLoading,
  } = useMutation(() => attachmentApi.getBulkTemplate()
  );

  const { mutate: uploadFiles } = useMutation(
    (files: File[]) => attachmentApi.uploadBulk(files),
    {
      onSuccess: () => showSuccess('Enrollment status update complete - more details in email notification'),
      onError: () => showError('Enrollment status update failed'),
    }
  );

  const [isDropzoneOpen, setIsDropzoneOpen] = useState<boolean>(false);

  const handleOnDropzoneSave: DropzoneDialogProps['onSave'] = (
    files
  ) => {
    uploadFiles(files);
    showInfo('Enrollment status update action queued');
    setIsDropzoneOpen(false);
  };

  return (
    <Content
      title="Bulk upload"
      subtitle="Enroll and update status for multiple users by uploading xls/xlsx file"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card header="Download template for bulk upload">
            <Typography variant="body2" display="block" style={{ whiteSpace: "pre-wrap" }}>
              The possibilities of bulk upload differ based on your role in the system.
              <br />
              <br />
              Please find detailed guide how to fill in the file correctly and upload it to the system in
              <Anchor href="https://search.abb.com/library/Download.aspx?DocumentID=9AKK108468A0289&LanguageCode=en&DocumentPartId=&Action=Launch">
                PCS2 INSTRUCTIONS - BULK UPLOAD
              </Anchor>
              <br />
              <br />
            </Typography>
            <ActionsRoot>
              <Button
                onClick={() => downloadBulkTemplateFile()}
                disabled={isTemplateLoading}
                color="secondary"
              >
                {isTemplateLoading ? (
                  <Loader size={24} />
                ) : (
                  <>
                    <ExportIcon /> &nbsp;Download template
                  </>
                )}
              </Button>
            </ActionsRoot>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card header="Upload the file">
            <Typography variant="body2" display="block" style={{ whiteSpace: "pre-wrap" }}>
              Once the data is uploaded in the system, you will be notified with email notification.
              <br />
              <br />
              If any record are rejected, you will receive details in the notification.
              <br />
              <br />
            </Typography>
            <ActionsRoot>
              <Button onClick={() => setIsDropzoneOpen(true)} color="secondary">
                Upload file
              </Button>
            </ActionsRoot>
            <DropzoneDialog
              dialogTitle="Upload files"
              open={isDropzoneOpen}
              acceptedFiles={acceptedFiles}
              filesLimit={filesLimit}
              onSave={handleOnDropzoneSave}
              onClose={() => setIsDropzoneOpen(false)}
              showPreviewsInDropzone
              showPreviews={false}
              showAlerts={false}
              maxFileSize={110000000}
            />
          </Card>
        </Grid>
      </Grid>
    </Content>
  );
};
