import styled from 'styled-components';
import Divider, { DividerProps } from '@material-ui/core/Divider';

import { PopoverOrigin } from '@material-ui/core/Popover/Popover';

export const anchorOriginStyles: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const transformOriginStyles: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const StyledDivider = styled(Divider)<DividerProps>`
  && {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`;
