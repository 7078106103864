import React from 'react';
import { FieldArray } from 'formik';
import {
    IconButton, DataTable, TablePagination,
    UsedIcons, FluidForEditors,
    SearchFiltersForm,
    DictionaryFieldByCode
} from 'components';
import { API, PaginatedList } from './model';

import * as Model from './model';

type TItemModel = Model.CertificateListLookupDTO

interface State2 {
    data?: PaginatedList<TItemModel>

    query: Model.CertificateListDefinitionFindQuery
    loadedOn: number
}

export function CertificationListForSelectionPart(p: { name: string }) {
    const defaultQuery: Model.CertificateListDefinitionFindQuery = {
        pageSize: 20,
        currentPageIndex: 0,
        sortExpression: "title",
        sortDescending: false,
        purpose: "Select"
    };
    const [state, setState] = React.useState<State2>({ query: defaultQuery, loadedOn: 0 });
    function loadData() {

        if (!state.query)
            return;

        API.ICertificateListContract
            .find(state.query)
            .then(x => setState(s => ({ ...s, data: x, loadedOn: new Date().getTime() })))
    }

    const onChangePage = (newPageIndex: number) => setState(s => ({ ...s, query: { ...s.query!, currentPageIndex: newPageIndex } }));
    const onChangeRowsPerPage = (newPageSize: number) => setState(s => ({ ...s, query: { ...s.query!, pageSize: newPageSize } }));
    const onChangeSort = (direction: string | undefined, field: keyof TItemModel) => setState(s => ({ ...s, query: { ...s.query!, sortDescending: direction === "desc", sortExpression: field } }));


    ///
    /// Effectss
    React.useEffect(loadData, [state.query]);

    /// 
    /// Render
    const fieldName = p.name;
    return (<FieldArray name={fieldName}>
        {arrayHelpers => (
            <div style={{ width: "100%" }}>
                <SearchFiltersForm
                    placeholder="Search ..."
                    initialValues={{ searchText: "", businessArea: undefined, division: undefined, level: [] }}
                    onSubmit={(f) => setState(s => ({ ...s, query: { ...s.query, title: f.searchText, ...f } }))}
                >
                    {(fp) => (
                        <FluidForEditors>
                            <DictionaryFieldByCode name="businessArea" label="Business Area" query={({ key: 'BusinessArea' })} dependandFieldName="division" />
                            <DictionaryFieldByCode name="division" label="Division" query={{ key: 'Division', businessArea: fp.values.businessArea }} />
                            <DictionaryFieldByCode name="level" multiple label="Level" query={{ key: 'Level' }} />
                        </FluidForEditors>
                    )}
                </SearchFiltersForm>
                <>
                    {!state.data ? <></> :
                        <>
                            <DataTable
                                key={state.loadedOn}
                                columns={[
                                    { field: 'title', headerName: 'Certificate' },
                                    { field: 'division', headerName: 'Division', renderField: (r) => r.division?.display },
                                    { field: 'release', headerName: 'Release' },
                                    { field: 'level', headerName: 'Level', renderField: (r) => r.level?.display },
                                    { field: 'publishDate', headerName: 'Published', renderField: ({ publishDate }) => DataTable.formatDate(publishDate) },
                                ]}
                                enableSort
                                defaultSort={state.query!}
                                onSortRequest={onChangeSort}
                                rows={state.data.result}
                                renderRowActions={(item) => (
                                    arrayHelpers.form.values[arrayHelpers.name].find((x: TItemModel) => x.id == item.id) ?
                                        <></> :
                                        <IconButton onClick={() => arrayHelpers.push(item)}>
                                            <UsedIcons.Add />
                                        </IconButton>)}
                                hideCollapseColumn />
                            <TablePagination
                                onChangePage={(e, p) => onChangePage(p)}
                                onChangeRowsPerPage={(e) => onChangeRowsPerPage(parseInt(e.target.value, 10))}

                                pagination={state.data ?? { currentPageIndex: 0, pageSize: 0, total: 0 }} /></>}
                </>
            </div>)}

    </FieldArray>
    );
}
