import fileDownload from 'js-file-download';

import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { Attachment, AttachmentList } from 'Attachment/model/Attachment';
import { HelpFile } from 'Attachment/model/HelpFile';

const get = async (
  id: Attachment['id'],
  fileName: Attachment['fileName']
): Promise<unknown> => {
  try {
    const response = await apiClient.get(`/attachments/${id}/download`, {
      responseType: 'blob',
    });
    fileDownload(response.data, fileName);
    return [];
  } catch (e) {
    throw new ApiError(e);
  }
};

const list = async (): Promise<AttachmentList> => {
  try {
    const response = await apiClient.get('/users/attachments-info');
    return {
      result: response.data.result,
      canCurrentUserRemoveAttachments: response.data.canCurrentUserRemoveAttachments
    };
  } catch (e) {
    throw new ApiError(e);
  }
};

const listByUserId = async (userId: string): Promise<AttachmentList> => {
  try {
    const response = await apiClient.get(`/users/${userId}/attachments-info`);
    return {
      result: response.data.result,
      canCurrentUserRemoveAttachments: response.data.canCurrentUserRemoveAttachments
    };
  } catch (e) {
    throw new ApiError(e);
  }
};

const listByUserAndRequirementId = async (userId: string, requirementId: string): Promise<AttachmentList> => {
  try {
    const response = await apiClient.get(`/users/${userId}/requirement/${requirementId}/attachments-info`);
    return {
      result: response.data.result,
      canCurrentUserRemoveAttachments: response.data.canCurrentUserRemoveAttachments
    };
  } catch (e) {
    throw new ApiError(e);
  }
};

const uploadFiles = async (files: File[]): Promise<unknown> => {
  try {
    return await Promise.all(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append('attachment', file);
        await apiClient.post('/attachments', formData);
      })
    );
  } catch (e) {
    throw new ApiError(e);
  }
};

const uploadBulk = async (files: File[]): Promise<unknown> => {
  try {
    return await Promise.all(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append('attachment', file);
        await apiClient.post('/bulk-template/upload', formData);
      })
    );
  } catch (e) {
    throw new ApiError(e);
  }
};

const uploadFilesByUserId = async (
  files: File[],
  userId: string
): Promise<unknown> => {
  try {
    return await Promise.all(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append('attachment', file);
        await apiClient.post(`users/${userId}/attachments`, formData);
      })
    );
  } catch (e) {
    throw new ApiError(e);
  }
};

const uploadFilesByUserAndRequirementId = async (
  files: File[],
  userId: string,
  requirementId: string
): Promise<unknown> => {
  try {
    return await Promise.all(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append('attachment', file);
        await apiClient.post(`users/${userId}/requirement/${requirementId}/attachments`, formData);
      })
    );
  } catch (e) {
    throw new ApiError(e);
  }
};

const remove = async (id: Attachment['id']): Promise<unknown> => {
  try {
    const response = await apiClient.delete(`/attachments/${id}`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getHelpFiles = async (): Promise<HelpFile[]> => {
  try {
    const response = await apiClient.get('/help-files');

    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getHelpFile = async (
  fileName: HelpFile['fileName']
): Promise<unknown> => {
  try {
    const response = await apiClient.get(`/help-files/${fileName}/download`, {
      responseType: 'blob',
    });
    fileDownload(response.data, fileName);
    return null;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getBulkTemplate = async (): Promise<unknown> => {
  try {
    const response = await apiClient.get(`/bulk-template/download`, {
      responseType: 'blob',
    });
    fileDownload(response.data, "BULK_TEMPLATE_PCS2.xlsx");
    return null;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const attachmentApi = {
  get,
  list,
  listByUserId,
  listByUserAndRequirementId,
  uploadFiles,
  uploadFilesByUserId,
  uploadFilesByUserAndRequirementId,
  remove,
  getHelpFile,
  getHelpFiles,
  getBulkTemplate,
  uploadBulk,
};
