import styled from 'styled-components';

export const StyledA = styled.a<{ $hover: boolean }>`
  display: flex;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: ${({ theme }) => theme.palette.grey[200]};      
  color: ${({ theme }) => theme.palette.grey[700]};

  ${({ $hover, theme }) =>
    $hover &&
    `
    &:hover {
      text-decoration-color: ${theme.palette.secondary.main};      
    }
  `}
`;
