import { FC } from 'react';

import { AppShellBaseProps } from 'components/AppShell';
import { useMyProfileQuery } from 'MyProfile/query/myProfile.query';
import { PrintOutCertificatesPage } from 'page/PrintOutCertificatesPage/PrintOutCertificatesPage';

export const PrintOutMyCertificatesPage: FC<AppShellBaseProps> = (props) => {
  const { data: myProfileData } = useMyProfileQuery();

  return (
    <PrintOutCertificatesPage
      userId={(myProfileData?.id ?? '').toString()}
      {...props}
    />
  );
};
