import styled from 'styled-components';
import Card, { CardProps } from '@material-ui/core/Card';

interface StyledCardProps extends CardProps {
  $transparent: boolean;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 0;
  && {
    ${({ $transparent }) =>
      $transparent &&
      `
      background: transparent;
    `}
  }
`;

export const HeaderWrapper = styled.div<{ listContent?: boolean }>`
  && {
    margin-bottom: ${({ listContent, theme }) =>
      listContent ? theme.spacing(1) : theme.spacing(3)}px;
  }
`;
