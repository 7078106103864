import { FC, HTMLAttributes } from 'react';
import { OpenInNewOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { AbbTheme } from 'theme/createAbbTheme';
import clsx from 'clsx';

export interface ExternalLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  href: string;
  showIcon?: boolean;
}

const useStyles = makeStyles(
  //@ts-ignore
  (theme: AbbTheme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textDecoration: 'none',
      ...theme.typography.body2,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey[400]
    },
    icon: {
        marginRight: theme.spacing(1),
    },
  }),
  { name: 'ExternalLink' }
);

export const ExternalLink: FC<ExternalLinkProps> = (props) => {
  const { className, showIcon = true, href, children } = props;
  const classes = useStyles();
  return (
    <a
      className={clsx(className, classes.root)}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {showIcon && <OpenInNewOutlined className={classes.icon} fontSize="small"/>} {children}
    </a>
  );
};
