import { useQuery } from 'react-query';

import { Company, CompanyFindQuery, CompanyInfoDTO } from 'Dictionary/model/Company';
import { BusinessUnit } from 'Dictionary/model/BusinessUnit';
import { PGUnit } from 'Dictionary/model/PGUnit';
import { Division } from 'Dictionary/model/Division';
import { EmployeeJob } from 'Dictionary/model/EmployeeJob';
import { Language } from 'Dictionary/model/Language';
import { dictionaryApi } from 'Dictionary/service/dictionary.api';
import { CertificateFunction } from 'Dictionary/model/CertificateFunction';
import { WorkType } from 'Dictionary/model/WorkType';
import { BusinessUnitContact } from 'Dictionary/model/BusinessUnitContact';
import { CompanyChannel } from 'Dictionary/model/CompanyChannel';
import { Country } from 'Dictionary/model/Country';
import { CompanyFilterParams } from 'Dictionary/model/CompanyFilterParams';
import { MyProfile } from 'MyProfile/model/MyProfile';
import { TrainingManagersFiltersDto } from 'Dictionary/model/TrainingManagersFiltersDto';
import { Approver } from 'Dictionary/model/Approver';
import { ScopeDetail } from 'Dictionary/model/ScopeDetail';
import { ScopeDetailsParams } from 'Dictionary/model/ScopeDetailsParams';
import { PaginatedList } from 'model';

export const companiesKey = 'companies';
export const useCompaniesQuery = () =>
  useQuery<Company[]>(companiesKey, dictionaryApi.getCompanies, {
    enabled: false,
  });

export const companyChannelKey = 'company-channel';
export const useCompanyChannelQuery = () =>
  useQuery<CompanyChannel[]>(
    companyChannelKey,
    dictionaryApi.getCompanyChannels,
    { enabled: false }
  );

export const businessUnitsKey = 'business-units';
export const useBusinessUnitsQuery = (divisionid: number) =>
  useQuery<BusinessUnit[]>(
    businessUnitsKey,
    () => dictionaryApi.getBusinessUnits(divisionid),
    { enabled: false }
  );

export const activeBusinessUnitsKey = 'active-business-units';
export const useActiveBusinessUnitsQuery = (divisionid: number) =>
  useQuery<BusinessUnit[]>(
    activeBusinessUnitsKey,
    () => dictionaryApi.getActiveBusinessUnits(divisionid),
    { enabled: false }
  );

export const pgUnitsKey = 'pg-units';
export const usePGUnitsQuery = (buid: number) =>
  useQuery<PGUnit[]>(pgUnitsKey, () => dictionaryApi.getPgUnits(buid), {
    enabled: false,
    //initialData: [],
  });

export const employeeJobKey = 'employee-jobs';
export const useEmployeeJobQuery = () =>
  useQuery<EmployeeJob[]>(employeeJobKey, dictionaryApi.getEmployeeJobs, {
    enabled: false,
  });

export const employeeJobFamilyKey = 'employee-job-families';
export const useEmployeeJobFamilyQuery = () =>
  useQuery<EmployeeJob[]>(employeeJobFamilyKey, dictionaryApi.getEmployeeJobFamilies, {
    enabled: false,
  });

export const managerKey = 'manager';
export const useManagerQuery = (query: string) =>
  useQuery<Partial<MyProfile>[]>(
    managerKey,
    () => dictionaryApi.getManagers(query),
    {
      enabled: false,
    }
  );

export const functionalmanagerKey = 'functionalmanager';
export const useFunctionalManagerQuery = (query: string) =>
  useQuery<Partial<MyProfile>[]>(
    functionalmanagerKey,
    () => dictionaryApi.getManagers(query),
    {
      enabled: false,
    }
  );

export const businessUnitContactsKey = 'business-unit-contacts';
export const useBusinessUnitContactsQuery = () =>
  useQuery<BusinessUnitContact[]>(businessUnitContactsKey, () =>
    dictionaryApi.getBusinessUnitContacts()
  );

export const preferredLanguagesKey = 'preferred-languages';
export const usePreferredLanguagesQuery = () =>
  useQuery<Language[]>(
    preferredLanguagesKey,
    () => dictionaryApi.getPreferredLanguages(),
    {
      enabled: false,
    }
  );

export const languagesByBusinessUnitKey = 'languages-by-business-unit';
export const useLanguagesByBusinessUnitQuery = (buId: number) =>
  useQuery<Language[]>(
    languagesByBusinessUnitKey,
    () => dictionaryApi.getLanguagesByBusinessUnit(buId),
    {
      enabled: false,
    }
  );

export const certificateFunctionsKey = 'certificate-functions';
export const useCertificateFunctionsQuery = () =>
  useQuery<CertificateFunction[]>(
    certificateFunctionsKey,
    () => dictionaryApi.getCertificateFunctions(),
    { enabled: false }
  );

export const workTypesKey = 'work-types';
export const useWorkTypesQuery = () =>
  useQuery<WorkType[]>(
    workTypesKey,
    () => dictionaryApi.getWorkTypes(),
    { enabled: false }
  );

export const divisionsKey = 'divisions';
export const useDivisionsQuery = () =>
  useQuery<Division[]>(
    divisionsKey,
    () => dictionaryApi.getDivisions(),
    { enabled: false }
  );

export const countriesKey = 'countries';
export const useCountriesQuery = () =>
  useQuery<Country[]>(countriesKey, () => dictionaryApi.getCountries(), {
    enabled: false,
  });

export const companiesWithFilterKey = 'companies-with-filter';
export const useCompaniesWithFilterQuery = (params: CompanyFilterParams) =>
  useQuery<Company[]>(
    [companiesWithFilterKey, params],
    () => dictionaryApi.getCompaniesWithFilter(params),
    { enabled: false, useErrorBoundary: false }
  );

export const trainingManagersKey = 'training-managers';
export const useTrainingManagersQuery = (params: TrainingManagersFiltersDto) =>
  useQuery<Partial<MyProfile>[]>(
    [trainingManagersKey, params],
    () => dictionaryApi.getTrainingManagers(params),
    { enabled: false, useErrorBoundary: false }
  );

export const approversKey = 'approvers';
export const useApproversQuery = () =>
  useQuery<Partial<Approver>[]>(
    [trainingManagersKey],
    () => dictionaryApi.getApprovers(),
    { enabled: false, useErrorBoundary: false }
  );

export const scopeDetailsKey = 'scope-details';
export const useScopeDetailsQuery = (params: ScopeDetailsParams) =>
  useQuery<ScopeDetail[]>(
    scopeDetailsKey,
    () => dictionaryApi.getScopeDetails(params),
    { enabled: false, useErrorBoundary: false }
  );



export const companyFindKey = 'admin-company';
export const useCompanyFindQuery = (params: CompanyFindQuery) =>
  useQuery<PaginatedList<CompanyInfoDTO>>(
    companyFindKey,
    () => dictionaryApi.company.find(params),
    { enabled: false, useErrorBoundary: false }
  );
