import { KeyboardEventHandler, ReactNode, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Collapse,
  IconButton,
  InputBase,
  Badge,
  InputBaseProps,
} from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import clsx from 'clsx';
import { AbbTheme } from '../../theme/createAbbTheme';

const useStyles = makeStyles(
  (theme: AbbTheme) => {
    const placeholderStyle = {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightLight,
      opacity: 1,
      color: theme.palette.grey[600],
    };
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
      },
      searchBar: {
        display: 'inline-flex',
        width: '100%',
        height: 56,
        boxSizing: 'border-box',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        '&$error': {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.contrastText,
        },
      },
      error: {},
      divider: {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      input: {
        margin: theme.spacing(0, 1),
        flexGrow: 1,
        '& input': {
          '&::placeholder': {
            ...placeholderStyle,
          },
          '&::-moz-placeholder': {
            ...placeholderStyle,
          },
          '&:-ms-input-placeholder': {
            ...placeholderStyle,
          },
          ':-moz-placeholder': {
            ...placeholderStyle,
          },
        },
      },
      filters: {
        padding: theme.spacing(2),
        '&$actions': {
          paddingBottom: 0,
        },
      },
      filtersBadge: {
        marginTop: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
      },
      actionsRoot: {
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        height: 56,
      },
      actions: {},
    };
  },
  { name: 'SearchBar' }
);

export interface SearchBarProps
  extends Partial<{
    searchText: string;
    placeholder: string;
    disabled: boolean;
    error: unknown;
    InputBaseProps?: Partial<InputBaseProps>;
    onEnter: () => void;
    onChange: (text: string) => void;
    onClearText: () => void;
    renderActions: () => ReactNode;
    selectedFiltersCount: number;
  }> {}

export const SearchBar: React.FC<SearchBarProps> = (props) => {
  const {
    children,
    searchText = '',
    placeholder = 'Search…',
    disabled = false,
    InputBaseProps = {},
    error = false,
    onEnter,
    onChange,
    onClearText,
    renderActions,
    selectedFiltersCount = 0,
  } = props;
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const handleSearchEnter: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) =>
    Boolean((e.target as HTMLInputElement | HTMLTextAreaElement).value) &&
    e.key === 'Enter' &&
    onEnter &&
    onEnter();

  return (
    <div className={classes.root}>
      <div
        className={clsx(
          classes.searchBar,
          classes.divider,
          Boolean(error) && classes.error
        )}
      >
        <IconButton
          onClick={() => onClearText && onClearText()}
          disabled={!searchText || disabled}
        >
          {!searchText ? <Search color="primary" /> : <Clear color="primary" />}
        </IconButton>
        <InputBase
          disabled={disabled}
          onChange={(e) => onChange && onChange(e.target.value)}
          className={classes.input}
          inputProps={{ onKeyUp: handleSearchEnter }}
          placeholder={placeholder}
          value={searchText}
          {...InputBaseProps}
        />
        {children && (
          <Badge
            badgeContent={selectedFiltersCount}
            color="secondary"
            classes={{ badge: classes.filtersBadge }}
          >
            <IconButton
              disabled={disabled}
              onClick={() => setOpen((prev) => !prev)}
            >
              <FilterAltIcon />
            </IconButton>
          </Badge>
        )}
      </div>
      {children && (
        <Collapse in={open}>
          <div
            className={clsx(
              classes.filters,
              classes.divider,
              renderActions && classes.actions
            )}
          >
            {children}
            {renderActions && (
              <div className={classes.actionsRoot}>{renderActions()}</div>
            )}
          </div>
        </Collapse>
      )}
    </div>
  );
};

export default SearchBar;
