import React from 'react'

import * as API from 'Dictionary/service/dictionary.api';

import { PageBase } from 'components/Page/PageBase'

import * as Model from './model'
import Editor from './Editor'
import SearchFiltersForm from './Filters'

type QueryModel = Model.PrintingStandardTextsFindQuery
type DTOModel = Model.PrintingStandardTextDTO

export const ContractAPI = API.dictionaryApi.IPrintingCertificatesContract

const defaultQuery: QueryModel = {
  currentPageIndex: 0,
  pageSize: 50
}

export function Page() {

  const [state, setState] = React.useState<{ selectedDivision?: DictionaryItemByCodeDTO }>({ selectedDivision: defaultQuery.division })

  const getNewItem = () => ({
    division: { id: state.selectedDivision?.id, description: state.selectedDivision?.display },
  }) as Model.PrintingStandardTextDTO


  return PageBase<DTOModel, QueryModel>({
    defaultQuery: defaultQuery,
    getIdFor: (item) => item.id,
    contract: ContractAPI,
    pageSettings: {
      title: "Standard Texts",
      subtitle: "Select and write the Standard Text to print on Certificates",
      addTitle: "Add new language",
      editTitle: "Edit language",
      newItem: {
        actionCaption: "ADD LANGUAGE",
        inPageAsRowView: true
      },
      deleteItem: {
        confirmTitle: "Delete language",
        confirmDescription: "The language will be deleted from Division",
        confirmButton: "DELETE"
      }
    },
    getRowView: (row, cancelHandler, updateHandler) => (<Editor item={row ?? getNewItem()} onCancel={cancelHandler} onCommit={updateHandler} />),
    getSearchFiltersForm: (onQuery) => (<SearchFiltersForm defaultQuery={defaultQuery} onQuery={q => { setState({ selectedDivision: q.division }); onQuery(q) }} />),
    columns: ([
      {
        field: 'language',
        headerName: 'Language',
        renderField: (r) => r.language.name
      },
    ]),
    renderHandlers: {
      list: (render) => state.selectedDivision ? render() : <></>
    }
  })

}
