import { useQuery } from 'react-query';

import { AttachmentList } from 'Attachment/model/Attachment';
import { HelpFile } from 'Attachment/model/HelpFile';
import { attachmentApi } from 'Attachment/service/attachment.api';

export const attachmentsKey = 'attachments-info';
export const useAttachmentsQuery = () =>
  useQuery<AttachmentList>(attachmentsKey, attachmentApi.list);

export const userAttachmentsKey = 'user-attachments-info';
export const useUserAttachmentsQuery = (userId: string) =>
  useQuery<AttachmentList>([userAttachmentsKey, userId], () =>
    attachmentApi.listByUserId(userId)
  );

export const userRequirementsAttachmentsKey = 'user-requirement-attachments-info';
export const useUserRequirementAttachmentsQuery = (userId: string, requirementId: string) =>
  useQuery<AttachmentList>([userRequirementsAttachmentsKey, userId, requirementId], () =>
    attachmentApi.listByUserAndRequirementId(userId, requirementId)
  );

export const helpFilesKey = 'help-files';
export const useHelpFilesQuery = () =>
  useQuery<HelpFile[]>(helpFilesKey, attachmentApi.getHelpFiles);
