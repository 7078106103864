import { FC } from 'react';
import { formatDate } from 'utils';
import Typography from '@material-ui/core/Typography';

import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import {
  getFullName,
  getIsExternalLabel,
  MyProfile,
} from 'MyProfile/model/MyProfile';
import { BoldTypography } from 'components/Page';
import { getCompanyName } from 'Dictionary/model/Company';

export const enrollmentColumns: DataTableColumn<MyProfile>[] = [
  {
    field: 'lastName',
    headerName: 'Name',
    renderField: (user) => (
      <>
        <BoldTypography variant="body2">{getFullName(user)}</BoldTypography>
        <Typography variant="caption">{user.email}</Typography>
      </>
    ),
  },
  {
    field: 'employeeJobs',
    headerName: 'Jobs',
    notSortable: true,
    renderField: (user) => (      
        <Typography component="span" variant="body2">
          {user.employeeJobs.map((job) => job.name).join(', ')}
        </Typography>
    ),
  },
];

export const managementColumns: DataTableColumn<MyProfile>[] = [
  {
    field: 'lastName',
    headerName: 'Name',
    width: 400,
    renderField: (user) => (
      <>
        <BoldTypography variant="body2">{getFullName(user)}</BoldTypography>
        <Typography variant="caption">{user.email}</Typography>
      </>
    ),
  },
  {
    field: 'businessUnit',
    headerName: 'User Division',
    renderField: ({ businessUnit }) => businessUnit?.description ?? '...',
  },
  {
    field: 'company',
    headerName: 'User Company',
    renderField: ({ company }) => getCompanyName(company),
  },
  {
    field: 'country',
    headerName: 'User Country',
    notSortable: true,
    renderField: ({ country }) => country?.name ?? '...',
  },
  {
    field: 'city',
    headerName: 'User City',
    renderField: ({ city }) => city ?? '...',
  },  
  {
    field: 'state',
    headerName: 'User State',
    width: 100,
    renderField: ({ state }) => state ?? '...',
  },
];

export const archivedUsersColumns: DataTableColumn<MyProfile>[] = [
  {
    field: 'lastName',
    headerName: 'Name',
    width: 400,
    renderField: (user) => (
      <>
        <BoldTypography variant="body2">{getFullName(user)}</BoldTypography>
        <Typography variant="caption">{user.email}</Typography>
      </>
    ),
  },
  {
    field: 'businessUnit',
    headerName: 'User Division',
    renderField: ({ businessUnit }) => businessUnit?.description ?? '...',
  },
  {
    field: 'company',
    headerName: 'User Company',
    renderField: ({ company }) => getCompanyName(company),
  },
  {
    field: 'country',
    headerName: 'User Country',
    notSortable: true,
    renderField: ({ country }) => country?.name ?? '...',
  },
  {
    field: 'isExternal',
    headerName: 'Internal/External',
    renderField: (user) => getIsExternalLabel(user),
  },
  {
    field: 'deactivatedByUser',
    headerName: 'Archived by',
    renderField: (user) => user?.deactivatedByUser?.email ?? 'No data',
  },
  {
    field: 'deactivateDate',
    headerName: 'Archived Date',
    renderField: (user) => user.deactivateDate ? formatDate(user.deactivateDate) : 'No data',
  },
];

export interface UserTableProps extends DataTableProps<MyProfile> {}

export const UserTable: FC<UserTableProps> = (props) => (
  <DataTable
    rowSelector={(row) => row.id}
    noDataMessage="No users to display"
    hideCollapseColumn
    {...props}
  />
);
