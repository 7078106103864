import * as route from 'route';
import { PageBase } from 'components/Page/PageBase';
import { BoldTypography, Typography } from 'components';
import * as Model from './model';
import { PageRowView } from './PageRowView';

import FiltersPart from './Filters';

type QueryModel = Model.TrainingSetFindQueryDTO
type DTOModel = Model.TrainingSetDTO
export const ContractAPI = Model.API.ITrainingSetContract

const defaultQuery: QueryModel = { currentPageIndex: 0, pageSize: 50 }
const getRowId = (row: DTOModel) => row.id

export default function Page() {

  return PageBase<DTOModel, QueryModel>({
    defaultQuery: defaultQuery,
    getIdFor: getRowId,
    contract: Model.API.ITrainingSetContract,
    pageSettings: {
      title: "Manage Training Sets",
      subtitle: "Here you can find, edit or delete Training Sets.",
      details: {
        route: (item) => route.managTrainingSetDetailsRoute.createRoute({ itemId: item.id })
      },
      inRowAction: 'DETAILS',
      newItem: {
        actionCaption: "DEFINE TRAINING SET",
        route: () => route.managTrainingSetDetailsRoute.createRoute({ itemId: 0 })
      },

    },
    getRowView: (row, cancelHandler, updateHandler) => (<PageRowView id={row!.id} />),
    getSearchFiltersForm: (onQuery) => (<FiltersPart defaultQuery={defaultQuery} onQuery={onQuery} />),
    columns: ([
      {
        field: "name", headerName: "Certificate", renderField: r => <CertificatesColumn item={r} />
      }
    ]),
  })

}

function CertificatesColumn(p: { item: DTOModel }) {
  const toRender = p.item

  let details = toRender.certificates.length > 0 ? toRender.certificates[0].title : 'none';
  if (toRender.certificates.length > 1)
    details += ` and ${toRender.certificates.length - 1} more...`;

  return <>
    <BoldTypography variant="body2">{toRender.name}</BoldTypography>
    <Typography variant="caption">{details}</Typography>
  </>
}
