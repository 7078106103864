import { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import MyLearningIcon from '@material-ui/icons/OpenInNew';

import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { CPStatusChip } from 'components/StatusChip';
import { formatDate } from 'utils';
import { Anchor } from 'components/Page/Anchor/Anchor';
import {
  getRequirementWorkflowActions,
  getSelectedRequirements,
  parseSelectedRequirementIds,
  Requirement,
} from 'Requirement/model/Requirement';
import { usePlanRequirementsQuery } from 'Requirement/query/requirementQuery';
import { getCertificateApprover } from 'enums/CertificateApproverType';
import { RequirementPlanActions } from 'Requirement/component/RequirementPlanActions/RequirementPlanActions';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import { DataObjectView } from 'components';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import { useParams } from 'react-router-dom';
import { CertificationsByPersonDetailsParams } from 'route';
import { useUserCertificationsQuery } from 'Certificate/query/certificateQuery';
import { useMyProfileQuery } from 'MyProfile/query';
import {
  DescriptionCaptionTypography,
  DescriptionTypography,
  RequirementsBar,
} from './PlanRequirementsTable.style';

const columns: DataTableColumn<Requirement>[] = [
  { field: 'listCode', headerName: 'Code' },
  {
    field: 'listDescription',
    headerName: 'Description',
    width: 300,
    renderField: (row) => (
      <div>
        <DescriptionTypography variant="body2">
          {row.listDescription}
        </DescriptionTypography>
        {row.myLearningLink && (
          <DescriptionTypography variant="body1">
            <Anchor href={row.myLearningLink} hover>
              {row.myLearningLinkDescription} &nbsp; <MyLearningIcon fontSize="inherit" />
            </Anchor>
          </DescriptionTypography>
        )}
        <DescriptionCaptionTypography variant="caption"
          dangerouslySetInnerHTML={{ __html: row.listExtendedDescription }} />
        {row.externalLink && (
          <DescriptionTypography variant="body1">
            <Anchor href={row.externalLink} hover>
              {row.externalLinkName ?? "Link"}
            </Anchor>
          </DescriptionTypography>
        )}  
      </div>
    ),
  },
  {
    field: 'approverType',
    headerName: 'Approved by',
    renderField: ({ approverType }) => getCertificateApprover(approverType),
  },
  {
    field: 'submitDate',
    headerName: 'Submitted',
    renderField: ({ submitDate }) => formatDate(submitDate),
  },
  {
    field: 'approvalDate',
    headerName: 'Approved',
    //renderField: ({ approvalDate }) => formatDate(approvalDate),
    renderField: (row) => formatDate(row.renewalDate ?? row.approvalDate),
  },
  {
    field: 'expirationDate',
    headerName: 'Expiration',
    renderField: ({ expirationDate }) => formatDate(expirationDate),
  },
  {
    field: 'status',
    headerName: 'Status',
    renderField: ({ status }) => <CPStatusChip status={status} />,
  },
];

export interface PlanRequirementsTableProps {
  certificateId: number;
  DataTableProps: Omit<DataTableProps<Requirement>, 'rows' | 'columns'>;
}

const noDataMessage = 'No requirements to display';

export const PlanRequirementsTable: FC<PlanRequirementsTableProps> = ({
  certificateId,
  DataTableProps,
}) => {
  const { userId } = useParams<CertificationsByPersonDetailsParams>();
  const { data, isLoading, refetch } = usePlanRequirementsQuery(certificateId);
  const { data: myProfile } = useMyProfileQuery();
  const { refetch: refetchUserCertifications } = useUserCertificationsQuery(
    (userId ?? myProfile?.id).toString()
  );
  return (
    <>
      <DesktopSection>
        <RequirementsBar>
          <Typography variant="h6">
            Requirements {data?.requirementsDone ?? '0'}/
            {data?.requirementsCount ?? '0'}
          </Typography>
        </RequirementsBar>
        <DataTable
          columns={columns}
          rows={data?.requirements ?? []}
          rowSelector={(row) => row.id}
          renderSelectedActions={(selectedRows, clearSelected) => (
            <RequirementPlanActions
              certificateId={certificateId}
              requirementIds={parseSelectedRequirementIds(selectedRows)}
              requirements={getSelectedRequirements(data?.requirements ?? [],selectedRows)}
              workflowActions={getRequirementWorkflowActions(
                data?.requirements ?? [],
                selectedRows
              )}
              onActionSuccess={() => {
                refetch();
                refetchUserCertifications();
                clearSelected();
              }}
            />
          )}
          outerBorder={false}
          loading={isLoading}
          noDataMessage={noDataMessage}
          headerRowAlwaysOnTop={true}
          {...DataTableProps}
        />
      </DesktopSection>
      <MobileSection>
        <DataObjectList
          loading={isLoading}
          items={data?.requirements ?? []}
          renderDataObjectView={(item) => (
            <DataObjectView
              title={item.listCode}
              item={item}
              dataSetMaps={mapToDataSetMap(columns, ['listCode'])}
              renderActions={() => (
                <RequirementPlanActions
                  certificateId={certificateId}
                  requirementIds={[item.id]}
                  requirements={getSelectedRequirements(data?.requirements ?? [], [item.id.toString()])}
                  workflowActions={getRequirementWorkflowActions(
                    data?.requirements ?? [],
                    [item.id.toString()]
                  )}
                  onActionSuccess={() => refetch()}
                  displayDisabledActions={false}
                />
              )}
            />
          )}
          noDataMessage={noDataMessage}
        />
      </MobileSection>
    </>
  );
};
