import { FC, HtmlHTMLAttributes, ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { Loader } from 'components/Loader/Loader';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import { useContentStyles } from './Content.style';
import { OverlayLoader } from '../../OverlayLoader/OverlayLoader';

export interface ContentProps extends HtmlHTMLAttributes<HTMLDivElement> {
  title?: string;
  subtitle?: string;
  renderSubheader?: () => ReactNode;
  loading?: boolean;
  mutating?: boolean;
  mutatingLabel?: string;
  renderActions?: () => ReactNode;
}

export const Content: FC<ContentProps> = ({
  title,
  subtitle,
  renderSubheader,
  children,
  loading = false,
  mutating = false,
  mutatingLabel,
  renderActions,
  ...other
}) => {
  const classes = useContentStyles();
  return loading ? (
    <Loader />
  ) : (
    <OverlayLoader mutating={mutating} label={mutatingLabel} {...other}>
      <Helmet>
        <title>{`${title ?? 'PCS 2'} ${
          subtitle ? `| ${subtitle}` : ''
        }`}</title>
      </Helmet>
      <header className={classes.header}>
        <div className={classes.headerContent}>
          {title && (
            <Typography className={classes.title} variant="h2">
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              className={clsx(classes.subHeader, classes.subtitle)}
              variant="body2"
            >
              {subtitle}
            </Typography>
          )}
          {renderSubheader && (
            <div className={classes.subHeader}>{renderSubheader()}</div>
          )}
        </div>
        <span className={classes.grow} />
        {renderActions && (
          <div className={classes.actions}>{renderActions()}</div>
        )}
      </header>

      <div className={classes.content}> {children}</div>
    </OverlayLoader>
  );
};

export default Content;
