import { makeDefaultApi } from 'Dictionary/service/dictionary.api';
import { API as CAPI, CertificateListLookupDTO, PaginationDto } from '../Certificate/model';

export const API =
{
    ITrainingSetContract: {
        ...makeDefaultApi<TrainingSetDTO, TrainingSetFindQueryDTO>({ basePath: "admin/training-set/" }),
    },
    dictionaryQuery: (query: {
        key: KnowDictionaryKey,
        businessArea?: DictionaryItemByCodeDTO,
        division?: DictionaryItemByCodeDTO,
        scope?: DictionaryItemByCodeDTO
    }) => CAPI.ICertificateListContract.dictionaryQuery(query)
}

export interface TrainingSetFindQueryDTO extends PaginationDto {
    name?: string
}

export interface TrainingSetDTO {
    id: number;
    isActive: boolean;
    name: string;
    createdOn: string;
    editedOn: string;
    forUserType: DictionaryItemByCodeDTO;
    forCountry: DictionaryItemByCodeDTO[];
    forDivision: DictionaryItemByCodeDTO[];
    forFunctionalJob: DictionaryItemByCodeDTO[];
    forFunctionalJobFamily: DictionaryItemByCodeDTO[];
    certificates: CertificateListLookupDTO[];

    reagisterAllUsers: boolean;
    automaticRenewal: boolean;

}

