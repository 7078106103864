import { Country } from 'Dictionary/model/Country';
import { EmployeeJob, EmployeeJobFamily } from 'Dictionary/model/EmployeeJob';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { RoleType } from 'MyProfile/enum/RoleType';
import { Role } from './Role';

export interface MyProfile {
  id: number;
  geid: string;
  firstName: string;
  lastName: string;
  email: string;
  country: Country;
  division: {
    id: number;
    name: string;
  };
  city: string;  
  state: string;
  manager: MyProfile;
  functionalManager: MyProfile;
  channelManager: MyProfile[];
  partnerCompanySupervisor: MyProfile[];
  notes: string;
  employeeJobs: EmployeeJob[];
  employeeJobFamilies: EmployeeJobFamily[];
  company: {
    id: number;
    description: string;
    location: string;
  };
  businessUnit: {
    id: number;
    description: string;
  };
  pgUnit: {
    id: number;
    code: string;
    name: string;
  };
  preferredLanguages: { id: number; name: string }[];
  roles: Role[];
  isExternal: boolean;
  isAdmin: boolean;  
  userNeedToUpdateProfile: boolean;
  deactivateDate: string;
  deactivatedByUser: MyProfile;
}

export const getFullName = (user?: Partial<MyProfile>): string =>
  user ? `${user.firstName} ${user.lastName}` : '...';

export const getFullNameWithGeid = (user?: Partial<MyProfile>): string =>
  user ? `${getFullName(user)} (${user.geid})` : '...';    

export const getFullNameWithGeidAndEmail = (user?: Partial<MyProfile>): string =>
  user ? `${getFullName(user)} (${user.email}, ${user.geid})` : '...';  

export const getRoles = (user: MyProfile): RoleType[] =>
  user.roles.map((role) => role.type as RoleType);

export const getIsExternalLabel = (user: MyProfile): string =>
  user.isExternal ? 'External' : 'Internal';

export const mapToAutocompleteFieldItems = (
  users: Partial<MyProfile>[]
): AutocompleteFieldItem[] =>
  users.map((user) => ({
    id: user.geid,
    group: user.email,
    name: user.email ? getFullNameWithGeidAndEmail(user) : getFullNameWithGeid(user) ,
  }));
