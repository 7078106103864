import React, { useEffect, useState, ChangeEvent } from 'react';
import {
    AutocompleteField,
    AutocompleteFieldItem,
  } from '../../components/Field/AutocompleteField/AutocompleteField';

  import { useUserHrgtQuery } from '../../MyProfile/query/myProfile.query';
  import { mapToAutocompleteFieldItems } from '../../Dictionary/component/HrgtUserSelectField/utils';
  import { getPagination, PaginatedList, Sort } from '../../model';
  import { MyProfile } from '../../MyProfile/model/MyProfile';


export interface SearchUserForMassEnrollmentProps {
    label: string;
    onChanged: React.Dispatch<{ id: string, email: string, name: string }[]>;
    initialValue?: string;
    multiple?: boolean;
  }

  const defaultSort: Sort<MyProfile> = {
    sortExpression: 'lastName',
    sortDescending: false,
  };
  
  const searchTimeout = 500;
  const minLenOfSearchValue = 3;
  export const SearchUserForMassEnrollment = (props: SearchUserForMassEnrollmentProps) => {
    const { 
      label,
      onChanged,
      initialValue,
      multiple = true,
    } = props;
  
    const [value, setValue] = useState('');
    const [selectedValues, setSelectedValues] = useState<object[]>([])
  
    const { data = [], isLoading, isFetching, refetch } = useUserHrgtQuery(
      getPagination(20),
      defaultSort,
      {
        isExternal: false,
        personNameOrEmail: value,
      },
      {
        enabled: false
      }
    );
  
    useEffect(() => {
      if (initialValue !== value && value.length >= minLenOfSearchValue) {
        const timer = setTimeout(() => refetch(), searchTimeout);
        return () => clearTimeout(timer);
      }
      return undefined;
    }, [value]);
  
    const handleItemsChange = (
      e: ChangeEvent<{}>,
      value: AutocompleteFieldItem | AutocompleteFieldItem[],
      xxvalues: AutocompleteFieldItem[]
    ) => {
      xxvalues.splice(0, xxvalues.length)
      if (!Array.isArray(value) || value.length === 0)
      xxvalues.splice(0, xxvalues.length)
      else
        xxvalues.push(...value);
  
      const toSend = xxvalues.map((x) => (
        {
          id: (x.id ?? "").toString(),
          //@ts-ignore
          email: x.email ?? "",
          name: x.name ?? ""
        }
      ));
      onChanged(toSend);
    }
  
    return (
  
      <AutocompleteField
        label={label}
        multiple={multiple}
        value={selectedValues}
        loading={isLoading}
        options={mapToAutocompleteFieldItems(data as PaginatedList<MyProfile>)}
        onChange={(e, v, r, d) => {handleItemsChange(e, v, selectedValues)}}
        onInputChange={(event, eventValue) => setValue(eventValue)}
      />
    );
  };
  