import { makeStyles } from '@material-ui/core';

import { AbbTheme } from 'theme/createAbbTheme';

export const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    attachmentsArea: {
      margin: theme.spacing(2, 0),
    },
    commentsArea: {
      margin: theme.spacing(2, 0),
    },
  }),
  { name: 'CertificationPlansDetailsPage' }
);
