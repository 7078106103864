import { FC } from 'react';

import { DataObjectView } from 'components';
import { formatDate } from 'utils';
import { DataObjectMap } from 'components/DataObjectView/DataObjectView';
import { CertificationPlansDetails } from 'CertificationPlansPage/model/CertificationPlansDetails';

const basicDataSetMap: DataObjectMap<CertificationPlansDetails>[] = [
  {
    field: 'division',
    headerName: 'Business Area',
    renderField: ({ division }) => division?.name ?? '...',
  },
  {
    field: 'businessUnit',
    headerName: 'Division',
    renderField: (item) => item?.businessUnit?.description,
  },
  {
    field: 'productGroup',
    headerName: 'Product group',
    renderField: ({ productGroup }) => productGroup ? `${productGroup?.code} - ${productGroup?.name}` : '...',
  },
  {
    field: 'release',
    headerName: 'Release',
  },
  { field: 'mainFunction', headerName: 'Main function' },
  {
    field: 'level',
    headerName: 'Level',
    renderField: ({ level }) => level?.description,
  },
  {
    field: 'scope',
    headerName: 'Scope',
    renderField: ({ scope }) => scope?.description,
  },
  {
    field: 'scopeDetails',
    headerName: 'Scope details',
    renderField: ({ scopeDetails }) =>
      <>
        {Array.isArray(scopeDetails) && scopeDetails.length ? scopeDetails.map((sd, i) => (
          <div key={i}>{sd.description}</div>
        )) : '...'}
      </>
  },
  {
    field: 'approverType',
    headerName: 'Approver type',
    renderField: ({ approverType }) => approverType?.description,
  },
  {
    field: 'approvers',
    headerName: 'Approvers',
    renderField: ({ approvers }) =>
      <>
        {Array.isArray(approvers) && approvers.length ? approvers.map((approver, i) => (
          <div key={i}>{approver}</div>
        )) : '...'}
      </>
  },
  {
    field: 'workType',
    headerName: 'Work type',
    renderField: ({ workType }) =>
      <>
        {Array.isArray(workType) && workType.length ? workType.map((work, i) => (
          <div key={i}>{work}</div>
        )) : '...'}
      </>
  },
  {
    field: 'enrollmentApproverType',
    headerName: 'Enrollment Approver type',
    renderField: ({ enrollmentApproverType }) => enrollmentApproverType?.description ?? '...',
  },
  {
    field: 'enrollmentApprovers',
    headerName: 'Enrollment Approvers',
    renderField: ({ enrollmentApprovers }) =>
      <>
        {Array.isArray(enrollmentApprovers) && enrollmentApprovers.length ? enrollmentApprovers.map((enrollmentApprover, i) => (
          <div key={i}>{enrollmentApprover}</div>
        )) : '...'}
      </>
  },
  {
    field: 'availableFor',
    headerName: 'Available for',
    renderField: ({ availableFor }) =>
      <>
        {(availableFor ?? []).map((availableForItem, i) => (
          <div key={i}>{availableForItem}</div>
        ))}
      </>
  },
];
export const catalogDataSetMap: DataObjectMap<CertificationPlansDetails>[] = [
  ...basicDataSetMap,
  { field: 'expirationFrame', headerName: 'Expiration period' },
  { field: 'renewalFrame', headerName: 'Renewal' },
  { field: 'duration', headerName: 'Estimated Certificate Duration' },
  { field: 'completeWithin', headerName: 'Due within' },
  { field: 'ownerCountry', headerName: 'Owner country' },
];
const validateFields: (keyof CertificationPlansDetails)[] = [
  'renewalFrame',
  'expirationFrame',
  'duration',
  'completeWithin',
  'ownerCountry',
];
export const getCatalogDataSetMap = (
  planDetails: CertificationPlansDetails,
  dataSetMap: DataObjectMap<CertificationPlansDetails>[]
): DataObjectMap<CertificationPlansDetails>[] =>
  dataSetMap.filter((dataSet) =>
    validateFields.includes(dataSet.field)
      ? planDetails[dataSet.field] !== null
      : dataSet
  );

export const getEnrollmentRequestDataSetMap = (
  requestDetails: CertificationPlansDetails
): DataObjectMap<CertificationPlansDetails>[] =>

  [
    ...basicDataSetMap,
    ...((requestDetails.dueDate
      ? [
        {
          field: 'dueDate',
          headerName: 'Due date',
          renderField: ({ dueDate }) => formatDate(dueDate),
        },
      ]
      : []) as DataObjectMap<CertificationPlansDetails>[]),
    ...((!requestDetails.dueDate && requestDetails.completeWithin
      ? [
        {
          field: 'completeWithin',
          headerName: 'Due within',
        },
      ]
      : []) as DataObjectMap<CertificationPlansDetails>[]),
  ]


export interface UserDetailsDataObjectViewProps {
  item?: CertificationPlansDetails;
  title?: string;
  dataSetMap?: DataObjectMap<CertificationPlansDetails>[];
}

export const UserDetailsDataObjectView: FC<UserDetailsDataObjectViewProps> = ({
  item,
  title = 'Details',
  dataSetMap = basicDataSetMap,
}) =>
  item ? (
    <DataObjectView title={title} item={item} dataSetMaps={dataSetMap} />
  ) : null;
