import ReactDOM from 'react-dom';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';

import './index.scss';
import { Router } from 'Router/Router';
import { theme } from 'theme/themeBase';

import ReactGA from 'react-ga';
import reportWebVitals from './reportWebVitals';

if(process.env.REACT_APP_GA_ID !== undefined)
  ReactGA.initialize(process.env.REACT_APP_GA_ID);

ReactGA.pageview(window.location.pathname);

ReactDOM.render(
  <MUIThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <Router />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </MUIThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
