import fileDownload from 'js-file-download';

import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { Print } from 'Print/model/Print';
import { Signer } from 'Print/model/Signer';
import { SubmitPrintDto } from 'Print/model/SubmitPrintDto';

const domainPath = '/print';

const getList = async (userId: string): Promise<Print[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/user/${userId}`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const print = async (userId: string, dto: SubmitPrintDto): Promise<unknown> => {
  try {
    const response = await apiClient.post(`${domainPath}/user/${userId}`, dto, {
      responseType: 'blob',
    });
    fileDownload(response.data, 'Certificate.pdf');

    return [];
  } catch (e) {
    throw new ApiError(e);
  }
};

const getSigners = async (
  businessUnitId: number,
  languageId?: number
): Promise<Signer[]> => {
  try {
    const response = await apiClient.get(
      `${domainPath}/signers/${businessUnitId}/lang/${languageId}`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const printApi = {
  getList,
  getSigners,
  print,
};
