import * as AppModel from 'model';

import * as Model from 'Dictionary/model/ScopeDetail'
import * as API from 'Dictionary/service/dictionary.api';

import { PageBase } from 'components/Page/PageBase';

import EditorPart from './Editor'
import FiltersPart from './Filters'

type QueryModel = Model.ScopeDetailFindQuery
type DTOModel = Model.ScopeKeyword
export const ContractAPI = API.dictionaryApi.scopeKeyword

const defaulQuery: QueryModel = {
  currentPageIndex: 0, pageSize: 50,
  sortExpression: "title",
  sortDescending: false
}
const defaultNewItem: DTOModel = { id: 0, sequence: 1, title: '' }
const getRowId = (row: DTOModel) => row.id

export default function Page() {

  const getNewItem = (): DTOModel => ({ ...defaultNewItem })

  return PageBase<DTOModel, QueryModel>({
    defaultQuery: { ...defaulQuery },
    getIdFor: getRowId,
    contract: ContractAPI,
    pageSettings: {
      title: "Manage Scope Details",
      subtitle: "Here you can find, define, edit or delete Scope Details",
      editTitle: "Edit detail",
      addTitle: "Add new detail",
      newItem: {
        actionCaption: "ADD NEW",
        inPageAsRowView: true
      },
      deleteItem: {
        confirmTitle: "Delete Scope Detail",
        confirmDescription: "Deleted Scope Detail can't be restored.",
        confirmButton: "DELETE"
      }
    },
    getRowView: (row, cancelHandler, updateHandler) => (<EditorPart item={row ?? getNewItem()} onCancel={cancelHandler} onCommit={updateHandler} />),
    getSearchFiltersForm: (onQuery) => (<FiltersPart defaultQuery={defaulQuery} onQuery={q => onQuery(q)} />),
    columns: (
      [
        {
          field: 'title',
          headerName: 'Title',
        },
        {
          field: 'scope',
          headerName: 'Scope',
          renderField: (r) => r.scope?.description
        },
        {
          field: 'division',
          headerName: 'Division',
          renderField: (r) => r.division?.description
        }
      ])
  })

}
