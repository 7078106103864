import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface Language {
  id: number;
  name: string;
}

export const mapToAutocompleteFieldItems = (
  languages: Language[] = []
): AutocompleteFieldItem[] =>
  languages.map((language) => ({
    id: language.id,
    name: language.name,
  }));
