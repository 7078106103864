import { FC } from 'react';

import { useCountriesQuery } from 'Dictionary/query/dictionaryQuery';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface CountriesSelectWithAllFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options'> {}

export const CountriesSelectWithAllField: FC<CountriesSelectWithAllFieldProps> = (props) => {
  const { data = [], isLoading, refetch } = useCountriesQuery();
  const getCountriesWithAllOption = () => {
    
  }
  return (
    <FormikAutocompleteField
      options={[{code: 'All',
        id: 0,
        name: 'All countries'},...data]}
      loading={isLoading}
      onOpen={() => refetch()}
      multiple
      {...props}
    />
  );
};
