import { Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AppShellBaseProps, DetailsAppShell } from 'components/AppShell';
import {
  CertificationsByPersonDetailsParams,
  certificationsByPersonMyLearningRoute,
  certificationsByPersonPrintOutCertificatesRoute,
  certificationsByPersonRoute,
} from 'route';
import { Card, Content } from 'components/Page';
import {
  RoundButton,
  PrintButton,
  DataObjectView,
  SyncButton,
} from 'components';

import { certificateApi } from 'Certificate/service/certificate.api';
import { useToasts } from 'components/ToastProvider';
import { queryClient } from 'service';
import {
  userHistoryKey,
  useUserCertificationsQuery,
  useUserTraningSetsQuery,
} from 'Certificate/query/certificateQuery';
import { UserCertificationsView } from 'Certificate/component/UserCertificationsView/UserCertificationsView';
import { myProfileApi } from 'MyProfile/service/myProfile.api';
import { useUserProfileQuery } from 'MyProfile/query/myProfile.query';
import { getFullName, MyProfile } from 'MyProfile/model/MyProfile';
import { getPgUnitFullName } from 'Dictionary/model/PGUnit';
import { TraningSetsTable } from 'Certificate/component/TraningSetsTable';
import { UserAttachmentList } from 'Attachment/component/UserAttachmentList/UserAttachmentList';
import { DataObjectMap } from 'components/DataObjectView/DataObjectView';

interface UserDataObjectItem {
  name: string;
  division?: string;
  geid?: string;
  productGroup?: string;
  businessUnitName?: string;
  hierarchicalManager?: string;
  functionalManager?: string;
  email?: string;
  company?: string;
  employeeJobs?: MyProfile['employeeJobs'];
  employeeJobFamily?: MyProfile['employeeJobFamilies'];
  preferredLanguage?: MyProfile['preferredLanguages'];
  country?: string;
  city?: string;
  state?: string;
  notes?: string;
  channelManager?: string;
  partnerCompanySupervisor?: string;
}
export const CertificationsByPersonDetailsPage = (props: AppShellBaseProps) => {
  const { logout } = props;
  const history = useHistory();
  const { showSuccess, showError } = useToasts();
  const params = useParams<CertificationsByPersonDetailsParams>();
  const { data: userProfile, isLoading, refetch: refetchUserProfile } = useUserProfileQuery(params.userId);
  
  const { data: userCertifications, isLoading: areCertificationsLoading, refetch } = useUserCertificationsQuery(params.userId);
  const { data: userTraningSets,
    isLoading: areTraningSetsLoading,
   refetch: refetchTraningSets,
  } = useUserTraningSetsQuery(Number(params.userId));
  const {
    mutate: syncUserHistory,
    isLoading: isSyncHistoryLoading,
  } = useMutation(() => certificateApi.updateUserHistory(params.userId), {
    onSuccess: () => {
      queryClient.removeQueries([userHistoryKey, params.userId]);
      
      refetchUserProfile();
      refetch();
      refetchTraningSets();
      showSuccess('User history synchronization success');
    },
    onError: () => showError('User history synchronization failed'),
  });
  const { mutate: askRemoval, isLoading: isAskRemovalLoading } = useMutation(
    () => myProfileApi.askRemoval(params.userId),
    {
      onSuccess: () => showSuccess('Ask removal action success'),
      onError: () => showError('Ask removal action failed'),
    }
  );
  const isMutating = isSyncHistoryLoading || isAskRemovalLoading;

  const handleOpenMyLearningDetails = () => {
    history.push(
      certificationsByPersonMyLearningRoute.createRoute({
        userId: params.userId,
      })
    );
  };
  const handleOpenStatusPdfDetails = () => {
    history.push(
      certificationsByPersonPrintOutCertificatesRoute.createRoute({
        userId: params.userId,
      })
    );
  };
  return (
    <DetailsAppShell
      title="Learner certifications list"
      loading={isLoading}
      logout={logout}
      defaultGoBackPath={certificationsByPersonRoute.path}
    >
      <Content
        title={getFullName(userProfile)}
        loading={isLoading}
        mutating={isMutating}
        mutatingLabel="Applying action..."
        renderActions={() => (
          <>
            <PrintButton onClick={handleOpenStatusPdfDetails} />
            <SyncButton
              disabled={isSyncHistoryLoading}
              onClick={() => syncUserHistory()}
            />
            <RoundButton onClick={handleOpenMyLearningDetails}>
              My learning
            </RoundButton>
            <RoundButton
              disabled={isAskRemovalLoading}
              onClick={() => askRemoval()}
            >
              Ask removal
            </RoundButton>
          </>
        )}
      >
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <DataObjectView<UserDataObjectItem>
              title="Details"
              item={{
                ...{
                  name: getFullName(userProfile),
                  geid: userProfile?.geid,
                  businessUnitName: userProfile?.businessUnit?.description
                },
                ...(!userProfile?.isExternal && {
                  hierarchicalManager: getFullName(userProfile?.manager),
                  functionalManager: getFullName(userProfile?.functionalManager)
                }),
                ...{                                  
                  email: userProfile?.email,
                  company: userProfile?.company?.description,
                  channelManager: (userProfile?.channelManager ?? [])
                    .map((manager) => getFullName(manager))
                    .join(', '),
                  employeeJobs: userProfile?.employeeJobs,
                  employeeJobFamily: userProfile?.employeeJobFamilies,
                  preferredLanguage: userProfile?.preferredLanguages,
                  country: userProfile?.country?.name,
                  city: userProfile?.city,
                  state: userProfile?.state,
                  notes: userProfile?.notes,
                  division: userProfile?.division?.name,
                  productGroup: userProfile?.pgUnit ? getPgUnitFullName(userProfile.pgUnit) : '...',
                },
                ...(userProfile?.isExternal && {
                  partnerCompanySupervisor: (
                    userProfile?.partnerCompanySupervisor ?? []
                  )
                    .map((manager) => getFullName(manager))
                    .join(', '),
                }),
              }}
              dataSetMaps={[
                { field: 'name', headerName: 'Name' },                
                { field: 'email', headerName: 'Email address' },
                { field: 'geid', headerName: 'GEID' },
                { field: 'division', headerName: 'Business area' }, 
                { field: 'businessUnitName', headerName: 'Division' }, 
                { field: 'productGroup', headerName: 'Product group' },                                                              
                { field: 'country', headerName: 'Country' },
                { field: 'city', headerName: 'City' },
                { field: 'state', headerName: 'State' },
                { field: 'company', headerName: 'Company' },                
                {
                  field: 'channelManager',
                  headerName: 'Int/Ext Company Coordinator',
                },
                ...((!userProfile?.isExternal
                  ? [
                      {
                        field: 'hierarchicalManager',
                        headerName: 'Hierarchical Manager',
                      },                        
                      {
                        field: 'functionalManager',
                        headerName: 'Functional Manager',
                      },
                    ]
                  : []) as DataObjectMap<UserDataObjectItem>[]),  
                ...((userProfile?.isExternal
                  ? [    
                      {
                        field: 'partnerCompanySupervisor',
                        headerName: 'External Company Responsible',
                      },
                    ]
                  : []) as DataObjectMap<UserDataObjectItem>[]),
                {
                  field: 'employeeJobFamily',
                  headerName: 'Employee Job Family',
                  renderField: (item) => (                    
                      (item.employeeJobFamily ?? []).map((job) => job.name).join(', ')                
                  ),
                }, 
                {
                  field: 'employeeJobs',
                  headerName: 'Employee Job',
                  renderField: (item) => (                    
                      (item.employeeJobs ?? []).map((job) => job.name).join(', ')                
                  ),
                },  
                {
                  field: 'preferredLanguage',
                  headerName: 'Preferred language',
                  renderField: (item) => (
                    <>
                      {(item.preferredLanguage ?? []).map((language, i) => (
                        <div key={i}>{language.name}</div>
                      ))}
                    </>
                  ),
                },                  
                {
                  field: 'notes',
                  headerName: 'Notes',
                  renderField: ({ notes }) => (
                    <>
                      {!notes ? '...' : notes.split("\n").map((line,i) => (
                      <div key={i}>{line}</div>
                      ))}
                    </>
                  ),
                },                
              ]}
            />
          </Grid>
          <Grid xs={12} item>
            <TraningSetsTable
              loading={areTraningSetsLoading}
              rows={userTraningSets ?? []}
            />
          </Grid>
          <Grid xs={12} item>
            <UserCertificationsView
              loading={areCertificationsLoading}
              userCertifications={userCertifications}
            />
          </Grid>
          <Grid xs={12} item>
            <Card header="Attachments" transparent listContent>
              <UserAttachmentList userId={params.userId} />
            </Card>
          </Grid>
        </Grid>
      </Content>
    </DetailsAppShell>
  );
};
