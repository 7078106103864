import { FC, useMemo } from 'react';

import { useMyProfileQuery } from 'MyProfile/query/myProfile.query';
import { Role } from 'MyProfile/model/Role';
import { RolesList } from 'MyProfile/component/RolesList';

export const MyRolesList: FC = () => {
  const { data } = useMyProfileQuery();
  const roles = useMemo<Role[]>(
    () => [{ id: -1, type: 'Learner', details: [] }, ...(data?.roles ?? [])],
    [data]
  );
  return <RolesList roles={roles} removable={false} />;
};
