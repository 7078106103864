import { TransitionProps } from '@material-ui/core/transitions';
import {
  AppBar,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography,
  ListItem,
  ListItemText,
  List,
  TablePagination,
} from '@material-ui/core';
import { Content } from 'components/Page';
import {
  forwardRef,
  Ref,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
} from 'react';
import { Close } from '@material-ui/icons';
import { AbbTheme } from 'theme/createAbbTheme';
import { getColDefName } from 'utils';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import {
  GridColDef,
  GridPageChangeParams,
  GridSortModelParams,
} from '@material-ui/data-grid';
import {
  ColumnDefinition,
  ReportPageTypes,
  ReportQuery,
  ReportQueryResponseFlat,
  ReportSortQuery,
} from '../../model';
import { ReportingFilters } from '../@types';
import { ReportDataGrid } from './ReportDataGrid';

export interface GroupByDialogProps {
  groupByCid: string | null;
  onClose: () => void;
  columnsDefinitions: ColumnDefinition[];
  filters: ReportingFilters;
  sort: ReportSortQuery;
  reportType: ReportPageTypes;
  reportApi: (query: ReportQuery) => Promise<ReportQueryResponseFlat>;
  gridColDef: GridColDef[];
}

const Transition = forwardRef((props: TransitionProps, ref: Ref<unknown>) => (
  <Slide direction="up" ref={ref} {...props} />
));

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    appBar: {
      position: 'fixed',
    },
    toolbar: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    paper: {
      backgroundColor: '#fafafa',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 64,
      width: '100%',
      margin: theme.spacing(-4, 2, 0, 0),
    },
    pagination: {
      marginBottom: theme.spacing(4),
    },
    groupsList: {
      position: 'fixed',
      height: `calc(100vh - 64px)`,
      overflowY: 'scroll',
      overflowX: 'hidden',
      backgroundColor: theme.palette.common.white,
      width: 280,
      marginRight: theme.spacing(2),
    },
    column: {
      width: 280,
      minHeight: `calc(100vh - 64px)`,
    },
    dataGrid: {
      boxSizing: 'border-box',
      margin: theme.spacing(0, 2),
      width: `calc(100% - 280px)`,
    },
    resultsTitle: {
      margin: theme.spacing(4, 0, 2, 0),
    },
  }),
  {
    name: 'GroupByDialog',
  }
);

export const GroupByDialog = (props: GroupByDialogProps) => {
  const {
    groupByCid,
    onClose,
    columnsDefinitions,
    filters,
    sort,
    reportApi,
    reportType,
    gridColDef,
  } = props;
  const classes = useStyles();
  const [pageGroups, setPageGroups] = useState(0);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [pageSizeGroups] = useState(50);
  const [groupSort, setGroupSort] = useState<ReportSortQuery>({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const title = useMemo<string>(() => {
    const col = columnsDefinitions.find((c) => c.cid === groupByCid);
    if (col) {
      return `Group by ${getColDefName(col)}`;
    }
    return 'Group by';
  }, [groupByCid, columnsDefinitions]);
  const {
    isLoading: reportLoading,
    data: report,
    refetch: getReport,
  } = useQuery(
    [reportType, page, pageSize, groupByCid, selectedValue, selectedValue],
    () =>
      reportApi({
        filters,
        grouping: {},
        groupings: [
          {
            columnId: groupByCid ?? '',
            isChosen: true,
            value: selectedValue as string,
          },
        ],
        sort,
        columns: gridColDef.map((x) => x.field),
        offset: page - 1,
        limit: pageSize,
      }),
    {
      staleTime: 0,
      keepPreviousData: false,
      refetchOnMount: false,
      enabled: Boolean(groupByCid && selectedValue && gridColDef.length > 0),
    }
  );
  const handleSort = (p: GridSortModelParams) => {
    const sortSettings = p?.sortModel[0];
    if (sortSettings) {
      setGroupSort({
        column: sortSettings?.field,
        direction: sortSettings.sort === 'asc',
      });
    } else {
      setGroupSort({});
    }
  };
  const {
    isLoading: groupsLoading,
    data: groups,
    refetch: getGroups,
  } = useQuery(
    [reportType, pageGroups, pageSizeGroups, groupByCid],
    () =>
      reportApi({
        filters,
        grouping: {},
        groupings: [{ columnId: groupByCid ?? '', isChosen: false }],
        sort,
        columns: [groupByCid ?? ''],
        offset: pageGroups,
        limit: pageSizeGroups,
      }),
    {
      staleTime: 0,
      keepPreviousData: false,
      refetchOnMount: false,
      enabled: Boolean(groupByCid),
    }
  );
  const handleChangePageGroup = (
    event: MouseEvent<HTMLButtonElement, unknown> | null,
    page: number
  ) => {
    setPageGroups(page);
  };
  useEffect(() => {
    if (groupByCid && selectedValue) {
      getReport();
    }
  }, [selectedValue]);
  useEffect(() => {
    if (groupByCid) {
      getGroups();
    }
  }, [groupByCid, pageGroups]);
  useEffect(
    () => () => {
      setPageGroups(0);
      setSelectedValue(null);
    },
    []
  );

  return (
    <Dialog
      fullScreen
      open={Boolean(groupByCid)}
      onClose={onClose}
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
    >
      <AppBar className={clsx(classes.appBar, classes.toolbar)}>
        <Toolbar variant="regular" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography
            className={classes.title}
            variant="h6"
            color="textPrimary"
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Content loading={groupsLoading}>
        <div className={classes.content}>
          <div className={classes.groupsList}>
            <List>
              {groups?.data.map(({ id, count, label }) => (
                <ListItem
                  key={id}
                  onClick={() => setSelectedValue(id as string)}
                  selected={selectedValue === id}
                  button
                >
                  <ListItemText primary={label} secondary={`Count: ${count}`} />
                </ListItem>
              ))}
            </List>
            {pageSizeGroups < (groups?.total ?? 0) && (
              <TablePagination
                className={classes.pagination}
                component="div"
                count={groups?.total ?? 0}
                page={pageGroups}
                onChangePage={handleChangePageGroup}
                rowsPerPage={pageSizeGroups}
                rowsPerPageOptions={[]}
              />
            )}
          </div>
          <div className={classes.column} />
          <div className={classes.dataGrid}>
            {selectedValue ? (
              <>
                <Typography className={classes.resultsTitle} variant="h5">
                  Results
                </Typography>
                <ReportDataGrid
                  loading={reportLoading}
                  reportType={reportType}
                  page={page}
                  pageSize={pageSize}
                  columns={gridColDef}
                  report={report ?? { data: [], total: 0 }}
                  sort={groupSort}
                  onPageChange={(p: GridPageChangeParams) => {
                    setPage(p.page);
                  }}
                  onPageSizeChange={(p: GridPageChangeParams) => {
                    setPageSize(p.pageSize);
                  }}
                  onSortModelChange={handleSort}
                  onGroupByChange={null}
                  columnsDefinitions={columnsDefinitions ?? []}
                />
              </>
            ) : (
              <Typography className={classes.resultsTitle} variant="h5">
                Select group to display results…
              </Typography>
            )}
          </div>
        </div>
      </Content>
    </Dialog>
  );
};
