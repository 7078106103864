import React, { FC } from 'react';

import { FormikAutocompleteField, FormikAutocompleteFieldProps } from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface DateRangeSelectFieldProps 
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'>
{
  name: string;
}

export enum DateRangeOption {
  Custom,
  Today,
  Yesterday,
  Last7Days,
  CurrentMonth,
  PreviousMonth,
  Last90Days
}

export const getDateRangeAutocompleteFieldItems = (): AutocompleteFieldItem[] => [
  { id: DateRangeOption.Custom, name: 'Custom' },
  { id: DateRangeOption.Today, name: 'Today' },
  { id: DateRangeOption.Yesterday, name: 'Yesterday' },
  { id: DateRangeOption.Last7Days, name: 'Last 7 days' },
  { id: DateRangeOption.CurrentMonth, name: 'Current month' },
  { id: DateRangeOption.PreviousMonth, name: 'Previous month' },
  { id: DateRangeOption.Last90Days, name: 'Last 90 days' },
];

//const getDefaultDateRangeItem = (): AutocompleteFieldItem => ({ id: DateRangeOption.Custom, name: 'Custom' })

export const DateRangeSelectField: FC<DateRangeSelectFieldProps> = ({
  name,
  ...otherProps
}) => (
  <FormikAutocompleteField    
    name={name}
    label="Date range"
    options={getDateRangeAutocompleteFieldItems()}    
    disableClearable={true}
    {...otherProps}    
  />
);
