import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  AutocompleteField,
  AutocompleteFieldItem,
} from '../Field/AutocompleteField/AutocompleteField';

import { useUserHrgtQuery } from '../../MyProfile/query/myProfile.query';
import { mapToAutocompleteFieldItems } from '../../Dictionary/component/HrgtUserSelectField/utils';
import { getPagination, PaginatedList, Sort } from '../../model';
import { MyProfile } from '../../MyProfile/model/MyProfile';


export interface UserSearchProps {
  label: string;
  onChanged: React.Dispatch<{ id: string, email: string, name: string }[]>;
  initialValue?: string[];
  multiple?: boolean;
  helperText?: string;
  required?: boolean
  disabled?: boolean
}

const defaultSort: Sort<MyProfile> = {
  sortExpression: 'lastName',
  sortDescending: false,
};
const searchTimeout = 500;
const minLenOfSearchValue = 3;
export const UserSearch = (props: UserSearchProps) => {
  const {
    label,
    onChanged,
    initialValue,
    multiple = true,
    required,
    disabled,
    helperText
  } = props;

  const [value, setValue] = useState('');
  const inv = (initialValue ?? []).map((x) => (
    {
      id: `${x ?? Math.random()}-`,
      //@ts-ignore
      email: x ?? "",
      name: x
    }
  ))
  const [selectedValues, setSelectedValues] = useState<object[]>(inv)

  const { data = [], isLoading, refetch } = useUserHrgtQuery(
    getPagination(20),
    defaultSort,
    {
      isExternal: false,
      personNameOrEmail: value,
    },
    {
      enabled: false
    }
  );


  useEffect(() => {

    if (value.length >= minLenOfSearchValue) {
      const timer = setTimeout(() => refetch(), searchTimeout);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [value]);

  const handleItemsChange = (
    e: ChangeEvent<{}>,
    value: AutocompleteFieldItem | AutocompleteFieldItem[],
    xxvalues: AutocompleteFieldItem[]
  ) => {
    xxvalues.splice(0, xxvalues.length)
    if (!Array.isArray(value) || value.length === 0)
      xxvalues.splice(0, xxvalues.length)
    else
      xxvalues.push(...value);

    const toSend = xxvalues.map((x) => (
      {
        id: (x.id ?? "").toString(),
        //@ts-ignore
        email: x.email ?? "",
        name: x.name ?? ""
      }
    ));
    onChanged(toSend);
  }

  return (

    <AutocompleteField
      label={label}
      multiple={multiple}
      value={selectedValues}
      loading={isLoading}
      helperText={helperText}
      required={required}
      options={mapToAutocompleteFieldItems(data as PaginatedList<MyProfile>)}
      onChange={(e, v, r, d) => { handleItemsChange(e, v, selectedValues) }}
      onInputChange={(event, eventValue) => setValue(eventValue)}
    />
  );
};
