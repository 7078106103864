import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { CardProps as MUICardProps } from '@material-ui/core/Card';

import { HeaderWrapper, StyledCard } from './Card.style';

export interface CardProps extends MUICardProps {
  header?: string;
  listContent?: boolean;
  transparent?: boolean;
}

export const Card: FC<CardProps> = ({
  header,
  listContent,
  elevation,
  transparent = false,
  children,
  ...cardProps
}) => (
  <StyledCard
    {...cardProps}
    $transparent={transparent}
    elevation={transparent ? 0 : elevation}
  >
    {header && (
      <HeaderWrapper listContent={listContent}>
        <Typography variant="h6">{header}</Typography>
      </HeaderWrapper>
    )}
    {children}
  </StyledCard>
);
