import { FC } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { AppShellBaseProps, DetailsAppShell } from 'components/AppShell';
import { Content } from 'components/Page';
import { TabsView } from 'components/TabsView';
import { useUserHistoryQuery } from 'Certificate/query/certificateQuery';
import {
  CertificationsByPersonDetailsParams,
  certificationsByPersonDetailsRoute,
} from 'route';
import { AbbTheme } from 'theme/createAbbTheme';
import { MyLearningCourseHistoryTable } from 'Certificate/component/MyLearningCourseHistoryTable/MyLearningCourseHistoryTable';
import { useUserProfileQuery } from 'MyProfile/query/myProfile.query';
import { getFullName } from 'MyProfile/model/MyProfile';
import { ReactComponent as NotFoundSvg } from '../../components/QueryErrorResetBoundary/svg/NotFound.svg';

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    empty: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }
  }),
  { name: 'MyLearningHistory' }
);

export const CertificationsByPersonMyLearningPage: FC<AppShellBaseProps> = (
  props
) => {
  const { logout } = props;
  const { userId } = useParams<CertificationsByPersonDetailsParams>();
  const { data, isLoading } = useUserHistoryQuery(userId);
  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
  } = useUserProfileQuery(userId);
  const classes = useStyles();

  return (
    <DetailsAppShell
      title="MyLearning courses history"
      subtitle={getFullName(userProfile)}
      loading={isUserProfileLoading}
      logout={logout}
      defaultGoBackPath={certificationsByPersonDetailsRoute.createRoute({
        userId: String(userId),
      })}
    >
      <Content
        title="MyLearning courses history"
        subtitle={userProfile?.email}
        loading={isLoading}
      >
        {data ?
          <Grid container spacing={3}>
          <Grid xs={12} item>
            <TabsView
              labels={['Uncompleted', 'Completed']}
              renderTabs={() => [
                <MyLearningCourseHistoryTable
                  key={0}
                  rows={data?.unCompleted ?? []}
                  noDataMessage="No uncompleted courses available"
                />,
                <MyLearningCourseHistoryTable
                  key={1}
                  rows={data?.completed ?? []}
                  noDataMessage="No completed courses available"
                />,
              ]}
              animateHeight={false}
            />
          </Grid>
        </Grid>
        :
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <NotFoundSvg />
          <Typography variant="h6">
            Error in connection with MyLearning
          </Typography>
        </div>
        }
        
      </Content>
    </DetailsAppShell>
  );
};
