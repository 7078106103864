import { FC, useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { useMutation } from 'react-query';

import { Role, RoleDetails } from 'MyProfile/model/Role';
import { List, ListItem } from 'components/List';
import { ListItemType } from 'components/List/ListItem/ListItem';
import { Table } from 'components/Table';
import { useModal } from 'hooks';
import { ModalContent } from 'components/Page';
import { ApiError } from 'model';
import { myProfileApi } from 'MyProfile/service/myProfile.api';
import { displayRoleType, RoleType } from 'MyProfile/enum/RoleType';
import { useToasts } from 'components/ToastProvider';
import { queryClient } from 'service';
import { userAuthorizationsKey } from 'MyProfile/query/myProfile.query';
import { AdminListItemRoot, DeleteIconButton } from './RolesList.style';

interface RolesListItemType extends ListItemType {
  role: Role;
}

const getRolesListItems = (roles: Role[]): RolesListItemType[] =>
  roles.map((role) => ({
    primaryText: displayRoleType(role.type as RoleType),
    Icon: PersonIcon,
    role,
    ...(role.details.length && { RightIcon: PeopleIcon }),
  }));

export interface RolesListProps {
  roles: Role[];
  removable: boolean;
  userId?: number;
}

export const RolesList: FC<RolesListProps> = ({ roles, removable, userId }) => {
  const { showSuccess, showError } = useToasts();
  const [myProfileRole, setMyProfileRole] = useState<Role | null>(null);
  const handleOnItemClick = (role: Role) => {
    setMyProfileRole(role);
    openModal();
  };
  const { mutate: removeUserRole, isLoading } = useMutation<
    unknown,
    ApiError,
    { userId: number; userAuthorizationId: number; roleType: RoleType }
  >(
    ({ userId, userAuthorizationId, roleType }) =>
      myProfileApi.deleteAuthorizations({
        userId,
        userAuthorizationId,
        roleType,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([userAuthorizationsKey, userId]);
        showSuccess('Remove user role action success');
        closeModal();
      },
      onError: (error) => showError(error.message),
    }
  );

  const { Modal, openModal, closeModal } = useModal(() => (
    <ModalContent
      header={`Details list for ${displayRoleType(myProfileRole?.type as RoleType)} (${myProfileRole?.details.length})`}
    >
      <Table<RoleDetails>
        rows={myProfileRole?.details ?? []}
        renderRow={(row) => {
          const isRemovable = removable && userId && row.authorizationId;
          return (
            <TableRow>
              <TableCell component="th" scope="row">
                {row.description1}
              </TableCell>
              <TableCell align="right">{row.description2}</TableCell>
              {isRemovable && (
                <TableCell align="right" width={100}>
                  <IconButton
                    onClick={() => {
                      if (userId && myProfileRole) {
                        removeUserRole({
                          userId,
                          roleType: myProfileRole.type as RoleType,
                          userAuthorizationId: row.authorizationId,
                        });
                      }
                    }}
                    disabled={isLoading}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          );
        }}
      />
    </ModalContent>
  ));

  const renderAdminListItem = (item: RolesListItemType) => (
    <AdminListItemRoot>
      <ListItem
        primaryText={item.primaryText}
        Icon={item.Icon}
        RightIcon={item.RightIcon}
        onClick={() => item.role.details.length && handleOnItemClick(item.role)}
        button={!!item.RightIcon}
      />
      <DeleteIconButton
        onClick={() =>
          userId &&
          removeUserRole({
            userId,
            userAuthorizationId: 0,
            roleType: RoleType.Administrator,
          })
        }
        disabled={isLoading}
      >
        <DeleteIcon />
      </DeleteIconButton>
    </AdminListItemRoot>
  );

  return (
    <>
      <List
        items={getRolesListItems(roles)}
        renderItem={(item) =>
          removable && item.role.type === RoleType.Administrator ? (
            renderAdminListItem(item)
          ) : (
            <ListItem
              primaryText={item.primaryText}
              Icon={item.Icon}
              RightIcon={item.RightIcon}
              onClick={() =>
                item.role.details.length && handleOnItemClick(item.role)
              }
              button={!!item.RightIcon}
            />
          )
        }
      />
      <Modal />
    </>
  );
};
