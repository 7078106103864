import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ExpandMore, Settings } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';

import { Content } from 'components/Page';
import { RoundButton } from 'components';
import { MassEnrollResponse } from 'CertificationList/model/MassEnrollResponse';
import { ApiError } from 'model';
import { SearchCertificates } from 'Catalog/component/SearchCertificates';
import { MassEnrollmentDialog, MassEnrollmentDialogState } from 'MassEnrollment/component/MassEnrollmentDialog';

import { massEnrollmentColumns } from 'Catalog/component/CatalogCertificateTable';
import { SearchUsers } from 'MyProfile/component/SearchUsers/SearchUsers';
import { enrollmentColumns } from 'MyProfile/component/UserTable';
import { certificationListApi } from 'CertificationList/service/certificationList.api';
import { useToasts } from 'components/ToastProvider';

import { formatTemplate } from '../../utils';
import { StyledCard } from './MassEnrollmentPage.style';


const pageSize = 10;

export const MassEnrollmentPage: FC = () => {
  const { showSuccess, showInfo, showError, showMany } = useToasts();
  const [selectedCertificates, setSelectedCertificates] = useState<string[]>(
    []
  );
  const theme = useTheme();
  const [dueDate, setDueDate] = useState<Dayjs | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);

  const { mutate: massEnroll } = useMutation<
    MassEnrollResponse,
    ApiError, 
    MassEnrollmentDialogState|undefined
  >(
    (data?:MassEnrollmentDialogState) =>
      data ?
      certificationListApi.massEnroll({
        certificationIds: selectedCertificates.map((id) => parseInt(id, 10)),
        usersIds: selectedUsers.map((id) => parseInt(id, 10)),
        notificationSubject: data?.subject,
        notificationMessage: data?.message,
        additionalRecipients: data?.recipients.split(",").map((r) => r.trim()).filter((r) => r),
        dueDate: dueDate ? dayjs(dueDate).toISOString() : null
      }) :
      certificationListApi.massEnroll({
        certificationIds: selectedCertificates.map((id) => parseInt(id, 10)),
        usersIds: selectedUsers.map((id) => parseInt(id, 10)),
        dueDate: dueDate ? dayjs(dueDate).toISOString() : null
      }),
    {
      onSuccess: (response) => {
        if (response.failed.length && !response.success.length) {          
          showMany('All enrollments failed', 
          response.failed.map(response => `User ${response.userEmail} was not enrolled with message: ${response.message}`)
          , true);          
        }
        if (!response.failed.length && response.success.length) {
          showSuccess('Users have been enrolled to Certifications');
        }
        if (response.failed.length && response.success.length) {
          showSuccess('Users have been enrolled to Certifications');
          showMany('Some enrollments failed', 
          response.failed.map(response => `User ${response.userEmail} was not enrolled with message: ${response.message}`)
          , true);          
        }
      },
      onError: (error) => showError(`Enroll Users to Certifications action failed with message: ${error.message}`),
    }
  );

  const handleOnEnroll = () => {    
    massEnroll(undefined);
    showInfo('Enroll Users to Certifications action queued');
    setSelectedUsers([]);
    setSelectedCertificates([]);    
  };

  const doSend = (data: MassEnrollmentDialogState)=>{
    massEnroll(data);
    showInfo('Enroll Users to Certifications action queued');
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const disableEnroll = !selectedCertificates.length || !selectedUsers.length;
  return (<>
    <Content
      title="Manual enrollment"
      subtitle="Enroll multiple users to certificates"
      renderActions={() => (
        <div>
          <RoundButton
            color="secondary"
            variant="text" type='button'
            disabled={disableEnroll}
            onClick={openDialog}
          >
            Enroll with customized notification
          </RoundButton>

          <RoundButton
            color="secondary"
            variant="contained"
            disabled={disableEnroll}
            onClick={handleOnEnroll}
          >
            Enroll
          </RoundButton>
        </div>
      )}
    >
      <Accordion
        elevation={0}
        style={{
          marginTop: theme.spacing(2),
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Settings style={{ marginRight: theme.spacing(2) }} />
          <Typography variant="h6">Define due date here</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} lg={6}>
            <KeyboardDatePicker
              value={dueDate}
              onChange={setDueDate}
              variant="inline"
              inputVariant="filled"
              label="Due date"
              format={formatTemplate}
              placeholder='yyyy/mm/dd'
              autoOk
            />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <StyledCard header="Users">
            <SearchUsers
              initialPageSize={pageSize}
              DataTableProps={{
                columns: enrollmentColumns,
                outerSelectedRows: selectedUsers,

                onCheckboxChange: setSelectedUsers,
                onRowsSelection: (x) => setSelectedProfiles(x.map(p=> p.email)),
                checkboxSelection: true,
              }}
              SearchFiltersFormProps={{ fullScreenList: false, displayRoleFilters: false, isForAction: true, withFilterSaveKey: "MassEnrolment" }}
              displaySelectedActions={false}
              mobileView={false} 
              hideExcelExport={true}
            />
          </StyledCard>
        </Grid>
        <Grid item xs={12} lg={6}>
          <StyledCard header="Certificates">
            <SearchCertificates
              initialPageSize={pageSize}
              enableExcelExport={false}
              DataTableProps={{
                columns: massEnrollmentColumns,
                checkboxSelection: true,
                outerSelectedRows: selectedCertificates,
                onCheckboxChange: setSelectedCertificates,
              }}
              SearchFiltersFormProps={{
                fullScreenList: false,
                displayTrainingSets: true,
                placeholder: 'Search by title or expand the filter on the right'
              }}
              mobileView={false}
            >
              {(renderSearchFilters, renderTable) => (
                <>
                  {renderSearchFilters()}
                  {renderTable()}
                </>
              )}
            </SearchCertificates>
          </StyledCard>
        </Grid>
      </Grid>
    </Content>
    <MassEnrollmentDialog
        isDialogOpen={isDialogOpen}
        selectedProfiles={selectedProfiles}
        onClose={closeDialog}
        onDoSend={doSend} 
        isReminder={false}
        />
  </>
  );
};
