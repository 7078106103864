import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { UserCertifications } from 'Certificate/model/UserCertifications';
import { PlanRequirements } from 'Requirement/model/PlanRequirements';
import { UserHistory } from 'Certificate/model/UserHistory';
import { UserTrainingSet } from 'Certificate/model/UserTraningSet';

const domainPath = '/certificates';

const getMyCertificates = async (): Promise<UserCertifications> => {
  try {
    const response = await apiClient.get(`${domainPath}/my`);

    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getUserCertifications = async (
  userId: string
): Promise<UserCertifications> => {
  try {
    const response = await apiClient.get(`${domainPath}/user/${userId}`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getUserHistory = async (userId: string): Promise<UserHistory | null>  => {
  try {
    const response = await apiClient.get(
      `${domainPath}/user/${userId}/history`
    );
    return response.data;
  } catch (e) {
    //throw new ApiError(e);
    return null;
  }
};

const updateUserHistory = async (userId: string): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/user/${userId}/history/update`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getPlanRequirements = async (
  certificatePlanId: number
): Promise<PlanRequirements> => {
  try {
    const response = await apiClient.get(
      `/certification-plans/${certificatePlanId}/requirements`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getUserTraningSets = async (
  userId: number
): Promise<UserTrainingSet[]> => {
  try {
    const response = await apiClient.get(
      `certificates/user/${userId}/training-sets`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const certificateApi = {
  getMyCertificates,
  getUserCertifications,
  getUserHistory,
  updateUserHistory,
  getPlanRequirements,
  getUserTraningSets
};
