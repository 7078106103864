import React, { FC } from 'react';

import { useCompanyChannelQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'Dictionary/model/CompanyChannel';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface CompanyChannelSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {
  defaultOptions?: AutocompleteFieldItem[];
}

export const CompanyChannelSelectField: FC<CompanyChannelSelectFieldProps> = ({
  disabled = false,
  defaultOptions = [],
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = useCompanyChannelQuery();

  return (
    <FormikAutocompleteField
      label="Channel type"
      disabled={disabled}
      options={[...defaultOptions, ...mapToAutocompleteFieldItems(data)]}
      loading={isLoading}
      onOpen={() => refetch()}
      required
      {...otherProps}
    />
  );
};
