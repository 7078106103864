import { withStyles } from '@material-ui/core/styles';
import { Chip, ChipProps } from '@material-ui/core';

const StyledChipButton = withStyles({
    root: {
        borderRadius: 5,
        margin: 5,
        paddingTop: 15,
        paddingBottom: 15
    },
})(Chip);

export const ChipButton = (p: ChipProps) => (<StyledChipButton variant='outlined' {...p} />)
