import React from 'react'
import { Content, Stack } from 'components'

import * as API from 'Dictionary/service/dictionary.api';

import { PrintingConfigurationCLIdentificatorsPart } from './PrintingConfigurationCLIdentificatorsPart';
import SearchFiltersForm from './Filters'
import * as Model from './model'

type QueryModel = Model.FindQuery

const defaulQuery: QueryModel = {
}

const port: Model.ContractApi = {
  get: API.dictionaryApi.IPrintingCertificatesContract.getSettings,
  update: API.dictionaryApi.IPrintingCertificatesContract.updateSettings,
}

export function Page() {

  const [state, setState] = React.useState({ query: defaulQuery })
  const onQuery = (query: QueryModel) => setState({ query })

  ///
  /// Render
  return (
    <Content
      title="Headers"
      subtitle="Select division to edit details"
    >
      <Stack >
        <SearchFiltersForm defaultQuery={state.query} onQuery={onQuery} />

        {state.query && state.query.division ?
          <PrintingConfigurationCLIdentificatorsPart key={state.query.division.id} port={port} division={state.query.division} /> :
          <></>}

      </Stack >
    </Content>)
};
