import { setLocale as setYupLocale } from 'yup'
import { Formik, FormikValues, FormikConfig } from 'formik'
import { EditorHelper, EditorBase } from './EditorBase'

export * from './FormikAutocompleteField/FormikAutocompleteField'
export * from './FormikTextField/FormikTextField'
export * from './FormikCheckboxField'
export * from './EditorBase'
export * from './DictionaryField'

export { Form, Formik, FieldArray } from 'formik'
export type { FormikProps, ArrayHelpers, FieldArrayRenderProps } from 'formik'
export * as Yup from 'yup'

setYupLocale({
    mixed: {
        required: 'This field is required',
    },
})

export function FormikForApp<Values extends FormikValues = FormikValues, ExtraProps = {}>(props:
    Omit<FormikConfig<Values>, "children">
    & { children: ((props: EditorHelper<Values>) => React.ReactNode) }
    & ExtraProps): JSX.Element {

    return <Formik {...props} >

        {formikProps => {
            const helper = EditorBase.getHelper(formikProps, () => { }, () => { })
            return <>{props.children(helper)}</>
        }}
    </Formik>
}