import fileDownload from 'js-file-download';

import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { PaginatedList, PaginationDto, Sort } from 'model';
import { ByPerson } from 'ByPerson/model/ByPerson';
import { ByPersonSearchFilters } from 'ByPerson/model/ByPersonSearchFilters';
import { getDateNow } from 'utils';

const domainPath = '/by-person';

const search = async (
  pagination: PaginationDto,
  sort: Sort<ByPerson>,
  filters: Partial<ByPersonSearchFilters>
): Promise<PaginatedList<ByPerson>> => {
  try {
    const response = await apiClient.post(`${domainPath}/search`, {
      ...filters,
      ...pagination,
      ...sort,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const exportToExcel = async (
  filters: Partial<ByPersonSearchFilters>
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/export-excel`,
      filters,
      {
        responseType: 'blob',
      }
    );
    fileDownload(response.data, `Export search by person ${getDateNow()}.xlsx`);
    return [];
  } catch (e) {
    throw new ApiError(e);
  }
};

export const byPersonApi = {
  search,
  exportToExcel,
};
