import React, { FC } from 'react';

import { useLanguagesByBusinessUnitQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'Dictionary/model/Language';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface LanguagesByBusinessUnitSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options'> {
  buId: number;
}

// eslint-disable-next-line max-len
export const LanguagesByBusinessUnitSelectField: FC<LanguagesByBusinessUnitSelectFieldProps> = ({
  buId,
  multiple = true,
  disabled = false,
  ...otherProps
}) => {
  const { data, isLoading, refetch } = useLanguagesByBusinessUnitQuery(buId);

  return (
    <FormikAutocompleteField
      {...otherProps}
      multiple={multiple}
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading}
      onOpen={() => refetch()}
      required
    />
  );
};
