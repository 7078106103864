import { FC } from 'react';
import MUIAccordion, {
  AccordionProps as MUIAccordionProps,
} from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SummaryWrapper } from './Accordion.style';

export interface AccordionProps extends MUIAccordionProps {
  summaryIcon?: JSX.Element;
  summaryHeader: string;
  summaryCaption?: string;
}

export const Accordion: FC<AccordionProps> = ({
  summaryHeader,
  summaryIcon,
  summaryCaption,
  children,
  ...accordionProps
}) => (
  <MUIAccordion
    TransitionProps={{ unmountOnExit: true }}
    square
    {...accordionProps}
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      {summaryIcon}
      <SummaryWrapper>
        {summaryCaption && (
          <Typography variant="caption">{summaryCaption}</Typography>
        )}
        <Typography variant="h6" style={{ marginLeft: summaryIcon ? "1rem" : 0 }}>{summaryHeader}</Typography>
      </SummaryWrapper>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </MUIAccordion>
)

