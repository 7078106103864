import React, { FC } from 'react';

import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { CertificationPlanScopeType } from 'enums/CertificationPlanScopeType';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface LevelSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {}

export const getAutocompleteFieldItems = (): AutocompleteFieldItem[] => [
  { id: CertificationPlanScopeType.AllScopes, name: 'All scopes' },
  { id: CertificationPlanScopeType.Application, name: 'Application' },
  { id: CertificationPlanScopeType.General, name: 'General' },
  { id: CertificationPlanScopeType.Product, name: 'Product' },
  { id: CertificationPlanScopeType.Process, name: 'Process' },
  { id: CertificationPlanScopeType.HealthAndSafety, name: 'Health, Safety and Environment' },
  { id: CertificationPlanScopeType.Sustainability, name: 'Sustainability' },
];
//TODO : Load scopes??? jesteśmy out of sync ze słownikiem na bazie
export const ScopeSelectField: FC<LevelSelectFieldProps & {forNewItem?: boolean}> = ({
  disabled = false,
  required=true,
  forNewItem=false,
  ...otherProps
}) => (
  <FormikAutocompleteField
    {...otherProps}
    label="Scope"
    disabled={disabled}
    options={getAutocompleteFieldItems().filter(x=> !forNewItem || x.id != 0 )}
    required={required}
  />
);
