import { Card, FluidForEditors, DictionaryField, FormikTextField, DictionaryFieldByCode, EditorHelper } from 'components';
import { API, CertificateListDefinitionDTO } from './model';

export type DTOModel = CertificateListDefinitionDTO

export function HeaderDataCardPart(p: { helper: EditorHelper<Partial<DTOModel>>; }) {
    const data = p.helper.values

    return (
        <Card>
            <FluidForEditors inRow={1}>
                <FormikTextField required name="title" label="Title" />
            </FluidForEditors>

            <FluidForEditors inRow={2}>
                <DictionaryField required name="businessArea" label="Business Area" query={API.ICertificateListContract.dictionaryQuery({ key: 'BusinessArea' })} dependandFieldName="division" />
                <DictionaryFieldByCode required name="division" label="Division" query={{ key: 'Division', businessArea: data.businessArea }} />
                <DictionaryField name="productGroup" label="Product Group" query={API.ICertificateListContract.dictionaryQuery({ key: 'ProductGroup', division: data.division })} />
                <DictionaryField required name="level" label="Level" query={API.ICertificateListContract.dictionaryQuery({ key: 'Level' })} />
                <DictionaryField required name="scope" label="Scope" query={API.ICertificateListContract.dictionaryQuery({ key: 'Scope', division: data.division })} />
                <DictionaryField multiple required name="scopeDetail" label="Scope Detail" query={API.ICertificateListContract.dictionaryQuery({ key: 'ScopeDetail', division: data.division, scope: data.scope })} />
                <DictionaryField required name="mainFunction" label="Main Function" query={API.ICertificateListContract.dictionaryQuery({ key: 'MainFunction' })} />
                <DictionaryField multiple name="validFor" label="Valid For" query={API.ICertificateListContract.dictionaryQuery({ key: 'ValidFor' })} />
                <DictionaryField multiple name="workType" label="Work Type" query={API.ICertificateListContract.dictionaryQuery({ key: 'WorkType' })} />
                <DictionaryField name="ownerCountry" label="Owner Country" query={API.ICertificateListContract.dictionaryQuery({ key: 'Country' })} />
                <FormikTextField name="duration" placeholder="HH:MM" label="Estimated Certificate Duration (HH:MM)" type="string" />
            </FluidForEditors>
            <FluidForEditors inRow={1}>
                <FormikTextField name="purpose" label="Purpose" multiline rows={4} />
            </FluidForEditors>
        </Card>

    );
}
