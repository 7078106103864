import { createRef, FC } from 'react';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { CheckCircle, Warning, Report, Info, Close } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useToastProviderStyles, StyledSnackbar } from './ToastProvider.style';
import { MultipleMessagesSnackbar } from "./MultipleMessagesSnackbar";

export const ToastProvider: FC = (props) => {
  const { children } = props;
  const notistackRef = createRef<SnackbarProvider>();
  const classes = useToastProviderStyles();
  const handleCloseToast = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={10}      
      action={(key: SnackbarKey) => (
        <IconButton className={classes.cancel} onClick={handleCloseToast(key)}>
          <Close fontSize="small" />
        </IconButton>
      )}
      classes={{
        root: classes.root,
        containerRoot: classes.containerRoot,        
      }}
      iconVariant={{
        default: <Info className={classes.icon} />,
        error: <Report className={clsx(classes.icon, classes.iconRed)} />,
        warning: <Warning className={clsx(classes.icon, classes.iconYellow)} />,
        info: <Info className={clsx(classes.icon, classes.iconBlue)} />,
        success: (
          <CheckCircle className={clsx(classes.icon, classes.iconGreen)} />
        ),
      }}
      Components={{
        success: StyledSnackbar,
        error: StyledSnackbar,
        warning: StyledSnackbar,
        info: StyledSnackbar,
        multipleMessages: MultipleMessagesSnackbar,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
