import React, { FC } from 'react';

import { useActiveBusinessUnitsQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'Dictionary/model/BusinessUnit';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface ActiveBusinessUnitSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options'> {
  defaultOptions?: AutocompleteFieldItem[];
  divisionID?: number;  
}

export const allBusinessUnitField: AutocompleteFieldItem = {
  id: 0,
  name: 'All Divisions',
};

export const ActiveBusinessUnitSelectField: FC<
  ActiveBusinessUnitSelectFieldProps
> = ({
  defaultOptions = [],
  required = true,
  divisionID, 
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = useActiveBusinessUnitsQuery(Number(divisionID ?? 0));

  return (
    <FormikAutocompleteField
      {...otherProps}
      options={[...defaultOptions, ...mapToAutocompleteFieldItems(data)]}
      loading={isLoading}
      onOpen={() => refetch()}
      required={required}
    />
  );
};
