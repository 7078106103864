import React, { FC } from 'react';

import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { CatalogSearchFilters } from 'Catalog/model/CatalogSearchFilters';

export interface LevelSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {}

export enum IsExternalType {
  External,
  Abb,
  All,
}

export const getAutocompleteFieldItems = (): AutocompleteFieldItem[] => [
  { id: IsExternalType.All, name: 'All users' },
  { id: IsExternalType.Abb, name: 'ABB' },
  { id: IsExternalType.External, name: 'External' },
];

export const getIsExternal = (
  isExternal?: IsExternalType
): CatalogSearchFilters['isExternal'] => {
  switch (isExternal) {
    case IsExternalType.Abb:
      return false;
    case IsExternalType.External:
      return true;
    case IsExternalType.All:
    default:
      return null;
  }
};

export const IsExternalSelectField: FC<LevelSelectFieldProps> = ({
  disabled = false,
  ...otherProps
}) => (
  <FormikAutocompleteField
    label="ABB / External"
    disabled={disabled}
    options={getAutocompleteFieldItems()}
    required
    {...otherProps}
  />
);
