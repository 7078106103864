import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { CertificationPlansDetails } from '../model/CertificationPlansDetails';

const getActionPath = (certificatePlanId: number, action: string): string =>
  `/certification-plans/${certificatePlanId}/${action}`;

const submit = async (certificatePlanId: number): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'submit')
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const submitWithDate = async (certificatePlanId: number, expirationDate: string): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'submit-with-date'),
      { expirationDate }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const approve = async (certificatePlanId: number, expirationDate?: string): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'approve'),
      { expirationDate }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const reject = async (
  certificatePlanId: number,
  reason: string
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'reject'),
      { reason }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const reset = async (certificatePlanId: number): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'reset')
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const resetToApproval = async (certificatePlanId: number): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'reset-to-approval')
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const submitForRenew = async (certificatePlanId: number): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'submit-for-renew')
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const submitForRenewWithDate = async (certificatePlanId: number, expirationDate: string): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'submit-for-renew-with-date'),
      { expirationDate }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const renew = async (certificatePlanId: number, expirationDate?: string): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'renew'),
      { expirationDate }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const approveRenewalEnro = async (certificatePlanId: number): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'renew-enro-approve')
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const rejectRenewalEnro = async (certificatePlanId: number): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      getActionPath(certificatePlanId, 'renew-enro-reject')
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const remove = async (certificatePlanId: number): Promise<unknown> => {
  try {
    const response = await apiClient.delete(
      `/certification-plans/${certificatePlanId}`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const approveEnrollRequest = async (
  certificatePlanId: number
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `/certification-plans/enroll-request/${certificatePlanId}/approve`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const rejectEnrollRequest = async (
  certificatePlanId: number,
  reason: string
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `/certification-plans/enroll-request/${certificatePlanId}/reject`,
      { reason }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export interface UpdateDatesDto
  extends Pick<
    CertificationPlansDetails,
    'approvedDate' | 'enrollmentDate' | 'expirationDate' | 'renewalDate' | 'dueDate' 
  > {}
const updateDates = async (
  updateDto: UpdateDatesDto,
  certificatePlanId: number
): Promise<unknown> => {
  try {
    const response = await apiClient.patch(
      `/certification-plans/${certificatePlanId}`,
      updateDto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const certificationPlanWorkflowApi = {
  submit,
  approve,
  reject,
  reset,
  resetToApproval,
  submitForRenew,
  renew,
  approveRenewalEnro,
  rejectRenewalEnro,
  remove,
  updateDates,
  approveEnrollRequest,
  rejectEnrollRequest,
  submitWithDate,
  submitForRenewWithDate
};
