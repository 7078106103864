import { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from '@material-ui/icons/Person';
import ExportIcon from '@material-ui/icons/VerticalAlignBottom';

import { UserTable } from 'MyProfile/component/UserTable';
import { userKey, useUserQuery } from 'MyProfile/query/myProfile.query';
import { ApiError, getPagination, PaginatedList, Pagination, Sort } from 'model';
import { DataTableProps } from 'components/DataTable/DataTable';
import { getFullName, MyProfile } from 'MyProfile/model/MyProfile';
import { TablePagination } from 'components/Table';
import {
  UserSearchFiltersForm,
  UserSearchFiltersFormProps,
} from 'MyProfile/component/UserSearchFiltersForm';
import {
  getDefaultSearchFilters,
  UserSearchFilters,
} from 'MyProfile/model/UserSearchFilters';
import { SearchResults } from 'components/DataTable/SearchResults/SearchResults';
import { Button, DataObjectView } from 'components';
import { MergeUsersDto, myProfileApi } from 'MyProfile/service/myProfile.api';
import { useToasts } from 'components/ToastProvider';
import { List, ListItem } from 'components/List';
import { Loader } from 'components/Loader/Loader';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import {
  DataObjectList,
  DataObjectListProps,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import { TableRoot } from './SearchUsers.style';

export enum SearchUsersRowActions {
  Edit = 0,
  ManageRole = 1,
  RoleList = 2,
}

const initialSort: Sort<MyProfile> = {
  sortExpression: 'lastName',
  sortDescending: false,
};

export interface SearchUsersProps {
  initialPageSize: number;
  DataTableProps: Omit<DataTableProps<MyProfile>, 'rows'>;
  DataObjectListProps?: Omit<
    DataObjectListProps<MyProfile>,
    'items' | 'renderDataObjectView'
  >;
  SearchFiltersFormProps?: Omit<
    UserSearchFiltersFormProps,
    'onFiltersSubmit' | 'renderPageActions'
  >;
  displaySearchResults?: boolean;
  displaySelectedActions?: boolean;
  hideExcelExport?: boolean;
  mobileView?: boolean;
}

export const mapMergeUsersDto = (
  destinationUserId: number,
  selectedUserIds: string[]
): MergeUsersDto => ({
  destinationUserId,
  sourceUsersIds: selectedUserIds
    .map((id) => Number(id))
    .filter((id) => id !== destinationUserId),
});

export const SearchUsers: FC<SearchUsersProps> = ({
  initialPageSize,
  DataTableProps,
  hideExcelExport = false,
  displaySearchResults = false,
  displaySelectedActions = true,
  mobileView = true,
  SearchFiltersFormProps = {},
  DataObjectListProps = {},
}) => {
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useToasts();
  const [isMergeDialogOpen, setIsMergeDialogOpen] = useState(false);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [pagination, setPagination] = useState<Pagination>(
    getPagination(pageSize)
  );  
  const [sort, setSort] = useState<Sort<MyProfile>>(
    initialSort
  );
  const [filters, setFilters] = useState<UserSearchFilters>(
    getDefaultSearchFilters(SearchFiltersFormProps?.isExternalType, SearchFiltersFormProps?.isForAction)
  );
  const { data, isLoading, isFetching, refetch } = useUserQuery(
    pagination,
    sort,
    filters,
    {
      onSuccess: (newData) => setPagination(getPagination(pageSize, newData)),
    }
  );
  const { mutate: archiveUsers, isLoading: isArchivingUsers } = useMutation<
    unknown,
    ApiError,
    number[]
  >((userIds) => myProfileApi.archiveUsers(userIds), {
    onSuccess: () => {
      refetch();
      showSuccess('Users archive action success');
    },
    onError: () => showError('Users archive action fail'),
  });
  const { mutate: mergeUsers, isLoading: isMergingUsers } = useMutation<
    unknown,
    ApiError,
    MergeUsersDto
  >((dto) => myProfileApi.mergeUsers(dto));

  const {
    mutate: exportToExcel,
    isLoading: isExportToExcelLoading,
  } = useMutation(() => myProfileApi.exportToExcel(filters), {
    onSuccess: () => showSuccess('Export to excel success'),
    onError: () => showError('Export to excel failed. Too many records on search. Must be less than 10000.'),
  });

  const getSelectedUserProfiles = (selectedRows: string[]): MyProfile[] => {
    const usersCache = queryClient
      .getQueryCache()
      .findAll(userKey)
      .reduce<MyProfile[]>(
        (acc, query) => [
          ...acc,
          ...((query.state.data as PaginatedList<MyProfile>)?.result ?? []),
        ],
        []
      );
    return selectedRows.map(
      (stringId) =>
        usersCache.find((user) => user.id.toString() === stringId) as MyProfile
    );
  };
  
  const renderUserTable = () => (
    <UserTable
      rows={data?.result ?? []}
      loading={loading}
      onSortRequest={(direction, sortExpression) =>
        setSort({
          sortExpression,
          sortDescending: direction === 'desc',
        })
      }
      defaultSort={initialSort}
      enableSort
      rowSelector={(row) => row.id}
      renderSelectedActions={(selectedRows, clearSelected) =>
        displaySelectedActions && (
          <>
            <Button
              onClick={() =>
                archiveUsers(
                  selectedRows.map((id) => Number(id)),
                  {
                    onSuccess: () => {
                      clearSelected();
                    },
                  }
                )
              }
              disabled={isArchivingUsers || isMergingUsers}
            >
              Archive selected
            </Button>
            {selectedRows.length > 1 && (
              <Button
                color="secondary"
                onClick={() => setIsMergeDialogOpen(true)}
                disabled={isArchivingUsers || isMergingUsers}
              >
                Merge selected
              </Button>
            )}
            <Dialog
              onClose={() => setIsMergeDialogOpen(false)}
              open={isMergeDialogOpen}
            >
              <DialogTitle>Pick destination User</DialogTitle>
              <DialogContent>
                <List<MyProfile>
                  items={getSelectedUserProfiles(selectedRows)}
                  renderItem={(user) => (
                    <ListItem
                      primaryText={`${getFullName(user)} (${user.email})`}
                      Icon={PersonIcon}
                      onClick={() =>
                        mergeUsers(mapMergeUsersDto(user.id, selectedRows), {
                          onSuccess: () => {
                            refetch();
                            showSuccess('Merge Users action success');
                            setIsMergeDialogOpen(false);
                            clearSelected();
                          },
                          onError: () => showError('Merge Users action fail'),
                        })
                      }
                      button
                    />
                  )}
                />
              </DialogContent>
            </Dialog>
          </>
        )
      }
      {...DataTableProps}
    />
  );
  const loading = isLoading || isFetching;

  const useStyles = makeStyles({
    selectIcon: {
      position: "relative"
    }
  });
  const classes = useStyles();

  return (
    <>
      <UserSearchFiltersForm
        renderPageActions={() => !hideExcelExport &&(
          <Button
            onClick={() => exportToExcel()}
            disabled={isExportToExcelLoading}
          >
            {isExportToExcelLoading ? (
              <Loader size={24} />
            ) : (
              <>
                <ExportIcon /> &nbsp;Export to excel
              </>
            )}
          </Button>
        )}
        onFiltersSubmit={(searchFilters) => {
          setFilters(searchFilters);
          setPagination(getPagination(pageSize));
        }}
        {...SearchFiltersFormProps}
      />
      {displaySearchResults && (
        <SearchResults total={data?.total} loading={loading} />
      )}
      <TableRoot>
        {mobileView ? (
          <>
            <DesktopSection>{renderUserTable()}</DesktopSection>
            <MobileSection>
              <DataObjectList
                loading={loading}
                items={data?.result ?? []}
                renderDataObjectView={(item) => (
                  <DataObjectView
                    title={`${getFullName(item)} (${item.email})`}
                    item={item}
                    dataSetMaps={mapToDataSetMap(DataTableProps.columns, [
                      'firstName',
                    ])}
                    variant="elevation"
                    transparent
                  />
                )}
                renderActions={(item) => (
                  <Button
                    onClick={() => archiveUsers([item.id])}
                    disabled={isArchivingUsers}
                  >
                    Archive user
                  </Button>
                )}
                noDataMessage={DataTableProps.noDataMessage}
                {...DataObjectListProps}
              />
            </MobileSection>
          </>
        ) : (
          renderUserTable()
        )}
      </TableRoot>
      {!isFetching && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          onChangeRowsPerPage={(event) => {
              setPageSize(parseInt(event.target.value,10));
              setPagination(getPagination(parseInt(event.target.value,10)));                        
          }}
          classes={{selectIcon: classes.selectIcon}}
          pagination={pagination}
          onChangePage={(event, currentPageIndex) =>
            setPagination((prevPagination) => ({
              ...prevPagination,
              currentPageIndex,
            }))
          }          
        />
      )}
    </>
  );
};
