import React, { FC } from 'react';

import { useDivisionsQuery } from 'Dictionary/query/dictionaryQuery';
import { FormikAutocompleteField, FormikAutocompleteFieldProps } from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface DivisionSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {
  name: string;
  disabled?: boolean;
  defaultOptions?: AutocompleteFieldItem[];
}

export const DivisionSelectField: FC<
DivisionSelectFieldProps
> = ({
  name,
  disabled = false,
  defaultOptions = [],
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = useDivisionsQuery();

  return (
    <FormikAutocompleteField
      {...otherProps}
      name={name}
      label="Business Area"
      disabled={disabled}
      options={[...defaultOptions, ...data]}
      loading={isLoading}
      onOpen={() => refetch()}
      disableClearable
    />
  );
};
