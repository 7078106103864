import axios, { AxiosInstance } from 'axios';
import { authProvider } from './authProvider';

const apiUrl = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_PATH}`;

export const apiClient: AxiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

apiClient.interceptors.request.use(async (config) => {
  const token = await authProvider?.getIdToken();

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token?.idToken?.rawIdToken}`,
    },
  };
});
