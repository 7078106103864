import { useState } from 'react';
import { Button, Typography, ChipButton } from "components"


import {
  ReportSetting,
} from 'Reports/model';

import { DateRange } from '@material-ui/icons';
import { ModalContent } from 'components/Page';
import { useModal } from 'hooks';
import { ActionsPanel } from './ActionsPanel';

export interface SelectedSetting {
  name: string;
  index: number;
}

export interface ReportSettingsListProps {
  selectedSetting?: SelectedSetting;
  inEditMode: boolean
  settings: ReportSetting[];
  onSelect: (name: string, index: number) => void;
  onDelete: (name: string, index: number) => void;
  onAccept: (name: string, index: number) => void;
  onAddNewSetting: (name: string) => void;
}

export const ReportSettingsList = (props: ReportSettingsListProps) => {
  const {
    settings,
    inEditMode,
    selectedSetting,
    onSelect,
    onAccept,
    onDelete,
    onAddNewSetting,
  } = props;

  const [deleting, setDeleting] = useState<number | null>(null)
  const { Modal, openModal, closeModal } = useModal(() => (
    <ModalContent
      header={`Deleted setting couldn't be resotred`}
      renderActions={() => (<>
        <Button onClick={closeModal} color="primary">
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() => { onDelete('', deleting!); closeModal() }}>
          Delete
        </Button>
      </>)}
    >
      <Typography style={{ minWidth: 400 }} variant="body1">
        Do you want to delete it?
      </Typography>


    </ModalContent>
  ));

  const handleSelect = (settingName: string, index: number) => {
    setDeleting(index)
    onSelect(settingName, index);
  };

  const handleDelete = (settingName: string, index: number) => {
    openModal()

  }

  const handleAddNewSettings = () => {
    const newName = `New setting ${settings.length}`
    onAddNewSetting(newName);
  };

  return (
    <>

      <Typography variant="subtitle2">Saved settings ({props.settings.length})</Typography>

      {settings?.map((s, index) => {
        const setting = settings[index]
        const isSelected = selectedSetting?.name === s.name
        return (
          <ChipButton
            color={isSelected ? "secondary" : "primary"}
            key={`${s.name}-${index}`}
            label={s.name}
            onClick={() => handleSelect(s.name, index)}
            onDelete={() => handleDelete(s.name, index)}
            disabled={inEditMode}
            icon={setting.schedule && setting.schedule.active ? <DateRange /> : <></>}
          />
        )
      }
      )}

      <ActionsPanel>
        <Modal />
        <Button onClick={handleAddNewSettings} color="secondary" disabled={inEditMode}>
          Add Settings
        </Button>
      </ActionsPanel>

    </>
  );
};
