import { ColumnDefinition, ReportPageTypes } from 'Reports/model';
import { ReportingAutocomplate } from './ReportingAutocomplate';
import { ReportingDatePicker } from './ReportingDatePicker';
import { ReportingTextField } from './ReportingTextField';
import { FieldType, useColumnDefinition } from '../hooks/useColumnDefinition';
import { FilterChangeHandler, ReportingFilters } from './@types';

export interface FilterFieldFactoryProps {
  columnDefinition: ColumnDefinition;
  onFilterChange: FilterChangeHandler;
  filters: ReportingFilters;
  reportType: ReportPageTypes;
}

export const FilterFieldFactory = (props: FilterFieldFactoryProps) => {
  const { columnDefinition, onFilterChange, filters, reportType } = props;
  const [fieldProps, fieldType] = useColumnDefinition(columnDefinition);
  if (!columnDefinition) {
    return null;
  }
  switch (fieldType) {
    case FieldType.text:
      return (
        <ReportingTextField
          columnDefinition={columnDefinition}
          fieldProps={fieldProps}
          onFilterChange={onFilterChange}
          filters={filters}
        />
      );
    case FieldType.select:
      return (
        <ReportingAutocomplate
          columnDefinition={columnDefinition}
          fieldProps={fieldProps}
          onFilterChange={onFilterChange}
          filters={filters}
          reportType={reportType}
        />
      );
    case FieldType.dateTime:
      return (
        <ReportingDatePicker
          columnDefinition={columnDefinition}
          fieldProps={fieldProps}
          onFilterChange={onFilterChange}
          filters={filters}
        />
      );
    default:
      return null;
  }
};
