export enum CertificateApproverType {
  CertificationAuthority = 0,
  Supervisor = 1,
  HierarchicalResponsible = 2,
  ApprovedByRules = 3,
  AutomaticallyApprovedWhenSubmitted = 4,
  ApproverGroup = 5,
  AtomaticllyWhenAllCompleted = 6,
  CertificationRequestForm = 7,
  CompanyCoordinator = 8,
}

export const getCertificateApprover = (
  type: CertificateApproverType
): string => {
  switch (type) {
    case CertificateApproverType.ApprovedByRules:
      return 'Approved by rules';
    case CertificateApproverType.ApproverGroup:
      return 'Approver group';
    case CertificateApproverType.AtomaticllyWhenAllCompleted:
      return 'Automatically when all completed';
    case CertificateApproverType.AutomaticallyApprovedWhenSubmitted:
      return 'Automatically approved when submitted';
    case CertificateApproverType.CertificationAuthority:
      return 'Certification authority';
    case CertificateApproverType.CertificationRequestForm:
      return 'Certification request form';
    case CertificateApproverType.HierarchicalResponsible:
      return 'Hierarchical responsible';
    case CertificateApproverType.Supervisor:
      return 'Supervisor';
    case CertificateApproverType.CompanyCoordinator:
      return 'Int/Ext Company Coordinator';
    default:
      return 'Unknown';
  }
}
