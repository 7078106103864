import { FC, HtmlHTMLAttributes } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      marginTop: theme.spacing(1),
      justifyContent: 'flex-end',
      width: '100%',
      height: 42,
    },
  }),
  { name: 'ActionPanel' }
);

export const ActionsPanel: FC<HtmlHTMLAttributes<unknown>> = (props) => {
  const { className: classNameProp, children, ...other } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classNameProp, classes.root)} {...other}>
      {children}
    </div>
  );
};
