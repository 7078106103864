import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface CompanyChannel {
  id: number;
  name: string;
}

export const mapToAutocompleteFieldItems = (
  companyChannels: CompanyChannel[]
): AutocompleteFieldItem[] =>
  companyChannels.map((channel) => ({ id: channel.id, name: channel.name }));
