import React, { FC, useState } from 'react';
import { DropzoneDialog, DropzoneDialogProps } from 'material-ui-dropzone';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { Skeleton } from '@material-ui/lab';

import { List } from 'components/List';
import { Button } from 'components/Button';
import { Attachment } from 'Attachment/model/Attachment';
import {
  AttachmentListItemType,
  AttachmentListItem,
} from 'Attachment/component/AttachmentListItem/AttachmentListItem';
import { Typography } from '@material-ui/core';
import { NoDataMessageWrapper } from 'components/DataTable/DataTable.style';
import { ActionsRoot } from './AttachmentList.style';

// const filesLimit = 3;
const acceptedFiles = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'image/jpeg',
];

const getAttachmentListItems = (
  attachments: Attachment[]
): AttachmentListItemType[] =>
  attachments.map((attachment) => ({
    attachment,
    primaryText: attachment.fileName,
    Icon: AttachmentIcon,
  }));

export interface AttachmentListProps {
  items: Attachment[];
  loading: boolean;
  onDropzoneSave: DropzoneDialogProps['onSave'];
  allowRemoveAttachment?: boolean;
  disableAddAttachment?: boolean;
  userId?: string;
  requirementId?: string;
  filesLimit?: number;
  noDataMessage?: string;
  onRemoveRequirementAttachmentSuccess: {() : void} | null;
}

export const AttachmentList: FC<AttachmentListProps> = ({
  items,
  loading = false,
  onDropzoneSave,
  allowRemoveAttachment = false,
  disableAddAttachment = false,
  userId,
  requirementId,
  filesLimit = 3,
  noDataMessage,
  onRemoveRequirementAttachmentSuccess
}) => {
  const [isDropzoneOpen, setIsDropzoneOpen] = useState<boolean>(false);

  if (loading) {
    return <Skeleton width={200} height={32} />;
  }

  const handleOnDropzoneSave: DropzoneDialogProps['onSave'] = (
    files,
    event
  ) => {
    onDropzoneSave && onDropzoneSave(files, event);
    setIsDropzoneOpen(false);
  };

  return (
    <>
      {noDataMessage && !items.length &&
        <NoDataMessageWrapper>
            <Typography variant="body2">{noDataMessage}</Typography>
        </NoDataMessageWrapper>}
      <List
        items={getAttachmentListItems(items)}
        renderItem={(item) => (
          <AttachmentListItem item={item} removable={allowRemoveAttachment} attachmentOwnerId={userId} attachmentRequirementId={requirementId}
            onRemoveRequirementAttachmentSuccess={onRemoveRequirementAttachmentSuccess} />
        )}
      />
      {allowRemoveAttachment &&
      <ActionsRoot>
        <Button color="secondary" disabled={disableAddAttachment} onClick={() => setIsDropzoneOpen(true)}>
          Add new file
        </Button>
      </ActionsRoot>
      }
      <DropzoneDialog
        dialogTitle="Upload Attachment"
        dropzoneText="Drag and drop a file here or click.
        Maximum file size: 11MB. Acceptable file types: .dot, .doc, .docx, .xls, .xlsx, .pdf, .jfif, .pjpeg, .jpeg, .pjp and .jpg."
        open={isDropzoneOpen}
        acceptedFiles={acceptedFiles}
        filesLimit={filesLimit}
        onSave={handleOnDropzoneSave}
        onClose={() => setIsDropzoneOpen(false)}
        showPreviewsInDropzone
        showPreviews={false}
        showAlerts={false}
        maxFileSize={11000000}
      />
    </>
  );
};
