import { useState } from 'react';
import {
    Button,
    Grid,
    Switch,
    Tooltip,
    Typography
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import { ReportSetting } from 'Reports/model';
import { useMyProfileQuery } from 'MyProfile/query/myProfile.query';
import { UserSearch } from '../../../components/UserSearch/UserSearch';
import { AutocompleteField, AutocompleteFieldItem } from '../../../components/Field/AutocompleteField/AutocompleteField';
import { ActionsPanel } from './ActionsPanel';
import { formatTemplate } from '../../../utils';

export const frequencyOptions: AutocompleteFieldItem[] = [
    { id: "once", name: 'Once' },
    { id: "daily", name: 'Daily' },
    { id: "weekly", name: 'Weekly' },
    { id: "biweekly", name: 'Biweekly' },
    { id: "monthly", name: 'Monthly' },
    { id: "quarterly", name: 'Quarterly' },

];

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
}

export interface ReportSettingEditorProps{
    setting?: ReportSetting| null
    inEditMode?: boolean
    onActiveChange: React.Dispatch<boolean>
  }
 
export const ScheduleSettings = (props: ReportSettingEditorProps) => {
    const forceUpdate = useForceUpdate();
    const schedule = props.setting?.schedule;
    const currentUser = useMyProfileQuery().data;
    const setFrequency = (v: AutocompleteFieldItem) => {
        schedule!.frequency = (v.id ?? "once").toString()
        forceUpdate()
    }

    const setstartDate = (v: Dayjs | null) => {
        schedule!.startDate = v ? v.toISOString() : undefined
        forceUpdate()
    }
    const setendDate = (v: Dayjs | null) => {
        schedule!.endDate = v ? v.toISOString() : undefined
        forceUpdate()
    }

    const setsendTo = (v: { id: string, email: string, name: string }[]) => {
        schedule!.sendTo = v.map(x => x.email)
        forceUpdate()
    }

    const setactive = (active: boolean) => {
        schedule!.active = active
        props.onActiveChange(active)
        forceUpdate()
    }

    const addSchedule = () => {
        const x = props.setting!

        x.schedule = {
            active: true,
            frequency: "once"
        }
        if (currentUser)
            x.schedule.sendTo = [currentUser?.email]

        forceUpdate()
    }
    const removeSchedule = () => {
        const x = props.setting!
        x.schedule = undefined
        forceUpdate()
    }

    const renderActivateButton = () => {
        if (props.inEditMode) return (<></>)
        return (
            <Tooltip title={schedule!.active ? "Activated – click to suspend" : "Suspended – click to activate"} >

            <Switch
                checked={schedule!.active}
                size='small'
                onChange={(e, c) => setactive(c)}
            />

            </Tooltip>)
    }

    if (!props.setting) return (<></>)
    if (!schedule) return (<>
        <Typography variant="subtitle2">
            Schedule is not set up
        </Typography>

        <ActionsPanel>
            <Button color="secondary" onClick={addSchedule} disabled={!props.inEditMode}>
                add schedule
            </Button>
        </ActionsPanel>
    </>)
    return (
        <>
            <Typography variant="subtitle2">
                Schedule
                {renderActivateButton()}
            </Typography>

            <Grid item xs={12}>
                <AutocompleteField
                    label="Frequency"
                    disabled={!props.inEditMode}
                    options={frequencyOptions}
                    required={true} disableClearable
                    value={frequencyOptions.find(x => x.id === schedule.frequency)}
                    onChange={(e, v) => setFrequency(v as AutocompleteFieldItem)}
                />

                <Grid spacing={4} container>
                    <Grid item md={12} lg={6}>
                        <KeyboardDatePicker
                            fullWidth
                            disabled={!props.inEditMode}
                            variant="inline"
                            inputVariant="filled"
                            label="Start date"
                            required={true}
                            format={formatTemplate}
                            placeholder='yyyy/mm/dd'
                            autoOk
                            onChange={setstartDate}
                            value={schedule.startDate ?? null}
                        />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <KeyboardDatePicker
                            variant="inline"
                            disabled={!props.inEditMode}
                            inputVariant="filled"
                            label="End date"
                            format={formatTemplate}
                            placeholder='yyyy/mm/dd'
                            autoOk
                            onChange={setendDate}
                            value={schedule.endDate ?? null}
                            helperText="if not defined, the report will be sent until suspended"
                        />
                    </Grid>
                </Grid>


                <UserSearch
                    label="Send to"
                    required={true}
                    disabled={!props.inEditMode}
                    helperText="Reports will be send only to users with ABB email"
                    initialValue={schedule.sendTo}
                    onChanged={v => setsendTo(v)}
                />
            </Grid>
            <ActionsPanel>
                <Button color="secondary" onClick={removeSchedule} disabled={!props.inEditMode}>
                    Remove schedule
                </Button>
            </ActionsPanel>

        </>
    )
}