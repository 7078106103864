import React, { FC } from 'react';
import MUIAlert, { AlertProps as MUIAlertProps } from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

import { TitleTypography } from './Alert.style';

export interface AlertProps extends MUIAlertProps {
  title?: string;
}

export const Alert: FC<AlertProps> = ({ title, children, ...alertProps }) => {
  const getProperIcon = () => {
    if (alertProps.severity === 'success') {
      return <SuccessIcon />;
    }
    if (alertProps.severity === 'warning') {
      return <WarningIcon />;
    }
    if (alertProps.severity === 'info') {
      return <InfoIcon />;
    }
    return <ErrorIcon />;
  };

  return (
    <MUIAlert {...alertProps} icon={getProperIcon()}>
      <TitleTypography variant="caption">{title}</TitleTypography>
      {title && <span>&nbsp;</span>}
      <Typography variant="caption">{children}</Typography>
    </MUIAlert>
  );
};
