import { useHistory } from 'react-router-dom';

import { PrintButton } from 'components';
import { printOutMyCertificatesRoute } from 'route';
import { Content } from 'components/Page';
import { useUserCertificationsQuery } from 'Certificate/query/certificateQuery';
import { UserCertifications } from 'Certificate/model/UserCertifications';
import { UserCertificationsView } from 'Certificate/component/UserCertificationsView/UserCertificationsView';
import { useMyProfileQuery } from 'MyProfile/query';

export interface UserCertificationsViewProps {
  userCertifications: UserCertifications;
}

export const MyCertificationsPage = () => {
  const { data: myProfile } = useMyProfileQuery();
  const { data, isLoading } = useUserCertificationsQuery(
    (myProfile?.id ?? '').toString()
  );
  const history = useHistory();

  const handleOpenStatusPdfDetails = () => {
    history.push(printOutMyCertificatesRoute.path);
  };

  return (
    <Content
      title="My Certifications"
      subtitle="Here you can see the status and details of your Certifications"
      renderActions={() => <PrintButton onClick={handleOpenStatusPdfDetails} />}
    >
      <UserCertificationsView loading={isLoading} userCertifications={data} />
    </Content>
  );
};
