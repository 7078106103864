import { FC } from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'components/Page';
import {
  UserCertificateTable,
  completedColumns,
  expiredColumns,
  pendingColumns,
} from 'Certificate/component/UserCertificateTable/UserCertificateTable';
import {
  EnrollmentRequestTable,
  enrollmentRequestsColumns,
} from 'Certificate/component/EnrollmentRequestTable/EnrollmentRequestTable';
import { PlanRequirementsTable } from 'Requirement/component/PlanRequirementsTable/PlanRequirementsTable';
import { UserCertifications } from 'Certificate/model/UserCertifications';

const noDataMessage = 'No certifications to display';

export interface UserCertificationsViewProps {
  loading: boolean;
  userCertifications?: UserCertifications;
}

export const UserCertificationsView: FC<UserCertificationsViewProps> = ({
  loading = false,
  userCertifications,
}) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Card
        header={`Completed Certifications (${
          userCertifications?.completedCertificates?.length ?? '0'
        })`}
        transparent
      >
        <UserCertificateTable
          rows={userCertifications?.completedCertificates ?? []}
          columns={completedColumns}
          loading={loading}
          noDataMessage={noDataMessage}
        />
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card
        header={`Expiring/Expired Certifications (${
          userCertifications?.expiredCertificates?.length ?? '0'
        })`}
        transparent
      >
        <UserCertificateTable
          rows={userCertifications?.expiredCertificates ?? []}
          columns={expiredColumns}
          loading={loading}
          noDataMessage={noDataMessage}
        />
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card
        header={`Pending Certifications (${
          userCertifications?.pendingCertificates?.length ?? '0'
        })`}
        transparent
      >
        <UserCertificateTable
          rows={userCertifications?.pendingCertificates ?? []}
          columns={pendingColumns}
          loading={loading}
          noDataMessage={noDataMessage}
        />
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card
        header={`Enrollment Requests (${
          userCertifications?.enrollmentRequests?.length ?? '0'
        })`}
        transparent
      >
        <EnrollmentRequestTable
          rows={userCertifications?.enrollmentRequests ?? []}
          columns={enrollmentRequestsColumns}
          renderCollapseRow={(row) => (
            <PlanRequirementsTable
              certificateId={row.id}
              DataTableProps={{ checkboxSelection: false }}
            />
          )}
          loading={loading}
          noDataMessage={noDataMessage}
        />
      </Card>
    </Grid>
  </Grid>
);
