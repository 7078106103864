import { Button } from 'components/Button';
import { ActionsRoot } from 'components/Trivial/Common.style';
import { FormikProps } from 'formik';


export function EditorFormActions<T>(p: {
    isNew: boolean

    onCancelAdd: React.DispatchWithoutAction

    formik: FormikProps<T>

    showDiscard?: boolean
}) {
    const {
        formik,
        isNew
    } = p

    const onDiscardChanges = ()=>{
        formik.resetForm()
    }

    const onAdd = ()=>{
        formik.submitForm()
    }
    const onSave = ()=>{
        formik.submitForm()
    }
    
    const actions = isNew ?
        (<ActionsRoot>
            <Button
                type="button"
                onClick={p.onCancelAdd}
            >
                Cancel
            </Button>
            <Button color="secondary"
                onClick={onAdd}>
                Add
            </Button>
        </ActionsRoot>) :
        (<ActionsRoot>
            
            {!p.showDiscard ? <></>: <Button
                type="button"
                onClick={onDiscardChanges}
                disabled={!formik.dirty}      
            >
                Discard
            </Button>}
            <Button
                type="button"
                onClick={p.onCancelAdd}
            >
                Cancel
            </Button>
            <Button color="secondary" 
                onClick={onSave}>
                Save
            </Button>
        </ActionsRoot>)
    return actions
}