import React, { DispatchWithoutAction } from 'react';
import { Formik, FormikProps } from 'formik';
import { FormikTextField } from 'components/FormikField/FormikTextField/FormikTextField';
import { FormikCheckboxField } from 'components/FormikField/FormikCheckboxField';
import { Button } from 'components/Button'
import { ActionsRoot, FluidForEditors } from 'components/Trivial'

import { DictionaryField, DictionaryFieldProps, DictionaryFieldByCodeProps, DictionaryFieldByCode } from './DictionaryField';
import { FieldProps } from './common';

export interface EditorHelper<TDataModel> extends FormikProps<TDataModel> {
    cancelEdit: DispatchWithoutAction
    TextField: (p: FieldProps<TDataModel>) => JSX.Element
    CheckboxField: (p: FieldProps<TDataModel>) => JSX.Element
    DropDownField: (p: DictionaryFieldProps<TDataModel>) => JSX.Element
    DictionaryFieldByCode: (p: DictionaryFieldByCodeProps<TDataModel>) => JSX.Element
}

function FEditorBase<TDataModel>(p: {
    item: TDataModel
    content: (helper: EditorHelper<TDataModel>) => JSX.Element
    title?: string
    actions?: (helper: EditorHelper<TDataModel>) => JSX.Element
    onCancelAdd: React.DispatchWithoutAction
    onUpdateRow: React.Dispatch<TDataModel>
}) {
    return (<>
        <Formik
            initialValues={p.item}

            onSubmit={values => p.onUpdateRow(values)}
            onReset={() => { }}
        >
            {formikProps => {
                const helper = getHelper<TDataModel>(formikProps, p.onCancelAdd, p.onUpdateRow)

                const inner = p.content(helper)
                const actions = p.actions ? p.actions(helper) : <></>
                return <>
                    {/* <div>FORMIK</div> */}

                    <FluidForEditors inRow={2}>
                        {p.title}<ActionsRoot>{actions}</ActionsRoot></FluidForEditors>
                    <div>{inner}</div>
                </>;
            }}
        </Formik>
    </>);
}

function devActions<T>(helper: EditorHelper<T>) {
    return (<>
        <Button
            type="button"
            onClick={() => helper.submitForm()}
        >
            SAVE
        </Button>
        <Button
            type="button"
            onClick={() => helper.resetForm()}
        >
            RESET
        </Button>
        <Button
            type="button"
            onClick={() => { helper.resetForm(); helper.cancelEdit(); }}
        >
            CANCEL
        </Button>
    </>)
}



function getHelper<TDataModel>(
    formikProps: FormikProps<TDataModel>,
    onCancel: React.DispatchWithoutAction,
    onCommit: React.Dispatch<TDataModel>) {
    const helper: EditorHelper<TDataModel> = {
        ...formikProps,
        TextField: (p: FieldProps<TDataModel>) => (<FormikTextField name={p.name} label={p.label ?? p.name} />),
        CheckboxField: (p: FieldProps<TDataModel>) => (<FormikCheckboxField name={p.name} label={p.label ?? p.name} helperText={p.helperText} />),
        DropDownField: (p: DictionaryFieldProps<TDataModel>) => (<DictionaryField<TDataModel> {...p} />),
        DictionaryFieldByCode: (p: DictionaryFieldByCodeProps<TDataModel>) => (<DictionaryFieldByCode<TDataModel> {...p} />),

        cancelEdit: onCancel
    };
    return helper
}
FEditorBase.devActions = devActions
FEditorBase.getHelper = getHelper

export const EditorBase = FEditorBase