import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { CertificationPlansDetails } from '../model/CertificationPlansDetails';
import { CertificationPlanHistory } from '../model/CertificationPlanHistory';
import { CertificationPlanRequirementComment } from '../model/CertificationPlanRequirementComment';
import { SaveQuestionnarieDetails } from '../model/QuestionnarieDetails';

const domainPath = '/certification-plans';

const getRootPath = (certificationPlanId: number): string =>
  `/certification-plans/${certificationPlanId}`;

const getCertificationPlan = async (
  certificationPlanId: number
): Promise<CertificationPlansDetails> => {
  try {
    const response = await apiClient.get(getRootPath(certificationPlanId));
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getCertificationPlanHistory = async (
  certificationPlanId: number
): Promise<CertificationPlanHistory[]> => {
  try {
    const response = await apiClient.get(
      `${getRootPath(certificationPlanId)}/history`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getCertificationRequirementComments = async (
  requirementId: number
): Promise<CertificationPlanRequirementComment[]> => {
  try {
    const response = await apiClient.get(
      `${domainPath}/requirements/${requirementId}/comments`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const submitQuestionnarie = async (
  certificationPlanId: number,
  data: SaveQuestionnarieDetails
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${getRootPath(certificationPlanId)}/questionnaires`,
      data
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const certificationPlanApi = {
  getCertificationPlan,
  getCertificationPlanHistory,
  getCertificationRequirementComments,
  submitQuestionnarie,
};
