import React from 'react';

import { useMyProfileQuery } from 'MyProfile/query/myProfile.query';
import { getFullName } from 'MyProfile/model/MyProfile';
import { Avatar } from 'components/Avatar';
import {
  EmailTypography,
  NameTypography,
  Details,
  Root,
} from './UserAvatar.style';

export interface UserAvatarProps {
  showDetails?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = React.forwardRef(
  ({ showDetails = false }, ref) => {
    const { isLoading, data } = useMyProfileQuery();

    if (isLoading || !data) {
      return null;
    }

    return showDetails ? (
      <Root>
        <Avatar fullName={getFullName(data)} />
        <Details>
          <NameTypography variant="body2">
            {data.firstName} {data.lastName}
          </NameTypography>
          <EmailTypography variant="caption">{data.email}</EmailTypography>
        </Details>
      </Root>
    ) : (
      <Avatar fullName={`${data.firstName} ${data.lastName}`} />
    );
  }
);
