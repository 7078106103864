export interface ByPersonSearchFilters {
  personName: string;
  businessUnitId: number;
  divisionId: number;
  productGroupList?: number[];
  isExternal: boolean | null;
  userJobList?: number[];
  userJobFamilyList?: number[];
  countryList?: number[];
  companyList?: number[];
}

export const defaultSearchFilters: ByPersonSearchFilters = {
  personName: '',
  businessUnitId: 0,
  divisionId: 0,
  isExternal: null,
};
