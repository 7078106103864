import { FC } from 'react';
import { IconButton, IconButtonProps, useTheme } from '@material-ui/core';
import { Sync } from '@material-ui/icons';

import { Loader } from 'components/Loader/Loader';

export interface SyncButtonProps extends Omit<IconButtonProps, 'children'> {
  loading?: boolean;
}

export const SyncButton: FC<SyncButtonProps> = ({
  loading = false,
  ...otherProps
}) => {
  const theme = useTheme();
  return (
    <IconButton style={{ margin: theme.spacing(0, 0.5) }} {...otherProps}>
      {loading ? <Loader size={24} /> : <Sync fontSize="large" />}
    </IconButton>
  );
};
