import { FC } from 'react';

import { formatDate } from 'utils';
import { DataTable, DataTableColumn } from 'components/DataTable/DataTable';
import { Requirement } from 'Requirement/model/Requirement';
import { useCertificationRequirementCommentsQuery } from '../../query/certificationPlansQuery';
import { CertificationPlanRequirementComment } from '../../model/CertificationPlanRequirementComment';

export interface CertificationRequirementCommentsTableProps {
  requirementId: Requirement['id'];
}

const columns: DataTableColumn<CertificationPlanRequirementComment>[] = [
  {
    field: 'comment',
    headerName: 'Comment',
  },
  {
    field: 'commentAuthor',
    headerName: 'Author',
    renderField: ({ commentAuthor }) => `${commentAuthor.firstName} ${commentAuthor.lastName} (${commentAuthor.email})`,
  },
  {
    field: 'commentDateTime',
    headerName: 'Published',
    renderField: ({ commentDateTime }) => formatDate(commentDateTime),
  },
];

export const CertificationRequirementCommentsTable: FC<
  CertificationRequirementCommentsTableProps
> = ({
  requirementId,
}) => {
  const { data, isLoading } = useCertificationRequirementCommentsQuery(
    requirementId
  );

  return (
    <DataTable
      columns={columns}
      rowSelector={(row) => row.id}
      rows={data ?? []}
      outerBorder={false}
      loading={isLoading}
      noDataMessage="No comments to display"
    />
  );
};
