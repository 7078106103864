import { makeStyles } from '@material-ui/core';
import { AbbTheme } from 'theme/createAbbTheme';

export const useRoundButtonStyles = makeStyles(
  (theme: AbbTheme) => ({
    root: {
      position: 'relative',
      height: 40,
      borderRadius: 20,
      margin: theme.spacing(0.5),
    },
    outlined: {
      color: theme.palette.grey[400],
      border: `2px solid ${theme.palette.grey[400]}`,
      '&$disabled': {
        borderWidth: 2,
      },
    },
    disabled: {},
  }),
  { name: 'RoundButton' }
);
