import React from 'react';
import MUIList, { ListProps } from '@material-ui/core/List';

import { ListItemType } from './ListItem/ListItem';
import { StyledListSubheader } from './List.style';

export interface Props<ItemType = ListItemType> extends ListProps {
  items: ItemType[];
  renderItem: (item: ItemType) => React.ReactNode;
  header?: string;
}

export function List<ItemType>({
  items,
  renderItem,
  header,
  ...listProps
}: Props<ItemType>) {
  return (
    <MUIList
      subheader={
        header ? <StyledListSubheader>{header}</StyledListSubheader> : undefined
      }
      {...listProps}
    >
      {items.map((item, index) => (
        <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
      ))}
    </MUIList>
  );
}
