import { FC, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useIsFetching } from 'react-query';

import {
  EmployeeJobSelectField,
  EmployeeJobFamilySelectField,
  DivisionSelectField,
  PGUnitSelectField,
  IsExternalType,
  CountriesSelectField,
  BusinessUnitSelectField,
  CountriesSelectWithAllField,
  CompanyWithFiltersField,
  ScopeSelectField,
} from 'Dictionary/component';

import {
  getIsExternal,
  IsExternalSelectField,
} from 'Dictionary/component/IsExternalSelectField/IsExternalSelectField';
import { SearchFiltersForm, SearchFiltersFormProps } from 'components';
import { FieldRoot } from 'components/SearchFiltersForm/SearchFiltersForm.style';
import { UserSearchFilters } from 'MyProfile/model/UserSearchFilters';
import { UserFiltersFormData } from 'MyProfile/model/UserFiltersFormData';
import { mapCompanyFilterParams } from 'Dictionary/component/CompanyWithFiltersField/CompanyWithFiltersField';
import { userKey } from 'MyProfile/query/myProfile.query';
import {
  addExternalUserRoleOptions,
  addInternalUserRoleOptions,
} from 'MyProfile/component/AddUserRole/AddUserRole';
import { FormikAutocompleteField } from '../../../components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface UserSearchFiltersFormProps 
  extends Pick<
  SearchFiltersFormProps<UserFiltersFormData>,
  'renderPageActions'
  > {
  onFiltersSubmit: (formData: UserSearchFilters) => void;
  fullScreenList?: boolean;
  isExternalType?: IsExternalType;
  isForAction?: boolean;
  displayRoleFilters?: boolean;
  withFilterSaveKey?: string;
}

export const initialValues: UserFiltersFormData = {
  searchText: '',
  businessUnit: { id: 0, name: 'All Divisions' },
  division: { id: 0, name: 'All Business Areas' },
  productGroups: [],
  employeeJobs: [],
  employeeJobFamilies: [],
  isExternal: { id: IsExternalType.All, name: 'All users' },
  companyList: [],
  countryList: [],

  role: { id: '', name: 'Select role' },
  roleBusinessUnit: { id: 0, name: 'All Divisions' },
  roleCountry: { id: 0, name: 'All countries' },
  roleCompany: { id: 0, name: 'All companies' },
  roleScope: { id: 0, name: 'All scopes' },
};

export const mapFiltersFormData = (
  formData: UserFiltersFormData
): UserSearchFilters => {
  const businessUnit = Number(formData?.businessUnit?.id);
  const division = Number(formData?.division?.id);
  return {
    personName: formData.searchText ?? '',
    businessUnitId: !isNaN(businessUnit) ? businessUnit : 0,
    divisionId: !isNaN(division) ? division : 0,
    productGroupList: formData.productGroups.map((pg) => Number(pg.id)),
    isExternal: getIsExternal(formData?.isExternal?.id as IsExternalType),
    isForAction: false,
    companyList: formData.companyList.map((company) => Number(company.id)),
    countryList: formData.countryList.map((country) => Number(country.id)),
    userJobList: formData.employeeJobs.map((job) => Number(job.id)),
    userJobFamilyList: formData.employeeJobFamilies.map((job) => Number(job.id)),

    role: String(formData.role?.id ?? ''),
    roleBusinessUnit: Number(formData.roleBusinessUnit?.id ?? 0),
    roleCountry: Number(formData.roleCountry?.id ?? 0),
    roleCompany: Number(formData.roleCompany?.id ?? 0),
    roleScope: Number(formData.roleScope?.id ?? 0),
  };
};

export const mapExternalFiltersFormData = (
  formData: UserFiltersFormData
): UserSearchFilters => ({
  ...mapFiltersFormData(formData),
  isExternal: true,
});

export const mapAbbFiltersFormData = (
  formData: UserFiltersFormData
): UserSearchFilters => ({
  ...mapFiltersFormData(formData),
  isExternal: false,
});

export const UserSearchFiltersForm: FC<UserSearchFiltersFormProps> = ({
  onFiltersSubmit,
  renderPageActions,
  fullScreenList = true,
  displayRoleFilters = true,
  isExternalType = IsExternalType.All,
  withFilterSaveKey
}) => {
  const fetchingKeys = useIsFetching(userKey);
  const lgValue = fullScreenList ? 6 : 12;

  const handleOnFiltersSubmit: SearchFiltersFormProps<UserFiltersFormData>['onSubmit'] = (
    values
  ) => {
    if (isExternalType === IsExternalType.External)
      return onFiltersSubmit(mapExternalFiltersFormData(values));
    if (isExternalType === IsExternalType.Abb)
      return onFiltersSubmit(mapAbbFiltersFormData(values));
    return onFiltersSubmit(mapFiltersFormData(values));
  };

  return (
    <SearchFiltersForm
      placeholder="Search by person name or expand the filter on the right"
      loading={!!fetchingKeys} 
      initialValues={initialValues}
      renderPageActions={renderPageActions}
      onSubmit={handleOnFiltersSubmit} 
      withFilterSaveKey={withFilterSaveKey}
    >
      {({ setFieldValue, values }) => {
        const handleResetCompany = () =>
          setFieldValue('companyList', initialValues.companyList);
        const handleOnDivisionChange = () => {
          setFieldValue('businessUnit', initialValues.businessUnit);
          setFieldValue('productGroups', initialValues.productGroups);
          setFieldValue('companyList', initialValues.companyList);
        }
        const handleOnBusinessUnitChange = () => {
          setFieldValue('productGroups', initialValues.productGroups);
          setFieldValue('companyList', initialValues.companyList);
        };

        return (
          <>
          <Typography variant="h6">
              User filters
          </Typography>
          <Grid container>
              <Grid item xs={12} md={12} lg={lgValue}>
                <FieldRoot>
                  <DivisionSelectField name="division"
                    defaultOptions={[initialValues.division]}
                    onFieldChange={handleOnDivisionChange}
                  />
                </FieldRoot>
                <FieldRoot>
                  <BusinessUnitSelectField
                    name="businessUnit"
                    disableClearable
                    divisionID={Number(values.division?.id ?? 0)}
                    onFieldChange={handleOnBusinessUnitChange}
                  />
                </FieldRoot>
                <FieldRoot>
                  <PGUnitSelectField
                    label="Product group"
                    name="productGroups"
                    businessUnitID={Number(values.businessUnit.id)}
                    disabled={
                      !values.businessUnit.id
                    }
                    multiple={true}
                    helperText="Depends on selected Division"
                  />
                </FieldRoot>
              </Grid>
              <Grid item xs={12} md={12} lg={lgValue}>
                <FieldRoot>
                  <EmployeeJobFamilySelectField name="employeeJobFamilies" required={false} />
                </FieldRoot>
                <FieldRoot>
                  <EmployeeJobSelectField name="employeeJobs" required={false} />
                </FieldRoot>
                <FieldRoot>
                  <CountriesSelectField
                    name="countryList"
                    label="Country/Territory"
                    onFieldChange={handleResetCompany}
                  />
                </FieldRoot>
                <FieldRoot>
                  <CompanyWithFiltersField
                    name="companyList"
                    label="Company"
                    filterParams={mapCompanyFilterParams(
                      values.businessUnit,
                      values.countryList,
                      null
                    )}
                    helperText="Depends on selected Division and Country"
                  />
                </FieldRoot>
                {isExternalType === IsExternalType.All && (
                  <FieldRoot>
                    <IsExternalSelectField name="isExternal" disableClearable />
                  </FieldRoot>
                )}
              </Grid>
            </Grid>

            {displayRoleFilters && (
              <>
                <Typography variant="h6">
                  PCS2 Role filters
                </Typography>
                <Grid spacing={2} container>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldRoot>
                      <FormikAutocompleteField
                        name="role"
                        label="Role"
                        disableClearable
                        options={isExternalType === IsExternalType.External
                          ? addExternalUserRoleOptions
                          : addInternalUserRoleOptions}
                      />
                    </FieldRoot>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldRoot>
                      <BusinessUnitSelectField
                        name="roleBusinessUnit"
                        required={false}
                        disableClearable
                      />
                    </FieldRoot>
                  </Grid>
                </Grid>  
                <Grid spacing={2} container>                  
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldRoot>
                      <CountriesSelectWithAllField
                        name="roleCountry"
                        label="Country/Territory"
                        disableClearable
                        multiple={false}
                      />
                    </FieldRoot>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <FieldRoot>
                      <CompanyWithFiltersField
                        name="roleCompany"
                        label="Company"
                        disableClearable
                        filterParams={mapCompanyFilterParams(values.roleBusinessUnit ?? initialValues.roleBusinessUnit, [values.roleCountry ?? initialValues.roleCountry], null)}
                        multiple={false}
                        addAlloption={true}
                      />
                    </FieldRoot>
                  </Grid>
                  {isExternalType === IsExternalType.Abb &&
                    <Grid item xs={12} md={6} lg={3}>
                    <FieldRoot>
                      <ScopeSelectField
                        name="roleScope"                        
                        required={false}
                        disableClearable
                      />
                    </FieldRoot>
                    </Grid>
                  }                  
                </Grid>
              </>
            )}
          </>
        );
      }}
    </SearchFiltersForm>
  );
};
