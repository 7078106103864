import { FC } from 'react';
import { Typography } from '@material-ui/core';

import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { formatDate } from 'utils';
import { formatUserName } from 'utils/formatUserName';
import { CertificationPlanHistory } from 'CertificationPlansPage/model/CertificationPlanHistory';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import { DataObjectView } from 'components';

const columns: DataTableColumn<CertificationPlanHistory>[] = [
  {
    field: 'userDetail',
    headerName: 'Author',
    renderField: ({ userDetail }) =>
      `${formatUserName(
        `${userDetail?.firstName} ${userDetail?.lastName}`
      )} (${userDetail?.email.toLowerCase()})`,
  },
  {
    field: 'eventDate',
    headerName: 'Event date',
    renderField: ({ eventDate }) => formatDate(eventDate),
    width: 100,
  },
  {
    field: 'description',
    headerName: 'Status',
    renderField:  ({ description }) => description,
  },
];

export interface HistoryTableProps {
  DataTableProps: Omit<DataTableProps<CertificationPlanHistory>, 'columns'>;
}

export const HistoryTable: FC<HistoryTableProps> = ({ DataTableProps }) => (
  <>
    <Typography variant="h6">History</Typography>
    <DesktopSection>
      <DataTable
        columns={columns}
        rowSelector={(row) => row?.id}
        {...DataTableProps}
      />
    </DesktopSection>
    <MobileSection>
      <DataObjectList
        loading={DataTableProps.loading}
        items={DataTableProps.rows}
        renderDataObjectView={(item) => (
          <DataObjectView item={item} dataSetMaps={mapToDataSetMap(columns)} />
        )}
      />
    </MobileSection>
  </>
);
