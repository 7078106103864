import { FC, ReactNode } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { TypographyWrapper } from './DataField.style';

export interface DataFieldProps {
  label: string;
  value?: string | number | ReactNode;
}

export const DataField: FC<DataFieldProps> = ({ label, value }) => (
  <FormControl fullWidth>
    <InputLabel>{label}</InputLabel>
      <TypographyWrapper>
        <Typography variant="h6">{value ?? '...'}</Typography>
      </TypographyWrapper>
  </FormControl>
);
