import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Link } from 'components/Link/Link.style';
import { myRoleMatchToRoles, RoleType } from 'MyProfile/enum/RoleType';
import { useMyProfileQuery } from 'MyProfile/query/myProfile.query';
import { getRoles } from 'MyProfile/model/MyProfile';
import { ExternalLink } from 'components';
import { List } from '../List';
import { ListItem, ListItemProps } from '../ListItem/ListItem';
import { CollapseListItem } from '../CollapseListItem/CollapseListItem';

export interface NavListItemType {
  primaryText: string;
  path?: string;
  roles?: RoleType[];
  Icon?: React.ElementType;
  collapseListItems?: NavListItemType[];
  externalLink?: boolean;
}

export interface NavListItemProps extends NavListItemType, ListItemProps {}

export const NavListItem: FC<NavListItemProps> = ({
  primaryText,
  Icon,
  path,
  collapseListItems,
  roles,
  externalLink = false,
  ...listItemProps
}) => {
  const { data } = useMyProfileQuery();
  const match = useRouteMatch(path ?? ' ');

  const myRoles = data ? getRoles(data) : [];

  if (roles && !myRoleMatchToRoles(myRoles, roles)) {
    return null;
  }

  if (Array.isArray(collapseListItems) && !!collapseListItems.length) {
    return (
      <CollapseListItem
        primaryText={primaryText}
        Icon={Icon}
        leftPadding="default"
        renderCollapseList={() => (
          <List<NavListItemType>
            items={collapseListItems}
            disablePadding
            renderItem={(item) => (
              <NavListItem {...item} leftPadding="default" iconPlaceholder />
            )}
          />
        )}
      />
    );
  }

  if (externalLink) {
    return (
      <ExternalLink href={path ?? ''} showIcon={false}>
        <ListItem
          {...listItemProps}
          primaryText={primaryText}
          selected={match?.isExact}
          leftPadding="default"
          Icon={Icon}
        />
      </ExternalLink>
    );
  }

  return (
    <Link to={path ?? ''}>
      <ListItem
        {...listItemProps}
        primaryText={primaryText}
        selected={match?.isExact}
        leftPadding="default"
        Icon={Icon}
      />
    </Link>
  );
};
