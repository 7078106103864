import { allBusinessUnitField } from 'Dictionary/component/ActiveBusinessUnitSelectField/ActiveBusinessUnitSelectField';

export interface UserDeactivatedSearchFilters {
  personName: string;
  businessUnitId: number;
  divisionId: number;
  productGroupList?: number[];
  companyId?: number;
  isExternal?: boolean | null;
  deactivatedByName?: string;
  deactivateDate?: string | null; 
}

export const defaultSearchFilters: UserDeactivatedSearchFilters = {
  personName: '',
  businessUnitId: Number(allBusinessUnitField.id),
  divisionId: 0,
};
