import React, { FC, ReactNode, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { ListItem, ListItemProps } from '../ListItem/ListItem';

export interface CollapseListItemProps extends ListItemProps {
  renderCollapseList: () => ReactNode;
}

export const CollapseListItem: FC<CollapseListItemProps> = ({
  renderCollapseList,
  ...listItemProps
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem
        {...listItemProps}
        RightIcon={open ? ExpandLess : ExpandMore}
        button
        onClick={() => setOpen(!open)}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {renderCollapseList()}
      </Collapse>
    </>
  );
};
