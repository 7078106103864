import React from 'react'
import * as Model from 'Dictionary/model/ScopeDetail'
import {
    SearchFiltersForm,
    FluidForEditors,
    DictionaryFieldByCode,
} from 'components'
import { BusinessUnitSelectField, ScopeSelectField } from 'Dictionary/component'

export default function Filter(p: {
    defaultQuery: Model.ScopeDetailFindQuery
    onQuery: React.Dispatch<Model.ScopeDetailFindQuery>
}) {
    const initialValues = {
        searchText: '',
        ...p.defaultQuery
    };

    // eslint-disable-next-line
    const onSubmit = (query: any) => {
        const targetQuery: Model.ScopeDetailFindQuery = {
            ...query,

            title: query.searchText,
            businessArea: query.businessArea,
            division: query.division,
            scope: query.scope,

        }
        p.onQuery(targetQuery)
    }

    return (<>
        <SearchFiltersForm
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {(fp) => (
                <>
                    <FluidForEditors>
                        <DictionaryFieldByCode name="businessArea" label="Business Area" query={({ key: 'BusinessArea' })} dependandFieldName="division" />
                        <DictionaryFieldByCode name="division" label="Division" query={{ key: 'Division', businessArea: fp.values.businessArea }} />
                        {/* <BusinessUnitSelectField name="division" required={false} /> */}
                        <ScopeSelectField name="scope" required={false} />
                    </FluidForEditors>
                </>)
            }
        </SearchFiltersForm>
    </>
    )
}
