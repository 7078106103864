import React, { FC } from 'react';

import { mapToAutocompleteFieldItems } from 'TrainingSet/model/TrainingSet';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { useTrainingSetsQuery } from 'TrainingSet/query/trainingSetQuery';

export interface TrainingSetsSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options'> {
  buId: number;
}

export const TrainingSetsSelectField: FC<TrainingSetsSelectFieldProps> = ({
  buId,
  multiple = true,
  disabled = false,
  ...otherProps
}) => {
  const { data, isLoading, refetch } = useTrainingSetsQuery(buId);

  return (
    <FormikAutocompleteField
      multiple={multiple}
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading}
      onOpen={() => refetch()}
      {...otherProps}
    />
  );
};
