import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { TrainingSet } from 'TrainingSet/model/TrainingSet';

const domainPath = '/training-sets';

const getList = async (buId: number): Promise<TrainingSet[]> => {
  try {
    const response = await apiClient.get(`${domainPath}/business-unit/${buId}`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const trainingSetApi = {
  getList,
};
