import { PaginatedList } from '../../../model';
import { AutocompleteFieldItem } from '../../../components/Field/AutocompleteField/AutocompleteField';
import { MyProfile } from '../../../MyProfile/model/MyProfile';

export const mapToAutocompleteFieldItems = (
  data: PaginatedList<MyProfile>
): AutocompleteFieldItem[] =>
  data?.result?.map((x) => ({
    id: x.id? x.id: x.geid,
    geid: x.geid,
    name: `${x.lastName}, ${x.firstName} --${x.email}`,
    email: x.email,
  })) ?? [];
