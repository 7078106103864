import { HtmlHTMLAttributes, memo } from 'react';
import { CPStatusType } from 'enums/CPStatusType';
import { AbbColor, StatusChip } from './StatusChip';

export interface CPStatusChipProps
  extends Omit<HtmlHTMLAttributes<HTMLSpanElement>, 'children' | 'color'> {
  status: CPStatusType;
}

interface CPStatusTypeMap {
  label: string;
  color: AbbColor;
}

const mapCPStatusType = (status: CPStatusType): CPStatusTypeMap => {
  switch (status) {
    case CPStatusType.InProcess:
      return {
        label: 'In Process',
        color: 'blue',
      };
    case CPStatusType.WaitingApproval:
      return {
        label: 'Waiting Approval',
        color: 'yellow',
      };
    case CPStatusType.Approved:
      return {
        label: 'Completed',
        color: 'green',
      };
    case CPStatusType.Expiring:
      return {
        label: 'Expiring',
        color: 'orange',
      };
    case CPStatusType.ExpiringNoRenewal:
      return {
        label: 'Expiring (No Renewal)',
        color: 'orange',
      };
    case CPStatusType.WaitingRenewal:
      return {
        label: 'Waiting Renewal',
        color: 'blue',
      };
    case CPStatusType.Expired:
      return {
        label: 'Expired',
        color: 'red',
      };
    case CPStatusType.ExpiredNoRenewal:
      return {
        label: 'Expired (No Renewal)',
        color: 'red',
      };
    case CPStatusType.WaitingRenewalExpired:
      return {
        label: 'Waiting Renewal (Expired)',
        color: 'red',
      };
    case CPStatusType.PendingQuestionnaire:
        return {
          label: 'Pending Renewal Questionnaire',
          color: 'blue',
        };
    case CPStatusType.PendingQuestionnaireExpired:
      return {
        label: 'Pending Renewal Questionnaire (Expired)',
        color: 'red',
      };
      case CPStatusType.RenewalEnrollment:
        return {
          label: 'Pending Renewal Enrollment',
          color: 'blue',
        };
    case CPStatusType.RenewalEnrollmentExpired:
      return {
        label: 'Pending Renewal Enrollment (Expired)',
        color: 'red',
      };
    case CPStatusType.Rejected:
      return {
        label: 'Rejected',
        color: 'red',
      };
    case CPStatusType.Removed:
      return {
        label: 'Removed',
        color: 'red',
      };
    case CPStatusType.NotEnrolled:
      return {
        label: 'Not Enrolled',
        color: 'grey',
      };
    default:
      return {
        label: 'Unknown',
        color: 'grey',
      };
  }
};

export const CPStatusChip = (props: CPStatusChipProps) => {
  const { className, status, ...other } = props;
  const map = mapCPStatusType(status);
  return (
    <StatusChip
      className={className}
      label={map.label}
      color={map.color}
      variant="light"
      {...other}
    />
  );
};

export default memo(CPStatusChip, (prev, next) => prev.status === next.status);
