import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';

import { MyRolesList } from 'MyProfile/component/MyRolesList';
import { ProfileInformationForm } from 'MyProfile/component/ProfileInformationForm';
import { useMyProfileQuery } from 'MyProfile/query/myProfile.query';
import { Content, Card } from 'components/Page';
import { Loader } from 'components/Loader/Loader';
import { MyAttachmentList } from 'Attachment/component/MyAttachmentList/MyAttachmentList';
import { Typography, useTheme } from '@material-ui/core';
import { AttachmentsCard } from './MyProfilePage.style';

export const MyProfilePage: FC = () => {
  const { isLoading, data, refetch } = useMyProfileQuery();
  const theme = useTheme();
  if (isLoading || !data) {
    return <Loader />;
  }

  return (
    <Content
      title="My profile"
      subtitle="Here you can view you profile basic information"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9}>
          <Card header="Profile information">
            {data.userNeedToUpdateProfile && (
              <Typography
                style={{
                  color: theme.palette.secondary.main,
                  marginBottom: theme.spacing(2),
                }}
              >
                Update and fill in missing data in your profile
              </Typography>
            )}
            <ProfileInformationForm
              myProfile={data}
              onChange={() => refetch()}
            />
          </Card>
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card header="My roles in PCS2" listContent>
            <MyRolesList />
          </Card>
          <AttachmentsCard header="Attachments" listContent>
            <MyAttachmentList />
          </AttachmentsCard>
        </Grid>
      </Grid>
    </Content>
  );
};
