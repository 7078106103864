import { makeStyles } from '@material-ui/core';
import { AbbTheme } from 'theme/createAbbTheme';

export const useOverlayLoaderStyles = makeStyles(
  (theme: AbbTheme) => ({
    root: {
      position: 'relative',
      margin: 0,
      padding: 0,
    },
    overlay: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.spacing(6),
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(250, 250, 250, 0.76)',
      zIndex: theme.zIndex.speedDial,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    label: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'OverlayLoader' }
);
