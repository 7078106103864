import React, { FC } from 'react';

import { useCompaniesWithFilterQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'Dictionary/model/Company';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { CompanyFilterParams } from 'Dictionary/model/CompanyFilterParams';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface CompanyWithFiltersFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options'> {
  filterParams: CompanyFilterParams;
  addAlloption?: boolean;
}

export const mapCompanyFilterParams = (
  businessUnit: AutocompleteFieldItem,
  countryList: AutocompleteFieldItem[],
  isExternal: boolean | null
): CompanyFilterParams => ({
  businessUnitID: Number(businessUnit.id),
  countryIds: countryList.map((country) => Number(country.id)),
  isExternal
});

export const CompanyWithFiltersField: FC<CompanyWithFiltersFieldProps> = ({
  filterParams,
  addAlloption = false,
  multiple = true,
  disabled = false,
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = useCompaniesWithFilterQuery(
    filterParams
  );

  return (
    <FormikAutocompleteField
      {...otherProps}
      disabled={disabled}
      options={addAlloption && data?.length ? [{id: 0,name: 'All companies'},...mapToAutocompleteFieldItems(data)] : mapToAutocompleteFieldItems(data)}           
      loading={isLoading}
      onOpen={() => refetch()}
      multiple={multiple}
    />
  );
};
