import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  max-width: 360px;
  margin-top: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.background.paper};
  ${({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10),
    },
  })}
`;
