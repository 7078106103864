import { IconButton, IconButtonProps, useTheme } from '@material-ui/core';
import { Print } from '@material-ui/icons';

export interface PrintButtonProps extends Omit<IconButtonProps, 'children'> {}

export const PrintButton = (props: PrintButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton style={{ margin: theme.spacing(0, 0.5) }} {...props}>
      <Print fontSize="large"/>
    </IconButton>
  );
};

export default PrintButton;
