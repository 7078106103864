export enum RoleType {
  Administrator = 'Administrator',
  CertificationAuthority = 'CertificationAuthority',
  Reader = 'Reader',
  Contact = 'Contact',
  Supervisor = 'Supervisor',
  HierarchicalManager = 'HierarchicalManager',
  ABBTrainingManager = 'ABBTrainingManager',
  ChannelPartnerManager = 'ChannelPartnerManager',
  PartnerSupervisor = 'PartnerSupervisor',
  CertificateBuilder = 'CertificateBuilder',
  ExternalTechnician = 'ExternalTechnician',
  ABBTechnician = 'ABBTechnician',
  Approver = 'Approver',
  Developer = 'Developer',
}

export const myRoleMatchToRoles = (
  myRoleTypes: RoleType[],
  roleTypes: RoleType[]
): boolean => myRoleTypes.some((role) => roleTypes.includes(role));

export const hasRole = (roleType: RoleType, roles: RoleType[]): boolean =>
  roles.includes(roleType);

export const displayRoleType = (roleType: RoleType): string => {
  switch (roleType) {
    case RoleType.CertificationAuthority:
      return 'Certification Authority';
    case RoleType.HierarchicalManager:
      return 'Hierarchical Manager';
    case RoleType.ChannelPartnerManager:
      return 'Int/Ext Company Coordinator';
    case RoleType.PartnerSupervisor:
      return 'External Company Responsible';
    case RoleType.CertificateBuilder:
      return 'Certificate Builder';
    case RoleType.ExternalTechnician:
      return 'External Technician';
    case RoleType.ABBTechnician:
      return 'ABB Technician';
    default:
      return roleType;
  }
};
