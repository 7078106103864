import React, { ReactNode } from 'react';
import { TableProps as MUITableProps } from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import { StyledTable } from './Table.style';

export interface TableProps<Row> extends MUITableProps {
  rows: Row[];
  renderRow: (row: Row) => ReactNode;
  renderHeaderRow?: () => ReactNode;
}

export function Table<Row>({
  rows,
  renderRow,
  renderHeaderRow,
  ...tableProps
}: TableProps<Row>) {
  return (
    <TableContainer>
      <StyledTable {...tableProps}>
        {renderHeaderRow && <TableHead>{renderHeaderRow()}</TableHead>}
        <TableBody>
          {rows.map((row, index) => (
            <React.Fragment key={index}>{renderRow(row)}</React.Fragment>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
