import fileDownload from 'js-file-download';

import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { PaginatedList, PaginationDto, Sort } from 'model';
import { CatalogSearchFilters } from 'Catalog/model/CatalogSearchFilters';
import { CertificationPlansDetails } from 'CertificationPlansPage/model/CertificationPlansDetails';
import { getDateNow } from 'utils';

const domainPath = '/catalog';

const search = async (
  pagination: PaginationDto,
  sort: Sort<CertificationPlansDetails>,
  filters: Partial<CatalogSearchFilters>
): Promise<PaginatedList<CertificationPlansDetails>> => {
  try {
    const response = await apiClient.post(`${domainPath}/search`, {
      ...filters,
      ...pagination,
      ...sort,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const exportToExcel = async (
  sort: Sort<CertificationPlansDetails>,
  filters: Partial<CatalogSearchFilters>
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/export-excel`,
      {
        ...filters,
        ...sort,
      },
      {
        responseType: 'blob',
      }
    );
    fileDownload(response.data, `Available Certificates ${getDateNow()}.xlsx`);
    return [];
  } catch (e) {
    throw new ApiError(e);
  }
};

export const catalogApi = {
  search,
  exportToExcel,
};
