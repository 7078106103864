import { FC } from 'react';
import { useField } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';

export type FormikTextFieldProps = TextFieldProps & {
  name: string;
};

export const FormikTextField: FC<FormikTextFieldProps> = (props) => {
  const [field, meta, helpers] = useField({ name: props.name });

  const handleChange: FormikTextFieldProps['onChange'] = (event) => {
    if (props.type === "number")
      helpers.setValue(+event.target.value);
    else
      helpers.setValue(event.target.value);
  }

  const getHelperText = () => {
    if (meta.error) {
      return meta.error;
    }
    return props.helperText;
  };

  return (
    <TextField
      {...props}
      value={field.value}
      helperText={getHelperText()}
      error={!!meta.error}
      onChange={handleChange}
    />
  );
};
