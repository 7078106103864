import { useState } from 'react';
import { ActionsRoot, Button, Card, Stack } from 'components';
import { CertificationListForSelectionPart } from './CertificationListForSelectionPart';
import { CertificateLookupForField } from './CertificateLookupForField';

export function AutomaticEnrollmentCardPart() {

    const [show, setShow] = useState(false);
    const togleShow = () => setShow(prev => !prev)

    return (
        <Stack>
            <Card header="Automatic Enrollment">
                <CertificateLookupForField name='automaticEnrollment'
                    noDataMessage={<Stack>
                        <span>After completing the certification, user will be automatically enrolled to the optional certificates.</span>
                        <span>Currently there are no Certificates set up. Click “Add certificate” button to add one.</span>
                    </Stack>} />
            </Card>
            {show ? <CertificationListForSelectionPart name='automaticEnrollment' /> : <ActionsRoot>
                <Button color="secondary" onClick={togleShow}>ADD CERTIFICATE</Button>
            </ActionsRoot>}
        </Stack>
    );
}
