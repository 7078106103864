import { Role, RoleDetails } from 'MyProfile/model/Role';

export interface UserAuthorization {
  id: number;
  roleType: string;
  type: string;
  details: RoleDetails[];
}

export const mapUserAuthorizationToRoles = (
  userAuthorizations: UserAuthorization[]
): Role[] =>
  userAuthorizations.map((authorization) => ({
    id: authorization.id,
    type: authorization.type,
    details: authorization.details,
  }));
