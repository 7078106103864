import { FC } from 'react';

import { catalogDetailsRoute, certificationPlansDetailsRoute } from 'route';
import { CPStatusType } from 'enums/CPStatusType';
import { makeStyles, Typography } from '@material-ui/core';
import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from 'react-router-dom';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import { Button, DataObjectView } from 'components';
import { Prerequisite } from '../model/CertificationPlansDetails';
import { AbbTheme } from '../../theme/createAbbTheme';
import { CPStatusChip } from '../../components/StatusChip';

const columns: DataTableColumn<Prerequisite>[] = [
  { field: 'title', headerName: 'Title', renderField: ({ title }) => title },
  {
    field: 'level',
    headerName: 'Level',
    renderField: ({ level }) => level?.description,
    width: 100,
  },
  {
    field: 'businessUnit',
    headerName: 'Division',
    renderField: ({ businessUnit }) => businessUnit.description,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderField: ({ status }) => <CPStatusChip status={status} />,
  },
];

export const certificationListColumns: DataTableColumn<Prerequisite>[] = [
  { field: 'title', headerName: 'Title', renderField: ({ title }) => title },
  {
    field: 'level',
    headerName: 'Level',
    renderField: ({ level }) => level?.description,
  },
  {
    field: 'businessUnit',
    headerName: 'Division',
    renderField: ({ businessUnit }) => businessUnit.description,
  },
];

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    title: {},
  }),
  {
    name: 'PrerequisitesTable',
  }
);

export interface PrerequisitesTableProps {
  prerequisites: Prerequisite[];
  title?: string;
  isCatalog?: boolean;
  DataTableProps?: Omit<DataTableProps<Prerequisite>, 'rows'>;
}

export const PrerequisitesTable: FC<PrerequisitesTableProps> = ({
  prerequisites,
  title = 'Prerequisites',
  isCatalog,
  DataTableProps = { columns },
}) => {
  const history = useHistory();
  const handleOpenDetails = (certificateId: number, status: CPStatusType) => () => {
    if(isCatalog || status === CPStatusType.NotEnrolled){
      history.push(
        catalogDetailsRoute.createRoute({
          certificationId: String(certificateId),      
        })
      );  
    }
    else{
      history.push(
        certificationPlansDetailsRoute.createRoute({
          certificationPlanId: String(certificateId),
        })
      );
    }
  }
  const classes = useStyles();
  return Array.isArray(prerequisites) && prerequisites.length ? (
    <>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>

      <DesktopSection>
        <DataTable
          rows={prerequisites}
          rowSelector={(row) => row?.id}
          renderRowActions={(row, collapseRow, isExpanded) => (
            <>
              <IconButton onClick={handleOpenDetails(row.id, row.status)}>
                <NavigateNextIcon />
              </IconButton>
            </>
          )}
          hideCollapseColumn
          {...DataTableProps}
        />
      </DesktopSection>
      <MobileSection>
        <MobileSection>
          <DataObjectList
            items={prerequisites}
            renderDataObjectView={(item) => (
              <DataObjectView
                title={item.title}
                item={item}
                dataSetMaps={mapToDataSetMap(DataTableProps?.columns, [
                  'title',
                ])}
                renderActions={() => (
                  <Button
                    color="secondary"
                    onClick={handleOpenDetails(item.id, item.status)}
                  >
                    Details
                  </Button>
                )}
              />
            )}
          />
        </MobileSection>
      </MobileSection>
    </>
  ) : null;
};

export default PrerequisitesTable;
