import { Grid, useTheme } from '@material-ui/core';
import { Content } from 'components/Page';
import {
  PendingApprovalsSearchBar,
  PendingApprovalsTabs,
} from 'PendingApprovalsPage/components';
import {
  getEmptyPendingApprovalsQuery,
  PendingApprovalsQuery,
} from 'PendingApprovalsPage/model';
import { pendingApprovalKey } from 'PendingApprovalsPage/query/pendingApprovals.query';
import { useState } from 'react';
import { useIsFetching } from 'react-query';

export const PendingApprovalsPage = () => {
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const [mutating, setMutating] = useState<boolean>(false);
  const fetching = useIsFetching({ queryKey: pendingApprovalKey });
  const [query, setQuery] = useState<PendingApprovalsQuery>(
    getEmptyPendingApprovalsQuery()
  );
  const handleSubmit = (query: PendingApprovalsQuery) => {
    setQuery({ ...query });
  };
  return (
    <Content
      title="Pending approvals"
      mutating={mutating || Boolean(fetching)}
      subtitle="Here you can find all tasks that require your action"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PendingApprovalsSearchBar onSubmit={handleSubmit} />
          <Grid style={{ marginTop: theme.spacing(6) }} xs={12} item>
            <PendingApprovalsTabs
              query={query}
              onMutating={setMutating}
              tabIndex={tab}
              onChange={setTab}
            />
          </Grid>
        </Grid>
      </Grid>
    </Content>
  );
};

export default PendingApprovalsPage;
