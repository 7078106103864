import { FC } from 'react';

import { useScopeDetailsQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'Dictionary/model/ScopeDetail';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { ScopeDetailsParams } from 'Dictionary/model/ScopeDetailsParams';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface ScopeDetailsFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options'> {
  filterParams: ScopeDetailsParams;
}

export const mapScopeDetailsFilterParams = (
  businessUnit: AutocompleteFieldItem,
  scope: AutocompleteFieldItem
): ScopeDetailsParams => ({
  businessUnitID: Number(businessUnit.id),
  scopeId: Number(scope.id),
});

export const ScopeDetailsField: FC<ScopeDetailsFieldProps> = ({
  filterParams,
  multiple = true,
  disabled = false,
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = useScopeDetailsQuery(filterParams);

  return (
    <FormikAutocompleteField
      {...otherProps}
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading}
      onOpen={() => refetch()}
      multiple={multiple}
    />
  );
};
