import { useQuery, UseQueryOptions } from 'react-query';

import { mapPaginationDto, PaginatedList, Pagination, Sort } from 'model';
import { catalogApi } from 'Catalog/service/catalog.api';
import { CatalogSearchFilters } from 'Catalog/model/CatalogSearchFilters';
import { CertificationPlansDetails } from 'CertificationPlansPage/model/CertificationPlansDetails';

export const catalogKey = 'catalog';
export const useCatalogQuery = (
  pagination: Pagination,
  sort: Sort<CertificationPlansDetails>,
  filters: Partial<CatalogSearchFilters>,
  options: UseQueryOptions<PaginatedList<CertificationPlansDetails>>
) =>
  useQuery<PaginatedList<CertificationPlansDetails>>(
    [catalogKey, mapPaginationDto(pagination), sort, filters],
    () => catalogApi.search(mapPaginationDto(pagination), sort, filters),
    options
  );
