import { FC, useState } from 'react';

import { Content } from 'components/Page';
import { useUserDeactivatedQuery } from 'MyProfile/query/myProfile.query';
import { ApiError, getPagination, Pagination } from 'model';
import {
  defaultSearchFilters,
  UserDeactivatedSearchFilters,
} from 'MyProfile/model/UserDeactivatedSearchFilters';
import ExportIcon from '@material-ui/icons/VerticalAlignBottom';
import { archivedUsersColumns, UserTable } from 'MyProfile/component/UserTable';
import { TablePagination } from 'components/Table';
import { Loader } from 'components/Loader/Loader';
import { SearchResults } from 'components/DataTable/SearchResults/SearchResults';
import { Button, DataObjectView } from 'components';
import { UserDeactivatedSearchFiltersForm } from 'MyProfile/component/UserDeactivatedSearchFiltersForm';
import { useMutation } from 'react-query';
import { myProfileApi } from 'MyProfile/service/myProfile.api';
import { useToasts } from 'components/ToastProvider';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import { getFullName } from 'MyProfile/model/MyProfile';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';

const pageSize = 20;

export const ArchivedUsersPage: FC = () => {
  const { showError, showSuccess } = useToasts();
  const [pagination, setPagination] = useState<Pagination>(
    getPagination(pageSize)
  );
  const [filters, setFilters] = useState<UserDeactivatedSearchFilters>(
    defaultSearchFilters
  );
  const { data, isLoading, isFetching, refetch } = useUserDeactivatedQuery(
    pagination,
    filters,
    {
      onSuccess: (newData) => setPagination(getPagination(pageSize, newData)),
    }
  );
  const {
    mutate: exportToExcel,
    isLoading: isExportToExcelLoading,
  } = useMutation(() => myProfileApi.exportToExcelDeactivated(filters), {
    onSuccess: () => showSuccess('Export to excel success'),
    onError: () => showError('Export to excel failed. Too many records on search. Must be less than 10000.'),
  });
  const {
    mutate: reactivateUsers,
    isLoading: isReactivateLoading,
  } = useMutation<unknown, ApiError, number[]>(
    (userIds) => myProfileApi.reactiveUsers(userIds),
    {
      onSuccess: () => {
        showSuccess('Reactivate Users action success');
        refetch();
      },
      onError: () => showError('Reactivate Users action fail'),
    }
  );
  const loading = isFetching || isLoading;
  // @ts-ignore
  return (
    <Content
      title="Archived users"
      subtitle="Here you can reactivate users archived before"
      mutating={isReactivateLoading}
    >
      <UserDeactivatedSearchFiltersForm 
        onFiltersSubmit={setFilters} 
        renderPageActions={() => 
          <Button
            onClick={() => exportToExcel()}
            disabled={isExportToExcelLoading}
          >
            {isExportToExcelLoading ? (
              <Loader size={24} />
            ) : (
              <>
                <ExportIcon /> &nbsp;Export to excel
              </>
            )}
          </Button>
        }
      />
      <SearchResults total={data?.total} loading={loading} />
      <DesktopSection>
        <UserTable
          columns={archivedUsersColumns}
          rows={data?.result ?? []}
          loading={loading}
          rowSelector={(user) => user.id}
          renderSelectedActions={(selectedRows, clearSelected) => (
            <Button
              color="secondary"
              onClick={() =>
                reactivateUsers(
                  selectedRows.map((id) => Number(id)),
                  {
                    onSuccess: () => {
                      clearSelected();
                    },
                  }
                )
              }
            >
              Reactivate
            </Button>
          )}
          checkboxSelection
        />
      </DesktopSection>
      <MobileSection>
        <DataObjectList
          loading={loading}
          items={data?.result ?? []}
          renderDataObjectView={(item) => (
            <DataObjectView
              title={`${getFullName(item)} (${item.email})`}
              item={item}
              dataSetMaps={mapToDataSetMap(archivedUsersColumns, ['firstName'])}
              renderActions={() => (
                <Button
                  onClick={() => reactivateUsers([item.id])}
                  disabled={isReactivateLoading}
                >
                  Reactivate user
                </Button>
              )}
            />
          )}
        />
      </MobileSection>
      {!isFetching && (
        <TablePagination
          rowsPerPageOptions={[]}
          pagination={pagination}
          onChangePage={(event, currentPageIndex) =>
            setPagination((prevPagination) => ({
              ...prevPagination,
              currentPageIndex,
            }))
          }
        />
      )}
    </Content>
  );
};
