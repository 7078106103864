import { DispatchWithoutAction, useState } from 'react';
import { useQuery as callquery } from 'react-query';
import { Formik, FieldArray } from 'formik';
import { usePCSContext } from 'Router/PCSContext';
import {
    IconButton,
    Button, Card, Stack,
    DataTable, UsedIcons, ActionsRoot, DictionaryField, FluidForEditors
} from 'components';

import * as Model from './model';

type TRootModel = Model.CertificateListDefinitionDTO
type TItemModel = Model.CertificateListAlternativeDTO

export function AlternativesCardPart() {

    const [show, setShow] = useState(false);
    const togleShow = () => setShow(prev => !prev)

    return (
        <Stack direction='column'>

            <Card header='Alternatives'>
                <AlternativeList />
            </Card>

            {!show ?
                <ActionsRoot>
                    <Button color="secondary" onClick={togleShow}>
                        Add Alternative
                    </Button>
                </ActionsRoot>
                :
                <Card header='Add new alternative'>
                    <AlternativePart onEnd={togleShow} />
                </Card>}
        </Stack>);
}

function AlternativeList() {
    const pcsContext = usePCSContext()!;
    return <FieldArray name='alternatives'>
        {arrayHelpers => {
            const items = arrayHelpers.form.values[arrayHelpers.name] as TItemModel[]
            function doDelete(item: TItemModel) {
                arrayHelpers.remove(items.indexOf(item));
            }

            function onDelete(item: TItemModel) {
                pcsContext.showQuestion({
                    onConfirmed: () => doDelete(item),
                    confirmTitle: "Delete alternative?",
                    confirmDescription: "This rule will be removed from alternatives list.",
                    confirmButton: "DELETE"
                });
            }
            return (<>
                <DataTable
                    columns={[
                        { field: "source", headerName: "Source", renderField: (r: TItemModel) => r.source.map(x => `${x.code} - ${x.display}`).join(", ") },
                        { field: "alternative", headerName: "Alternative", renderField: (r: TItemModel) => r.alternative.map(x => `${x.code} - ${x.display}`).join(", ") },
                    ]}
                    hideCollapseColumn
                    rowSelector={x => x.id}
                    renderCollapseRow={(item, collapseRow) => (<Card header='Edit alternative'><AlternativePart item={item} onEnd={collapseRow} /></Card>)}
                    rows={items}
                    noDataMessage="Currently there are no alternatives set up. Click “Add alternative” button to add one."
                    renderRowActions={(item, collapseRow) => (<>
                        <IconButton onClick={() => { collapseRow(); }}>
                            <UsedIcons.Edit />
                        </IconButton>
                        <IconButton onClick={() => onDelete(item)}>
                            <UsedIcons.Delete />
                        </IconButton>
                    </>
                    )} />
            </>
            )
        }}
    </FieldArray>
}

function AlternativePart(p: {
    item?: TItemModel;
    onEnd: DispatchWithoutAction;
}) {
    const fieldName = "alternatives";
    const data: Partial<TItemModel> = p.item ?? {
        id: 0,
        source: [],
        alternative: []
    }
    return <FieldArray name={fieldName}>
        {arrayHelpers => {
            const { form } = arrayHelpers
            const isNew = data.id === 0
            const cancel = p.onEnd
            const submit = (value: Partial<TItemModel>) => {
                const items = arrayHelpers.form.values[fieldName] as Partial<TItemModel>[];
                const currentIndex = items.indexOf(data);
                currentIndex == -1 ? arrayHelpers.push(value) : arrayHelpers.replace(currentIndex, value);
                p.onEnd();
            }

            const getData = () => arrayHelpers.form.values.requirements.map((x: Record<string, unknown>) => ({ id: x.id, code: x.code, display: x.description }));

            return (
                <Formik
                    initialValues={data}
                    onSubmit={submit}>
                    {formikProps => (<>
                        <FluidForEditors inRow={2}>
                            <DictionaryField multiple name="source" label="Source"
                                query={callquery(`Alternatives`, (c) => getData(), { enabled: true })} />
                            <DictionaryField multiple name="alternative" label="Alternative"
                                query={callquery(`Alternatives`, (c) => getData(), { enabled: true })} />
                        </FluidForEditors>
                        <ActionsRoot>
                            <Button onClick={cancel}>
                                Cancel
                            </Button>
                            <Button color="secondary" onClick={formikProps.submitForm}>
                                {isNew ? "Add" : "Save"}
                            </Button>
                        </ActionsRoot>
                    </>
                    )}
                </Formik>
            )
        }}
    </FieldArray>;
}
