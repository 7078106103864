import { ApprovalGroup, ApprovalGroupDetails, UpdateApprovalGroupQuery } from '../model';
import { apiClient } from '../../service';
import { ApiError } from '../../model/ApiError';

const getApprovalGroups = async (
  buId: number | null
): Promise<ApprovalGroup[]> => {
  try {
    const response = await apiClient.get<ApprovalGroup[]>(
      `/approval-groups/business-unit/${buId}`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getApprovalGroupDetails = async (
  id: number
): Promise<ApprovalGroupDetails> => {
  try {
    const response = await apiClient.get<ApprovalGroupDetails>(
      `/approval-groups/${id}`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const addApprovalGroup = async (query: ApprovalGroup): Promise<number> => {
  try {
    const response = await apiClient.post<number>(`/approval-groups/add`, {
      ...query,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const updateApprovalGroup = async (query: ApprovalGroup): Promise<unknown> => {
  try {
    const response = await apiClient.put<unknown>('/approval-groups/update', {
      ...query,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const deleteApprovalGroup = async (query: ApprovalGroup): Promise<unknown> => {
  try {
    const response = await apiClient.put<unknown>(
      `/approval-groups/remove`,

      {
        ...query,
      }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const updateApprovalGroupUsers = async (
  query: UpdateApprovalGroupQuery
): Promise<number> => {
  try {
    const response = await apiClient.post<number>(
      `/approval-groups/update-users`,
      {
        ...query,
      }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export {
  getApprovalGroups,
  getApprovalGroupDetails,
  updateApprovalGroup,
  addApprovalGroup,
  deleteApprovalGroup,
  updateApprovalGroupUsers,
};
