import { FC, ReactNode } from 'react';
import { Form, Formik, FormikConfig, FormikProps } from 'formik';
import { Grid } from '@material-ui/core';

import {
  getFullNameWithGeidAndEmail,
  getRoles,
  MyProfile,
} from 'MyProfile/model/MyProfile';
import { hasRole, RoleType } from 'MyProfile/enum/RoleType';
import { EditUserDto } from 'MyProfile/model/EditUserDto';
import { CompanySelectField } from 'Dictionary/component/CompanySelectField/CompanySelectField';
import { DivisionSelectField } from 'Dictionary/component/DivisionSelectField/DivisionSelectField';
import { BusinessUnitSelectField } from 'Dictionary/component/BusinessUnitSelectField/BusinessUnitSelectField';
import { PGUnitSelectField } from 'Dictionary/component/PGUnitSelectField/PGUnitSelectField';
import { PreferredLanguagesSelectField } from 'Dictionary/component/PreferredLanguagesSelectField/PreferredLanguagesSelectField';
import { Button } from 'components/Button';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { getCompanyName } from 'Dictionary/model/Company';
import { EmployeeJobSelectField } from 'Dictionary/component/EmployeeJobSelectField/EmployeeJobSelectField';
import { EmployeeJobFamilySelectField } from 'Dictionary/component/EmployeeJobFamilySelectField/EmployeeJobFamilySelectField';
import { getPgUnitFullName } from 'Dictionary/model/PGUnit';
import { ManagerSelectField } from 'Dictionary/component/ManagerSelectField/ManagerSelectField';
import { FunctionalManagerSelectField } from 'Dictionary/component/FunctionalManagerSelectField/FunctionalManagerSelectField';
import { FieldRoot } from 'components/SearchFiltersForm/SearchFiltersForm.style';
import {
  CompanyWithFiltersField,
  CountriesSelectField,
  IsExternalType,
  TrainingManagerField,
} from 'Dictionary/component';
import { FormikTextField } from 'components/FormikField/FormikTextField/FormikTextField';
import { mapTrainingManagersFilterParams } from 'Dictionary/component/TrainingManagerField/TrainingManagerField';
import { ActionsRoot } from './EditUserForm.style';

export interface EditUserFormProps {
  user: MyProfile;
  loading: boolean;
  onSubmit: FormikConfig<EditUserFormData>['onSubmit'];
  validationSchema: FormikConfig<EditUserFormData>['validationSchema'];
  countryId?: number;
  showUserCompanies?: boolean;
  isExternalType?: IsExternalType;
  renderActions?: (
    submitForm: FormikProps<EditUserFormData>['submitForm'],
    disableSubmit: boolean
  ) => ReactNode;
}

export interface EditUserFormData {
  firstName: string;
  lastName: string;
  notes: string;
  city: string;
  state: string;
  country: AutocompleteFieldItem;
  manager: AutocompleteFieldItem;
  functionalmanager: AutocompleteFieldItem;
  employeeJobs: AutocompleteFieldItem[];
  jobFamilies: AutocompleteFieldItem[];
  company: AutocompleteFieldItem | null;
  division: AutocompleteFieldItem;
  businessUnit: AutocompleteFieldItem;
  pgUnit: AutocompleteFieldItem | null;
  preferredLanguages: AutocompleteFieldItem[];
}

export const mapEditUserDto = (
  profileInformationForm: EditUserFormData
): EditUserDto => ({
  managerGeid: (profileInformationForm?.manager?.id ?? '').toString(),
  managerEmail: (profileInformationForm?.manager?.group ?? '').toString(),
  functionalmanagerGeid: (profileInformationForm?.functionalmanager?.id ?? '').toString(),
  functionalmanagerEmail: (profileInformationForm?.functionalmanager?.group ?? '').toString(),
  companyId: Number(profileInformationForm.company?.id),
  divisionId: Number(profileInformationForm?.division?.id),
  businessUnitId: Number(profileInformationForm?.businessUnit?.id),
  pgUnitId: Number(profileInformationForm?.pgUnit?.id),
  employeeJobsIds: profileInformationForm.employeeJobs?.map((job) =>
    Number(job.id)
  ),
  employeeJobFamiliesIds: profileInformationForm.jobFamilies?.map((job) =>
    Number(job.id)
  ),
  preferredLanguagesIds: profileInformationForm.preferredLanguages.map(
    (language) => Number(language?.id)
  ),
  notes: profileInformationForm.notes,
  city: profileInformationForm.city,
  state: profileInformationForm.state,
});

export const getInitialFormData = (user: MyProfile, fillCompany: boolean): EditUserFormData => ({
  firstName: user.firstName,
  lastName: user.lastName,
  notes: user.notes,
  city: user.city,
  state: user.state,
  country: user.country && {
    id: user.country.id,
    name: user.country.name,
  },
  manager: user.manager && {
    id: user.manager?.geid,
    name: getFullNameWithGeidAndEmail(user.manager),
  },
  functionalmanager: user.functionalManager && {
    id: user.functionalManager?.geid,
    name: getFullNameWithGeidAndEmail(user.functionalManager),
  },
  employeeJobs: user.employeeJobs,
  jobFamilies: user.employeeJobFamilies,
  company: user.company && {
    id: user.company?.id,
    name: getCompanyName(user.company),
  },
  division: user.division && {
    id: user.division.id,
    name: user.division.name,
  },
  businessUnit: user.businessUnit && {
    id: user.businessUnit.id,
    name: user.businessUnit.description,
  },
  pgUnit: user.pgUnit
    ? {
      id: user.pgUnit.id,
      name: getPgUnitFullName(user.pgUnit),
    }
    : null,
  preferredLanguages: user.preferredLanguages,
});

export const EditUserForm: FC<EditUserFormProps> = ({
  user,
  countryId,
  loading,
  validationSchema,
  onSubmit,
  showUserCompanies = false,
  isExternalType = IsExternalType.Abb,
  renderActions,
}) => {
  const initialValues = getInitialFormData(user, showUserCompanies);
  const disableEdit = hasRole(RoleType.ExternalTechnician, getRoles(user));
  const disableField = disableEdit || loading;
  const enableExternalFields = isExternalType === IsExternalType.External;

  const renderCompanyField = (buId?: number, countryId?: number) => {
    if (showUserCompanies) {
      return <CompanySelectField name="company" disabled={disableField} />;
    }
    // if(!showUserCompanies && countryId && isExternalType !== IsExternalType.External){
    //   return (
    //     <CompanyByCountrySelectField
    //       name="company"
    //       label="Company"
    //       countryIds={[countryId]}
    //       disabled={disableField}
    //       multiple={false}
    //     />
    //   );
    // }
    if (!showUserCompanies && countryId && buId) {
      return (
        <CompanyWithFiltersField
          name="company"
          label="Company"
          filterParams={{
            businessUnitID: buId,
            countryIds: [countryId],
            isExternal: enableExternalFields
          }}
          helperText="Depends on Country and selected Division"
          disabled={disableField}
          multiple={false}
        />
      );
    }
    return (
      <CompanySelectField
        name="company"
        helperText="Depends on Country and selected Division"
        disabled
      />
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const hasError = (fieldName: string): boolean =>
          Object.prototype.hasOwnProperty.call(formikProps.errors, fieldName);
        const disableSubmit =
          !formikProps.dirty || disableField || !formikProps.isValid;
        return (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                {enableExternalFields && (
                  <FieldRoot $hintMargin={hasError('lastName')}>
                    <FormikTextField
                      name="firstName"
                      label="First name"
                      required
                    />
                  </FieldRoot>
                )}
                <FieldRoot>
                  <DivisionSelectField
                    name="division"
                    disabled={disableField}
                    onFieldChange={() => {
                      formikProps.setFieldValue('pgUnit', null);
                      formikProps.setFieldValue('businessUnit', null);
                      formikProps.setFieldValue('company', null);
                    }}
                    disableClearable
                  />
                </FieldRoot>
                <FieldRoot $hintMargin={!hasError('businessUnit') ?? true}>
                  <BusinessUnitSelectField
                    name="businessUnit"
                    disabled={disableField}
                    removePgUnitValue={() => {
                      formikProps.setFieldValue('pgUnit', null);
                      formikProps.setFieldValue('company', null);
                      if (enableExternalFields)
                        formikProps.setFieldValue('manager', null);
                    }}
                    divisionID={Number(formikProps.values.division?.id ?? 0)}
                    disableClearable
                  />
                </FieldRoot>
                <FieldRoot>
                  <PGUnitSelectField
                    label="Product group"
                    name="pgUnit"
                    businessUnitID={
                      formikProps.values.businessUnit?.id as number
                    }
                    disabled={
                      disableField || !formikProps.values.businessUnit?.id
                    }
                    helperText="Depends on selected Division"
                  />
                </FieldRoot>
                {/* {!enableExternalFields && (
                  <FieldRoot>
                    {formikProps.values.businessUnit ? (
                      <PGUnitSelectField
                        label="Product group"
                        name="pgUnit"
                        businessUnitID={
                          formikProps.values.businessUnit.id as number
                        }
                        disabled={
                          disableField || !formikProps.values.businessUnit.id
                        }
                        helperText="Depends on selected Division"
                      />
                    ) : (
                      <FormikAutocompleteField
                        name="pgUnit"
                        label="Product group"
                        options={[]}
                        disabled
                        helperText="Depends on selected Division"
                      />
                    )}
                  </FieldRoot>
                )} */}
                <FieldRoot>
                  <EmployeeJobFamilySelectField
                    name="jobFamilies"
                    disabled={disableField}
                  />
                </FieldRoot>
                <FieldRoot>
                  <EmployeeJobSelectField
                    name="employeeJobs"
                    disabled={disableField}
                    required={Object.prototype.hasOwnProperty.call(
                      validationSchema.fields,
                      'employeeJobs'
                    )}
                  />
                </FieldRoot>
                <FieldRoot>
                  <FormikTextField
                    name="notes"
                    label="Notes"
                    multiline
                    variant="outlined"
                  />
                </FieldRoot>
              </Grid>
              <Grid item xs={12} lg={6}>
                {enableExternalFields && (
                  <FieldRoot $hintMargin={hasError('firstName')}>
                    <FormikTextField
                      name="lastName"
                      label="Last name"
                      required
                    />
                  </FieldRoot>
                )}
                {enableExternalFields && (
                  <FieldRoot>
                    <CountriesSelectField
                      name="country"
                      label="Country/Territory"
                      multiple={false}
                      onFieldChange={() =>
                        formikProps.setFieldValue('company', null)
                      }
                      required
                    />
                  </FieldRoot>
                )}
                <FieldRoot>
                  {renderCompanyField(
                    formikProps.values.businessUnit?.id as number,
                    (formikProps.values.country?.id as number) ?? countryId
                  )}
                </FieldRoot>
                {enableExternalFields && (
                  <FieldRoot $hintMargin>
                    <FormikTextField
                      name="city"
                      label="City"
                    />
                  </FieldRoot>
                )}
                {enableExternalFields && (
                  <FieldRoot>
                    <FormikTextField
                      name="state"
                      label="State"
                    />
                  </FieldRoot>
                )}
                <FieldRoot>
                  {enableExternalFields ? (
                    <TrainingManagerField
                      name="manager"
                      label="Int/Ext Company Coordinator"
                      filterParams={mapTrainingManagersFilterParams(
                        formikProps.values.businessUnit,
                        formikProps.values.company
                      )}
                      disabled={disableField}
                      helperText="Depends on selected Division and Company"
                    />
                  ) : (
                    <ManagerSelectField
                      name="manager"
                      disabled={disableField}
                      initialValue={initialValues.manager?.name}
                    />
                  )}
                </FieldRoot>
                {!enableExternalFields && (
                  <FieldRoot>
                    <FunctionalManagerSelectField
                      name="functionalmanager"
                      disabled={disableField}
                      initialValue={initialValues.functionalmanager?.name}
                    />
                  </FieldRoot>
                )}
                <FieldRoot $hintMargin={!showUserCompanies}>
                  <PreferredLanguagesSelectField
                    label="Preferred languages"
                    name="preferredLanguages"
                    disabled={disableField}
                    required={Object.prototype.hasOwnProperty.call(
                      validationSchema.fields,
                      'preferredLanguages'
                    )}
                  />
                </FieldRoot>
              </Grid>
            </Grid>
            {renderActions ? (
              renderActions(formikProps.submitForm, disableSubmit)
            ) : (
              <ActionsRoot>
                <Button
                  type="button"
                  disabled={!formikProps.dirty || disableField}
                  onClick={() => formikProps.resetForm()}
                >
                  Discard
                </Button>
                <Button
                  type="submit"
                  disabled={disableSubmit}
                  color="secondary"
                >
                  Save
                </Button>
              </ActionsRoot>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
