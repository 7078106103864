import { FC, useState } from 'react';
import { useIsFetching } from 'react-query';

import {
  BusinessUnitSelectField,
  DivisionSelectField,
  PGUnitSelectField,
  CountriesSelectField,
  CompanyWithFiltersField,
} from 'Dictionary/component';
import { formatTemplate } from 'utils';
import dayjs, { Dayjs } from 'dayjs';
import { FormikTextField } from 'components/FormikField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { SearchFiltersForm, SearchFiltersFormProps } from 'components';
import { FieldRoot } from 'components/SearchFiltersForm/SearchFiltersForm.style';
import { mapCompanyFilterParams } from 'Dictionary/component/CompanyWithFiltersField/CompanyWithFiltersField';
import { UserDeactivatedFiltersFormData } from 'MyProfile/model/UserDeactivatedFiltersFormData';
import { UserDeactivatedSearchFilters } from 'MyProfile/model/UserDeactivatedSearchFilters';
import { allBusinessUnitField } from 'Dictionary/component/ActiveBusinessUnitSelectField/ActiveBusinessUnitSelectField';
import { userDeactivatedKey } from 'MyProfile/query/myProfile.query';
import { IsExternalSelectField, IsExternalType, getIsExternal } from 'Dictionary/component/IsExternalSelectField/IsExternalSelectField';

export interface UserDeactivatedSearchFiltersFormProps
  extends Pick<
  SearchFiltersFormProps<UserDeactivatedFiltersFormData>,
  'renderPageActions'
  > {
  onFiltersSubmit: (formData: UserDeactivatedSearchFilters) => void;
}

const initialValues: UserDeactivatedFiltersFormData = {
  searchText: '',
  businessUnit: allBusinessUnitField,
  division: { id: 0, name: 'All Business Areas' },
  productGroups: [],
  company: {},
  countryList: [],
  isExternal: { id: IsExternalType.All, name: 'All users' },
  deactivateDate: null,
  deactivatedBy: '',
};
const mapFiltersFormData = (
  formData: UserDeactivatedFiltersFormData
): UserDeactivatedSearchFilters => {
  const businessUnit = Number(formData?.businessUnit?.id);
  const division = Number(formData?.division?.id);
  return {
    personName: formData.searchText,
    businessUnitId: !isNaN(businessUnit) ? businessUnit : 0,
    divisionId: !isNaN(division) ? division : 0,
    productGroupList: (formData.productGroups ?? []).map((pg) =>
      Number(pg.id)
    ),
    ...(formData.company && { companyId: Number(formData.company.id ?? 0) }),
    isExternal: getIsExternal(formData?.isExternal?.id as IsExternalType),
    deactivateDate: formData.deactivateDate,
    deactivatedByName: formData.deactivatedBy
  };
};

// eslint-disable-next-line max-len
export const UserDeactivatedSearchFiltersForm: FC<UserDeactivatedSearchFiltersFormProps> = ({
  onFiltersSubmit,  
  renderPageActions
}) => {
  const [deactivateDate, setDeactivateDate] = useState<Dayjs | null>(null);
  const fetchingKeys = useIsFetching(userDeactivatedKey);
  return (
    <SearchFiltersForm
      placeholder="Search by person name or expand the filter on the right"
      loading={!!fetchingKeys}
      initialValues={initialValues}
      renderPageActions={renderPageActions}
      onSubmit={(values) => onFiltersSubmit(mapFiltersFormData(values))}
    >
      {({ values, setFieldValue }) => {
        const handleResetCompany = () =>
          setFieldValue('company', initialValues.company);

        const handleOnDivisionChange = () => {
          setFieldValue('businessUnit', initialValues.businessUnit);
          setFieldValue('productGroups', initialValues.productGroups);
          setFieldValue('company', initialValues.company);
        }

        const handleOnBusinessUnitChange = () => {
          setFieldValue('productGroups', initialValues.productGroups);
          setFieldValue('company', initialValues.company);
        };

        const handleDeactivateDateChange = (date: Dayjs | null) => {
          setDeactivateDate(date);
          setFieldValue('deactivateDate',  date && date.isValid() ? dayjs(date).toISOString() : null);
        };

        return (
          <>
            <FieldRoot>
              <DivisionSelectField name="division"
                defaultOptions={[initialValues.division]}
                onFieldChange={handleOnDivisionChange}
              />
            </FieldRoot>
            <FieldRoot>
              <BusinessUnitSelectField
                name="businessUnit"
                disableClearable
                divisionID={Number(values.division?.id ?? 0)}
                onFieldChange={handleOnBusinessUnitChange}
              />
            </FieldRoot>
            <FieldRoot>
              <PGUnitSelectField
                label="Product group"
                name="productGroups"
                businessUnitID={Number(values.businessUnit.id)}
                disabled={
                  !values.businessUnit.id
                }
                multiple={true}
                helperText="Depends on selected Division"
              />
            </FieldRoot>
            <FieldRoot>
              <CountriesSelectField
                name="countryList"
                label="Country/Territory"
                onFieldChange={handleResetCompany}
                helperText="Select to narrow down Company list"
              />
            </FieldRoot>
            <FieldRoot>
              <CompanyWithFiltersField
                name="company"
                label="Company"
                filterParams={mapCompanyFilterParams(
                  values.businessUnit,
                  values.countryList,
                  null
                )}
                multiple={false}
                helperText="Depends on selected Division and Country"
              />
            </FieldRoot>
            <FieldRoot>
              <IsExternalSelectField name="isExternal" disableClearable required={false} />
            </FieldRoot>
            <FieldRoot>
              <FormikTextField name="deactivatedBy" label="Archived by" />
            </FieldRoot>
            <FieldRoot>
              <KeyboardDatePicker
                value={deactivateDate}
                onChange={handleDeactivateDateChange}
                variant="inline"
                inputVariant="filled"
                label="Archived date"
                format={formatTemplate}
                placeholder='yyyy/mm/dd'
                autoOk
              /> 
            </FieldRoot>
          </>
        );
      }}
    </SearchFiltersForm>
  );
};
