import styled from 'styled-components';

import { theme } from 'theme/themeBase';
import { Card } from 'components/Page';
import { CardProps } from 'components/Page/Card/Card';

export const StyledCard = styled(Card)<CardProps>`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const autocompleteInputStyles = {
  backgroundColor: theme.palette.background.default,
};
