import { FocusEvent, useState, useEffect } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { FilterChangeHandler, ReportingFilters } from './@types';
import { ColumnDefinition } from '../model';
import { useReportingFieldDisabled } from '../hooks';

export interface ReportingTextFieldProps {
  columnDefinition: ColumnDefinition;
  fieldProps: TextFieldProps;
  onFilterChange: FilterChangeHandler;
  filters: ReportingFilters;
}

export const ReportingTextField = (props: ReportingTextFieldProps) => {
  const { filters, onFilterChange, columnDefinition, fieldProps } = props;
  const disabled = useReportingFieldDisabled(columnDefinition, filters);
  const [value, setValue] = useState<string>('');
  useEffect(() => {
    const newValue = filters[columnDefinition?.cid] as string;
    setValue(newValue ?? '');
  }, [filters]);
  const handleTextFieldChange = (e: FocusEvent<HTMLInputElement>) =>
    setValue(e.target.value);
  const handleTextFieldBlur = () => onFilterChange(columnDefinition.cid, value);
  return (
    <TextField
      {...fieldProps}
      value={value}
      onChange={handleTextFieldChange}
      onBlur={handleTextFieldBlur}
      disabled={disabled}
    />
  );
};
