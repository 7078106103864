import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';

export const Grow = styled.div`
  flex-grow: 1;
`;

export const StyledTypography = styled(Typography)`
  font-weight: bold;
`;

export const LogoWrapper = styled.div`
  padding: 12px;
  margin-right: 12px;
`;

export const StyledDrawer = styled(Drawer)<DrawerProps>`
  min-width: 256px;
  flex-shrink: 0;
  background-color: pink;
`;

export const Root = styled.div`
  display: flex;
`;

export const ContentRoot = styled.div`
  margin-top: 64px;
  padding: 30px;
  width: 100%;
  height: 100%;
`;

export const DetailsContentRoot = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(4, 2)};
`;

export const DetailsTitleRoot = styled.div`
  margin: ${({ theme }) => theme.spacing(0, 2)};
`;
