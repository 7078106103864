import { useContext, useMemo } from 'react';
import {
  GridSortDirection,
  useGridSlotComponentProps,
} from '@material-ui/data-grid';
import { MenuList, MenuItem } from '@material-ui/core';
import { GridContext } from './GridContext';

export const CustomColumnMenu = () => {
  const {
    state,
    apiRef: { current },
  } = useGridSlotComponentProps();
  const {
    onGroupByChange,
    columnsDefinitions,
    onVisibleColumnsChange,
  } = useContext(GridContext);
  const {
    columnMenu: { field },
  } = state;
  const col = field ? state.columns.lookup[field] : null;
  const sortable = col ? col.sortable : false;
  const groupable = useMemo(() => {
    const def = columnsDefinitions.find((x) => x.cid === col?.field);
    return def?.groupable;
  }, [columnsDefinitions]);
  const sortModel = current.getSortModel();
  const handleColumnVisibilityChange = () =>
    onVisibleColumnsChange &&
    onVisibleColumnsChange(
      current
        .getAllColumns()
        .filter((x) => !x.hide)
        .map((x) => x.field),
      false
    );
  const sortDirection: GridSortDirection =
    sortModel?.find((s) => s.field === field)?.sort ?? null;
  const handleShowAllColumns = () => {
    current.hideColumnMenu();
    setTimeout(() => {
      const columns = current.getAllColumns();
      current.updateColumns(columns.map((c) => ({ ...c, hide: false })));
      onVisibleColumnsChange &&
        onVisibleColumnsChange(
          columns.map((x) => x.field),
          true
        );
    }, 200);
  };
  const handleHideColumn = () => {
    if (col) {
      current.hideColumnMenu();
      setTimeout(() => {
        current.setColumnHeaderFocus({
          colIndex: -1,
        });
        col.hide = true;
        current.updateColumn(col);
        handleColumnVisibilityChange();
      }, 200);
    }
  };
  const handleUnsort = () => {
    current.setSortModel([]);
  };
  const handleAscSort = () => {
    current.setSortModel([{ field: field ?? '', sort: 'asc' }]);
  };
  const handleDescSort = () => {
    current.setSortModel([{ field: field ?? '', sort: 'desc' }]);
  };
  const menuItems: Array<{
    label: string;
    action?: () => void;
    disabled?: boolean;
  }> = [];
  if (sortable) {
    menuItems.push({
      label: 'Unsort',
      action: handleUnsort,
      disabled: !sortModel?.some((s) => s.field === field),
    });
    menuItems.push({
      label: 'Sort by ASC',
      action: handleAscSort,
      disabled: sortDirection === 'asc',
    });
    menuItems.push({
      label: 'Sort by DESC',
      action: handleDescSort,
      disabled: sortDirection === 'desc',
    });
  }
  menuItems.push({ label: 'Hide column', action: handleHideColumn });
  menuItems.push({ label: 'Show all columns', action: handleShowAllColumns });
  if (onGroupByChange && groupable) {
    menuItems.push({
      label: 'Group by',
      action: () => onGroupByChange(field ?? null),
    });
  }
  return (
    <MenuList>
      {menuItems.map(({ label, action, disabled }, i) => (
        <MenuItem
          key={`${label}-${i}`}
          onClick={() => action && action()}
          disabled={disabled}
        >
          {label}
        </MenuItem>
      ))}
    </MenuList>
  );
};
