import { GridColDef } from '@material-ui/data-grid';
import { useMemo } from 'react';
import { ColumnDefinition, ReportPageTypes } from 'Reports/model';
import { capitalize } from 'utils';

const uncompletedRequirementsColumnsDefOverridesLookup: Record<
  string,
  Partial<GridColDef>
> = {  
  CwQMDwcPBgYFDQwMBAYEAg: { hide: false },
  BQ4IBQQPDgwOCQgGBAgDBQ: { hide: false },
  AA0GBAMEAgIPCg2137sFBQ: { hide: true },
  AA0GBAMEAgIPCgICBAsFBQ: { hide: true },
  AA0GIMBAAgIPCg2137sFBQ: { hide: true },
  DgUACgQPCgsLAgYGBAsNDA: { hide: true },
  CAgOCg0KAQQLDQUIBAQIDg: { hide: true },
  CAgOCg0KAKAODQUIBAQIDg: { hide: true },
  AgAODQYODwkICwYKBAgHBA: { hide: true },
  AgEHBAIFDAQJDgsOBA4HDA: { hide: true },
  CwoFAwMKCQEHAgFOKAcNDw: { hide: true },
  CAoKBQkGAwMEAAIPBAsGDQ: { hide: true },
  BQ4IBwtskgwOCQgGBAgDBQ: { hide: true },
  BENIBwtskgwOCQgGBAgDBQ: { hide: true },
  uWuGDQAMBgIIDAoJBAYIAg: { hide: true },
  BQYGDQAMBgIIDAoJBAYIAg: { hide: true },
  BYqGDAqMBgIIDAoJBAYIAg: { hide: true },
  CgACAQYDDAMEDQkGBAwTSK: { hide: true },
  Bw4BAg0ECgALDAINBAAOAw: { hide: false },
  AwUDAQsMAgULCw0MBAYCBA: { hide: false },
  DwQHAQUIAgAAAw8KBAYPDQ: { hide: true },
  DQoNAAwJAAMBDQsOBAkODw: { hide: false },
  Cg8HAQIADgcFAgkEBAEEBQ: { hide: false },
  Bg8KCg8CAQADDwAEBAkJCw: { hide: true },
  BQ0CBQsHAQ4OABMEBAcLCK: { hide: true },
  BQ0CBQsHAQ4OABMEBAwtsK: { hide: true },
  CAgOCg0KAQQLDQUIBAQIwK: { hide: true },
  EA6xEbEHxJz9SNf0un2PIU: { hide: true },
  
};

const certificationsStatusColumnsDefOverridesLookup: Record<
  string,
  Partial<GridColDef>
> = {
  wTSKAQ4MAQwCBAAABAYHBg: { width: 400 },  
  CgACAQYDDAMEDQkGBAkJBw: {
    headerName: 'Version',
    width: 80,    
  },  
  DgwJAQoHDgwHDAACBAkGCA: {
    headerName: 'Level',
    width: 100,    
  },  
  XgACAQYDDAMEDQkGBAkJBw: {
    headerName: 'Req. Count',
    width: 80,
  },
  Bw4BAg0ECgALDAINBAAOAw: {
    width: 140,
  },
  AwUDAQsMAgULCw0MBAYCBA: {
    width: 140,
  },
  AwwTSKsMAgULCw0MBAPASY: {
    width: 140,
    hide: true,
  },
  AwwTSKs1ligaCw0MBAPASY: {
    width: 140,
    hide: true,
  },
  AQ0CAQsHAQ4OAAMEBAcLCg: {
    headerName: 'Status',
    width: 140,
  },
  AA0GBAMEAgIPCg2137sFBQ: { headerName: 'Certificate Business Area', hide: true },
  AA0GBAMEAgIPCgICBAsFBQ: { headerName: 'Certificate Division', hide: true },
  AA0GIMBAAgIPCg2137sFBQ: { headerName: 'Certificate Product Group', hide: true },
  DwcFAQ0PBw0ODg0IBAUGBw: {
    headerName: 'Scope',
    width: 100,
    hide: true,
  },
  DwcFAQ0PBw0ODg0IBAUGBz: {
    headerName: 'Scope Details',
    width: 160,
    hide: true,
  },
  DA8BAQ4MAQwCBAAABAYHBg: {
    headerName: 'Selection',
    width: 400,
    hide: true,
  },
  DgUACgQPCgsLAgYGBAsNDA: {
    headerName: 'Country',
    width: 120,
    hide: true,
  },
  CwoFAwMKCQEHAgsOBAcNDw: {
    headerName: 'User Type',
    width: 120,
    hide: true,
  },
  AA8ODgMJDgsNCAsLBA8JAg: {
    headerName: 'Function',
    width: 200,
    hide: true,
  },
  BQIDAAkCDAANDgcKBA4FDA: {
    headerName: 'Channel Type',
    hide: true,
  },
  CgACAQYDejMEDQkGBAkJBw: {
    headerName: 'Expiration period',
    width: 120,
    hide: true,
  },
  CgACAQYDejMiTokGBAkJBw: {
    headerName: 'Est. Cert. Duration',
    width: 120,
    hide: true,
  },
  CAgOCg0KAQQLDQUIBAQIDg: {
    headerName: 'User Division',
    hide: true,
  },
  CAgOCg0KAKAODQUIBAQIDg: {
    headerName: 'User Business area',
    hide: true,
  },
  AgEHBAIFDAQJDgsOBA4HDA: {
    width: 120,
    hide: true,
  },
  CwoFAwMKCQEHAgFOKAcNDw: {
    width: 120,
    hide: true,
  },  
  uWuGDQAMBgIIDAoJBAYIAg: {
    headerName: 'User Employee Job Family',
    hide: true,
  },
  BQYGDQAMBgIIDAoJBAYIAg: {
    headerName: 'User Employee Job Role',
    hide: true,
  },
  BYqGDAqMBgIIDAoJBAYIAg: {
    headerName: 'Work Type',
    hide: true,
  },  
  CgACAQYDDAMEDQkGBAwTSK: {
    headerName: 'Training Set',
    hide: true,
  }, 
  BQ0CBQsHAQ4OABMEBAcLCK: {
    headerName: 'Hierarchical manager',
    width: 240,
    hide: true,
  },
  BQ0CBQsHAQ4OABMEBAwtsK: {
    headerName: 'Functional manager',
    width: 240,
    hide: true,
  },
  CAgOCg0KAQQLDQUIBAQIwK: {
    width: 200,
    hide: true,
  },
  AgAODQYODwkICwYKBAgHBA: {
    hide: true,
  },
  CAoKBQkGAwMEAAIPBAsGDQ: {
    hide: true,
  },
  BQ4IBwtskgwOCQgGBAgDBQ: {
    hide: true,
  },
  BENIBwtskgwOCQgGBAgDBQ: {
    hide: true,
  },
  EA6xEbEHxJz9SNf0un2PIU: {
      headerName: 'User External Company Responsible',
     hide: true },
};

// const initGridColDef: GridColDef[] = [
//   { field: 'wTSKAQ4MAQwCBAAABAYHBg' }, // Certificate Title
//   { field: 'AA0GBAMEAgIPCgICBAsFBQ' }, // Certificate Business Unit
//   { field: 'DgwJAQoHDgwHDAACBAkGCA' }, // Certificate Level
//   { field: 'AQ0CAQsHAQ4OAAMEBAcLCg' }, // Certificate Status
//   { field: 'DwcFAQ0PBw0ODg0IBAUGBw' }, // Certificate Scope
//   { field: 'DwcFAQ0PBw0ODg0IBAUGBz' }, // Certificate Scope Details
//   { field: 'DA8BAQ4MAQwCBAAABAYHBg' }, // Certificate Selection
//   { field: 'DgUACgQPCgsLAgYGBAsNDA' }, // Certificate Country
//   { field: 'CwoFAwMKCQEHAgsOBAcNDw' }, // Certificate User Type
//   { field: 'AA8ODgMJDgsNCAsLBA8JAg' }, // Certificate Function
//   { field: 'BQIDAAkCDAANDgcKBA4FDA' }, // Certificate Channel Type
//   { field: 'CgACAQYDDAMEDQkGBAkJBw' }, // Certificate Version
//   { field: 'XgACAQYDDAMEDQkGBAkJBw' }, // Certificate Req. Count
//   { field: 'CAgOCg0KAQQLDQUIBAQIDg' }, // User Business Unit
//   { field: 'AgAODQYODwkICwYKBAgHBA' }, // User Country
//   { field: 'AgEHBAIFDAQJDgsOBA4HDA' }, // User Type
//   { field: 'CAoKBQkGAwMEAAIPBAsGDQ' }, // User Company
//   { field: 'BQ4IBQQPDgwOCQgGBAgDBQ' }, // User Email
//   { field: 'BQYGDQAMBgIIDAoJBAYIAg' }, // User Employee Job
//   { field: 'Bw4BAg0ECgALDAINBAAOAw' }, // Date Enrolled
//   { field: 'AwUDAQsMAgULCw0MBAYCBA' }, // Date Approved
//   { field: 'AwwTSKsMAgULCw0MBAPASY' }, // Date Expiration
//   { field: 'AwwTSKs1ligaCw0MBAPASY' }, // Date Due Date
//   { field: 'CwQMDwcPBgYFDQwMBAYEAg' }, // User Name
//   { field: 'BQ0CBQsHAQ4OABMEBAcLCK' }, // User Hierarchical manager
//   { field: 'CAgOCg0KAQQLDQUIBAQIwK' }, // User PG Unit
// ];

export const useReportGridColumns = (
  columnsDefinitions: ColumnDefinition[],
  selectedColumns: string[] | null = null,
  reportType: ReportPageTypes
): [defs: GridColDef[], allcolumns: string[]] => {
  const gridColDef = useMemo<GridColDef[]>(() => {
    let colDefSettings: GridColDef[] = columnsDefinitions.map((x) => ({
      field: x.cid,
    }));

    if (Array.isArray(selectedColumns) && selectedColumns.length > 0) {
      colDefSettings = selectedColumns.map((c) => ({ field: c }));
    }
    const colDefs = colDefSettings.map((x) => {
      const colDefBase = (reportType === ReportPageTypes.StatusOfCertifications
        ? certificationsStatusColumnsDefOverridesLookup
        : uncompletedRequirementsColumnsDefOverridesLookup)[x.field];
      const colDef = columnsDefinitions.find((c) => c.cid === x.field);
      const headerName =
        colDefBase?.headerName ??
        `${capitalize(colDef?.scopeId as string)} ${colDef?.name}`;
      const width = colDefBase?.width ?? 200;
      let hide = x?.hide ?? colDefBase?.hide ?? false;
      if (selectedColumns?.some((c) => c === x.field)) {
        hide = false;
      }
      return {
        ...x,
        headerName,
        width,
        hide,
        sortable: Boolean(colDef?.sortable),
      };
    });
    return colDefs;
  }, [columnsDefinitions, selectedColumns]);
  return [gridColDef, columnsDefinitions.map((x) => x.cid)];
};
