import { ReportingFilters } from 'Reports/components/@types';
import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import { getDateNow, uuid } from 'utils';
import { apiClient } from '../../service';
import { ApiError } from '../../model/ApiError';
import {
  FilterScope,
  ReportPageTypes,
  ColumnDefinition,
  ColumnValue,
  ReportQuery,
  ReportQueryResponse,
  ReportQueryResponseFlat,
  ReportSettings,
} from '../model';

const getFilterScopes = async (
  pageId: ReportPageTypes
): Promise<FilterScope[]> => {
  try {
    const response = await apiClient.get('/reports/getColumnScopes', {
      params: {
        pageId,
      },
    });

    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getFilters = async (): Promise<ReportSettings> => {
  try {
    const response = await apiClient.get('/reports/getFilters');
    if (response.data === '') {
      return {};
    }
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const saveFilters = async (
  variables: ReportSettings
): Promise<AxiosResponse> => {
  try {
    const response = await apiClient.post(
      '/reports/saveFilters',
      JSON.stringify({ ...variables })
    );
    return response;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getColumns = async (
  pageId: ReportPageTypes
): Promise<ColumnDefinition[]> => {
  try {
    const response = await apiClient.get<ColumnDefinition[]>(
      '/reports/getColumns',
      {
        params: {
          pageId,
        },
      }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

// Change backend model
interface ValueUgly {
  AdditionalData: string;
  Label: string;
  ValueId: number;
}

const getValues = async (
  pageId: ReportPageTypes,
  columnId: string,
  qs: ReportingFilters = {}
): Promise<ColumnValue[]> => {
  try {
    const response = await apiClient.get<ValueUgly[]>('/reports/getValues', {
      params: {
        pageId,
        columnId,
        qs,
      },
    });

    return response.data.map((u) => ({
      data: u.AdditionalData,
      label: u.Label,
      vid: u.ValueId,
    }));
  } catch (e) {
    throw new ApiError(e);
  }
};

export const getFilterCertificationPlans = async (
  query: ReportQuery
): Promise<ReportQueryResponseFlat> => {
  try {
    const response = await apiClient.post<ReportQueryResponse>(
      '/reports/filterCertificationPlans',
      JSON.stringify(query)
    );
    const { data } = response;
    return {
      total: data.total,
      data: data.data.map((x) => {
        const values: Record<string, string | number | null> = {};
        Object.keys(x.values).forEach((k) => {
          values[k] = x.values[k].label;
          if (x.values[k]?.count) {
            values.count = x.values[k]?.count ?? 0;
            values.label = x.values[k]?.label ?? null;
          }
        });

        return {
          id: x.id,
          guid: uuid(),
          ...values,
        };
      }),
    };
  } catch (e) {
    throw new ApiError(e);
  }
};

export type ReportExportFileType = 'pdf' | 'excel';

export const getFilterCertificationPlansToFile = async (
  query: ReportQuery,
  exportFileType: ReportExportFileType
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `/reports/filterCertificationPlans/${exportFileType}`,
      JSON.stringify(query),
      {
        responseType: 'blob',
        params: { pageId: ReportPageTypes.StatusOfCertifications },
      }
    );
    const fileName =
      exportFileType === 'excel'
        ? `Export status of certifications ${getDateNow()}.xlsx`
        : `Export status of certifications ${getDateNow()}.pdf`;
    fileDownload(response.data, fileName);
    return;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const getUncompletedRequirements = async (
  query: ReportQuery
): Promise<ReportQueryResponseFlat> => {
  try {
    const response = await apiClient.post<ReportQueryResponse>(
      '/reports/filterCPRequirements',
      JSON.stringify(query)
    );
    const { data } = response;
    return {
      total: data.total,
      data: data.data.map((x) => {
        const values: Record<string, string | number | null> = {};
        Object.keys(x.values).forEach((k) => {
          values[k] = x.values[k].label;
          if (x.values[k]?.count) {
            values.count = x.values[k]?.count ?? 0;
            values.label = x.values[k]?.label ?? null;
          }
        });
        return {
          id: x.id,
          guid: uuid(),
          ...values,
        };
      }),
    };
  } catch (e) {
    throw new ApiError(e);
  }
};

export const getUncompletedRequirementsToFile = async (
  query: ReportQuery,
  exportFileType: ReportExportFileType
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `/reports/filterCPRequirements/${exportFileType}`,
      JSON.stringify(query),
      {
        responseType: 'blob',
        params: { pageId: ReportPageTypes.UncompletedRequirements },
      }
    );
    const fileName =
      exportFileType === 'excel'
        ? `Export uncompleted requirements ${getDateNow()}.xlsx`
        : `Export uncompleted requirements ${getDateNow()}.pdf`;
    fileDownload(response.data, fileName);
    return;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const reportsApi = {
  getFilterScopes,
  getColumns,
  getValues,
  getFilters,
  saveFilters,
  getFilterCertificationPlans,
  getFilterCertificationPlansToFile,
  getUncompletedRequirements,
  getUncompletedRequirementsToFile,
};
