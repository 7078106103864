import { FC, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { AppShellBaseProps, DetailsAppShell } from 'components/AppShell';
import { CertificationPlansDetailsParams } from 'route';
import { useMutating } from 'hooks';
import { Content } from 'components/Page';
import { RecommendedCertificatesTable } from 'CertificationPlansPage/components/RecommendedCertificatesTable';
import {
  CertificationPlanActions,
  mutatingFilterKeys,
} from 'CertificationPlansPage/components/CertificationPlanActions';
import { HistoryTable } from 'CertificationPlansPage/components/HistoryTable';
import { AlternativesCertificatesTable } from 'CertificationPlansPage/components/AlternativesCertificatesTable';
import { UserDetailsDataObjectView } from 'CertificationList/component';
import {
  useCertificationEnrollRequestQuery,
  useCertificationListHistoryQuery,
} from 'CertificationList/query/certificationListQuery';
import {
  getEnrollmentRequestDataSetMap,
} from 'CertificationList/component/UserDetailsDataObjectView/UserDetailsDataObjectView';

export interface CertificationPlansEnrollRequestDetailsPageProps
  extends AppShellBaseProps {}

// eslint-disable-next-line max-len
export const CertificationPlansEnrollRequestDetailsPage: FC<CertificationPlansEnrollRequestDetailsPageProps> = ({
  logout,
}) => {
  const { certificationPlanId } = useParams<CertificationPlansDetailsParams>();
  const { isLoading, data, refetch } = useCertificationEnrollRequestQuery(
    Number(certificationPlanId)
  );
  const {
    isLoading: isHistoryLoading,
    data: historyData,
    refetch: historyRefetch,
  } = useCertificationListHistoryQuery(data?.id as number, { enabled: false });
  const { data: enrollRequest } = useCertificationEnrollRequestQuery(
    Number(certificationPlanId)
  );
  const isMutating = useMutating([...mutatingFilterKeys]);

  useEffect(() => {
    if (data?.id) {
      historyRefetch();
    }
  }, [data]);

  return (
    <DetailsAppShell
      title={data?.title ?? ''}
      subtitle={
        data?.user &&
        `${data?.user?.firstName} ${data?.user?.lastName} (${data?.user?.email})`
      }
      loading={isLoading}
      logout={logout}
    >
      <Content
        loading={isLoading}
        mutating={isMutating}
        mutatingLabel="Applying action..."
        title={data?.title ?? ''}
        subtitle={data?.purpose ?? ''}
        renderActions={() => (
          <CertificationPlanActions
            certificationPlanId={Number(certificationPlanId)}
            userCertificationPlan={data?.userCertificationPlan}
            workflowActions={data?.userActions}
            onActionSuccess={refetch}
          />
        )}
      >
        <Grid container spacing={3}>
          {data && (
            <Grid xs={12} item>
              <UserDetailsDataObjectView 
                item={enrollRequest} 
                dataSetMap={
                  enrollRequest &&
                  getEnrollmentRequestDataSetMap(enrollRequest)
                } />
            </Grid>
          )}
          <Grid xs={12} item>
            <AlternativesCertificatesTable
              alternativesCertificates={data?.alternatives ?? []}
            />
          </Grid>
          <Grid xs={12} item>
            <RecommendedCertificatesTable
              recommendedCertificates={data?.recommended ?? []}
              title="Recommended Certificates"
            />
          </Grid>
          <Grid xs={12} item>
            <RecommendedCertificatesTable
              recommendedCertificates={data?.automaticEnrollment ?? []}
              title="Certificates automatically enrolled after completion"
            />
          </Grid>
          <Grid xs={12} item>
            <HistoryTable
              DataTableProps={{
                loading: isHistoryLoading,
                rows: historyData ?? [],
                noDataMessage: '',
              }}
            />
          </Grid>
        </Grid>
      </Content>
    </DetailsAppShell>
  );
};
