import { makeStyles } from '@material-ui/core';
import { HtmlHTMLAttributes } from 'react';
import clsx from 'clsx';
import { AbbTheme } from 'theme/createAbbTheme';

export type AbbColor = 'blue' | 'grey' | 'green' | 'red' | 'yellow' | 'orange';
export type ColorTone = 'light' | 'default';

export interface StatusChipProps
  extends Omit<HtmlHTMLAttributes<HTMLSpanElement>, 'children'> {
  label: string;
  color?: AbbColor;
  variant?: ColorTone;
}

const useStyles = makeStyles(
  //@ts-ignore
  (theme: AbbTheme) => ({
    root: {
      ...theme.typography.caption,
      lineHeight: '0.75rem',
      display: 'inline-flex',
      alignItems:"center",
      margin: theme.spacing(0.5),
      minHeight: 20,
      borderRadius: 4,
      padding: theme.spacing(0, 0.75),
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
      '&$grey': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[400],
        '&$light': {
          color: theme.palette.grey[600],
          backgroundColor: theme.palette.grey[200],
        },
      },
      '&$blue': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.blue?.main,
        '&$light': {
          color: theme.palette.blue?.main,
          backgroundColor: theme.palette.blue?.light,
        },
      },
      '&$green': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.green?.main,
        '&$light': {
          color: theme.palette.green?.main,
          backgroundColor: theme.palette.green?.light,
        },
      },
      '&$red': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.red?.main,
        '&$light': {
          color: theme.palette.red?.main,
          backgroundColor: theme.palette.red?.light,
        },
      },
      '&$orange': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.orange?.main,
        '&$light': {
          color: theme.palette.orange?.main,
          backgroundColor: theme.palette.orange?.light,
        },
      },
      '&$yellow': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.yellow?.main,
        '&$light': {
          color: theme.palette.grey[600],
          backgroundColor: theme.palette.yellow?.light,
        },
      },
    },
    blue: {},
    grey: {},
    green: {},
    red: {},
    orange: {},
    yellow: {},
    light: {},
    default: {},
  }),
  { name: 'StatusChip' }
);

export const StatusChip = (props: StatusChipProps) => {
  const {
    className,
    label,
    color = 'grey',
    variant = 'light',
    ...other
  } = props;
  const classes = useStyles(props);
  return (
    <span
      className={clsx(
        className,
        classes.root,
        classes[color],
        classes[variant]
      )}
      {...other}
    >
      {label}
    </span>
  );
};

export default StatusChip;
