export enum RequirementWorkFlowActions {
  Submit,
  Approve,
  Reject,
  Reset,
  BackToWaitingApproval,
  Renew,
  EditDates,
}

export const requirementWorFlowActionsArray = [
  RequirementWorkFlowActions.Submit,
  RequirementWorkFlowActions.Approve,
  RequirementWorkFlowActions.Reject,
  RequirementWorkFlowActions.Reset,
  RequirementWorkFlowActions.BackToWaitingApproval,
  RequirementWorkFlowActions.Renew,
  RequirementWorkFlowActions.EditDates,
];
