import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { PaginationDto, Sort } from 'model';
import { BusinessUnit } from './BusinessUnit';

export interface Scope {
  id: number;
  description: string;
}

export interface ScopeDetail {
  id: number;
  description: string;
  sequence: number;
}

export interface ScopeKeyword {
  id: number;
  scope?: Scope
  division?: BusinessUnit
  title: string;
  sequence: number;
}

export interface ScopeDetailFindQuery extends PaginationDto, Sort<ScopeKeyword> {
  pageSize: number
  currentPageIndex: number

  businessArea?: DictionaryItemByCodeDTO
  division?: number
  scope?: number
}

export const mapToAutocompleteFieldItems = (
  scopeDetails: ScopeDetail[]
): AutocompleteFieldItem[] =>
  scopeDetails.map((details) => ({
    id: details.id,
    name: details.description,
  }));










