import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { useIsFetching } from 'react-query';

import {
  EmployeeJobSelectField,
  EmployeeJobFamilySelectField,
  BusinessUnitSelectField,
  DivisionSelectField,
  PGUnitSelectField,
  IsExternalType,
  CountriesSelectField,
  CompanyWithFiltersField,
} from 'Dictionary/component';
import {
  getIsExternal,
  IsExternalSelectField,
} from 'Dictionary/component/IsExternalSelectField/IsExternalSelectField';
import { SearchFiltersForm, SearchFiltersFormProps } from 'components';
import { ByPersonFiltersFormData } from 'ByPerson/model/ByPersonFiltersFormData';
import { ByPersonSearchFilters } from 'ByPerson/model/ByPersonSearchFilters';
import { FieldRoot } from 'components/SearchFiltersForm/SearchFiltersForm.style';
import { mapCompanyFilterParams } from 'Dictionary/component/CompanyWithFiltersField/CompanyWithFiltersField';
import { byPersonKey } from 'ByPerson/query/byPersonQuery';

export interface ByPersonFiltersFormProps
  extends Pick<
    SearchFiltersFormProps<ByPersonFiltersFormData>,
    'renderPageActions'
  > {
  onFiltersSubmit: (searchFilters: ByPersonSearchFilters) => void;
}

export const initialValues: ByPersonFiltersFormData = {
  searchText: '',
  businessUnit: { id: 0, name: 'All Divisions' },
  division: { id: 0, name: 'All Business Areas' },
  productGroups: [],
  isExternal: { id: IsExternalType.All, name: 'All users' },
  employeeJobs: [],
  employeeJobFamilies: [],
  countryList: [],
  companyList: [],
};

export const mapByPersonSearchFilters = (
  byPersonFiltersFormData: ByPersonFiltersFormData
): ByPersonSearchFilters => ({
  personName: byPersonFiltersFormData.searchText,
  businessUnitId: Number(byPersonFiltersFormData.businessUnit.id),
  divisionId: Number(byPersonFiltersFormData.division.id),
  productGroupList: (byPersonFiltersFormData.productGroups ?? []).map((pg) =>
    Number(pg.id)
  ),
  isExternal: getIsExternal(
    byPersonFiltersFormData?.isExternal?.id as IsExternalType
  ),
  userJobList: (byPersonFiltersFormData.employeeJobs ?? []).map((job) =>
    Number(job.id)
  ),
  userJobFamilyList: (byPersonFiltersFormData.employeeJobFamilies ?? []).map((job) =>
    Number(job.id)
  ),
  countryList: (byPersonFiltersFormData.countryList ?? []).map((country) =>
    Number(country.id)
  ),
  companyList: (byPersonFiltersFormData.companyList ?? []).map((company) =>
    Number(company.id)
  ),
});

export const ByPersonFiltersForm: FC<ByPersonFiltersFormProps> = ({
  onFiltersSubmit,
  renderPageActions,
}) => {
  const fetchingKeys = useIsFetching(byPersonKey);
  const handleOnFiltersSubmit: SearchFiltersFormProps<ByPersonFiltersFormData>['onSubmit'] = (
    values
  ) => onFiltersSubmit(mapByPersonSearchFilters(values));

  return (
    <SearchFiltersForm
      placeholder="Search by person name or expand the filter on the right"
      loading={!!fetchingKeys}
      initialValues={initialValues}
      renderPageActions={renderPageActions}
      onSubmit={handleOnFiltersSubmit}
    >
      {({ values, setFieldValue }) => {
        const handleResetCompany = () =>
          setFieldValue('companyList', initialValues.companyList);
          const handleOnDivisionChange = () => {
            setFieldValue('businessUnit', initialValues.businessUnit);
            setFieldValue('productGroups', initialValues.productGroups);
            setFieldValue('companyList', initialValues.companyList);
          }
        const handleOnBusinessUnitChange = () => {
          setFieldValue('productGroups', initialValues.productGroups);
          setFieldValue('companyList', initialValues.companyList);
        };
        return (
          <Grid container>
            <Grid item xs={12} md={12} lg={4}>
              <FieldRoot>
                <DivisionSelectField name="division"
                  defaultOptions={[initialValues.division]}   
                  onFieldChange={handleOnDivisionChange}  
                />
              </FieldRoot>  
              <FieldRoot>
                <BusinessUnitSelectField
                  name="businessUnit"
                  onFieldChange={handleOnBusinessUnitChange}
                  disableClearable
                  divisionID={Number(values.division?.id ?? 0)}
                />
              </FieldRoot>
              <FieldRoot>
                <PGUnitSelectField 
                  label="Product group"
                  name="productGroups" 
                  businessUnitID={Number(values.businessUnit.id)}
                  disabled={
                    !values.businessUnit.id
                  }
                  multiple={true}
                  helperText="Depends on selected Division" 
                />
              </FieldRoot> 
            </Grid>
            <Grid item xs={12} md={12} lg={4}>              
              <FieldRoot>
                <CountriesSelectField
                  name="countryList"
                  label="Country/Territory"
                  onFieldChange={handleResetCompany}
                />
              </FieldRoot>
              <FieldRoot>
                <CompanyWithFiltersField
                  name="companyList"
                  label="Company"
                  filterParams={mapCompanyFilterParams(
                    values.businessUnit,
                    values.countryList,
                    null                    
                  )}
                  helperText="Depends on selected Division and Country"
                />
              </FieldRoot>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <FieldRoot>
                <IsExternalSelectField name="isExternal" disableClearable />
              </FieldRoot>
              <FieldRoot>
                <EmployeeJobFamilySelectField name="employeeJobFamilies" required={false} />
              </FieldRoot>              
              <FieldRoot>
                <EmployeeJobSelectField name="employeeJobs" required={false} />
              </FieldRoot>              
            </Grid>
          </Grid>
        );
      }}
    </SearchFiltersForm>
  );
};
