import { ModalQuetionProps, useConfirmationModal2 } from 'hooks/useModal';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

export type { ModalQuetionProps }
interface PCSContextRef {
    onLogout: () => void
    user: string
    showQuestion: (p: ModalQuetionProps) => void
}
interface PCSProviderData {
    onLogout: () => void
    user: string
}

export const PCSContext = createContext<PCSContextRef | null>(null)
export const usePCSContext = () => useContext(PCSContext)
export const PCSContextProvider = (p: PropsWithChildren<PCSProviderData>) => {
    const Modal = useConfirmationModal2()

    const contextValue = useMemo<PCSContextRef>(() => (
        {
            onLogout: p.onLogout,
            user: p.user,
            showQuestion: Modal.showQuestion
        }), [p])

    return (<PCSContext.Provider value={contextValue}>
        {p.children}
        <Modal.Modal />
    </PCSContext.Provider>)
}
