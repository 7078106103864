import React from 'react'
import {
    Checkbox,
    EditorFormActions,
    FieldRoot,
    FluidForEditors,
    FormControlLabel,
} from 'components'
import { Form, Formik, FormikTextField, Yup, FormikProps, DictionaryFieldByCode } from 'components/FormikField';


import { dictionaryApi } from 'Dictionary/service/dictionary.api';
import * as Model from './model'

type DTOModel = Model.CompanyInfoDTO

export default function Editor(p: {
    item: DTOModel
    onCancel: React.DispatchWithoutAction
    onCommit: React.Dispatch<Model.CompanyInfoDTO>
}) {
    let similarNameError: boolean = false;
    const toEdit = p.item
    const onCancelAdd = () => p.onCancel()
    const onUpdateRow = (item: DTOModel) => p.onCommit(item)

    function renderForm(formikProps: FormikProps<DTOModel>) {
        return (
            <Form>
                <FluidForEditors inRow={2}>
                    <FormikTextField name="name" label="Company" required helperText={similarNameError ? (<span style={{color:'red', fontWeight: 'bold', fontSize: 'small'}}>A similar Company already exists</span>) : null} error={similarNameError}/>

                    <DictionaryFieldByCode name="divisions" label="Division" query={{ key: 'Division' }} multiple required />

                    <DictionaryFieldByCode name="country" label="Country/Territory" query={{ key: 'Country' }} required />
                    <FormikTextField name="city" label="City" required />

                    <DictionaryFieldByCode name="channels" label="Channels" query={{ key: 'Channel' }} multiple required helperText='Required only for external companies' />
                    <FieldRoot>
                        <div>&nbsp;</div>
                        <FormControlLabel
                            control={<Checkbox checked={formikProps.values.isAbb} />}
                            label="Part of ABB"
                            name="isAbb"
                            labelPlacement="end"
                            onChange={formikProps.handleChange}
                        />
                    </FieldRoot>
                </FluidForEditors>
                <EditorFormActions isNew={!formikProps.values.id} formik={formikProps} onCancelAdd={onCancelAdd} />
            </Form>
        )
    }
    const formValidationSchema = Yup.object().shape({
        divisions: Yup.array().min(1),
        country: Yup.object().nullable().required(),
        city: Yup.string().required(),
        name: Yup.string().required()
        .when(["country", "divisions"], {
            is: (country: DictionaryItemByCodeDTO, divisions: DictionaryItemByCodeDTO[]) => !!country && divisions.length>0,
            then: Yup.string().required().test("checkIsNameUnique","A similar Company already exists",
            (value, context) => 
              new Promise((resolve) => {
                    const query = {
                        name: value,
                        divisions: context.parent.divisions,
                        country: context.parent.country
                    };
                    dictionaryApi.company.find(query as Model.CompanyFindQuery).then(res => {
                        similarNameError = res.total > 0;
                        resolve(true);
                    });
                }
              )
           ),
        }),    
        channels: Yup.array().required().when("isAbb", {
            is: false,
            then: Yup.array().min(1).required()
        })
    });

    if (!toEdit) return (<></>)
    return (<>
        <Formik
            initialValues={toEdit}
            validationSchema={formValidationSchema}
            onSubmit={onUpdateRow}
        >
            {(formikProps) => renderForm(formikProps)}
        </Formik>
    </>)
}
