import { FC } from 'react';

import { usePGUnitsQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'Dictionary/model/PGUnit';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface PGUnitSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' > {  
    name: string;
    businessUnitID: number;  
    disabled?: boolean;
}

export const PGUnitSelectField: FC<PGUnitSelectFieldProps> = ({
  name,
  businessUnitID,    
  disabled = false,
  multiple = false,
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = usePGUnitsQuery(businessUnitID);

  return (
    <FormikAutocompleteField      
      name={name}
      disabled={disabled}
      //options={businessUnitID > 0 ? mapToAutocompleteFieldItems(data) : []}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading}
      onOpen={() => refetch()}
      multiple={multiple}
      {...otherProps}
    />
  );
};
