import { IsExternalType } from 'Dictionary/component';

export interface UserSearchFilters {
  personName: string;
  businessUnitId: number;
  divisionId: number;
  productGroupList?: number[];
  isExternal: boolean | null;
  isForAction: boolean | null;
  countryList?: number[];
  userJobList?: number[];
  userJobFamilyList?: number[];
  companyList?: number[];

  role?: string;
  roleBusinessUnit?: number|undefined;
  roleCountry?: number;
  roleCompany?: number;
  roleScope?: number;
}

export const defaultSearchFilters: UserSearchFilters = {
  personName: '',
  businessUnitId: 0,
  divisionId: 0,
  isExternal: null,
  isForAction: false
};

export const getDefaultSearchFilters = (
  isExternalType?: IsExternalType,
  isForAction? :boolean
): UserSearchFilters => {
  const result = {
    ...defaultSearchFilters,
    isExternal: (isExternalType !== undefined) ? isExternalType === IsExternalType.External : null,
    isForAction: isForAction ?? false
  }

  return result;
};
