import { PageBase } from 'components/Page/PageBase'
import { Button } from 'components'

import * as Model from 'Dictionary/model/Company'
import * as API from 'Dictionary/service/dictionary.api'

import EditorPart from './Editor'
import FiltersPart from './Filters'

type QueryModel = Model.CompanyFindQuery
type DTOModel = Model.CompanyInfoDTO
export const ContractAPI = API.dictionaryApi.company

const defaulQuery: QueryModel = {
  currentPageIndex: 0,
  pageSize: 50,
  sortExpression: "name",
  sortDescending: false,
}
const defaultNewItem: DTOModel = { id: 0, divisions: [], channels: [] } as unknown as DTOModel
const getRowId = (row: DTOModel) => row.id

export default function Page(p?: { contract?: ApiContract<DTOModel, QueryModel> }) {

  const getNewItem = (): DTOModel => ({ ...defaultNewItem })
  return PageBase<DTOModel, QueryModel>({
    defaultQuery: { ...defaulQuery },
    getIdFor: getRowId,
    contract: p?.contract ?? ContractAPI,
    pageSettings: {
      title: "Manage companies",
      subtitle: "Here you can add or manage existing companies",
      editTitle: "Edit company",
      addTitle: "Add company",
      newItem: {
        actionCaption: "ADD COMPANY",
        inPageAsRowView: true
      },
      editorWithPrefetch: true,
      deleteItem: {
        confirmTitle: "Delete Company",
        confirmDescription: "Deleted Company can't be restored.",
        confirmButton: "DELETE"
      },
      selectedActionComponent: ctx => (<>
        <Button type="button" color="secondary" onClick={() => ContractAPI
          .deleteBatch(ctx.selectedRows)
          .then(e => ctx.pagePorts.showSuccess('Items deleted'))
          .then(() => ctx.pagePorts.reloadData())
          .catch(e => ctx.pagePorts.showError(e.message ? `Cannot delete some items. Additional message: ${e.message}` : "Cannot delete item."))}>DELETE SELECTED</Button></>)

    },
    getRowView: (row, cancelHandler, updateHandler) => (<EditorPart item={row ?? getNewItem()} onCancel={cancelHandler} onCommit={updateHandler}/>),
    getSearchFiltersForm: (onQuery) => (<FiltersPart defaultQuery={defaulQuery} onQuery={q => onQuery(q)} />),
    SearchFiltersForm: FiltersPart,
    columns: (
      [
        {
          field: 'name',
          headerName: 'Company',
        },
        {
          field: 'divisions',
          headerName: 'Division',

          notSortable: true,
          renderField: (r) => r.divisions.map(x => x.display).join(", ")
        },
        {
          field: 'country',
          headerName: 'Country',
          renderField: (r) => r.country.display
        },
        {
          field: 'city',
          headerName: 'City'
        },
        {
          field: 'isAbb',
          width: 50,
          headerName: 'ABB',
          renderField: (r) => r.isAbb ? "Yes" : "No"
        }
      ]
    )
  })
};
