import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';

import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { EnrollmentRequestStatusChip } from 'components/StatusChip';
import { formatDate } from 'utils';
import { certificationPlansEnrollRequestDetailsRoute } from 'route';
import { EnrollmentRequest } from 'Certificate/model/EnrollmentRequest';
import { TitleTypography } from './EnrollmentRequestTable.style';

export const enrollmentRequestsColumns: DataTableColumn<EnrollmentRequest>[] = [
  {
    field: 'title',
    headerName: 'Certificate',
    width: 400,
    renderField: ({ title }) => (
      <TitleTypography variant="body2">{title}</TitleTypography>
    ),
  },
  { field: 'release', headerName: 'Release' },
  { field: 'level', headerName: 'Level' },
  {
    field: 'requestDate',
    headerName: 'Request date',
    renderField: ({ requestDate }) => formatDate(requestDate),
  },
  {
    field: 'rejectReason',
    headerName: 'Reject reason',
    renderField: ({ rejectReason }) => rejectReason,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderField: ({ status }) => (
      <EnrollmentRequestStatusChip status={status} />
    ),
  },
];

export interface EnrollmentRequestTableProps
  extends DataTableProps<EnrollmentRequest> {}

export const EnrollmentRequestTable: FC<EnrollmentRequestTableProps> = ({
  rows,
  columns,
  ...otherProps
}) => {
  const history = useHistory();
  const handleOpenDetails = (certificationPlanId: number) => () =>
    history.push(
      certificationPlansEnrollRequestDetailsRoute.createRoute({
        certificationPlanId: String(certificationPlanId),
      })
    );

  return (
    <DataTable
      {...otherProps}
      columns={columns}
      rows={rows}
      rowSelector={(row) => row.id}
      renderRowActions={(row, collapseRow, isExpanded) => (
        <>
          <IconButton onClick={handleOpenDetails(row.id)}>
            <NavigateNextIcon />
          </IconButton>
        </>
      )}
      hideCollapseColumn
    />
  );
};
