import {
  requirementWorFlowActionsArray,
  RequirementWorkFlowActions,
} from 'enums/RequirementWorkFlowActions';
import { CPStatusType } from '../../enums/CPStatusType';
import { CertificateApproverType } from '../../enums/CertificateApproverType';

export type ListExpirationTime = number | 'Never';

export interface Requirement {
  id: number;
  certificatePlanId: number;
  approverType: CertificateApproverType;
  approverTypeLabel: string;
  approverTypeDescription: string;
  approvalGroupName: string;
  isValid: false;
  listCode: string;
  listDescription: string;
  listExtendedDescription: string;
  listFreeTextComment: string;
  listExpirationTime: ListExpirationTime;
  myLearningLink: string;
  myLearningLinkDescription: string;
  externalLink: string;
  externalLinkName: string;
  planDescription: string;
  enrollDate: string;
  approvalDate: string;
  renewalDate: string;
  submitDate: string;
  status: CPStatusType;
  expirationDate: string;
  userActions: RequirementWorkFlowActions[];
  additionalNotesCount: number;
  isAttachmentMandatory: boolean;
}

export const parseSelectedRequirementIds = (
  selectedRequirementIds: string[]
): number[] => selectedRequirementIds.map((row) => Number(row));

export const getSelectedRequirements = (
  requirements: Requirement[],
  selectedRequirementIds: string[]
): Requirement[] => {
  const requirementIds = parseSelectedRequirementIds(selectedRequirementIds);
  return requirements
    .filter((requirement) => requirementIds.includes(requirement.id));
};

export const getRequirementWorkflowActions = (
  requirements: Requirement[],
  selectedRequirementIds: string[]
): RequirementWorkFlowActions[] => {
  const requirementIds = parseSelectedRequirementIds(selectedRequirementIds);
  return requirementIds.length ? 
  requirements
    .filter((requirement) => requirementIds.includes(requirement.id))
    .reduce<RequirementWorkFlowActions[]>(
      (workflowActionsAcc, currentRequirement) =>
        workflowActionsAcc.filter((workflowAction) =>
          currentRequirement.userActions.includes(workflowAction)
        ),
      requirementWorFlowActionsArray      
    ) : []
    ;
};
