import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import { useIsFetching } from 'react-query';

import {
  CertificateFunctionSelectField,
  WorkTypeSelectField,
  DivisionSelectField,
  PGUnitSelectField,
  LevelSelectField,
  ValiditySelectField,
  ActiveBusinessUnitSelectField,
  ScopeSelectField,
  IsExternalType,
  CompanyChannelSelectField,
  ScopeDetailsField,
  mapScopeDetailsFilterParams,
} from 'Dictionary/component';
import { CatalogFiltersFormData } from 'Catalog/model/CatalogFiltersFormData';
import {
  getIsExternal,
  IsExternalSelectField,
} from 'Dictionary/component/IsExternalSelectField/IsExternalSelectField';
import { SearchFiltersForm, SearchFiltersFormProps } from 'components';
import { CatalogSearchFilters } from 'Catalog/model/CatalogSearchFilters';
import { CertificationPlanScopeType } from 'enums/CertificationPlanScopeType';
import { CertificationPlanLevelType } from 'enums/CertificationPlanLevelType';
import { FieldRoot } from 'components/SearchFiltersForm/SearchFiltersForm.style';
import { TrainingSetsSelectField } from 'TrainingSet/component/TrainingSetsSelectField/TrainingSetsSelectField';
import { allBusinessUnitField } from 'Dictionary/component/ActiveBusinessUnitSelectField/ActiveBusinessUnitSelectField';
import { catalogKey } from 'Catalog/query/catalogQuery';

export interface CatalogSearchFiltersFormProps
  extends Pick<
    SearchFiltersFormProps<CatalogFiltersFormData>,
    'renderPageActions' | 'placeholder'
  > {
  onFiltersSubmit: (catalogSearchFilters: CatalogSearchFilters) => void;
  fullScreenList?: boolean;
  displayTrainingSets?: boolean;
}

export const initialValues: CatalogFiltersFormData = {
  searchText: '',
  businessUnit: allBusinessUnitField,
  division: { id: 0, name: 'All Business Areas' },
  level: [
    { id: CertificationPlanLevelType.Basic, name: 'Basic' },
    { id: CertificationPlanLevelType.Expert, name: 'Expert' },
    { id: CertificationPlanLevelType.General, name: 'General' },
    { id: CertificationPlanLevelType.Master, name: 'Master' },
    { id: CertificationPlanLevelType.Trainer, name: 'Trainer' },
  ],
  validity:[],
  scope: { id: CertificationPlanScopeType.AllScopes, name: 'All scopes' },
  certificateFunction: [],
  workType: [],
  productGroups: [],
  isExternal: { id: IsExternalType.All, name: 'All users' },
  channelType: { id: 0, name: 'All channel types' },
  trainingSets: [],
  scopeDetails: [],
};

const formValidationSchema = Yup.object().shape({
  level: Yup.array().min(1, 'Select at least one Level'),
});

export const mapCatalogSearchFilters = (
  catalogFiltersFormData: Partial<CatalogFiltersFormData>
): CatalogSearchFilters => {
  const businessUnit = Number(catalogFiltersFormData?.businessUnit?.id);
  const division = Number(catalogFiltersFormData?.division?.id);
  const scope = Number(catalogFiltersFormData?.scope?.id);
  const channelType = Number(catalogFiltersFormData?.channelType);

  return {
    businessUnitID: !isNaN(businessUnit) ? businessUnit : 0,
    divisionID: !isNaN(division) ? division : 0,    
    scopeID: !isNaN(scope) ? scope : CertificationPlanScopeType.Application,
    channelTypeID: !isNaN(channelType) ? channelType : 0,
    isExternal: getIsExternal(
      catalogFiltersFormData?.isExternal?.id as IsExternalType
    ),
    productGroups: (catalogFiltersFormData?.productGroups ?? []).map((pg) =>
      Number(pg.id)
    ),
    levels: (catalogFiltersFormData?.level ?? []).map((eachLevel) =>
      Number(eachLevel.id)
    ),
    validity: (catalogFiltersFormData?.validity ?? []).map((eachValidity) =>
      Number(eachValidity.id)
    ),
    certificateFunction: (
      catalogFiltersFormData?.certificateFunction ?? []
    ).map((eachFunction) => Number(eachFunction.id)),
    workType: (
      catalogFiltersFormData?.workType ?? []
    ).map((work) => Number(work.id)),
    certificateTitle: catalogFiltersFormData.searchText ?? '',
    excludePrerequisites: false,
    trainingSets: (catalogFiltersFormData?.trainingSets ?? []).map((set) =>
      Number(set.id)
    ),
    countries: [0],
    certificateList: [],
    scopeDetails: (catalogFiltersFormData?.scopeDetails ?? []).map((field) =>
      Number(field.id)
    ),
  };
};

export const CatalogSearchFiltersForm: FC<CatalogSearchFiltersFormProps> = ({
  onFiltersSubmit,
  renderPageActions,
  fullScreenList = true,
  displayTrainingSets = false,
  placeholder,
}) => {
  const fetchingKeys = useIsFetching(catalogKey);
  const lgValue = fullScreenList ? 4 : 12;
  const handleOnFiltersSubmit: SearchFiltersFormProps<CatalogFiltersFormData>['onSubmit'] = (
    values
  ) => onFiltersSubmit(mapCatalogSearchFilters(values));

  return (
    <SearchFiltersForm
      placeholder={placeholder ?? "Search by certificate title, MyLearning course code or expand the filter on the right"}
      loading={!!fetchingKeys}
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      renderPageActions={renderPageActions}
      onSubmit={handleOnFiltersSubmit}
    >
      {({ values, setFieldValue }) => {
        const handleOnDivisionChange = () => {
          setFieldValue('businessUnit', initialValues.businessUnit);
          setFieldValue('trainingSets', initialValues.trainingSets);
          setFieldValue('scopeDetails', initialValues.scopeDetails);
          setFieldValue('productGroups', initialValues.productGroups);
        }
        const handleOnBusinessUnitChange = () => {
          setFieldValue('trainingSets', initialValues.trainingSets);
          setFieldValue('scopeDetails', initialValues.scopeDetails);
          setFieldValue('productGroups', initialValues.productGroups);
        };
        const handleOnScopeChange = () =>
          setFieldValue('scopeDetails', initialValues.scopeDetails);
        return (
          <Grid container>
            <Grid item xs={12} md={12} lg={lgValue}>
              <FieldRoot>
                <DivisionSelectField name="division" 
                  defaultOptions={[initialValues.division]}  
                  onFieldChange={handleOnDivisionChange}
                />
              </FieldRoot> 
              <FieldRoot>
                <ActiveBusinessUnitSelectField
                  name="businessUnit"
                  label="Division"
                  defaultOptions={[allBusinessUnitField]}
                  onFieldChange={handleOnBusinessUnitChange}
                  disableClearable
                  divisionID={Number(values.division?.id ?? 0)}
                  required={false}
                />
              </FieldRoot>
              <FieldRoot>
                <PGUnitSelectField 
                  label="Product group"
                  name="productGroups" 
                  businessUnitID={Number(values.businessUnit.id)}
                  disabled={
                    !values.businessUnit.id
                  }
                  multiple={true}
                  helperText="Depends on selected Division" 
                />
              </FieldRoot> 
              <FieldRoot>
                <LevelSelectField name="level" />
              </FieldRoot>              
            </Grid>
            <Grid item xs={12} md={12} lg={lgValue}>              
              <FieldRoot>
                <IsExternalSelectField name="isExternal" disableClearable required={false} />
              </FieldRoot>
              <FieldRoot>
                <ScopeSelectField
                  name="scope"
                  onFieldChange={handleOnScopeChange}
                  disableClearable
                  required={false}
                />
              </FieldRoot>
              <FieldRoot>
                <ScopeDetailsField
                  label="Scope details"
                  name="scopeDetails"
                  filterParams={mapScopeDetailsFilterParams(
                    values.businessUnit,
                    values.scope
                  )}
                  helperText="Depends on selected Division and Scope"
                />
              </FieldRoot>              
              <FieldRoot>
                <WorkTypeSelectField name="workType" />
              </FieldRoot>
            </Grid>
            <Grid item xs={12} md={12} lg={lgValue}>
              <FieldRoot>
                <CertificateFunctionSelectField name="certificateFunction" />
              </FieldRoot>
              <FieldRoot>
                <ValiditySelectField name="validity" />
              </FieldRoot>              
              <FieldRoot>
                <CompanyChannelSelectField
                  name="channelType"
                  defaultOptions={[initialValues.channelType]}
                  disableClearable
                  required={false}
                />
              </FieldRoot>
              {displayTrainingSets && (
                <FieldRoot>
                  <TrainingSetsSelectField
                    label="Training sets"
                    name="trainingSets"
                    buId={Number(values.businessUnit.id)}
                    disabled={
                      values.businessUnit.id === allBusinessUnitField.id
                    }
                    helperText="Select a specific Division first"
                  />
                </FieldRoot>
              )}              
            </Grid>
          </Grid>
        );
      }}
    </SearchFiltersForm>
  );
};
