import { useSnackbar } from 'notistack';

export interface ToastsProviderResults {
  showToast: (message: string) => void;
  showError: (error: string) => void;
  showMany: (message: string, errors: string[], isError: boolean) => void;
  showWarning: (warning: string) => void;
  showInfo: (info: string) => void;
  showSuccess: (success: string) => void;
  closeAll: () => void;
}

export const useToasts = (): ToastsProviderResults => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return {
    showToast: (message) => enqueueSnackbar(message),
    showError: (error) => enqueueSnackbar(error, { variant: 'error', persist: true, style: {whiteSpace: 'pre-line'} }),
    showMany: (message, details, isError) => enqueueSnackbar(message, { variant: "multipleMessages", messages: details, isError, persist: true, style: {whiteSpace: 'pre-line'} }),
    showWarning: (warning) => enqueueSnackbar(warning, { variant: 'warning', persist: true }),
    showInfo: (info) => enqueueSnackbar(info, { variant: 'info', persist: true }),
    showSuccess: (success) => enqueueSnackbar(success, { variant: 'success', persist: true }),
    closeAll: () => closeSnackbar(),
  };
};
