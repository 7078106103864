import { useQuery } from 'react-query';

import { trainingSetApi } from 'TrainingSet/service/trainingSet.api';
import { TrainingSet } from 'TrainingSet/model/TrainingSet';

export const trainingSetsKey = 'training-set';
export const useTrainingSetsQuery = (buId: number) =>
  useQuery<TrainingSet[]>(
    [trainingSetsKey, buId],
    () => trainingSetApi.getList(buId),
    {
      enabled: false,
    }
  );
