import { FC, ReactNodeArray, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { useTabsViewStyles } from './TabsView.style';

export interface TabsViewProps {
  disabled?: boolean;
  labels: string[];
  animateHeight?: boolean;
  tabIndex?: number;
  onChange?: (tabIndex: number) => void;
  renderTabs?: () => ReactNodeArray;
}

export const TabsView: FC<TabsViewProps> = (props) => {
  const {
    tabIndex: tabIndexProp,
    onChange,
    labels,
    renderTabs,
    disabled = false,
    animateHeight = true,
    children,
  } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useTabsViewStyles(props);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    handleChangeIndex(newValue);
  };

  const handleChangeIndex = (index: number) => {
    if (onChange) {
      onChange(index);
    } else {
      setTabIndex(index);
    }
  };
  const index = tabIndexProp ?? tabIndex;
  return (
    <>
      <Tabs
        className={classes.tabs}
        value={index}
        onChange={handleChange}
        disabled={disabled}
      >
        {labels.map((label, i) => (
          <Tab style={{minWidth: 135}} key={i} label={label} />
        ))}
      </Tabs>
      <div className={classes.content}>
        <SwipeableViews
          style={{ minHeight: 170 }}
          disabled={disabled}
          index={index}
          onChangeIndex={handleChangeIndex}
          animateHeight={animateHeight}
          containerStyle={{
            height: animateHeight ? undefined : "100%",
            WebkitOverflowScrolling: 'touch',
          }}
        >
          {renderTabs ? renderTabs() : children}
        </SwipeableViews>
      </div>
    </>
  );
};

export default TabsView;
