import { makeStyles } from '@material-ui/core';
import { AbbTheme } from 'theme/createAbbTheme';

const mobileQuery = '@media (max-width: 1024px)';

export const useContentStyles = makeStyles(
  //@ts-ignore
   (theme: AbbTheme) => ({
    header: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      
      [mobileQuery]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    headerContent: {
      maxWidth: '75%'
    },
    content: {
      margin: theme.spacing(4,0),
    },
    subHeader: {
      display: 'inline-block',
      margin: theme.spacing(1.25, 0, 0.25),
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitle: {
      color: theme.palette.grey[400],
      marginRight: theme.spacing(2),
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      [mobileQuery]: {
        margin: theme.spacing(2, 0),
      },
    },
    grow: {
      flexGrow: 1,
      [mobileQuery]: {
        display: 'none',
      },
    },
  }),
  { name: 'Content' }
);
