import React from 'react';
import {
    Button, Typography,
    Stack
} from 'components';
import { useField } from 'formik';
import { Alert } from 'components/Alert';

export function ImageField(p: { fieldName: string; }) {
    const [field, meta, helpers] = useField({ name: p.fieldName });

    function onFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.currentTarget.files || !e.currentTarget.files[0])
            return;

        const FR = new FileReader();

        FR.addEventListener("load", (evt) => {
            imageRef.current!.src = evt.target!.result as string;
        });

        FR.readAsDataURL(e.currentTarget.files[0]);

        const FRBinary = new FileReader();

        FRBinary.addEventListener("load", (evt) => {
            const bytes = new Uint8Array(evt.target!.result as ArrayBuffer);

            const len = bytes.byteLength;
            let binary = '';
            for (let i = 0; i < len; i++)
                binary += String.fromCharCode(bytes[i]);

            helpers.setValue(btoa(binary));
        });

        FRBinary.readAsArrayBuffer(e.currentTarget.files[0]);
    }

    const imageRef = React.useRef<HTMLImageElement>(null);
    const fileRef = React.useRef<HTMLInputElement>(null);

    return (<Stack direction='row'>
        <Stack>
            <Button onClick={() => fileRef.current!.click()} color="secondary">
                Upload file
            </Button>
            <Typography variant="caption">Acceptable file types (jpg, jpeg, png). File size limit 10KB.</Typography>
            <input hidden ref={fileRef} name="signature" type="file" accept="image/png, image/jpeg" onChange={e => onFileSelected(e)} />
        </Stack>

        <div style={{ flexGrow: 1, display: "flex", border: "1px solid rgba(0,0,0,0.34)", padding: 15, justifyContent: "center" }}>
            <div hidden={!meta.error}>
                <Alert severity="error" variant='outlined'>This field is required</Alert>
            </div>
            <img id="img"
                alt="Signature"
                ref={imageRef}
                style={{ display: !meta.error ? "block" : "none", maxWidth: 300, maxHeight: 200, minHeight: 70, width: "auto", height: "auto" }}
                src={`data:image;base64,${field.value ?? "R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="}`} />
        </div>

    </Stack>);

}
