import { FC, useEffect } from 'react';

import { useApproversQuery } from '../../query/dictionaryQuery';
import { FormikAutocompleteField, FormikAutocompleteFieldProps } from '../../../components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { mapToAutocompleteFieldItems } from '../../model/Approver';

export interface ApproverSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {}

export const ApproverSelectField: FC<ApproverSelectFieldProps> = ({
  name,
  disabled = false,
}) => {
  const { data = [], isLoading, isFetching, refetch } = useApproversQuery();

  useEffect(() => {
    refetch();
  }, []);

  return (
    <FormikAutocompleteField
      name={name}
      label="Approver"
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading || isFetching}
    />
  );
};
