import styled from 'styled-components';
import Toolbar, { ToolbarProps } from '@material-ui/core/Toolbar';
import TableRow, { TableRowProps } from '@material-ui/core/TableRow';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

export const StyledToolbar = styled(Toolbar)<
  ToolbarProps & { $outerBorder: boolean; $isSelected: boolean }
>`
  display: flex;
  background-color: ${({ $isSelected, theme }) =>
    $isSelected
      ? theme.palette.shades?.red05_t
      : theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  border-left: ${({ $outerBorder }) => !$outerBorder && 'none'};
  border-right: ${({ $outerBorder }) => !$outerBorder && 'none'};
  && {
    min-height: 60px;
    box-sizing: border-box;
    padding: ${({ theme }) => theme.spacing(0, 0.75)};
  }
`;

export const ToolbarActionsRoot = styled.div`
  margin-left: auto;
`;

interface StyledTableRowProps extends TableRowProps {
  $outerBorder: boolean;
}

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
  && {
    ${({ $outerBorder }) => !$outerBorder && 'border: none;'}
  }
`;

export const DataTableRow = styled(StyledTableRow)<
  StyledTableRowProps & { $isSelected: boolean; $isExpanded: boolean }
>`
  background-color: ${({ $isSelected, theme }) =>
    $isSelected
      ? theme.palette.shades?.red05_t
      : theme.palette.background.default};
  && {
    border-top: none;
    border-bottom: ${({ $isExpanded }) => $isExpanded && 'none'};
  }
`;

export const HeaderTableRow = styled(StyledTableRow)<
  StyledTableRowProps & { $headerOnTop: boolean; }
>`
  background-color: ${({ $headerOnTop, theme }) => $headerOnTop && theme.palette.background.default};
  && {
    border: 1px solid ${({ $headerOnTop, theme }) => $headerOnTop ? theme.palette.grey[200] : 'transparent'};
    border-top: none;
  }
`;

export const RowActionsRoot = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CollapseTableCell = styled(TableCell)<TableCellProps>`
  && {
    padding: 0;
    border: none;
  }
`;

export const DataTableCell = styled(TableCell)<
  TableCellProps & { $isExpanded: boolean }
>`
  && {
    border-bottom: ${({ $isExpanded }) => $isExpanded && 'none'};
  }
`;

export const CollapseTableRow = styled(StyledTableRow)<
  StyledTableRowProps & { $isExpanded: boolean }
>`
  && {
    border-top: none;
    border-bottom: ${({ $isExpanded }) => !$isExpanded && 'none'};
    background-color: ${({ theme }) => theme.palette.background.default};
  }
`;

export const ToolbarTypography = styled(Typography)<TypographyProps>`
  padding: ${({ theme }) => theme.spacing(0, 2.5)};
  && {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`;

export const NoDataMessageWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;
