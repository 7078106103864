import { useState } from 'react';
import {
    Typography,
    DialogContent,
    TextField
} from '@material-ui/core';

import { RoundButton } from 'components';
import { SearchUserForMassEnrollment } from 'MassEnrollment/component/SearchUserForMassEnrollment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';


export interface MassEnrollmentDialogProps {
    isDialogOpen: boolean,
    selectedProfiles: string[],
    isReminder: boolean,
    onClose: React.DispatchWithoutAction
    onDoSend: React.Dispatch<MassEnrollmentDialogState>
}

export interface MassEnrollmentDialogState {
    from: string,
    subject: string,
    message: string,
    recipients: string
}

const initialState = {
    from: "PL-PCS2@abb.com",
    subject: "IMPORTANT: Additional information regarding your Certificate",
    message: "",
    recipients: ""
}

export const MassEnrollmentDialog = (props: MassEnrollmentDialogProps) => {

    const {
        isDialogOpen,
        selectedProfiles,
        onClose,
        onDoSend,
        isReminder
    } = props;

    const [state, setState] = useState<MassEnrollmentDialogState>(initialState);
    if (!isDialogOpen) return (<></>)

    const handleOnEnrollWithMessage = () => {
        onDoSend(state);
        closeDialog();
    }

    const closeDialog = () => {
        onClose();
        reset();
    };

    const reset = () => setState(initialState);

    return (
        <>
            <Dialog open={isDialogOpen} onClose={closeDialog}>
                <DialogTitle id="form-dialog-title">Enroll with customized notification</DialogTitle>
                <DialogContent>
                    <TextField label="From:" disabled={true} value={state.from} />
                    <TextField multiline={true} style={{ height: 'auto' }} label="To:" disabled={true} value={selectedProfiles.join(", ")} />
                    <SearchUserForMassEnrollment label="Send copy of summary email also to:" onChanged={(x) => setState({ ...state, recipients: x.map(r => r.email).join(", ") })} />
                    <TextField label="Subject:" onChange={(event) => setState({ ...state, subject: event.target.value })} value={state.subject} />
                    <TextField label="Your Message:" onChange={(event) => setState({ ...state, message: event.target.value })} value={state.message} multiline rows={4} />
                    <Typography variant="caption">
                        {isReminder ? "External users will not receive the notification." 
                        : "External users will be enrolled to the Certificates but will not receive the notification."}
                    </Typography>
                    <Typography variant="caption" display="block">
                        Please note that the recipients will also receive information related to the enrolled Certificates.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <RoundButton onClick={closeDialog} color="primary">
                        Cancel
                    </RoundButton>
                    <RoundButton color="secondary" onClick={handleOnEnrollWithMessage}>
                        {isReminder ? "SEND REMINDER" : "SEND AND ENROLL"}
                    </RoundButton>
                </DialogActions>
            </Dialog>
        </>
    )
}