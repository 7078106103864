import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Paper, { PaperProps } from '@material-ui/core/Paper';

export const Root = styled(Paper)<PaperProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  max-height: 80%;
  padding: ${({ theme }) => theme.spacing(2)}px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
`;

export const HeaderRoot = styled.div`
  margin: ${({ theme }) => theme.spacing(1, 0, 3)};
`;

export const ActionsRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const ContentRoot = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 0)};
`;

export const HeaderTypography = styled(Typography)<TypographyProps>`
  && {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`;
