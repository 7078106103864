import { makeStyles } from '@material-ui/core';
import { AbbTheme } from 'theme/createAbbTheme';

export const useTabsViewStyles = makeStyles((theme: AbbTheme) => ({
    tabs:{
        borderBottom: `1px solid ${theme.palette.divider}`,
        minWidth: '130px'
    },
    content: {
        margin: theme.spacing(3,0)
    }
}), {
  name: 'TabsView',
});
