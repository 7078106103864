import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface BusinessUnit {
  id: number;
  description: string;
}

export const mapToAutocompleteFieldItems = (
  businessUnits: BusinessUnit[]
): AutocompleteFieldItem[] => businessUnits.map(toAutocomplateField);

export const toAutocomplateField = (
  businessUnit: BusinessUnit | null
): AutocompleteFieldItem => ({
  ...businessUnit,
  id: businessUnit?.id ?? 0,
  name: businessUnit?.description ?? '',
});
