import { reportsApi } from 'Reports/service/reports.api';
import { ReportBasePage } from '../../Reports/components/ReportBasePage';
import { ReportPageTypes } from '../../Reports/model/ReportPageTypes';

export const UncompletedRequirementsPage = () => (
  <ReportBasePage
    reportType={ReportPageTypes.UncompletedRequirements}
    reportApi={reportsApi.getUncompletedRequirements}
    reportToFileApi={reportsApi.getUncompletedRequirementsToFile}
  />
);
