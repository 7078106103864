import { useState, forwardRef, useCallback } from "react";
import clsx from "clsx";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import { Card, CardActions, Collapse, IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { CheckCircle, Report } from '@material-ui/icons';
import { AbbTheme } from 'theme/createAbbTheme';

const useStyles = makeStyles(
  //@ts-ignore
  (theme: AbbTheme) => ({
  root: {
    minWidth: 300,
  },
  card: {
    width: "100%",    
    borderRadius: 4,
  },
  error: {
    border: `1px solid ${theme.palette.red?.main}`,    
    backgroundColor: `${theme.palette.red?.light} !important`,    
  },
  success: {
    border: `1px solid ${theme.palette.green?.main}`,    
    backgroundColor: `${theme.palette.green?.light} !important`,    
  },
  typography: {
    color: `${theme.palette.grey[600]} !important`,
    fontWeight: theme.typography.fontWeightBold,
  },
  actionRoot: {
    justifyContent: "space-between"
  },  
  paper: {
    padding: "8px 16px",
    borderRadius: 0,
    borderBottom: 0,
    borderRight: 0,
    borderLeft: 0,    
    fontSize: 14
  },
  iconExpand: {
    transform: "rotate(0deg)",
    transition: "all .2s"
  },
  iconExpandOpen: {
    transform: "rotate(180deg)"
  },
  iconError:{
    padding: 4,
    color: theme.palette.red?.main
  },
  iconSuccess:{
    padding: 4,
    color: theme.palette.green?.main
  },
}));

declare module "notistack" {
  interface VariantOverrides {
    multipleMessages: {
      messages: string[],
      isError: boolean
    };
  }
}

interface MultipleMessagesSnackbarProps extends CustomContentProps {
  messages: string[],
  isError: boolean
}

export const MultipleMessagesSnackbar = forwardRef<HTMLDivElement, MultipleMessagesSnackbarProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    
    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleClose = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Card className={clsx(classes.card, props.isError ? classes.error : classes.success) }>
          <CardActions classes={{ root: classes.actionRoot }}>
            {props.isError
            ? <Report className={classes.iconError} />     
            : <CheckCircle className={classes.iconSuccess} />
            }
                <Typography variant="body2" className={classes.typography}>
                  {props.message}
                </Typography>
            <div>
              <IconButton
                aria-label="Show more"
                size="small"
                className={clsx(classes.iconExpand, {
                  [classes.iconExpandOpen]: expanded
                })}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Paper className={clsx(classes.paper, props.isError ? classes.error : classes.success)}>
              { Array.isArray(props.messages) && props.messages.length ? props.messages.map((msg, i) => (
                <div key={i} style={{ padding: "4px 0"}}>
                  {msg}
                </div>
              )) : '...' }              
            </Paper>
          </Collapse>
        </Card>
      </SnackbarContent>
    );
  }
);