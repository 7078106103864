import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core/styles';
import {
  PaletteOptions,
  PaletteColorOptions,
  Palette,
  PaletteColor,
} from '@material-ui/core/styles/createPalette';
import { Overrides } from '@material-ui/core/styles/overrides';

export interface Shades {
  gray06_t: string;
  gray05_t: string;
  red05_t: string;
  red10_t: string;
  red80_t: string;
  red1_10: string;
}
export interface AbbPalette extends Palette {
  blue?: PaletteColor;
  green?: PaletteColor;
  yellow?: PaletteColor;
  red?: PaletteColor;
  orange?: PaletteColor;
  shades?: Shades;
}
export interface AbbTheme extends Theme {
  palette: AbbPalette;
}
export interface AbbPaletteOptions extends PaletteOptions {
  blue?: PaletteColorOptions;
  green?: PaletteColorOptions;
  yellow?: PaletteColorOptions;
  orange?: PaletteColorOptions;
  red?: PaletteColorOptions;
  shades?: Shades;
}
export interface AbbOverrides extends Overrides {
  /* eslint-disable no-alert, @typescript-eslint/no-explicit-any */
  MuiPickersToolbar?: any;
  MuiPickersCalendarHeader?: any;
  MuiPickersDay?: any;
  MuiAutocomplete?: any;
  MuiAlert?: any;
  MuiDropzoneArea?: any;
  MuiDropzonePreviewList?: any;
  /* eslint-enable no-alert, @typescript-eslint/no-explicit-any */
}
export interface AbbThemeOptions extends ThemeOptions {
  palette: AbbPaletteOptions;
  overrides: AbbOverrides;
}

export function createAbbTheme(options: AbbThemeOptions): AbbTheme {
  const themeBase = createMuiTheme({
    ...options,
    palette: {
      ...options.palette,
    },
  }) as Theme;

  return themeBase;
}
