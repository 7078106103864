import { FC } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { RequirementAttachmentList } from 'Attachment/component/RequirementAttachmentList/RequirementAttachmentList';
import { useStyles } from './CertificationRequirementAttachments.style';

export interface CertificationRequirementAttachmentsProps {
  requirementId: number;
  userId: number;
  onAttachmentSuccess: {() : void} | null;
}

export const CertificationRequirementAttachments: FC<
  CertificationRequirementAttachmentsProps
> = ({
  requirementId,
  userId,
  onAttachmentSuccess
}) => {
  const classes = useStyles();
  const theme = useTheme();


  return (
    <>
      <Typography className={classes.title} style={{ fontWeight: theme.typography.fontWeightBold }}>
        Attachments
      </Typography>
      <div className={classes.title} >
        <RequirementAttachmentList userId={userId.toString()} requirementId={requirementId.toString()} 
          onAttachmentSuccess={onAttachmentSuccess} />
      </div>
    </>
  );
};
