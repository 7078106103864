import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';

import { useUserAuthorizationsQuery } from 'MyProfile/query/myProfile.query';
import { RolesList } from 'MyProfile/component/RolesList';
import { mapUserAuthorizationToRoles } from 'MyProfile/model/UserAuthorization';
import { Card } from 'components/Page';

export interface UserRolesListProps {
  userId: number;
  removable: boolean;
}

export const UserRolesList: FC<UserRolesListProps> = ({
  userId,
  removable,
}) => {
  const { data, isLoading } = useUserAuthorizationsQuery(userId);

  const renderList = () =>
    (data ?? []).length ? (
      <Card transparent>
        <RolesList
          roles={mapUserAuthorizationToRoles(data ?? [])}
          userId={userId}
          removable={removable}
        />
      </Card>
    ) : (
      <Card transparent>User got no roles</Card>
    );

  return (
    <Card header="User roles" transparent>
      {isLoading ? <Skeleton width={200} height={32} /> : renderList()}
    </Card>
  );
};
