import { useQuery, UseQueryOptions } from 'react-query';

import { MyProfile } from 'MyProfile/model/MyProfile';
import { myProfileApi } from 'MyProfile/service/myProfile.api';
import { mapPaginationDto, PaginatedList, Pagination, Sort } from 'model';
import { UserSearchFilters } from 'MyProfile/model/UserSearchFilters';
import { UserDeactivatedSearchFilters } from 'MyProfile/model/UserDeactivatedSearchFilters';
import { UserHrgtSearchFilters } from 'MyProfile/model/UserHrgtSearchFilters';
import { UserAuthorization } from 'MyProfile/model/UserAuthorization';
import { twentyFourHoursInMs } from 'service/queryClient';

export const myProfileKey = 'my-profile';
export const useMyProfileQuery = () =>
  useQuery<MyProfile>(myProfileKey, myProfileApi.get, {
    refetchOnMount: false,
    staleTime: twentyFourHoursInMs,
  });

export const userProfileKey = 'user-profile';
export const useUserProfileQuery = (userId: string) =>
  useQuery<MyProfile>([userProfileKey, userId], () =>
    myProfileApi.getUserProfile(userId)
  );

export const userKey = 'user';
export const useUserQuery = (
  pagination: Pagination,
  sort: Sort<MyProfile>,
  filters: UserSearchFilters,
  options: UseQueryOptions<PaginatedList<MyProfile>>
) =>
  useQuery<PaginatedList<MyProfile>>(
    [userKey, mapPaginationDto(pagination), sort, filters],
    () => myProfileApi.search(mapPaginationDto(pagination), sort, filters),
    options
  );

export const userDeactivatedKey = 'user-deactivated';
export const useUserDeactivatedQuery = (
  pagination: Pagination,
  filters: UserDeactivatedSearchFilters,
  options: UseQueryOptions<PaginatedList<MyProfile>>
) =>
  useQuery<PaginatedList<MyProfile>>(
    [userDeactivatedKey, mapPaginationDto(pagination), filters],
    () => myProfileApi.searchDeactivated(mapPaginationDto(pagination), filters),
    options
  );

export const userHrgtKey = 'user-hrgt';
export const useUserHrgtQuery = (
  pagination: Pagination,
  sort: Sort<MyProfile>,
  filters: UserHrgtSearchFilters,
  options: UseQueryOptions<PaginatedList<MyProfile>>
) =>
  useQuery<PaginatedList<MyProfile>>(
    [userHrgtKey, mapPaginationDto(pagination), sort, filters],
    () => myProfileApi.searchHrgt(mapPaginationDto(pagination), sort, filters),
    options
  );

export const userAuthorizationsKey = 'user-authorizations';
export const useUserAuthorizationsQuery = (userId: number) =>
  useQuery<UserAuthorization[]>([userAuthorizationsKey, userId], () =>
    myProfileApi.getAuthorizations(userId)
  );
