import React, { FC } from 'react';

import { useEmployeeJobFamilyQuery } from 'Dictionary/query/dictionaryQuery';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface EmployeeJobFamilySelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {
  name: string;
  disabled?: boolean;
  required?: boolean;
}

export const EmployeeJobFamilySelectField: FC<EmployeeJobFamilySelectFieldProps> = ({
  name,
  disabled = false,
  required = false,
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = useEmployeeJobFamilyQuery();

  return (
    <FormikAutocompleteField
      name={name}
      label="Employee job family"
      disabled={disabled}
      options={data}
      loading={isLoading}
      onOpen={() => refetch()}
      required={required}
      multiple
      {...otherProps}
    />
  );
};
