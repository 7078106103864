import { CertificationPlanLevelType } from 'enums/CertificationPlanLevelType';
import { TaskStatus } from '../../Dictionary/model/TaskStatus';
import { ObjectType } from '../../Dictionary/model/TaskType';
import { PendingApprovalsFormData } from './PendingApprovalsFormData';
import { CertificationPlanScopeType } from '../../enums/CertificationPlanScopeType';
import { PendingApprovalsQuery } from './PendingApprovalsQuery';
import {
  getIsExternal,
  IsExternalType,
} from '../../Dictionary/component/IsExternalSelectField/IsExternalSelectField';
import { authProvider } from '../../service/authProvider';

export const getPendingApprovalsQuery = (
  formData: PendingApprovalsFormData
): PendingApprovalsQuery => {
  const businessUnit = Number(formData?.businessUnit?.id);
  const scope = Number(formData?.scope?.id);
  return {
    impersonateUserName: (formData.impersonateUserName.id as string) ?? '',
    taskStatus: formData.taskStatus.id as TaskStatus,
    taskType: formData.taskType.id as ObjectType,
    createDateAfter: formData.createdDateAfter,
    createDateBefore: formData.createdDateBefore,
    peopleFilter: {
      countryList: formData.peopleCountries.map((x) => x.id as number),
      userJobList: formData.peopleJobList.map((x) => x.id as number),
      userJobFamilyList: formData.peopleJobFamilyList.map((x) => x.id as number),
      companyList: formData.peopleCompanies.map((x) => x.id as number),
      isExternal:
        getIsExternal(formData?.isExternalPeople?.id as IsExternalType),
      businessUnitId: formData.peopleBusinessUnit.id as number,
      divisionId: formData.peopleDivision.id as number,
      productGroupList: formData.peopleProductGroups.map(x => x.id as number),
      personName: formData.searchText ?? '',
    },
    certificateFilter: {
      businessUnitID: !isNaN(businessUnit) ? businessUnit : 0,
      divisionId: formData.division.id as number,
      productGroups: formData.productGroups.map((x) => x.id as number),
      scopeID: !isNaN(scope) ? scope : 0,
      channelTypeID: 0,
      isExternal:
        getIsExternal(formData?.isExternal?.id as IsExternalType),
      countries: [],
      levels: formData.levels.map((x) => x.id as number),
      jobRoles: [],
      certificateFunction: formData.certificateFunction.map(
        (x) => x.id as number
      ),
      workType: formData.workType.map(
        (x) => x.id as number
      ),
      scopeDetails: formData.scopeDetails.map(x => x.id as number),
      certificateTitle: formData.certificateTitle ?? '',
      certificateList: [], // not required
    },
  };
};

export const getEmptyPendingApprovalsQuery = (): PendingApprovalsQuery => ({
  impersonateUserName: authProvider.getAccount()?.userName ?? '',
  taskStatus: TaskStatus.Open,
  taskType: ObjectType.All,
  createDateAfter: null,
  createDateBefore: null,
  peopleFilter: {
    countryList: [],
    userJobList: [],
    userJobFamilyList: [],
    companyList: [],
    isExternal: null,
    businessUnitId: 0,
    divisionId: 0,
    productGroupList: [],
    personName: '',
  },
  certificateFilter: {
    businessUnitID: 0,
    divisionId: 0,
    productGroups: [],
    scopeID: 0,
    channelTypeID: 0,
    isExternal: null,
    countries: [],
    levels: [
      CertificationPlanLevelType.Basic,
      CertificationPlanLevelType.Expert,
      CertificationPlanLevelType.General,
      CertificationPlanLevelType.Master,
      CertificationPlanLevelType.Trainer
    ],
    jobRoles: [],
    certificateFunction: [],
    workType: [],
    scopeDetails: [],
    certificateTitle: '',
    certificateList: [],
  },
});
