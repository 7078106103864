import { useEffect, useState } from 'react';
import { useField } from 'formik';
import * as API from 'Dictionary/service/dictionary.api';
import { UserDetails } from 'model';
import { useQuery } from 'react-query';
import { AutocompleteField } from 'components/Field';

export const SignerSelectField = () => {
    const searchTimeout = 100;
    const minLenOfSearchValue = 1;
    const initialValue = "";

    const [field, meta, helpers] = useField({ name: "signer" });
    const [searchFor, setSearchFor] = useState(initialValue);
    const { data = [], isLoading, isFetching, refetch } = useQuery<UserDetails[]>(
        ["digitalSignature-signers"],
        () => API.dictionaryApi.digitalSignature.findSigner({ byText: searchFor }),
        { enabled: false, useErrorBoundary: false }
    );

    useEffect(() => {
        if (initialValue !== searchFor && searchFor.length >= minLenOfSearchValue) {
            const timer = setTimeout(refetch, searchTimeout);
            return () => clearTimeout(timer);
        }
        return undefined;
    }, [searchFor]);


    const selectedSigner = (field.value ? {
        id: field.value.id,
        group: field.value.email,
        name: field.value.email,
    } : undefined);

    const signersMapper = data.map(user => ({
        id: user.id,
        group: user.email,
        name: user.email,
    }));
    const signers = selectedSigner ? [...signersMapper, selectedSigner] : signersMapper;

    return (
        <AutocompleteField
            label="Signer"
            defaultValue={selectedSigner}
            options={signers}
            multiple={false}
            error={!!meta.error}
            helperText={meta.error}
            loading={isLoading || isFetching}
            onInputChange={(event, eventValue) => setSearchFor(eventValue)}
            onChange={(e, v) => helpers.setValue(v)} />
    );
};
