import { FC, useEffect, useState } from 'react';

import { useFunctionalManagerQuery } from 'Dictionary/query/dictionaryQuery';
import { FormikAutocompleteField } from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { mapToAutocompleteFieldItems } from 'MyProfile/model/MyProfile';

export interface FunctionalManagerSelectFieldProps {
  name: string;
  initialValue?: string;
  disabled?: boolean;
}

const searchTimeout = 1000;
const minLenOfSearchValue = 3;

export const FunctionalManagerSelectField: FC<FunctionalManagerSelectFieldProps> = ({
  name,
  initialValue,
  disabled = false,
}) => {
  const [value, setValue] = useState('');
  const { data = [], isLoading, isFetching, refetch } = useFunctionalManagerQuery(value);

  useEffect(() => {
    if (initialValue !== value && value.length >= minLenOfSearchValue) {
      const timer = setTimeout(refetch, searchTimeout);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [value]);

  return (
    <FormikAutocompleteField
      name={name}
      label="Functional Manager"
      helperText="Optional, for information purposes only"
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading || isFetching}
      onInputChange={(event, eventValue) => setValue(eventValue)}      
      filterOptions={(options) => options}
    />
  );
};
