import React, { FC } from 'react';

import { useBusinessUnitsQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'Dictionary/model/BusinessUnit';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface BusinessUnitSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'label' | 'options'> {
  name: string;
  removePgUnitValue?: () => void;
  disabled?: boolean;
  divisionID?: number;
}

export const BusinessUnitSelectField: FC<BusinessUnitSelectFieldProps> = ({
  name,
  removePgUnitValue,
  disabled = false,
  divisionID, 
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = useBusinessUnitsQuery(Number(divisionID ?? 0));

  return (
    <FormikAutocompleteField
      name={name}
      label="Division"
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading}
      onOpen={() => refetch()}
      onFieldChange={removePgUnitValue}
      required
      {...otherProps}
    />
  );
};
