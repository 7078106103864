import { makeStyles } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridPageChangeParams,
  GridRowParams,
  GridSortDirection,
  GridSortModelParams,
} from '@material-ui/data-grid';
import { ElementType, useMemo } from 'react';
import { AbbTheme } from 'theme/createAbbTheme';
import { certificationPlansDetailsRoute } from 'route';
import {
  ColumnDefinition,
  ReportPageTypes,
  ReportQueryResponseFlat,
  ReportSortQuery,
} from '../../model';
import { ColumnsPanel } from './ColumnsPanel';
import { GridPanel } from './GridPanel';
import { GridContext } from './GridContext';
import { CustomColumnMenu } from './CustomColumnMenu';

export interface ReportDataGridProps {
  reportType: ReportPageTypes;
  columnsDefinitions: ColumnDefinition[];
  columns?: GridColDef[];
  page: number;
  pageSize: number;
  loading: boolean;
  report: ReportQueryResponseFlat;
  sort?: ReportSortQuery;
  onPageChange?: (param: GridPageChangeParams) => void;
  onPageSizeChange?: (param: GridPageChangeParams) => void;
  onSortModelChange: (params: GridSortModelParams) => void;
  onGroupByChange?: ((cid: string | null) => void) | null;
  onVisibleColumnsChange?: (columns: string[], allColumns: boolean) => void;
}

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      '& .MuiDataGrid-colCellTitle': {
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.grey[600],
      },
      '& .MuiDataGridPanel-root': {
        zIndex: theme.zIndex.tooltip,
      },
    },
  }),
  { name: 'ReportDataGrid' }
);

const rowHeight = 42;

export const ReportDataGrid = (props: ReportDataGridProps) => {
  const {
    page,
    pageSize,
    report,
    loading,
    sort,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    onGroupByChange,
    onVisibleColumnsChange,
    reportType,
    columnsDefinitions,
    columns,
  } = props;
  const classes = useStyles(this);
  const sortModel = useMemo(
    () =>
      sort
        ? [
            {
              field: String(sort.column),
              sort: (sort.direction ? 'asc' : 'desc') as GridSortDirection,
            },
          ]
        : [],
    [sort]
  );
  const total = report?.total ?? 0;
  const rows = report?.data ?? [];
  const pageSizeSafe = total < pageSize ? total : pageSize;
  const handleRowClick = (param: GridRowParams) => {
    const certId = param?.getValue('id');
    if (param?.id) {
      const pathName = certificationPlansDetailsRoute.createRoute({
        certificationPlanId: String(certId),
      });
      window.open(window.location.origin + pathName, '_blank');
    }
  };
  return (
    <GridContext.Provider
      value={{
        reportType,
        columnsDefinitions,
        onGroupByChange,
        onVisibleColumnsChange,
      }}
    >
      <DataGrid
        getRowId={(r) => r?.guid}
        classes={{ root: classes.root }}
        autoHeight={true}
        rows={rows}
        columns={columns ?? []}
        rowHeight={rowHeight}
        page={page}
        pageSize={pageSizeSafe}
        rowCount={total}
        loading={loading}
        paginationMode="server"
        rowsPerPageOptions={[]}
        disableSelectionOnClick={true}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableMultipleColumnsSorting={true}
        editMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onRowClick={handleRowClick}
        components={{
          ColumnsPanel: ColumnsPanel as ElementType,
          Panel: GridPanel as ElementType,
          ColumnMenu: CustomColumnMenu as ElementType,
        }}
        onSortModelChange={onSortModelChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </GridContext.Provider>
  );
};
