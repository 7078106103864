import { makeStyles, Typography } from '@material-ui/core';
import { ApprovalGroupUser } from 'ApprovalGroupsPage/model';
import { useApprovalGroupsDetailsQuery } from 'ApprovalGroupsPage/query/approvalGroup.query';
import { updateApprovalGroupUsers } from 'ApprovalGroupsPage/service/approvalGroup.api';
import { RoundButton } from 'components';
import { useToasts } from 'components/ToastProvider';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { ApprovalGroupsDetailsParams, approvalGroupsRoute } from 'route';
import { AbbTheme } from 'theme/createAbbTheme';
import {
  ApprovalGroupUserList,
  ApprovalGroupUserAddForm,
} from '../../ApprovalGroupsPage/components';
import { AppShellBaseProps, DetailsAppShell } from '../../components/AppShell';
import { Content } from '../../components/Page';

const useStyles = makeStyles((theme: AbbTheme) => ({
  form: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },
}));

export interface ApprovalGroupDetailsPageProps extends AppShellBaseProps {}

export const ApprovalGroupDetailsPage = (
  props: ApprovalGroupDetailsPageProps
) => {
  const { logout } = props;
  const classes = useStyles();
  const [approvalGroupUsers, setApprovalGroupUsers] = useState<
    ApprovalGroupUser[] | null
  >(null);
  const { groupId } = useParams<ApprovalGroupsDetailsParams>();
  const { data, isLoading, refetch } = useApprovalGroupsDetailsQuery(
    Number(groupId)
  );
  const { showSuccess, showError } = useToasts();
  const { mutate: updateUsers, isLoading: mutating } = useMutation({
    mutationKey: 'update-approval-group-users',
    mutationFn: updateApprovalGroupUsers,
    onSuccess: () => {
      showSuccess('Approval group users were updated');
      refetch();
      setApprovalGroupUsers(null);
    },
    onError: () => {
      showError('Cannot update group of the users');
    },
  });
  useEffect(() => {
    if (groupId) {
      refetch();
    }
  }, [groupId]);
  useEffect(() => {
    if (data?.approvalGroupUsers) {
      setApprovalGroupUsers(data?.approvalGroupUsers.map((x) => ({ ...x })));
    }
  }, [data]);
  const handleDeleteUser = (user: ApprovalGroupUser) => {
    const index =
      approvalGroupUsers?.findIndex(
        (x) => x.userAuthorizationId === user.userAuthorizationId
      ) ?? -1;
    if (index > -1 && approvalGroupUsers) {
      const nextArr = [...approvalGroupUsers];
      nextArr.splice(index, 1);
      setApprovalGroupUsers(nextArr);
    }
  };
  const handleAddUser = (user: ApprovalGroupUser) => {
    setApprovalGroupUsers([{ ...user }, ...(approvalGroupUsers ?? [])]);
  };
  const handleUpdateUsers = () => {
    if (approvalGroupUsers) {
      updateUsers({
        approvalGroupId: Number(groupId),
        approvalGroupUsers,
      });
    }
  };
  return (
    <DetailsAppShell
      title={data?.description ?? ''}
      subtitle="Approval groups list"
      loading={isLoading}
      logout={logout}
      defaultGoBackPath={approvalGroupsRoute.path}
    >
      <Content
        loading={isLoading}
        mutating={mutating}
        mutatingLabel="Applying action..."
        title={data?.description ?? ''}
        renderActions={() => (
          <RoundButton
            onClick={handleUpdateUsers}
            variant="contained"
            color="secondary"
          >
            Save changes
          </RoundButton>
        )}
      >
        <ApprovalGroupUserAddForm
          className={classes.form}
          onAddUser={handleAddUser}
        />
        <Typography
          className={classes.subTitle}
          variant="h6"
        >{`Users in the group (${
          approvalGroupUsers?.length ?? 0
        })`}</Typography>
        <ApprovalGroupUserList
          items={approvalGroupUsers}
          onDelete={handleDeleteUser}
        />
      </Content>
    </DetailsAppShell>
  );
};
