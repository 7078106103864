/* eslint-disable no-bitwise */
export const deepCopy = <T extends unknown>(obj: T): T => {
  if (!obj) {
    return obj;
  }
  const json = JSON.stringify(obj);
  return JSON.parse(json);
};

export const uuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
