import { ReactNode, useState, Fragment } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';
import { formatTemplate } from 'utils';
import { useMutation, useQueryClient } from 'react-query';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent, TextField, Tooltip } from '@material-ui/core';

import { certificationListApi } from 'CertificationList/service/certificationList.api';
import { CertificationPlanWorkFlowActions } from 'enums/CertificationPlanWorkFlowActions';
import { useMutating } from 'hooks';
import { Button, RoundButton } from 'components';
import { useToasts } from 'components/ToastProvider';
import { ApiError } from 'model';
import { certificationPlansDetailsRoute, certificationPlansEnrollRequestDetailsRoute } from 'route';
import { RenderSubmitWithDateDialog } from 'PendingApprovalsPage/components/CertificateReleatedTableActions';
import { certificationPlanApi } from '../service/certificationPlans.api';
import { certificationPlanWorkflowApi } from '../service/certificationPlanWorkflow.api';
import { certificationPlansKey } from '../query/certificationPlansQuery';

export interface CertificationPlanActionsProps {
  certificationPlanId: number;
  userCertificationPlan?: number;
  userEnrollmentRequest?: number;
  workflowActions?: CertificationPlanWorkFlowActions[];
  onActionSuccess?: () => void;
}

const submitKey = 'cerification-plan-submit';
const approveKey = 'cerification-plan-approve';
const resetKey = 'cerification-plan-reset';
const resetToApprovalKey = 'cerification-plan-reset-to-approval';
const rejectKey = 'cerification-plan-reject';
const submitForRenewKey = 'cerification-plan-submit-for-renew';
const renewKey = 'cerification-plan-renew';
const renewalEnroApproveKey = 'cerification-plan-renewal-enrollment-approve';
const renewalEnroRejectKey = 'cerification-plan-renewal-enrollment-reject';
const removeKey = 'cerification-plan-remove';
const enrollKey = 'cerification-list-enroll';
const alreadyEnrolledKey = 'cerification-list-already-enrolled';
const alreadyEnrolledOtherReleaseKey =
  'cerification-list-already-enrolled-other-release';
const noLongerAvailableKey = 'no-longer-available';
const clApproveKey = 'cerification-list-cl-approve';
const clRejectKey = 'cerification-list-cl-reject';
const clApproveDisabledKey = 'cerification-list-cl-approve-disabled';
const clRejectDisabledKey = 'cerification-list-cl-reject-disabled';
const clPublishKey = 'cerification-list-cl-publish';
const clRejectPublishKey = 'cerification-list-cl-reject-publish';
const submitWithDateKey = 'cerification-plan-submit-with-date';
const submitForRenewWithDateKey = 'cerification-plan-submit-for-renew-with-date';

export const mutatingFilterKeys = [
  submitKey,
  approveKey,
  resetKey,
  resetToApprovalKey,
  rejectKey,
  submitForRenewKey,
  renewKey,
  renewalEnroApproveKey,
  renewalEnroRejectKey,
  removeKey,
  enrollKey,
  clApproveKey,
  clRejectKey,
  clPublishKey,
  clRejectPublishKey,
  submitWithDateKey,
  submitForRenewWithDateKey
];

export const getSuccessMessage = (actionKey: string): string => {
  switch (actionKey) {
    case submitKey:
    case submitWithDateKey:
      return 'Submit certification plan action success';
    case approveKey:
      return 'Approve certification plan action success';
    case renewalEnroApproveKey:
      return 'Approve renewal enrollment action success';
    case renewalEnroRejectKey:
      return 'Reject renewal enrollment action success';
    case resetKey:
      return 'Reset certification plan action success';
    case resetToApprovalKey:
      return 'Reset to approval certification plan action success';
    case rejectKey:
      return 'Reject certification plan action success';
    case submitForRenewKey:
    case submitForRenewWithDateKey:
      return 'Submit for renew certification plan action success';
    case renewKey:
      return 'Renew certification plan action success';
    case removeKey:
      return 'Remove certification plan action success';
    case enrollKey:
      return 'Enroll certification list action success';
    case clApproveKey:
      return 'Approve certification list action success';
    case clRejectKey:
      return 'Reject certification list action success';
    case clPublishKey:
      return 'Publish certification list action success';
    case clRejectPublishKey:
      return 'Reject publish certification list action success';
    default:
      return 'Action success';
  }
};

export const CertificationPlanActions = (
  props: CertificationPlanActionsProps
) => {
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useToasts();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogForApproveOpen, setIsDialogForApproveOpen] = useState(false);
  const [isDialogForRenewOpen, setIsDialogForRenewOpen] = useState(false);
  const [reason, setReason] = useState('');
  const isMutating = useMutating([...mutatingFilterKeys]);
  const { certificationPlanId, userCertificationPlan, userEnrollmentRequest, workflowActions = [] } = props;
  const onSuccess = async (actionKey: string) => {
    const data = await certificationPlanApi.getCertificationPlan(
      certificationPlanId
    );
    queryClient.setQueryData(certificationPlansKey, {
      ...data,
    });
    showSuccess(getSuccessMessage(actionKey));
    props.onActionSuccess && props.onActionSuccess();
  };
  const onError = async (error: ApiError) => showError(error.message);
  const { mutate: submit } = useMutation<unknown, ApiError>(
    () => certificationPlanWorkflowApi.submit(certificationPlanId),
    { mutationKey: submitKey, onSuccess: () => onSuccess(submitKey), onError }
  );
  const { mutate: submitWithDate } = useMutation<unknown, ApiError, string>(
    (expirationDate) => certificationPlanWorkflowApi.submitWithDate(certificationPlanId, expirationDate),
    {
      mutationKey: submitWithDateKey,
      onSuccess: () => onSuccess(submitWithDateKey),
      onError,
    }
  );
  const { mutate: approve } = useMutation<unknown, ApiError, string | undefined>(
    (expirationDate) => certificationPlanWorkflowApi.approve(certificationPlanId, expirationDate),
    { mutationKey: approveKey, onSuccess: () => onSuccess(approveKey), onError }
  );

  const { mutate: reset } = useMutation<unknown, ApiError>(
    () => certificationPlanWorkflowApi.reset(certificationPlanId),
    { mutationKey: resetKey, onSuccess: () => onSuccess(resetKey), onError }
  );
  const { mutate: resetToApproval } = useMutation<unknown, ApiError>(
    () => certificationPlanWorkflowApi.resetToApproval(certificationPlanId),
    {
      mutationKey: resetToApprovalKey,
      onSuccess: () => onSuccess(resetToApprovalKey),
      onError,
    }
  );
  const { mutate: reject } = useMutation<unknown, ApiError, string>(
    (reason) =>
      certificationPlanWorkflowApi.reject(certificationPlanId, reason),
    { mutationKey: rejectKey, onSuccess: () => onSuccess(rejectKey), onError }
  );
  const { mutate: submitForRenew } = useMutation<unknown, ApiError>(
    () => certificationPlanWorkflowApi.submitForRenew(certificationPlanId),
    {
      mutationKey: submitForRenewKey,
      onSuccess: () => onSuccess(submitForRenewKey),
      onError,
    }
  );
  const { mutate: submitForRenewWithDate } = useMutation<unknown, ApiError, string>(
    (expirationDate) => certificationPlanWorkflowApi.submitForRenewWithDate(certificationPlanId, expirationDate),
    {
      mutationKey: submitForRenewWithDateKey,
      onSuccess: () => onSuccess(submitForRenewWithDateKey),
      onError,
    }
  );
  const { mutate: renew } = useMutation<unknown, ApiError, string | undefined>(
    (expirationDate?) => certificationPlanWorkflowApi.renew(certificationPlanId, expirationDate),
    { mutationKey: renewKey, onSuccess: () => onSuccess(renewKey), onError }
  );
  const { mutate: approveRenewalEnrollment } = useMutation<unknown, ApiError>(
    () => certificationPlanWorkflowApi.approveRenewalEnro(certificationPlanId),
    { mutationKey: renewalEnroApproveKey, onSuccess: () => onSuccess(renewalEnroApproveKey), onError }
  );
  const { mutate: rejectRenewalEnrollment } = useMutation<unknown, ApiError>(
    () => certificationPlanWorkflowApi.rejectRenewalEnro(certificationPlanId),
    { mutationKey: renewalEnroRejectKey, onSuccess: () => onSuccess(renewalEnroRejectKey), onError }
  );
  const { mutate: remove } = useMutation<unknown, ApiError>(
    () => certificationPlanWorkflowApi.remove(certificationPlanId),
    { mutationKey: removeKey, onSuccess: () => onSuccess(removeKey), onError }
  );
  const { mutate: enroll } = useMutation<unknown, ApiError>(
    () => certificationListApi.enroll(certificationPlanId),
    { mutationKey: enrollKey, onSuccess: () => onSuccess(enrollKey), onError }
  );

  const handleNavigate = () => {

    const path: string | null = userCertificationPlan != null ? certificationPlansDetailsRoute.createRoute({
      certificationPlanId: String(userCertificationPlan),
    }) :
      certificationPlansEnrollRequestDetailsRoute.createRoute({
        certificationPlanId: String(userEnrollmentRequest),
      });

    if (path) {
      window.open(
        `${window.location.origin}${path}`,
        '_blank'
      );
    }
  };

  const { mutate: clApprove } = useMutation<unknown, ApiError>(
    () =>
      certificationPlanWorkflowApi.approveEnrollRequest(certificationPlanId),
    {
      mutationKey: clApproveKey,
      onSuccess: () => onSuccess(clApproveKey),
      onError,
    }
  );
  const { mutate: clReject } = useMutation<unknown, ApiError, string>(
    (reason) =>
      certificationPlanWorkflowApi.rejectEnrollRequest(
        certificationPlanId,
        reason
      ),
    {
      mutationKey: clRejectKey,
      onSuccess: () => onSuccess(clRejectKey),
      onError,
    }
  );
  const { mutate: clRejectPublish } = useMutation<unknown, ApiError>(
    () => certificationListApi.rejectPublish(certificationPlanId),
    {
      mutationKey: clRejectPublishKey,
      onSuccess: () => onSuccess(clRejectPublishKey),
      onError,
    }
  );
  const { mutate: clPublish } = useMutation<unknown, ApiError>(
    () => certificationListApi.publish(certificationPlanId),
    {
      mutationKey: clPublishKey,
      onSuccess: () => onSuccess(clPublishKey),
      onError,
    }
  );
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => {
    setReason('');
    setIsDialogOpen(false);
  };
  const openDialogForApprove = () => setIsDialogForApproveOpen(true);
  const closeDialogForApprove = () => {
    setReason('');
    setIsDialogForApproveOpen(false);
  };
  const renderRejectDialog = (
    rejectAction: (reason: string) => void
  ): ReactNode => (
    <Dialog open={isDialogOpen} onClose={closeDialog}>
      <DialogTitle id="form-dialog-title">Are you sure to reject?</DialogTitle>
      <DialogContent>
        <TextField
          label="Reason"
          variant="outlined"
          multiline
          onChange={(ev) => setReason(ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            rejectAction(reason);
            closeDialog();
          }}
          disabled={!reason}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );

  const [expirationDate, setExpirationDate] = useState<Dayjs | null>(null);
  const getUpdateDate = (dateOrNull: Dayjs | null): string =>
    dateOrNull ? dayjs(dateOrNull).format() : "";

  const renderSubmitWithDateDialog = (
    submitAction: (expirationDate: string) => void
  ): ReactNode => (
    <Dialog open={isDialogForApproveOpen} onClose={closeDialogForApprove}>
      <DialogTitle id="form-dialog-title">Provide Certification Expiration Date</DialogTitle>
      <DialogContent>
        <KeyboardDatePicker
          variant="inline"
          inputVariant="outlined"
          label="Expiration date"
          value={expirationDate}
          onChange={setExpirationDate}
          format={formatTemplate}
          //disabled={isLoading}
          autoOk
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialogForApprove} color="primary">
          Close
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            submitAction(getUpdateDate(expirationDate));
            closeDialogForApprove();
          }}
          disabled={!expirationDate?.isValid()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const actionsMap: Partial<
    Record<CertificationPlanWorkFlowActions, ReactNode>
  > = {
    [CertificationPlanWorkFlowActions.Submit]: (
      <RoundButton
        key={submitKey}
        onClick={() => submit()}
        disabled={isMutating}
      >
        Submit
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.Approve]: (
      <RoundButton
        key={approveKey}
        onClick={() => approve(undefined)}
        disabled={isMutating}
      >
        Approve
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.ApproveWithDate]: (
      <>
        <RoundButton key={approveKey} onClick={() => openDialogForApprove()} disabled={isMutating}>
          Approve with date
        </RoundButton>
        {isDialogForApproveOpen ?
          (<RenderSubmitWithDateDialog
            submitAction={(d) => approve(d)}
            onClose={() => setIsDialogForApproveOpen(false)} />) :
          (<></>)}
      </>
    ),
    [CertificationPlanWorkFlowActions.Reset]: (
      <RoundButton key={resetKey} onClick={() => reset()} disabled={isMutating}>
        Reset
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.ResetToApproval]: (
      <RoundButton
        key={resetToApprovalKey}
        onClick={() => resetToApproval()}
        disabled={isMutating}
      >
        Reset to Approval
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.Reject]: (
      <Fragment key={rejectKey}>
        {renderRejectDialog(reject)}
        <RoundButton onClick={openDialog} disabled={isMutating}>
          Reject
        </RoundButton>
      </Fragment>
    ),
    [CertificationPlanWorkFlowActions.SubmitWithDate]: (
      <Fragment key={submitWithDateKey}>
        {renderSubmitWithDateDialog(submitWithDate)}
        <RoundButton onClick={openDialog} disabled={isMutating}>
          Submit
        </RoundButton>
      </Fragment>
    ),
    [CertificationPlanWorkFlowActions.SubmitForRenewWithDate]: (
      <Fragment key={submitForRenewWithDateKey}>
        {renderSubmitWithDateDialog(submitForRenewWithDate)}
        <RoundButton onClick={openDialog} disabled={isMutating}>
          Submit for renewal
        </RoundButton>
      </Fragment>
    ),
    [CertificationPlanWorkFlowActions.SubmitForRenew]: (
      <RoundButton
        key={submitForRenewKey}
        onClick={() => submitForRenew()}
        disabled={isMutating}
      >
        Submit for renewal
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.ApproveRenewalEnrollment]: (
      <RoundButton key={renewalEnroApproveKey} onClick={() => approveRenewalEnrollment()} disabled={isMutating}>
        Approve renewal enrollment
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.RejectRenewalEnrollment]: (
      <RoundButton key={renewalEnroRejectKey} onClick={() => rejectRenewalEnrollment()} disabled={isMutating}>
        Reject renewal enrollment
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.Renew]: (
      <RoundButton key={renewKey} onClick={() => renew(undefined)} disabled={isMutating}>
        Renew
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.RenewWithDate]: (
      <>
        <RoundButton key={renewKey} onClick={() => setIsDialogForRenewOpen(true)} disabled={isMutating}>
          Renew with date
        </RoundButton>
        {isDialogForRenewOpen ?
          (<RenderSubmitWithDateDialog
            submitAction={(d) => renew(d)}
            onClose={() => setIsDialogForRenewOpen(false)} />) :
          (<></>)}
      </>
    ),
    [CertificationPlanWorkFlowActions.Remove]: (
      <RoundButton
        key={removeKey}
        onClick={() => remove()}
        disabled={isMutating}
      >
        Remove
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.Enroll]: (
      <RoundButton
        key={enrollKey}
        onClick={() => enroll()}
        color="secondary"
        variant="contained"
        disabled={isMutating}
      >
        Enroll
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.AlreadyEnrolled]: (
      <>
        <Tooltip
          key={alreadyEnrolledKey}
          title="You are already enrolled for this Certification"
          aria-label="add"
        >
          <div>
            <RoundButton color="secondary" variant="contained" disabled>
              Enroll
            </RoundButton>
          </div>
        </Tooltip>
        <RoundButton color="secondary" variant="contained" onClick={() => handleNavigate()}  >
          {userCertificationPlan != null ? "Go to enrollment" : "Go to enrollment request"}
        </RoundButton>
      </>
    ),
    [CertificationPlanWorkFlowActions.AlreadyEnrolledOtherRelease]: (
      <>
        <Tooltip
          key={alreadyEnrolledOtherReleaseKey}
          title="You have already enrolled for this Certification (different release)"
        >
          <div>
            <RoundButton color="secondary" variant="contained" disabled>
              Enroll
            </RoundButton>
          </div>
        </Tooltip>
        <RoundButton color="secondary" variant="contained" onClick={() => handleNavigate()}  >
          Go to enrollment
        </RoundButton>
      </>
    ),
    [CertificationPlanWorkFlowActions.NoLongerAvailable]: (
      <Tooltip
        key={noLongerAvailableKey}
        title="This certificate is no longer available"
      >
        <div>
          <RoundButton color="secondary" variant="contained" disabled>
            Enroll
          </RoundButton>
        </div>
      </Tooltip>
    ),
    [CertificationPlanWorkFlowActions.ClApprove]: (
      <RoundButton
        key={clApproveKey}
        onClick={() => clApprove()}
        disabled={isMutating}
      >
        Approve
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.ClReject]: (
      <Fragment key={clRejectKey}>
        {renderRejectDialog(clReject)}
        <RoundButton onClick={openDialog} disabled={isMutating}>
          Reject
        </RoundButton>
      </Fragment>
    ),
    [CertificationPlanWorkFlowActions.ClApproveDisabled]: (
      <RoundButton key={clApproveDisabledKey} disabled>
        Approve
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.ClRejectDisabled]: (
      <RoundButton key={clRejectDisabledKey} disabled>
        Reject
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.ClPublish]: (
      <RoundButton
        key={clRejectDisabledKey}
        disabled={isMutating}
        onClick={() => clPublish()}
      >
        Publish
      </RoundButton>
    ),
    [CertificationPlanWorkFlowActions.ClRejectPublish]: (
      <RoundButton
        key={clRejectDisabledKey}
        disabled={isMutating}
        onClick={() => clRejectPublish()}
      >
        Reject publish
      </RoundButton>
    ),
  };
  return <>{workflowActions.map((a) => actionsMap[a])}</>;
};
