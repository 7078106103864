import { FC } from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';

import { Root } from './Loader.style';

export const Loader: FC<CircularProgressProps> = (props) => {
  const { children, ...other } = props;
  return (
    <Root>
      <CircularProgress color="secondary" {...other} />
      {children}
    </Root>
  );
};
