import { useQuery, UseQueryOptions } from 'react-query';

import { mapPaginationDto, PaginatedList, Pagination, Sort } from 'model';
import { ByPerson } from 'ByPerson/model/ByPerson';
import { ByPersonSearchFilters } from 'ByPerson/model/ByPersonSearchFilters';
import { byPersonApi } from 'ByPerson/service/byPerson.api';

export const byPersonKey = 'by-person';
export const useByPersonQuery = (
  pagination: Pagination,
  sort: Sort<ByPerson>,
  filters: Partial<ByPersonSearchFilters>,
  options: UseQueryOptions<PaginatedList<ByPerson>>
) =>
  useQuery<PaginatedList<ByPerson>>(
    [byPersonKey, mapPaginationDto(pagination), sort, filters],
    () => byPersonApi.search(mapPaginationDto(pagination), sort, filters),
    options
  );
