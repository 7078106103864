import {
  makeStyles,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import Axios, { AxiosRequestConfig } from 'axios';
import { OVERRIDE_TOKEN_KEY } from 'service/authProvider';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      flexDirection: 'column',
      width: 600,
      marginTop: theme.spacing(4),
      'box-sizing': 'border-box',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(4),
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      '@media (max-width: 600px)': {
        width: '100vw',
      },
    },
    info: {
      maxWidth: 320,
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    subHeadline: {
      marginBottom: theme.spacing(4),
    },
  }),
  { name: 'LoginMock' }
);

interface LoginMockState {
  error?: string;
  email: string;
  emailError?: string;
  password: string;
  passwordError?: string;
  loading: boolean;
}

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

export interface LoginMockProps {
  login: () => void;
}

const PASSWORD_KEY = 'mock-password';

export const LoginMock = (props: LoginMockProps) => {
  const { login } = props;
  const [state, setState] = useState<LoginMockState>({
    error: undefined,
    email: '',
    emailError: undefined,
    password: '',
    passwordError: undefined,
    loading: false,
  });
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      password: localStorage.getItem(PASSWORD_KEY) ?? '',
    }));
  }, []);
  const classes = useStyles();
  const handleLogin = async () => {
    let isValid = true;
    if (state.email === '') {
      isValid = false;
      setState((prev) => ({ ...prev, emailError: 'Email is required' }));
    } else {
      setState((prev) => ({ ...prev, emailError: '' }));
    }
    if (state.password === '') {
      isValid = false;
      setState((prev) => ({
        ...prev,
        passwordError: 'Password is required',
      }));
    } else {
      setState((prev) => ({ ...prev, passwordError: '' }));
    }
    if (isValid && !state.loading) {
      try {
        const config: AxiosRequestConfig = { headers: {} };
        config.headers['tester-secret'] = state.password;
        localStorage.setItem(PASSWORD_KEY, state.password);
        setState((prev) => ({ ...prev, loading: true }));
        const { data: token } = await axios.post(
          `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_PATH}auth/login`,
          {
            email: state.email,
            password: state.password,
          },
          config
        );
        localStorage.setItem(OVERRIDE_TOKEN_KEY, token);
        login();
      } catch (err) {
        setState((prev) => ({
          ...prev,
          error: 'Error!',
        }));
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    }
  };
  return (
    <>
      <div className={classes.root}>
        <section className={classes.info}>
          <Typography variant="h4">PCS 2</Typography>
          <Typography component="p" className={classes.subHeadline}>
            Login Mock Tool
          </Typography>
          <TextField
            id="email"
            label="Email"
            variant="filled"
            required
            value={state.email}
            error={Boolean(state.emailError)}
            helperText={state.emailError}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                email: e.target.value,
                emailError: '',
              }))
            }
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            variant="filled"
            required
            value={state.password}
            error={Boolean(state.passwordError)}
            helperText={state.passwordError}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                password: e.target.value,
                passwordError: '',
              }));
            }}
          />
        </section>
        {state.loading ? (
          <CircularProgress size={32} color="secondary" />
        ) : (
          <Button
            onClick={handleLogin}
            color="secondary"
            variant="contained"
            disabled={state.loading}
          >
            Log in
          </Button>
        )}
      </div>
    </>
  );
};

export default LoginMock;
