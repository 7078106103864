import { Card } from 'components/Page';
import { DataTable, DataTableColumn } from 'components/DataTable/DataTable';
import { UserTrainingSet } from 'Certificate/model/UserTraningSet';
import { CPStatusChip } from 'components/StatusChip';
import { formatDate, formatExternalFlag } from 'utils';
import { useHistory } from 'react-router-dom';
import { CertificationPlansDetails } from 'CertificationPlansPage/model/CertificationPlansDetails';
import { TitleTypography } from 'Certificate/component/UserCertificateTable/UserCertificateTable.style';
import { IconButton } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { catalogDetailsRoute, certificationPlansDetailsRoute } from 'route';
import { CPStatusType } from 'enums/CPStatusType';
import { getPgUnitFullName } from 'Dictionary/model/PGUnit';

export interface TraningSetsTableProps {
  loading: boolean;
  rows: UserTrainingSet[];
}

const userTrainingSetsColumns: DataTableColumn<UserTrainingSet>[] = [
  { field: 'name', headerName: 'Title', width: 400 },
  {
    field: 'businessUnits',
    headerName: 'Division',
    renderField: ({ businessUnits }) => (businessUnits ?? []).map((bu) => bu.description).join(', '),
  },
  {
    field: 'productGroups',
    headerName: 'Product Groups',
    renderField: ({ productGroups }) => Array.isArray(productGroups) && productGroups.length ? 
      productGroups.map((pg) => getPgUnitFullName(pg)).join(', ') : 'All Product Groups',
  },
  {
    field: 'countries',
    headerName: 'Countries',
    renderField: ({ countries }) => Array.isArray(countries) && countries.length ? 
      countries.map((c) => c.name).join(', ') : 'All countries',
  },
  {
    field: 'isExternal',
    headerName: 'ABB/External',
    renderField: ({ isExternal }) => formatExternalFlag(isExternal),
  },
  {
    field: 'completionPercentage',
    headerName: 'Certifications completed',
    renderField: ({ completionPercentage }) => `${completionPercentage} %`,
  },
];

const userCertificateColumns: DataTableColumn<CertificationPlansDetails>[] = [
  {
    field: 'title',
    headerName: 'Certificate',
    width: 400,
    renderField: ({ title }) => (
      <TitleTypography variant="body2">{title}</TitleTypography>
    ),
  },  
  {
    field: 'enrollmentDate',
    headerName: 'Enrolled on',
    renderField: ({ enrollmentDate }) => formatDate(enrollmentDate),
  },
  {
    field: 'status',
    headerName: 'Status',
    renderField: ({ status }) => <CPStatusChip status={status} />,
  },
];

export const TraningSetsTable = (props: TraningSetsTableProps) => {
  const { loading, rows } = props;
  const history = useHistory();
  const handleOpenCertificateDetails = (row: CertificationPlansDetails) =>{
    if(row.status === CPStatusType.NotEnrolled){
      history.push(
        catalogDetailsRoute.createRoute({
          certificationId: String(row.id),      
        })
      );  
    }
    else{
      history.push(
        certificationPlansDetailsRoute.createRoute({
          certificationPlanId: String(row.id),
        })
      );
    }
  };
    
  return (
    <Card
      header={`Training sets (${rows?.length ?? 0})`}
      transparent
      listContent
    >
      <DataTable
        columns={userTrainingSetsColumns}
        loading={loading}
        rows={rows}
        rowSelector={(row) => row.id}
        renderCollapseRow={(row) => (
          <Card
            header={`Details (${row.certificates?.length ?? 0})`}
            transparent
            listContent
          >
            <DataTable
              columns={userCertificateColumns}
              rows={row?.certificates ?? []}
              rowSelector={(row) => row.id}
              renderRowActions={(row) => (
                <IconButton onClick={() => handleOpenCertificateDetails(row)}>
                  <NavigateNext />
                </IconButton>
              )}
            />
          </Card>
        )}
        noDataMessage="No training sets to display"
      />
    </Card>
  );
};

export default TraningSetsTable;
