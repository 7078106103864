import React,{ FC } from 'react';

import { BoldTypography } from 'components/Page';
import { Root } from './SearchResults.style';


export interface SearchResultsProps {
  total?: number;
  loading?: boolean;
  actions?: React.ReactNode
}

export const SearchResults: FC<SearchResultsProps> = ({
  total,
  loading = false,
  actions
}) => (
  <Root>
    <BoldTypography variant="body1">
      Search results: {loading ? '...' : total ?? '...'}
    </BoldTypography>
    {actions}
  </Root>
);
