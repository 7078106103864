import { useQuery as callquery } from 'react-query';
import fileDownload from 'js-file-download';
import { certificationListApi } from 'CertificationList/service/certificationList.api';
import { catalogApi } from 'Catalog/service/catalog.api';
import { ApiError, PaginatedList, PaginationDto, Sort, UserSearchItemDto } from 'model';
import { CertificationPlansDetails } from 'CertificationPlansPage/model/CertificationPlansDetails';
import { defaultApiClient, makeDefaultApi } from 'Dictionary/service/dictionary.api';

export type { PaginatedList, PaginationDto, CertificationPlansDetails }

export enum KnownCertificateListStatus {

    Removed = "Removed",
    Draft = "Draft",
    Temporary = "Temporary",
    Published = "Published",
    Withdrawn = "Withdrawn",
    Imported = "Imported",
    WaitingApproval = "WaitingApproval"
}

export const API =
{
    certificationList: certificationListApi,
    catalog: catalogApi,

    // map: (x: CertificationPlansDetails): CertificateListDefinitionDTO => ({
    //     id: x.id,
    //     title: x.title,
    //     release: x.release,
    //     publishDate: x.publishDate,

    //     behavior: { code: "behavior2", display: "behavior2" },
    //     level: { id: x.level.id, code: x.level.type, name: x.level.description },
    //     division: { id: x.businessUnit.id, name: x.businessUnit.description },
    //     recommended: x.recommended.map<CertificateListLookupDTO>(x => ({
    //         id: x.id,
    //         title: x.title,
    //         division: { id: x.provider.id, name: x.provider.description },
    //         level: { id: x.level.id, code: x.level.type, name: x.level.description }
    //     })),
    //     prerequisites: x.prerequisites.map<CertificateListLookupDTO>(x => ({
    //         id: x.id,
    //         title: x.title,
    //         division: { id: x.businessUnit.id, name: x.businessUnit.description },
    //         level: { id: x.level.id, code: x.level.type, name: x.level.description }
    //     })),
    // }),  

    ICertificateListContract: {
        ...makeDefaultApi<CertificateListDefinitionDTO, CertificateListDefinitionFindQuery>({ basePath: "admin/certificate-list/" }),

        Withdraw: async (item: CertificateListLookupDTO): Promise<unknown> => {

            try {
                const response = await defaultApiClient.put(
                    `admin/certificate-list/Withdraw/${item.id}`
                );
                return response.data;
            } catch (e) {
                throw new ApiError(e);
            }

        },

        RemoveDraft: async (item: CertificateListLookupDTO): Promise<unknown> => {

            try {
                const response = await defaultApiClient.put(
                    `admin/certificate-list/RemoveDraft/${item.id}`
                );
                return response.data;
            } catch (e) {
                throw new ApiError(e);
            }

        },

        Copy: async (item: CertificateListLookupDTO): Promise<{ itemID: number }> => {

            try {
                const response = await defaultApiClient.post(
                    `admin/certificate-list/Copy/${item.id}`
                );
                return response.data;
            } catch (e) {
                throw new ApiError(e);
            }

        },

        NewVersion: async (item: CertificateListLookupDTO): Promise<{ itemID: number }> => {

            try {
                const response = await defaultApiClient.post(
                    `admin/certificate-list/NewVersion/${item.id}`
                );
                return response.data;
            } catch (e) {
                throw new ApiError(e);
            }

        },

        NewSubVersion: async (item: CertificateListLookupDTO): Promise<{ itemID: number }> => {

            try {
                const response = await defaultApiClient.post(
                    `admin/certificate-list/NewSubVersion/${item.id}`
                );
                return response.data;
            } catch (e) {
                throw new ApiError(e);
            }

        },
        preview: async (query: { id: unknown, forExternal: boolean }): Promise<unknown> => {
            try {

                const response = await defaultApiClient.get(
                    `admin/certificate-list/${query.id}/preview?forExternal=${query.forExternal ? "true" : "false"}`,
                    { responseType: 'blob' }
                );
                fileDownload(response.data, 'preview.pdf');
                return undefined;
            } catch (e) {
                throw new ApiError(e);
            }
        },

        findOwner: async (query: { byText: string }): Promise<UserSearchItemDto[]> => {
            try {
                const response = await defaultApiClient.get(
                    `admin/certificate-list/find-owner?${new URLSearchParams(query).toString()}`
                );
                return response.data;
            } catch (e) {
                throw new ApiError(e);
            }
        },
        findOwnerQuery: (query: { searchFor: string }) =>
            callquery<UserSearchItemDto[]>(
                `DICT_findOwnerQuery_${query.searchFor ?? ""}`,
                (c) => API.ICertificateListContract.findOwner({ byText: query.searchFor }),
                { enabled: false }
            ),
        internal_dictionary: async (query: { key: KnowDictionaryKey } & Record<string, string | number | undefined>): Promise<DictionaryItemByCodeDTO[]> => {

            try {
                const response = await defaultApiClient.get(
                    //@ts-ignore 
                    `admin/dictionary/${query.key}?${new URLSearchParams(query).toString()}`
                );
                return response.data;
            } catch (e) {
                throw new ApiError(e);
            }

        },
        dictionaryQuery: (query: DictionaryContextQueryDTO) =>
            callquery<DictionaryItemByCodeDTO[]>(
                `DICT_${query.key}_${query.businessArea?.id ?? 0}_${query.division?.id ?? 0}_${query.scope?.id ?? 0}`,
                (c) => API.ICertificateListContract.internal_dictionary({
                    key: query.key,
                    businessArea: query.businessArea?.code ?? "",
                    division: query.division?.id ?? "",
                    scope: query.scope?.code ?? "",
                }),
                { enabled: false }
            )

    },

}


export interface CertificateListDefinitionFindQuery extends PaginationDto, Sort<CertificateListLookupDTO> {
    purpose: "Manage" | "Select"
    title?: string
    businessArea?: DictionaryItemByCodeDTO
    division?: DictionaryItemByCodeDTO
    mainFunction?: DictionaryItemByCodeDTO[]
    productGroup?: DictionaryItemByCodeDTO[]
    scope?: DictionaryItemByCodeDTO
    scopeDetail?: DictionaryItemByCodeDTO[]
    country?: DictionaryItemByCodeDTO[]
    userType?: DictionaryItemByCodeDTO
    channelType?: DictionaryItemByCodeDTO[]
    functionalJob?: DictionaryItemByCodeDTO[]
    level?: DictionaryItemByCodeDTO[]
    status?: DictionaryItemByCodeDTO[]
}

export interface CertificateListDefinitionDTO extends CertificateListLookupDTO {
    id: number
    title: string
    release: string
    duration: string
    purpose: string
    publishDate: string | null
    expirationDate: DictionaryItemByCodeDTO | null
    recommended: CertificateListLookupDTO[]
    automaticEnrollment: CertificateListLookupDTO[]
    prerequisites: CertificateListLookupDTO[]

    behavior: DictionaryItemByCodeDTO
    businessArea: DictionaryItemByCodeDTO
    division: DictionaryItemByCodeDTO
    productGroup: DictionaryItemByCodeDTO
    level: DictionaryItemByCodeDTO
    scope: DictionaryItemByCodeDTO
    scopeDetail: DictionaryItemByCodeDTO[]

    mainFunction: DictionaryItemByCodeDTO
    validFor: DictionaryItemByCodeDTO[]
    workType: DictionaryItemByCodeDTO[]
    ownerCountry: DictionaryItemByCodeDTO

    enrollmentApproverType: DictionaryItemByCodeDTO
    enrollmentApproverGroup: DictionaryItemByCodeDTO
    completionApproverType: DictionaryItemByCodeDTO
    completionApproverTypeGroup: DictionaryItemByCodeDTO
    renewalApproverType: DictionaryItemByCodeDTO
    renewalApproverGroup: DictionaryItemByCodeDTO
    renewalEnrollmentApproverType: DictionaryItemByCodeDTO
    renewalEnrollmentApproverGroup: DictionaryItemByCodeDTO

    forCountries: DictionaryItemByCodeDTO[]
    forDivisions: DictionaryItemByCodeDTO[]
    forChannels: DictionaryItemByCodeDTO[]
    forUserType: DictionaryItemByCodeDTO

    requirements: CertificateListRequirementDTO[]
    alternatives: CertificateListAlternativeDTO[]

    history: HistoryEventEntry[]

    includeCLIdentificators: boolean
    identificators: CertificateListPrintIdentificators[]
}

export interface CertificateListPrintIdentificators {
    header: DictionaryItemByCodeDTO
    subheader: DictionaryItemByCodeDTO
    identifiers: DictionaryItemByCodeDTO[]
}
export interface CertificateListAlternativeDTO {
    id: number
    source: DictionaryItemByCodeDTO[]
    alternative: DictionaryItemByCodeDTO[]
}

export interface CertificateListRequirementDTO {
    id: number
    code: string
    description: string
    extendedDescription: string
    approverType: DictionaryItemByCodeDTO
    approverGroup: DictionaryItemByCodeDTO
    rule: DictionaryItemByCodeDTO
    expiration: number | null
    renewal: number | null

    training: DictionaryItemByCodeDTO
    certification: DictionaryItemByCodeDTO
}

export interface CertificateListLookupDTO {
    id: number
    title: string
    release: string
    publishDate: string | null
    owner: UserSearchItemDto
    status: DictionaryItemByCodeDTO

    division: DictionaryItemByCodeDTO
    level: DictionaryItemByCodeDTO
}

export interface HistoryEventEntry {
    by: string
    eventDate: string
    description: string
}
