import { Dispatch } from 'react'
import {
  FluidForEditors,
  SearchFiltersFormSmart,
  DictionaryFieldByCode
} from 'components'

import * as Model from './model'

type QueryModel = Model.DigitalSignatureFindQuery

export default function SearchForm(p: {
  defaultQuery: QueryModel
  onQuery: Dispatch<QueryModel>;
}) {

  return (
    <SearchFiltersFormSmart
      searchBarField='byPerson'
      placeholder='Search by Signer'
      initialValues={p.defaultQuery}
      onSubmit={p.onQuery}
    >
      {(fp) => (
        <FluidForEditors inRow={2}>
        <DictionaryFieldByCode name="businessArea" label="Business Area" query={({ key: 'BusinessArea' })} dependandFieldName="division" />
        <DictionaryFieldByCode name="division" label="Division" query={{ key: 'Division', businessArea: fp.values.businessArea }} />
        </FluidForEditors>
      )}
    </SearchFiltersFormSmart>
  )
}
