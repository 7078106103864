import { forwardRef, useState } from 'react';
import { IconButton, Button } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { PendingApprovalsResults } from 'PendingApprovalsPage/model';
import { useToasts } from 'components/ToastProvider';
import { useMutation } from 'react-query';
import { formatDate } from 'utils';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import { ApiError } from 'model';
import { DataObjectView } from 'components';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import { PendingApprovalCertificate } from '../model/PendingApprovals';
import {
  DataTable,
  DataTableColumn,
} from '../../components/DataTable/DataTable';
import { approvePendingApprovals } from '../service/pendingApprovals.api';
import { ObjectTypeChip } from './ObjectTypeChip';
import { ObjectType } from '../../Dictionary/model/TaskType';
import {
  catalogDetailsRoute,
  certificationPlansDetailsRoute,
  certificationPlansEnrollRequestDetailsRoute,
} from '../../route';
import { TaskStatusChip } from './TaskStatusChip';
import { RenderSubmitWithDateDialog } from './CertificateReleatedTableActions';

export interface CertificateReleatedTableProps {
  loading: boolean;
  rows: PendingApprovalCertificate[];
  onApprove: (mutating: boolean) => void;
  onApproveDone: (taskIds: number[]) => void;
}

// eslint-disable-next-line max-len
const userCertificateReleatedColumns: DataTableColumn<PendingApprovalCertificate>[] = [
  {
    field: 'certificateTitle',
    headerName: 'Certificate information',
    renderField: ({ certificateTitle, status }) => (
      <>
        <TaskStatusChip taskStatus={status} />
        <div>{certificateTitle}</div>
      </>
    ),
    width: 400,
  },
  {
    field: 'type',
    headerName: 'Task type',
    width: 140,
    renderField: ({ type }) => <ObjectTypeChip type={type} />,
  },
  {
    field: 'companyName',
    headerName: 'Company',
  },
  {
    field: 'approver',
    headerName: 'Approver',
  },
  {
    field: 'user',
    headerName: 'User',
  },
  {
    field: 'submitter',
    headerName: 'Submitter',
  },
  {
    field: 'createDate',
    headerName: 'Create date',
    renderField: ({ createDate }) =>
      formatDate(createDate),
  }
];

const noDataMessage = "No certificate related pending approvals to display";
const approvePendingApprovalKey = 'pending-approvals-mass-approval';

export const CertificateReleatedTable = forwardRef<
  HTMLDivElement,
  CertificateReleatedTableProps
>((props: CertificateReleatedTableProps, ref) => {
  const { loading, rows, onApprove, onApproveDone } = props;
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const { showSuccess, showError } = useToasts();
  const { mutate: approve } = useMutation<
    Partial<PendingApprovalsResults>,
    ApiError,
    { taskIds: number[], expirationDate?: string }
  >(
    [approvePendingApprovalKey],
    ({ taskIds, expirationDate }) => approvePendingApprovals(taskIds, expirationDate),
    {
      useErrorBoundary: false,
      onSuccess: () => {
        onApprove(false);
        onApproveDone(selectedRows);
        showSuccess('Tasks were approved');
      },
      onError: () => {
        onApprove(false);
        showError('Cannot mass approve tasks');
      },
      onMutate: () => onApprove(true),
    }
  );
  const handleNavigate = (navigateTo: number, type: ObjectType) => {
    let path: string | null = null;
    const params = {
      certificationPlanId: String(navigateTo),
    };
    switch (type) {
      case ObjectType.CPRequirement:
      case ObjectType.Questionnaire:
      case ObjectType.CertificationPlan:
        path = certificationPlansDetailsRoute.createRoute(params);
        break;
      case ObjectType.CertificateList:
        path = catalogDetailsRoute.createRoute({
          certificationId: String(navigateTo),
        });
        break;
      case ObjectType.CPEnrollmentRequest:
        path = certificationPlansEnrollRequestDetailsRoute.createRoute(params);
        break;
    }
    if (path) {
      //history.push(path);
      window.open(
        `${window.location.origin}${path}`,
        '_blank'
      );
    }
  };

  const [isDialogForApproveOpen, setIsDialogForApproveOpen] = useState(false);
  const renderActions = () => {
    const x = 1
    const uqActions = rows.filter(x => selectedRows.includes(x.taskId)).map(x => x.userAction ?? 'Approve').filter((value, index, self) => self.indexOf(value) === index)
    const canExecute = uqActions.length == 1
    console.log({ canExecute, uqActions, da: uqActions[0] })
    return (<>
      <Button color="secondary" disabled={(!canExecute) || uqActions[0] !== 'ApproveWithDate'} onClick={() => setIsDialogForApproveOpen(true)}>
        Approve selected with expiration date 
      </Button>
      <Button color="secondary" disabled={(!canExecute) || uqActions[0] !== 'Approve'} onClick={() => approve({ taskIds: selectedRows })}>
        Approve selected
      </Button>
      {isDialogForApproveOpen ?
        (<RenderSubmitWithDateDialog
          submitAction={(d) => approve({ taskIds: selectedRows, expirationDate: d })}
          onClose={() => setIsDialogForApproveOpen(false)} />) :
        (<></>)}
    </>)
  };

  return (
    <div ref={ref}>
      <MobileSection>
        <DataObjectList
          items={rows}
          renderDataObjectView={(item) => (
            <DataObjectView
              title={item.certificateTitle}
              item={item}
              dataSetMaps={mapToDataSetMap(userCertificateReleatedColumns, [
                'certificateTitle',
              ])}
              renderActions={() => (
                <>
                  {renderActions()}
                  {item.type !== ObjectType.All && (
                    <Button
                      color="secondary"
                      onClick={() => handleNavigate(item.navigateTo, item.type)}
                    >
                      Details
                    </Button>
                  )}
                </>
              )}
            />
          )}
          noDataMessage={noDataMessage}
        />
      </MobileSection>
      <DesktopSection>
        <DataTable
          checkboxSelection={true}
          columns={userCertificateReleatedColumns}
          loading={loading}
          rows={rows}
          rowSelector={(row) => row.taskId}
          outerSelectedRows={selectedRows.map((x) => String(x))}
          onCheckboxChange={(selected) =>
            setSelectedRows(selected.map((x) => Number(x)))
          }
          renderSelectedActions={renderActions}
          renderRowActions={({ navigateTo, type }) =>
            type !== ObjectType.All ? (
              <IconButton onClick={() => handleNavigate(navigateTo, type)}>
                <NavigateNext />
              </IconButton>
            ) : null
          }
          enableSort
          onSortRequest={(direction, fieldName) => 
            rows.sort((a,b) => {
                if((a[fieldName] > b[fieldName]))
                  return direction === "asc" ? 1 : -1;
                if((a[fieldName] < b[fieldName]))
                  return direction === "asc" ? -1 : 1;
                return 0;
              })
          }
          noDataMessage={noDataMessage}
        />
      </DesktopSection>
    </div>
  );
});
