import { FC } from 'react';

import { useTrainingManagersQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'MyProfile/model/MyProfile';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { TrainingManagersFiltersDto } from 'Dictionary/model/TrainingManagersFiltersDto';

export interface TrainingManagerFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options'> {
  filterParams: TrainingManagersFiltersDto;
}

export const mapTrainingManagersFilterParams = (
  businessUnit: AutocompleteFieldItem,
  company: AutocompleteFieldItem | null
): TrainingManagersFiltersDto =>
  businessUnit?.id && company?.id
    ? {
        businessUnitId: Number(businessUnit.id),
        companyId: Number(company.id),
      }
    : {};

export const TrainingManagerField: FC<TrainingManagerFieldProps> = ({
  filterParams,
  multiple = true,
  disabled = false,
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = useTrainingManagersQuery(
    filterParams
  );

  return (
    <FormikAutocompleteField
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading}
      onOpen={() => refetch()}
      {...otherProps}
    />
  );
};
