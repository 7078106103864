import { ColumnDefinition } from 'Reports/model';
import { TextFieldProps } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { formatTemplate } from '../../utils';
import {
  FilterChangeHandler,
  FiltersDateRange,
  ReportingFilters,
} from './@types';

export interface ReportingDatePickerProps {
  columnDefinition: ColumnDefinition;
  fieldProps: TextFieldProps;
  onFilterChange: FilterChangeHandler;
  filters: ReportingFilters;
}

export const ReportingDatePicker = (props: ReportingDatePickerProps) => {
  const { columnDefinition, fieldProps, onFilterChange, filters } = props;
  const [before, setBefore] = useState<Dayjs | null>(null);
  const [after, setAfter] = useState<Dayjs | null>(null);
  useEffect(() => {
    const values = filters[columnDefinition?.cid] as FiltersDateRange;
    if (values?.start) {
      setBefore(values?.start ? dayjs(values?.start, formatTemplate) : null);
    } else {
      setBefore(null);
    }
    if (values?.end) {
      setAfter(values?.end ? dayjs(values?.end, formatTemplate) : null);
    } else {
      setAfter(null);
    }
  }, [filters]);
  const handleDatesChange = (before: Dayjs | null, after: Dayjs | null) => {
    const results: FiltersDateRange = {};
    if (before) {
      results.start = before.toISOString();
    }
    if (after) {
      results.end = after.toISOString();
    }
    if (Object.keys(results).length > 0) {
      onFilterChange(columnDefinition.cid, results);
    } else {
      onFilterChange(columnDefinition.cid, null);
    }
  };
  const handleBeforeChange = (date: Dayjs | null) => {
    setBefore(date);
    handleDatesChange(date, after);
  };
  const handleAfterChange = (date: Dayjs | null) => {
    setAfter(date);
    handleDatesChange(before, date);
  };
  return (
    <>
      <KeyboardDatePicker
        value={after}
        onChange={handleAfterChange}
        variant="inline"
        inputVariant="filled"
        label={`${fieldProps.label} before`}
        helperText={fieldProps.helperText}
        format={formatTemplate}
        autoOk
      />
      <KeyboardDatePicker
        value={before}
        onChange={handleBeforeChange}
        variant="inline"
        inputVariant="filled"
        label={`${fieldProps.label} after`}
        helperText={fieldProps.helperText}
        format={formatTemplate}
        autoOk
      />
    </>
  );
};
