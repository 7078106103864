import { FC } from 'react';
import { Grid } from '@material-ui/core';

import { AccordionList } from 'components';
import { certificationsByPersonDetailsRoute } from 'route';
import { AppShellBaseProps, DetailsAppShell } from 'components/AppShell';
import { Content } from 'components/Page';
import { PrintOutForm } from 'Print/component/PrintOutForm';
import { useUserProfileQuery } from 'MyProfile/query/myProfile.query';
import { getFullName } from 'MyProfile/model/MyProfile';
import { usePrintQuery } from 'Print/query/printQuery';
import { Print } from 'Print/model/Print';
import { AccordionListProps } from 'components/Accordion/AccordionList';
import Typography from '@material-ui/core/Typography';

export interface PrintOutCertificatesPageProps extends AppShellBaseProps {
  userId: string;
}

export const mapPrintToAccordions = (
  userId: string,
  prints?: Print[]
): AccordionListProps['accordions'] =>
  (prints ?? []).map((print) => ({
    summaryHeader: print.businessUnit.description,
    summaryCaption: 'Division',
    TransitionProps: { unmountOnExit: false },
    children: <PrintOutForm print={print} userId={userId} />,
  }));

export const PrintOutCertificatesPage: FC<PrintOutCertificatesPageProps> = ({
  logout,
  userId,
}) => {
  const { data, isLoading } = usePrintQuery(userId);
  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
  } = useUserProfileQuery(userId);

  return (
    <DetailsAppShell
      title="Print out certificates"
      loading={isLoading}
      logout={logout}
      defaultGoBackPath={certificationsByPersonDetailsRoute.createRoute({
        userId,
      })}
    >
      <Content
        title={getFullName(userProfile)}
        subtitle={userProfile?.email}
        loading={isLoading || isUserProfileLoading}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data?.length ? (
              <AccordionList
                accordions={mapPrintToAccordions(userId, data)}
                spaceBetween
                defaultExpandedFirst
              />
            ) : (
              <Typography variant="body1">
                No certifications to print
              </Typography>
            )}
          </Grid>
        </Grid>
      </Content>
    </DetailsAppShell>
  );
};
