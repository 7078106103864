import { Dispatch } from 'react';
import { FluidForEditors, SearchFiltersFormSmart } from 'components';

import * as Model from './model';

export default function FiltersPart(p: {
  defaultQuery: Model.TrainingSetFindQueryDTO
  onQuery: Dispatch<Model.TrainingSetFindQueryDTO>;
}) {

  return (
    <SearchFiltersFormSmart
      placeholder="Search by Name ..."
      searchBarField="name"
      initialValues={p.defaultQuery}
      onSubmit={p.onQuery}
    >
      {() => (
        <FluidForEditors inRow={2}>
          {/* <DictionaryField name="businessArea" label="Business Area" query={API.dictionaryQuery({ key: 'BusinessArea' })} />
          <DictionaryField name="division" label="Division" query={API.dictionaryQuery({ key: 'Division' })} />
          <DictionaryField name="functionalJob" label="Employee Job" query={API.dictionaryQuery({ key: 'FunctionalJob' })} />
          <DictionaryField name="country" label="Country/Territory" query={API.dictionaryQuery({ key: 'Country' })} />
          <DictionaryField name="userType" label="ABB / External" query={API.dictionaryQuery({ key: 'UserType' })} /> */}
        </FluidForEditors>

      )}
    </SearchFiltersFormSmart>
  );
}
