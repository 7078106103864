import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface PGUnit {
  id: number;
  name: string;
  code: string;
}

export const getPgUnitFullName = (pgUnit: PGUnit): string =>
  `${pgUnit.code} ${pgUnit.name}`;

export const mapToAutocompleteFieldItems = (
  pgUnits: PGUnit[]
): AutocompleteFieldItem[] =>
  pgUnits.map((unit) => ({ id: unit.id, name: getPgUnitFullName(unit) }));
