import { FC, ReactNode, useState } from 'react';
import { useMutation } from 'react-query';

import { RequirementWorkFlowActions } from 'enums/RequirementWorkFlowActions';
import { useModal, useMutating } from 'hooks';
import { Button } from 'components';
import { useToasts } from 'components/ToastProvider';
import { ApiError } from 'model';
import { requirementApi } from 'Requirement/service/requirement.api';
import { EditDatesModal } from 'CertificationPlansPage/components/EditDatesModal/EditDatesModal';
import { RequirementPlanActionParams } from 'Requirement/model/RequirementPlanActionParams';
import { Loader } from 'components/Loader/Loader';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import { Requirement } from 'Requirement/model/Requirement';

export interface RequirementPlanActionsProps {
  certificateId: number;
  requirementIds: number[];
  workflowActions: RequirementWorkFlowActions[];
  requirements: Requirement[];
  displayDisabledActions?: boolean;
  onActionSuccess?: () => void;
}

const submitKey = 'requirement-submit';
const approveKey = 'requirement-approve';
const rejectKey = 'requirement-reject';
const resetKey = 'requirement-reset';
const backToWaitingApprovalKey = 'requirement-back-to-waiting-approval';
const renewKey = 'requirement-renew';

export const mutatingFilterKeys = [
  submitKey,
  approveKey,
  rejectKey,
  resetKey,
  backToWaitingApprovalKey,
  renewKey,
];

export const getSuccessMessage = (actionKey: string): string => {
  switch (actionKey) {
    case submitKey:
      return 'Submit requirement action success';
    case approveKey:
      return 'Approve requirement action success';
    case resetKey:
      return 'Reset requirement action success';
    case rejectKey:
      return 'Reject requirement action success';
    case backToWaitingApprovalKey:
      return 'Back to waiting approval requirement action success';
    case renewKey:
      return 'Renew requirement action success';
    default:
      return 'Action success';
  }
};

export const getButtonContent = (title: string, loading: boolean): ReactNode =>
  loading ? <Loader size={24} /> : title;

export const RequirementPlanActions: FC<RequirementPlanActionsProps> = ({
  certificateId,
  requirementIds,
  requirements,
  workflowActions,
  displayDisabledActions = true,
  onActionSuccess,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [reason, setReason] = useState('');
  const { showError, showSuccess } = useToasts();
  const isMutating = useMutating([...mutatingFilterKeys]);
  const params: RequirementPlanActionParams = { certificateId, requirementIds };

  const onSuccess = async (actionKey: string) => {
    showSuccess(getSuccessMessage(actionKey));
    onActionSuccess && onActionSuccess();
  };
  const onError = async (error: ApiError) => showError(error.message);
  const { mutate: submit, isLoading: submitLoading } = useMutation<
    unknown,
    ApiError
  >(() => requirementApi.submit(params), {
    mutationKey: submitKey,
    onSuccess: () => onSuccess(submitKey),
    onError,
  });
  const { mutate: renew, isLoading: renewLoading } = useMutation<
    unknown,
    ApiError
  >(() => requirementApi.renew(params), {
    mutationKey: renewKey,
    onSuccess: () => onSuccess(renewKey),
    onError,
  });
  const { mutate: approve, isLoading: approveLoading } = useMutation<
    unknown,
    ApiError
  >(() => requirementApi.approve(params), {
    mutationKey: approveKey,
    onSuccess: () => onSuccess(approveKey),
    onError,
  });
  const { mutate: reject, isLoading: rejectLoading } = useMutation<
    unknown,
    ApiError
  >(() => requirementApi.reject({ ...params, rejectReason: reason }), {
    mutationKey: rejectKey,
    onSuccess: async () => {
      await onSuccess(rejectKey);
      setReason('');
    },
    onError,
  });
  const { mutate: reset, isLoading: resetLoading } = useMutation<
    unknown,
    ApiError
  >(() => requirementApi.reset(params), {
    mutationKey: resetKey,
    onSuccess: () => onSuccess(resetKey),
    onError,
  });
  const {
    mutate: resetToApproval,
    isLoading: resetToApprovalLoading,
  } = useMutation<unknown, ApiError>(
    () => requirementApi.resetToApproval(params),
    {
      mutationKey: backToWaitingApprovalKey,
      onSuccess: () => onSuccess(backToWaitingApprovalKey),
      onError,
    }
  );
  const closeDialog = () => setIsDialogOpen(false);
  const renderRejectDialog = (): ReactNode => (
    <Dialog open={isDialogOpen} onClose={closeDialog}>
      <DialogTitle>Are you sure to reject?</DialogTitle>
      <DialogContent>
        <TextField
          label="Reason"
          variant="outlined"
          multiline
          onChange={(ev) => setReason(ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            reject();
            closeDialog();
          }}
          disabled={!reason}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
  const getIsDisabled = (action: RequirementWorkFlowActions): boolean =>
    isMutating || !workflowActions.includes(action);
  const displayButton = (action: RequirementWorkFlowActions): boolean =>
    displayDisabledActions || workflowActions.includes(action);
  const { Modal, openModal } = useModal((closeModal) => (
    <EditDatesModal
      requirementsIds={requirementIds}
      certificatePlanId={certificateId}
      initialSubmitDate={requirements.map(r => r.submitDate).find(date => !!date) ?? null}
      initialApprovedDate={requirements.map(r => r.approvalDate).find(date => !!date) ?? null}
      initialExpirationDate={requirements.map(r => r.expirationDate).find(date => !!date) ?? null}
      initialEnrollmentDate={null}
      initialDueDate={null}
      initialRenewalDate={null}
      onClose={closeModal}
      onSuccess={onActionSuccess}
    />
  ));

  return (
    <>
      {displayButton(RequirementWorkFlowActions.EditDates) && (
        <>
          <Button 
            onClick={openModal}
            disabled={getIsDisabled(RequirementWorkFlowActions.EditDates)}
          >
          Edit Dates
          </Button>
          <Modal />
        </>
      )}
      {displayButton(RequirementWorkFlowActions.BackToWaitingApproval) && (
        <Button
          onClick={() => resetToApproval()}
          disabled={getIsDisabled(
            RequirementWorkFlowActions.BackToWaitingApproval
          )}
        >
          {getButtonContent('Back to waiting approval', resetToApprovalLoading)}
        </Button>
      )}
      {displayButton(RequirementWorkFlowActions.Renew) && (
        <Button
          onClick={() => renew()}
          disabled={getIsDisabled(RequirementWorkFlowActions.Renew)}
        >
          {getButtonContent('Renew', renewLoading)}
        </Button>
      )}
      {displayButton(RequirementWorkFlowActions.Reset) && (
        <Button
          onClick={() => reset()}
          disabled={getIsDisabled(RequirementWorkFlowActions.Reset)}
        >
          {getButtonContent('Reset', resetLoading)}
        </Button>
      )}
      {displayButton(RequirementWorkFlowActions.Reject) && (
        <>
          {renderRejectDialog()}
          <Button
            onClick={() => setIsDialogOpen(true)}
            disabled={getIsDisabled(RequirementWorkFlowActions.Reject)}
          >
            {getButtonContent('Reject', rejectLoading)}
          </Button>
        </>
      )}
      {displayButton(RequirementWorkFlowActions.Approve) && (
        <Button
          onClick={() => approve()}
          disabled={getIsDisabled(RequirementWorkFlowActions.Approve)}
        >
          {getButtonContent('Approve', approveLoading)}
        </Button>
      )}
      {displayButton(RequirementWorkFlowActions.Submit) && (
        <Button
          onClick={() => submit()}
          disabled={getIsDisabled(RequirementWorkFlowActions.Submit)}
          color="secondary"
        >
          {getButtonContent('Submit', submitLoading)}
        </Button>
      )}
    </>
  );
};
