import fileDownload from 'js-file-download';
import { apiClient } from 'service';
import { MyProfile } from 'MyProfile/model/MyProfile';
import { EditUserDto } from 'MyProfile/model/EditUserDto';
import { PaginatedList, ApiError, PaginationDto, Sort } from 'model';
import { UserSearchFilters } from 'MyProfile/model/UserSearchFilters';
import { UserDeactivatedSearchFilters } from 'MyProfile/model/UserDeactivatedSearchFilters';
import { UserHrgtSearchFilters } from 'MyProfile/model/UserHrgtSearchFilters';
import { AddInternalDto } from 'MyProfile/model/AddInternalDto';
import { AddExternalDto } from 'MyProfile/model/AddExternalDto';
import { AddUserResponse } from 'MyProfile/model/AddUserResponse';
import { EditExternalUserDto } from 'MyProfile/model/EditExternalUserDto';
import { UserAuthorization } from 'MyProfile/model/UserAuthorization';
import { UpdateAuthorizationsDto } from 'MyProfile/model/UpdateAuthorizationsDto';
import { DeleteAuthorizationsDto } from 'MyProfile/model/DeleteAuthorizationsDto';
import { getDateNow } from 'utils';

const domainPath = '/users';

const get = async (): Promise<MyProfile> => {
  try {
    const response = await apiClient.get(`${domainPath}/my-profile`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getUserProfile = async (userId: string): Promise<MyProfile> => {
  try {
    const response = await apiClient.get(`${domainPath}/${userId}/profile`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const post = async (dto: EditUserDto): Promise<unknown> => {
  try {
    const response = await apiClient.post(`${domainPath}/my-profile`, dto);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getSettings = async <T>(key: string)=> {
  try {
    const response = await apiClient.get<T>(`${domainPath}/my-profile/additional-setting/${key}`);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
}
  const saveSettings = async (key: string, value: string) => {
  try {
    const response = await apiClient.post<never>(`${domainPath}/my-profile/additional-setting/${key}`, value);
    return undefined;
  } catch (e) {
    throw new ApiError(e);
  }
};

const updateUser = async (
  userId: number,
  dto: EditUserDto
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${userId}/profile`,
      dto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const updateExternalUser = async (
  userId: number,
  dto: EditExternalUserDto
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${userId}/profile/external`,
      dto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const askRemoval = async (userId: string): Promise<unknown> => {
  try {
    const response = await apiClient.delete(
      `${domainPath}/${userId}/ask-removal`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const search = async (
  pagination: PaginationDto,
  sort: Sort<MyProfile>,
  filters: UserSearchFilters
): Promise<PaginatedList<MyProfile>> => {
  try {
    const response = await apiClient.post(`${domainPath}/search`, {
      ...pagination,
      ...filters,
      ...sort,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const searchDeactivated = async (
  pagination: PaginationDto,
  filters: UserDeactivatedSearchFilters
): Promise<PaginatedList<MyProfile>> => {
  try {
    const response = await apiClient.post(`${domainPath}/search-deactivated`, {
      ...pagination,
      ...filters,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const searchHrgt = async (
  pagination: PaginationDto,
  sort: Sort<MyProfile>,
  filters: UserHrgtSearchFilters
): Promise<PaginatedList<MyProfile>> => {
  try {
    const response = await apiClient.post(`${domainPath}/search/hrgt`, {
      ...pagination,
      ...filters,
      ...sort,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const reactiveUsers = async (usersIds: number[]): Promise<unknown> => {
  try {
    const response = await apiClient.post(`${domainPath}/reactive-users`, {
      usersIds,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const archiveUsers = async (usersIds: number[]): Promise<unknown> => {
  try {
    const response = await apiClient.post(`${domainPath}/archive-users`, {
      withCertificateList: true,
      usersIds,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export interface MergeUsersDto {
  sourceUsersIds: number[];
  destinationUserId: number;
}
const mergeUsers = async (dto: MergeUsersDto): Promise<unknown> => {
  try {
    const response = await apiClient.post(`${domainPath}/merge-users`, dto);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const addInternals = async (
  dto: AddInternalDto[]
): Promise<AddUserResponse> => {
  try {
    const response = await apiClient.put(`${domainPath}/add-internals`, dto);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const addExternals = async (
  dto: AddExternalDto[]
): Promise<AddUserResponse> => {
  try {
    const response = await apiClient.put(`${domainPath}/add-externals`, dto);
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const getAuthorizations = async (
  userId: number
): Promise<UserAuthorization[]> => {
  try {
    const response = await apiClient.get(
      `${domainPath}/${userId}/authorizations`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const updateAuthorizations = async (
  dto: UpdateAuthorizationsDto
): Promise<unknown> => {
  try {
    const response = await apiClient.put(
      `${domainPath}/${dto.userId}/authorizations`,
      dto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const deleteAuthorizations = async (
  dto: DeleteAuthorizationsDto
): Promise<unknown> => {
  try {
    const response = await apiClient.delete(
      `${domainPath}/${dto.userId}/authorizations`,
      { data: dto }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const exportToExcel = async (
  filters: UserSearchFilters
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/export-excel`,
      filters,
      {
        responseType: 'blob',
      }
    );
    fileDownload(response.data, `Export_search_users_${getDateNow()}.xlsx`);
    return [];
  } catch (e) {
    throw new ApiError(e);
  }
};

const exportToExcelDeactivated = async (
  filters: UserDeactivatedSearchFilters
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/export-excel-archive`,
      filters,
      {
        responseType: 'blob',
      }
    );
    fileDownload(response.data, `Export_archived_users_${getDateNow()}.xlsx`);
    return [];
  } catch (e) {
    throw new ApiError(e);
  }
};

export const myProfileApi = {
  get,
  getUserProfile,
  post,
  updateUser,
  updateExternalUser,
  askRemoval,
  search,
  searchDeactivated,
  searchHrgt,
  archiveUsers,
  mergeUsers,
  reactiveUsers,
  addExternals,
  addInternals,
  getAuthorizations,
  updateAuthorizations,
  deleteAuthorizations,
  exportToExcel,
  exportToExcelDeactivated,

  getSettings,
  saveSettings
};
