import { makeStyles, styled } from '@material-ui/core';
import { MaterialDesignContent } from 'notistack';
import { AbbTheme } from 'theme/createAbbTheme';

export const useToastProviderStyles = makeStyles(
  (theme: AbbTheme) => ({
    root: {
      minWidth: 300,
    },
    containerRoot: {
      ...theme.typography.caption,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    iconBlue:{
      color: theme.palette.blue?.main
    },
    iconYellow:{
      color: theme.palette.yellow?.main
    },
    iconRed:{
      color: theme.palette.red?.main
    },
    iconGreen:{
      color: theme.palette.green?.main
    },
    cancel: {
      color: 'inherit',
    },
  }),
  {
    name: 'ToastProvider',
  }
);

export const StyledSnackbar = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    color: `#4a4a4a !important`,
    border: `1px solid #007A33`,
    backgroundColor: `#E5F1EA !important`,
    fontWeight: 700,
  },  
  '&.notistack-MuiContent-error': {
    color: `#4a4a4a !important`,
    border: `1px solid #ff000f`,
    backgroundColor: `#FFE5E6 !important`,
    fontWeight: 700,
  },
  '&.notistack-MuiContent-info': {
    color: `#4a4a4a !important`,
    border: `1px solid #004C97`,
    backgroundColor: `#E5EDF4 !important`,
    fontWeight: 700,
  },
  '&.notistack-MuiContent-warning': {
    color: `#4a4a4a !important`,
    border: `1px solid #FFD100`,
    backgroundColor: `#FFFBE5 !important`,
    fontWeight: 700,
  },
}));
