import { useToasts } from 'components/ToastProvider';
import { useMutation } from 'react-query';
import { reportsApi } from '../service/reports.api';

export const saveFilterKey = 'saveFilterKey';

export const useFilterSettingsMutation = () => {
  const { showSuccess, showError } = useToasts();
  return useMutation(reportsApi.saveFilters, {
    onError: () => showError('Cannot save settings'),
    onSuccess: () => showSuccess('Settings save'),
  });
};
