import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryErrorResetBoundary as ReactQueryErrorResetBoundary } from 'react-query';
import Typography from '@material-ui/core/Typography';

import { ApiError } from 'model';
import { ReactComponent as NoInternetConnectionSvg } from 'components/QueryErrorResetBoundary/svg/NoInternetConnection.svg';
import { ErrorDetailsAppShell } from 'components/QueryErrorResetBoundary/ErrorDetailsAppShell';
import { Root, MessageRoot } from './QueryErrorResetBoundary.style';

const getErrorMessage = (error: ApiError): string =>
  `${error.message} (${error.code})`;

export const IsBrowserOnline: FC = ({ children }) =>
  window.navigator.onLine ? (
    <>{children}</>
  ) : (
    <Root>
      <NoInternetConnectionSvg />
      <MessageRoot>
        <Typography variant="h4">No internet connection</Typography>
      </MessageRoot>
    </Root>
  );

export const QueryErrorResetBoundary: FC = ({ children }) => (
  <ReactQueryErrorResetBoundary>
    {({ reset }) => (
      <ErrorBoundary
        onReset={reset}
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorDetailsAppShell
            errorCode={(error as ApiError).code}
            errorMsg={getErrorMessage(error as ApiError)}
            handleOnTryAgain={resetErrorBoundary}
          />
        )}
      >
        <IsBrowserOnline>{children}</IsBrowserOnline>
      </ErrorBoundary>
    )}
  </ReactQueryErrorResetBoundary>
);
