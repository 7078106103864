export enum CPStatusType {
  InProcess = 0, // In Process / Enrolled
  WaitingApproval = 1, // Waiting Approval
  Approved = 2, // Completed
  Expiring = 3, // Expiring
  WaitingRenewal = 4, // Waiting Renewal
  Renewed = 5, // inactive skip mapping
  Expired = 6, // Expired
  Withdrawn = 7, // inactive skip mapping
  ObjectveExpired = 8, // inactive skip mapping
  PrerequisiteExpired = 9, // inactive skip mapping
  WaitingRenewalExpired = 10, // Waiting Renewal (Expired)
  Rejected = 11, // Rejected
  PendingQuestionnaire = 21,
  PendingQuestionnaireExpired = 22,
  RenewalEnrollment = 23,
  RenewalEnrollmentExpired = 24,
  ExpiringNoRenewal = 25, // Expiring without possibility to renew
  ExpiredNoRenewal = 26, // Expired without possibility to renew
  NotEnrolled = 44, // NotEnrolled (for missing prerequisites)
  RemovalPending = 98, // inactive skip mapping
  Removed = 99, // Removed
}
