import { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { AppShellBaseProps, DetailsAppShell } from 'components/AppShell';
import { CertificationPlansDetailsParams } from 'route';
import { Content } from 'components/Page';
import { Button, DataObjectView } from 'components';
import { formatDate } from 'utils';
import dayjs from 'dayjs';
import {
  CertificationPlansRequirementsTable,
  columns,
} from 'Requirement/component/PlanRequirementsTable/CertificationPlansRequirementsTable';
import {
  certificationPlansKey,
  useCertificationPlansQuery,
} from 'CertificationPlansPage/query/certificationPlansQuery';
import { CPStatusChip } from 'components/StatusChip';
import { PrerequisitesTable } from 'CertificationPlansPage/components/PrerequisitesTable';
import { CertificationRequirementCommentsTable } from 'CertificationPlansPage/components/CertificationRequirementCommentsTable/CertificationRequirementCommentsTable';
import { EditDatesModal } from 'CertificationPlansPage/components/EditDatesModal/EditDatesModal';
import { RecommendedCertificatesTable } from 'CertificationPlansPage/components/RecommendedCertificatesTable';
import {
  CertificationPlanActions,
  mutatingFilterKeys,
} from 'CertificationPlansPage/components/CertificationPlanActions';
import { HistoryTable } from 'CertificationPlansPage/components/HistoryTable';
import { AlternativesCertificatesTable } from 'CertificationPlansPage/components/AlternativesCertificatesTable';
import { CertificationRequirementCommentForm } from 'CertificationPlansPage/components/CertificationRequirementCommentForm/CertificationRequirementCommentForm';
import { CertificationRequirementAttachments } from 'CertificationPlansPage/components/CertificationRequirementAttachments/CertificationRequirementAttachments';
import { useModal, useMutating } from 'hooks';
import { QuestionnarieSection } from 'CertificationPlansPage/components/QuestionnarieSection';
import {
  PeriodQuestionnaireQuestionAnswer,
  Question2Choices,
  Question3Choices,
  QuestionnarieDetails,
  SaveQuestionnarieDetails,
} from 'CertificationPlansPage/model/QuestionnarieDetails';
import { certificationPlanApi } from 'CertificationPlansPage/service/certificationPlans.api';
import { useMutation, useQueryClient } from 'react-query';
import { useToasts } from 'components/ToastProvider';
import { CertificationPlanWorkFlowActions } from 'enums/CertificationPlanWorkFlowActions';
import { useStyles } from './CertificationPlansDetailsPage.style';
import { useCertificationPlansHistoryQuery } from '../../CertificationPlansPage/query/certificationPlansHistoryQuery';
import { usePlanRequirementsQuery } from '../../Requirement/query/requirementQuery';

export interface CertificationPlansDetailsPageProps extends AppShellBaseProps { }

export const submitQuestionnarieKey = 'submit-questionnarie-key';

// eslint-disable-next-line max-len
export const CertificationPlansDetailsPage: FC<CertificationPlansDetailsPageProps> = (
  props
) => {
  const { logout } = props;
  const { certificationPlanId } = useParams<CertificationPlansDetailsParams>();
  const [
    nextQuestionnarie,
    setNextQuestionnarie,
  ] = useState<QuestionnarieDetails>();
  const { isLoading, data, refetch } = useCertificationPlansQuery(
    Number(certificationPlanId)
  );
  const {
    isLoading: historyLoading,
    data: historyData,
  } = useCertificationPlansHistoryQuery(Number(certificationPlanId));
  const {
    isLoading: requirementsLoading,
    data: requirementsData,
    refetch: requirementsRefetch,
  } = usePlanRequirementsQuery(Number(certificationPlanId));
  const { showSuccess, showError } = useToasts();
  const queryClient = useQueryClient();
  const isMutating = useMutating([
    ...mutatingFilterKeys,
    submitQuestionnarieKey,
  ]);
  const { mutate: submitQuestionnarie } = useMutation(
    (data: SaveQuestionnarieDetails) =>
      certificationPlanApi.submitQuestionnarie(
        Number(certificationPlanId),
        data
      ),
    {
      mutationKey: submitQuestionnarieKey,
      onSuccess: () => {
        showSuccess('Questionnaire submitted');
        queryClient.invalidateQueries(certificationPlansKey);
      },
      onError: () => showError('Questionnaire submit fail'),
    }
  );

  const classes = useStyles();
  const { Modal, openModal } = useModal((closeModal) => (
    <EditDatesModal
      certificatePlanId={data?.id}
      initialExpirationDate={data?.expirationDate ?? null}
      initialEnrollmentDate={data?.enrollmentDate ?? null}
      initialApprovedDate={data?.approvedDate ?? null}
      initialRenewalDate={data?.renewalDate ?? null}
      initialDueDate={data?.dueDate ?? null}
      initialSubmitDate={null}
      onClose={closeModal}
    />
  ));

  const canEditDates =
    data?.userActions?.includes(CertificationPlanWorkFlowActions.EditDates) ??
    false;

  const canSubmitQuestionnarie =
    data?.userActions?.includes(
      CertificationPlanWorkFlowActions.FillinQuestionnaire
    ) ?? false;

  const allQuestionsFilled =
    nextQuestionnarie?.question1 != PeriodQuestionnaireQuestionAnswer.NotSelected &&
    nextQuestionnarie?.question2 != Question2Choices.NotSelected &&
    nextQuestionnarie?.question3 != Question3Choices.NotSelected;

  const handleSubmitQuestionnarie = () => {
    const submitData: SaveQuestionnarieDetails = {
      questionnaireId: nextQuestionnarie?.id ?? -1,
      question1AnswerId:
        nextQuestionnarie?.question1 ?? PeriodQuestionnaireQuestionAnswer.Never,
      question2AnswerId: nextQuestionnarie?.question2 ?? Question2Choices.No,
      question3AnswerId: nextQuestionnarie?.question3 ?? Question3Choices.No,
    };
    submitQuestionnarie(submitData);
  };

  const renderQuestionnarieSection = () =>
    data?.questionnaire ||
      data?.userActions?.includes(
        CertificationPlanWorkFlowActions.FillinQuestionnaire
      ) ? (
      <QuestionnarieSection
        disabled={!canSubmitQuestionnarie}
        questionnarie={data?.questionnaire}
        onChange={(q) => {
          setNextQuestionnarie(q);
        }}
      />
    ) : null;

  const renderContentActions = () => (
    <CertificationPlanActions
      certificationPlanId={Number(certificationPlanId)}
      userCertificationPlan={data?.userCertificationPlan}
      userEnrollmentRequest={data?.userEnrollmentRequest}
      workflowActions={data?.userActions ?? []}
      onActionSuccess={refetch}
    />
  );

  const renderDetailsActions = () => (
    <>
      {canEditDates && (
        <>
          <Button onClick={openModal}>Edit dates</Button>
          <Modal />
        </>
      )}
      {canSubmitQuestionnarie && (
        <Button onClick={handleSubmitQuestionnarie} color="secondary" disabled={!allQuestionsFilled}>
          Submit Questionnaire
        </Button>
      )}
    </>
  );
  return (
    <DetailsAppShell
      title={data?.title ?? ''}
      subtitle={
        data?.user &&
        `${data?.user?.firstName} ${data?.user?.lastName} (${data?.user?.email})`
      }
      loading={isLoading}
      logout={logout}
    >
      <Content
        loading={isLoading}
        mutating={isMutating}
        mutatingLabel="Applying action..."
        title={data?.title ?? ''}
        subtitle={data?.purpose ?? ''}
        renderActions={renderContentActions}
      >
        <Grid container spacing={3}>
          {data && (
            <>
              <Grid xs={12} item>
                <DataObjectView
                  title="Certificate details"
                  item={data}
                  dataSetMaps={[
                    {
                      field: 'division',
                      headerName: 'Business Area',
                      renderField: ({ division }) => division?.name ?? '...',
                    },
                    {
                      field: 'businessUnit',
                      headerName: 'Division',
                      renderField: (item) => item?.businessUnit?.description,
                    },
                    {
                      field: 'productGroup',
                      headerName: 'Product group',
                      renderField: ({ productGroup }) => productGroup ? `${productGroup?.code} - ${productGroup?.name}` : '...',
                    },
                    {
                      field: 'release',
                      headerName: 'Release',
                    },
                    { field: 'mainFunction', headerName: 'Main function' },
                    {
                      field: 'level',
                      headerName: 'Level',
                      renderField: ({ level }) => level?.description,
                    },
                    {
                      field: 'scope',
                      headerName: 'Scope',
                      renderField: ({ scope }) => scope?.description,
                    },
                    {
                      field: 'scopeDetails',
                      headerName: 'Scope details',
                      renderField: ({ scopeDetails }) => //scopeDetail?.description,
                        <>
                          {Array.isArray(scopeDetails) && scopeDetails.length ? scopeDetails.map((sd, i) => (
                            <div key={i}>{sd.description}</div>
                          )) : '...'}
                        </>
                    },
                    {
                      field: 'approverType',
                      headerName: 'Approver type',
                      renderField: ({ approverType }) => approverType?.description,
                    },
                    {
                      field: 'approvers',
                      headerName: 'Approvers',
                      renderField: ({ approvers }) =>
                        <>
                          {Array.isArray(approvers) && approvers.length ? approvers.map((approver, i) => (
                            <div key={i}>{approver}</div>
                          )) : '...'}
                        </>
                    },
                    {
                      field: 'workType',
                      headerName: 'Work type',
                      renderField: ({ workType }) =>
                        <>
                          {Array.isArray(workType) && workType.length ? workType.map((work, i) => (
                            <div key={i}>{work}</div>
                          )) : '...'}
                        </>
                    },
                    {
                      field: 'enrollmentApproverType',
                      headerName: 'Enrollment Approver type',
                      renderField: ({ enrollmentApproverType }) => enrollmentApproverType?.description ?? '...',
                    },
                    {
                      field: 'enrollmentApprovers',
                      headerName: 'Enrollment Approvers',
                      renderField: ({ enrollmentApprovers }) =>
                        <>
                          {Array.isArray(enrollmentApprovers) && enrollmentApprovers.length ? enrollmentApprovers.map((enrollmentApprover, i) => (
                            <div key={i}>{enrollmentApprover}</div>
                          )) : '...'}
                        </>
                    },
                    {
                      field: 'availableFor',
                      headerName: 'Available for',
                      renderField: ({ availableFor }) =>
                        <>
                          {(availableFor ?? []).map((availableForItem, i) => (
                            <div key={i}>{availableForItem}</div>
                          ))}
                        </>
                    },
                    {
                      field: 'expirationFrame',
                      headerName: 'Expiration period'
                    },
                    {
                      field: 'renewalFrame',
                      headerName: 'Renewal'
                    },
                    {
                      field: 'duration',
                      headerName: 'Estimated Certificate Duration'
                    },
                    {
                      field: 'ownerCountry',
                      headerName: 'Owner country'
                    },
                  ]}
                />
              </Grid>

              <Grid xs={12} item>
                <DataObjectView
                  title="Enrollment details"
                  item={data}
                  dataSetMaps={[
                    {
                      field: 'status',
                      headerName: 'Status',
                      renderField: ({ status }) => (
                        <CPStatusChip status={status} />
                      ),
                    },
                    {
                      field: 'enrollmentDate',
                      headerName: 'Enrollment date',
                      renderField: ({ enrollmentDate }) =>
                        formatDate(enrollmentDate),
                    },
                    {
                      field: 'approvedDate',
                      headerName: 'Approval date',
                      renderField: ({ approvedDate }) => formatDate(approvedDate),
                    },
                    {
                      field: 'renewalDate',
                      headerName: 'Renewal date',
                      renderField: ({ renewalDate }) => formatDate(renewalDate),
                    },
                    {
                      field: 'expirationDate',
                      headerName: 'Expiration date',
                      renderField: ({ expirationDate }) =>
                        formatDate(expirationDate),
                    },
                    {
                      field: 'dueDate',
                      headerName: 'Due date',
                      renderField: ({ dueDate }) => dueDate && dayjs(dueDate) < dayjs().set('hour', 0).set('minute', 0) ?
                        <span style={{ color: 'red' }}>{formatDate(dueDate)}</span>
                        : formatDate(dueDate),
                    },
                  ]}
                  renderExtraContent={renderQuestionnarieSection}
                  renderActions={renderDetailsActions}
                />
              </Grid>
            </>
          )}
          <Grid xs={12} item>
            <PrerequisitesTable prerequisites={data?.prerequisites ?? []} />
          </Grid>
          <Grid xs={12} item>
            <CertificationPlansRequirementsTable
              certificateId={Number(certificationPlanId)}
              displayRowActions
              requirementsCount={requirementsData?.requirementsCount}
              onRequirementActionSuccess={() => {
                queryClient.invalidateQueries(certificationPlansKey);
                requirementsRefetch();
              }}
              DataTableProps={{
                columns,
                rows: requirementsData?.requirements ?? [],
                loading: requirementsLoading,
                noDataMessage: 'No requirements to display',
                checkboxSelection: true,
                renderCollapseRow: (row, collapseRow) => (
                  <div>
                    <div className={classes.attachmentsArea}>                   
                      <CertificationRequirementAttachments
                        requirementId={row.id}
                        onAttachmentSuccess={row.isAttachmentMandatory ? requirementsRefetch : null}
                        userId={data!.user!.id}
                      />
                    </div>
                    <div className={classes.commentsArea}>
                      <CertificationRequirementCommentForm
                        requirementId={row.id}
                        onCancel={collapseRow}
                        onAdded={() => {
                          // eslint-disable-next-line 
                          row.additionalNotesCount += 1;
                        }
                        }
                      />
                      <CertificationRequirementCommentsTable
                        requirementId={row.id}
                      />
                    </div>
                  </div>
                ),
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <AlternativesCertificatesTable
              alternativesCertificates={data?.alternatives ?? []}
            />
          </Grid>
          <Grid xs={12} item>
            <RecommendedCertificatesTable
              recommendedCertificates={data?.recommended ?? []}
              title="Recommended Certificates"
            />
          </Grid>
          <Grid xs={12} item>
            <RecommendedCertificatesTable
              recommendedCertificates={data?.automaticEnrollment ?? []}
              title="Certificates automatically enrolled after completion"
            />
          </Grid>
          <Grid xs={12} item>
            <HistoryTable
              DataTableProps={{
                loading: historyLoading,
                rows: historyData ?? [],
                noDataMessage: '',
              }}
            />
          </Grid>
        </Grid>
      </Content>
    </DetailsAppShell>
  );
};

export default CertificationPlansDetailsPage;
