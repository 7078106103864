import { useMemo } from 'react';
import { ReportingFilters } from 'Reports/components/@types';
import { ColumnDefinition } from 'Reports/model/ColumnDefinition';

export const useReportingFieldDisabled = (
  columnDefinition: ColumnDefinition,
  filters: ReportingFilters
): boolean => {
  const disabled = useMemo(() => {
    const { dependsOn, cid } = columnDefinition;
    if (dependsOn && cid !== "AA0GBAMEAgIPCgICBAsFBQ" && cid !== "CAgOCg0KAQQLDQUIBAQIDg") {
      if (dependsOn.length > 0) {
        for (const dep of dependsOn) {
          if (!Object.keys(filters).some((k) => k === dep)) {
            return true;
          }
        }
      }
      return false;
    }
    return false;
  }, [columnDefinition, filters]);
  return disabled;
};
