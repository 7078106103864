import { useQuery } from 'react-query';

import { PlanRequirements } from 'Requirement/model/PlanRequirements';
import { requirementApi } from 'Requirement/service/requirement.api';

export const planRequirementsKey = 'plan-requirements';
export const usePlanRequirementsQuery = (certificatePlanId: number) =>
  useQuery<PlanRequirements>([planRequirementsKey, certificatePlanId], () =>
    requirementApi.getPlanRequirements(certificatePlanId)
  );
