export interface PaginationDto {
  pageSize: number;
  currentPageIndex: number;
}

export interface Pagination extends PaginationDto {
  total: number;
}

export const mapPaginationDto = (pagination: Pagination): PaginationDto => ({
  pageSize: pagination.pageSize,
  currentPageIndex: pagination.currentPageIndex,
});
