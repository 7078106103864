import { Dispatch } from 'react'
import {
  FluidForEditors,
  SearchFiltersFormSmart,
  DictionaryFieldByCode,
} from 'components'
import * as Model from './model'

type QueryModel = Model.CertificateListDefinitionFindQuery

export default function SearchForm(props: {
  defaulQuery: QueryModel
  onQuery: Dispatch<QueryModel>
}) {

  // eslint-disable-next-line
  const onSubmit = (query: QueryModel) => {
    props.onQuery(query)
  }

  return (
    <SearchFiltersFormSmart
      searchBarField='title'
      initialValues={props.defaulQuery}
      onSubmit={onSubmit}
    >
      {(fp) => (<>
        <FluidForEditors>
          <DictionaryFieldByCode name="businessArea" label="Business Area" query={({ key: 'BusinessArea' })} dependandFieldName="division" />
          <DictionaryFieldByCode name="division" label="Division" query={{ key: 'Division', businessArea: fp.values.businessArea }} />
          <DictionaryFieldByCode name="mainFunction" multiple label="Main Function" query={{ key: 'MainFunction', businessArea: fp.values.businessArea }} />
          <DictionaryFieldByCode name="productGroup" multiple label="Product Group" query={{ key: 'ProductGroup', division: fp.values.division }} />
          <DictionaryFieldByCode name="scope" label="Scope" query={{ key: 'Scope' }} />
          <DictionaryFieldByCode name="scopeDetails" multiple label="Scope Detail" helperText="Select Scope First" query={{ key: 'ScopeDetail', scope: fp.values.scope }} />
          <DictionaryFieldByCode name="country" multiple label="Country/Territory" query={{ key: 'Country' }} />
          <DictionaryFieldByCode name="userType" label="ABB/External" query={{ key: 'UserType' }} />
          <DictionaryFieldByCode name="channelType" multiple label="Channel Type" query={{ key: 'Channel' }} />
          {/* <DictionaryFieldByCode name="functionalJob" multiple label="Employee Job" query={{ key: 'FunctionalJob' }} /> */}
        </FluidForEditors>
        <FluidForEditors>
          <DictionaryFieldByCode name="level" multiple label="Level" query={{ key: 'Level' }} />
          <DictionaryFieldByCode name="status" multiple label="Status" query={{ key: 'CertificateListStatus' }} />

        </FluidForEditors></>
      )
      }
    </SearchFiltersFormSmart>
  )
}
