import { ApprovalGroupDetails } from 'ApprovalGroupsPage/model';
import { useQuery } from 'react-query';
import { ApprovalGroup } from '../model/ApprovalGroup';
import {
  getApprovalGroups,
  getApprovalGroupDetails,
} from '../service/approvalGroup.api';

export const approvalGroupsKey = 'approval-groups-key';
export const useApprovalGroupsQuery = (buId: number | null) =>
  useQuery<ApprovalGroup[]>(
    [approvalGroupsKey],
    () => getApprovalGroups(buId),
    { enabled: false }
  );

export const approvalGroupsDetailsKey = 'approval-groups-details-key';
export const useApprovalGroupsDetailsQuery = (groupId: number) =>
  useQuery<ApprovalGroupDetails>(
    [approvalGroupsDetailsKey],
    () => getApprovalGroupDetails(groupId),
    { enabled: false }
  );
