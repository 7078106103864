import {
  MsalAuthProvider,
  LoginType,
  AuthenticationState,
  AccessTokenResponse,
  IdTokenResponse,
} from 'react-aad-msal';
import { Account, Configuration } from 'msal';
import {
  IAccountInfo,
  IAuthProvider,
} from 'react-aad-msal/dist/typings/interfaces';
import { MOCK_LOGIN } from 'featureToggles';
import dayjs from 'dayjs';

export const OVERRIDE_TOKEN_KEY = 'override-token';

export interface TokenProfile {
  name: string;
}

const config: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY ?? '',
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID ?? '',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const authenticationParameters = {
  scopes: ['openid', 'profile', 'email', 'offline_access', 'User.Read'],
};

const parseJwtPayload = <T extends {} = TokenProfile>(
  token: string
): T | null => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
  if (base64) {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  return null;
};

interface TokenData {
  unique_name: string;
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
}

const getTokenData = (token: string): TokenData | null => {
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
  if (base64) {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  return null;
};

const checkTokenExpired = (): AuthenticationState => {
  const token = localStorage.getItem(OVERRIDE_TOKEN_KEY);
  if (!token) {
    return AuthenticationState.Unauthenticated;
  }
  const payload = getTokenData(token ?? '');
  const exp = payload?.exp;
  if (exp) {
    return dayjs.unix(exp).isAfter(dayjs())
      ? AuthenticationState.Authenticated
      : AuthenticationState.Unauthenticated;
  }
  return AuthenticationState.Unauthenticated;
};

export class MockMsalAuthProvider implements IAuthProvider {
  onAuthenticationStateChanged?: (
    state: AuthenticationState,
    account?: IAccountInfo
  ) => void;

  authenticationState: AuthenticationState =
    AuthenticationState.Unauthenticated;

  authenticationStateHandler: (state: AuthenticationState) => void = () => {};

  getAccount(): Account {
    const token = localStorage.getItem(OVERRIDE_TOKEN_KEY);
    const profile = parseJwtPayload<{ unique_name: string }>(String(token));
    this.authenticationState = checkTokenExpired();
    return new Account(
      '',
      '',
      profile?.unique_name ?? '',
      profile?.unique_name ?? '',
      {},
      '',
      ''
    );
  }

  getAccountInfo(): IAccountInfo {
    const token = localStorage.getItem(OVERRIDE_TOKEN_KEY) ?? '';
    const account = this.getAccount();
    return {
      jwtAccessToken: token,
      jwtIdToken: token,
      account,
    };
  }

  login(): void {
    this.authenticationState = checkTokenExpired();
    this.authenticationStateHandler(this.authenticationState);
    this.onAuthenticationStateChanged &&
      this.onAuthenticationStateChanged(this.authenticationState, undefined);
  }

  logout(): void {
    this.authenticationState = AuthenticationState.Unauthenticated;
    localStorage.removeItem(OVERRIDE_TOKEN_KEY);
    this.authenticationStateHandler(this.authenticationState);
    this.onAuthenticationStateChanged &&
      this.onAuthenticationStateChanged(this.authenticationState, undefined);
  }

  getError(): unknown {
    return null;
  }

  registerAuthenticationStateHandler(listener: unknown): void {
    this.authenticationStateHandler = listener as (
      state: AuthenticationState
    ) => void;
  }

  unregisterAuthenticationStateHandler(): void {
    this.authenticationStateHandler = () => {};
  }

  registerAcountInfoHandler(): void {}

  unregisterAccountInfoHandler(): void {}

  registerErrorHandler(listener: unknown): void {}

  unregisterErrorHandler(listener: unknown): void {}

  getAccessToken(): Promise<AccessTokenResponse> {
    const expiresOn = new Date();
    expiresOn.setFullYear(expiresOn.getFullYear() + 1);
    return new Promise((resolve) => {
      resolve({
        accessToken: String(localStorage.getItem(OVERRIDE_TOKEN_KEY)),
        scopes: [],
        expiresOn,
        state: '',
      });
    });
  }

  getIdToken(): Promise<IdTokenResponse> {
    return new Promise((resolve) =>
      resolve({
        idToken: {
          issuer: '',
          objectId: '',
          subject: '',
          tenantId: '',
          version: '',
          preferredName: '',
          name: '',
          homeObjectId: '',
          nonce: '',
          expiration: '',
          rawIdToken: String(localStorage.getItem(OVERRIDE_TOKEN_KEY)),
          claims: {},
          sid: '',
          cloudInstance: '',
        },
        state: '',
      })
    );
  }
}

export const authProvider = MOCK_LOGIN
  ? new MockMsalAuthProvider()
  : new MsalAuthProvider(config, authenticationParameters, {
      loginType: LoginType.Redirect,
      tokenRefreshUri: `${window.location.origin}/auth.html`,
    });
