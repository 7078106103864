import styled from 'styled-components';

export const Root = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: ${theme.spacing(12, 0, 0)};
  `}
`;

export const MessageRoot = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(2, 0)};
  `}
`;
