import { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { OpenInNew as MyLearningIcon } from '@material-ui/icons';
import {
  Badge,
  IconButton,
  Tooltip,
  makeStyles
} from '@material-ui/core';

import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CPStatusChip } from 'components/StatusChip';
import { formatDate } from 'utils';
import { Anchor } from 'components/Page/Anchor/Anchor';
import {
  getRequirementWorkflowActions,
  getSelectedRequirements,
  parseSelectedRequirementIds,
  Requirement,
} from 'Requirement/model/Requirement';
import { getCertificateApprover } from 'enums/CertificateApproverType';
import { AbbTheme } from 'theme/createAbbTheme';
import { RequirementPlanActions } from 'Requirement/component/RequirementPlanActions/RequirementPlanActions';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import { DataObjectView } from 'components';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import {
  DescriptionCaptionTypography,
  DescriptionTypography,
} from './PlanRequirementsTable.style';

export const basicColumns: DataTableColumn<Requirement>[] = [
  { field: 'listCode', headerName: 'Code', width: 30 },
  {
    field: 'listDescription',
    headerName: 'Description',
    width: 510,
    renderField: ({
      status,
      listDescription,
      listExtendedDescription,
      approverTypeLabel,
      approverTypeDescription,
      myLearningLink,
      myLearningLinkDescription,
      externalLink,
      externalLinkName
    }) => (
      <div>
        <CPStatusChip style={{ marginLeft: 0 }} status={status} />
        <DescriptionTypography variant="body2">
          {listDescription}
        </DescriptionTypography>
        {myLearningLink && (
          <DescriptionTypography variant="body2">
            <Anchor href={myLearningLink} hover>
              {myLearningLinkDescription} &nbsp; <MyLearningIcon fontSize="small" />
            </Anchor>
          </DescriptionTypography>
        )}
        <DescriptionCaptionTypography variant="caption"
          dangerouslySetInnerHTML={{ __html: listExtendedDescription }} />
        {externalLink && (
          <DescriptionTypography variant="body2">
            <Anchor href={externalLink} hover>
              {externalLinkName}
            </Anchor>
          </DescriptionTypography>
        )}
        {approverTypeLabel && (
          <Typography
            variant="body2"
            style={{ marginTop: 8, whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: approverTypeLabel }}
          />
        )}
        {approverTypeDescription && (
          <Typography
            variant="caption"
            style={{ fontStyle: 'italic', marginTop: 8 }}
          >
            {approverTypeDescription}
          </Typography>
        )}
      </div>
    ),
  },
  {
    field: 'submitDate',
    headerName: 'Submitted',
    width: 140,
    renderField: ({ submitDate }) => formatDate(submitDate),
  },
  {
    field: 'approvalDate',
    headerName: 'Approved',
    width: 140,
    //renderField: ({ approvalDate }) => formatDate(approvalDate),
    renderField: (row) => formatDate(row.renewalDate ?? row.approvalDate),
  },
  {
    field: 'expirationDate',
    headerName: 'Expiration',
    width: 140,
    renderField: ({ expirationDate }) => formatDate(expirationDate),
  },
];

export const catalogDetailsColumns: DataTableColumn<Requirement>[] = [
  { field: 'listCode', headerName: 'Code', width: 30 },
  {
    field: 'listDescription',
    headerName: 'Description',
    width: 600,
    renderField: ({
      listDescription,
      listExtendedDescription,
      myLearningLink,
      myLearningLinkDescription,
      externalLink,
      externalLinkName
    }) => (
      <div>
        <DescriptionTypography variant="body2">
          {listDescription}
        </DescriptionTypography>
        {myLearningLink && (
          <DescriptionTypography variant="body2">
            <Anchor href={myLearningLink} hover>
              {myLearningLinkDescription} &nbsp; <MyLearningIcon fontSize="small" />
            </Anchor>
          </DescriptionTypography>
        )}
        <DescriptionCaptionTypography variant="caption"
          dangerouslySetInnerHTML={{ __html: listExtendedDescription }} />
        {externalLink && (
          <DescriptionTypography variant="body2">
            <Anchor href={externalLink} hover>
              {externalLinkName}
            </Anchor>
          </DescriptionTypography>
        )}
      </div>
    ),
  },
  {
    field: 'approverType',
    headerName: 'Approved by',
    width: 80,
    renderField: ({ approverType }) => getCertificateApprover(approverType),
  },
  {
    field: 'listExpirationTime',
    headerName: 'Expiration',
  },
];

export const columns: DataTableColumn<Requirement>[] = [...basicColumns];

export interface CertificationPlansRequirementsTableProps {
  certificateId: number;
  displayRowActions: boolean;
  DataTableProps: DataTableProps<Requirement>;
  requirementsCount?: number;
  onRequirementActionSuccess?: () => void;
}

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    header: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    addComment: {
      minWidth: 120,
    },
  }),
  { name: 'CertificationPlansRequirementsTable' }
);

// eslint-disable-next-line max-len
export const CertificationPlansRequirementsTable: FC<CertificationPlansRequirementsTableProps> = ({
  certificateId,
  displayRowActions,
  requirementsCount,
  onRequirementActionSuccess,
  DataTableProps,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.header} variant="h6">
        Requirements {`(${requirementsCount ?? 0})`}
      </Typography>
      <DesktopSection>
        <DataTable
          rowSelector={(row) => row.id}
          renderSelectedActions={(selectedRows, clearSelected) =>
            displayRowActions && (
              <RequirementPlanActions
                certificateId={certificateId}
                requirementIds={parseSelectedRequirementIds(selectedRows)}
                requirements={getSelectedRequirements(DataTableProps.rows, selectedRows)}
                workflowActions={getRequirementWorkflowActions(
                  DataTableProps.rows,
                  selectedRows
                )}
                onActionSuccess={() => {
                  onRequirementActionSuccess && onRequirementActionSuccess();
                  clearSelected();
                }}
              />
            )
          }
          renderRowActions={(row, collapseRow, isExpanded) =>
            displayRowActions && (
              <div className={classes.actions}>
                <Badge badgeContent={row.additionalNotesCount >0 ? row.additionalNotesCount?.toString(): null} color="secondary">
                  <Tooltip title="To upload attachment or provide a comment, expand this requirement">
                    <IconButton onClick={collapseRow} >
                      {isExpanded ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Badge>
              </div>
            )
          }
          hideCollapseColumn
          headerRowAlwaysOnTop
          {...DataTableProps}
        />
      </DesktopSection>
      <MobileSection>
        <DataObjectList
          loading={DataTableProps.loading}
          items={DataTableProps.rows}
          renderDataObjectView={(item) => (
            <DataObjectView
              title={item.listCode}
              item={item}
              dataSetMaps={mapToDataSetMap(columns, ['listCode'])}
              renderActions={() =>
                displayRowActions && (
                  <RequirementPlanActions
                    certificateId={certificateId}
                    requirementIds={[item.id]}
                    requirements={getSelectedRequirements(DataTableProps.rows, [item.id.toString()])}
                    workflowActions={getRequirementWorkflowActions(
                      DataTableProps.rows,
                      [item.id.toString()]
                    )}
                    onActionSuccess={() =>
                      onRequirementActionSuccess && onRequirementActionSuccess()
                    }
                    displayDisabledActions={false}
                  />
                )
              }
            />
          )}
          noDataMessage={DataTableProps.noDataMessage}
        />
      </MobileSection>
    </>
  );
};
