import {
  Grid,
  Typography,
  makeStyles,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import clsx from 'clsx';
import { DataField } from 'components';
import { useEffect, useState } from 'react';
import { AbbTheme } from 'theme/createAbbTheme';
import { formatDate } from 'utils';
import {
  PeriodQuestionnaireQuestionAnswer,
  Question2Choices,
  Question3Choices,
  QuestionnarieDetails,
} from '../model/QuestionnarieDetails';

const useStyles = makeStyles(
  //@ts-ignore
  (theme: AbbTheme) => ({
    title: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(3),
    },
    space: {
      marginBottom: theme.spacing(3),
    },
    question: {
      maxWidth: '75%',
      '@media (max-width: 1024px)': {
        maxWidth: '100%',
      },
    },
    radio: {
      marginRight: theme.spacing(4),
    },
  }),
  { name: 'QuestionnarieSection' }
);

export interface QuestionnarieSectionProps {
  disabled?: boolean;
  questionnarie?: QuestionnarieDetails;
  onChange?: (questionnarie: QuestionnarieDetails) => void;
}

export const getDefaultQuestionnarie = () => ({
  id: -1,
  submittedBy: null,
  submittedDate: null,
  question1: PeriodQuestionnaireQuestionAnswer.NotSelected,
  question2: Question2Choices.NotSelected,
  question3: Question3Choices.NotSelected,
});

export const QuestionnarieSection = (props: QuestionnarieSectionProps) => {
  const { disabled = true, questionnarie = {}, onChange } = props;
  const [
    questionnarieState,
    setQuestionnarieState,
  ] = useState<QuestionnarieDetails>(getDefaultQuestionnarie());
  const updateQuestionnarie = (stateChunk: Partial<QuestionnarieDetails>) => {
    setQuestionnarieState((prev) => {
      const next = { ...prev, ...stateChunk };
      onChange && onChange({ ...next });
      return next;
    });
  };

  useEffect(() => {
    updateQuestionnarie({ ...questionnarie });
  }, []);
  const classes = useStyles();
  const submittedBy = questionnarieState?.submittedBy;
  const fullName = submittedBy
    ? `${submittedBy.firstName} ${submittedBy.lastName} (${submittedBy.email})`
    : null;
  const { question1, question2, question3 } = questionnarieState;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.title}>Questionnaire</Typography>
      </Grid>
      <Grid className={classes.space} xl={4} lg={4} md={4} sm={6} xs={12} item>
        <DataField
          label="Submitted date"
          value={formatDate(questionnarieState.submittedDate)}
        />
      </Grid>
      <Grid xl={8} lg={8} md={8} sm={6} xs={12} item>
        <DataField label="Submitted by" value={fullName} />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>Questions</InputLabel>
      </Grid>
      <Grid item xs={12}>
        <Typography className={clsx(classes.question, classes.space)}>
          Please select the period that the applicant has worked on the
          equipment/process since he/she has received the first certification of
          the original training course?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          className={classes.space}
          row
          value={question1}
          onChange={(e, value) => {
            updateQuestionnarie({
              question1: Number(value) as PeriodQuestionnaireQuestionAnswer,
            });
          }}
        >
          <FormControlLabel
            disabled={disabled}
            className={classes.radio}
            value={PeriodQuestionnaireQuestionAnswer.Never}
            control={<Radio color="secondary" />}
            label="Never"
            labelPlacement="end"
          />
          <FormControlLabel
            disabled={disabled}
            className={classes.radio}
            value={PeriodQuestionnaireQuestionAnswer.Occasionally}
            control={<Radio color="secondary" />}
            label="Occasionally"
            labelPlacement="end"
          />
          <FormControlLabel
            disabled={disabled}
            className={classes.radio}
            value={PeriodQuestionnaireQuestionAnswer.OnContinuousBasis}
            control={<Radio color="secondary" />}
            label="On continuous basis"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <Typography className={clsx(classes.question, classes.space)}>
          Does the applicant have the right knowledge and expertise to conduct
          all standard type of replacement and repairs to the equipment or is
          able to run the process with the highest competence?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          className={classes.space}
          value={question2}
          row
          onChange={(e, value) => {
            updateQuestionnarie({
              question2: Number(value) as Question2Choices,
            });
          }}
        >
          <FormControlLabel
            disabled={disabled}
            className={classes.radio}
            value={Question2Choices.Yes}
            control={<Radio color="secondary" />}
            label="Yes"
            labelPlacement="end"
          />
          <FormControlLabel
            disabled={disabled}
            className={classes.radio}
            value={Question2Choices.No}
            control={<Radio color="secondary" />}
            label="No"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <Typography className={clsx(classes.question, classes.space)}>
          Is the applicant capable of training his/her colleagues, with less
          knowledge and experience, on maintenance, repairs and troubleshooting
          procedures of the equipment or the process they have been trained on?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          className={classes.space}
          value={question3}
          row
          onChange={(e, value) => {
            updateQuestionnarie({
              question3: Number(value) as Question3Choices,
            });
          }}
        >
          <FormControlLabel
            disabled={disabled}
            className={classes.radio}
            value={Question3Choices.Yes}
            control={<Radio color="secondary" />}
            label="Yes"
            labelPlacement="end"
          />
          <FormControlLabel
            disabled={disabled}
            className={classes.radio}
            value={Question3Choices.No}
            control={<Radio color="secondary" />}
            label="No"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default QuestionnarieSection;
