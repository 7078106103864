import React, { FC } from 'react';

import { useCertificateFunctionsQuery } from 'Dictionary/query/dictionaryQuery';
import { FormikAutocompleteField } from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface CertificateFunctionSelectFieldProps {
  name: string;
  disabled?: boolean;
}

export const CertificateFunctionSelectField: FC<
  CertificateFunctionSelectFieldProps
> = ({
  name,
  disabled = false,
}) => {
  const { data = [], isLoading, refetch } = useCertificateFunctionsQuery();

  return (
    <FormikAutocompleteField
      name={name}
      label="Certificate function"
      disabled={disabled}
      options={data}
      loading={isLoading}
      onOpen={() => refetch()}
      multiple
    />
  );
};
