import { Content } from 'components/Page';
import { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  AccordionActions,
  Button,
  Grid,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AbbTheme } from 'theme/createAbbTheme';
import {
  GridPageChangeParams,
  GridSortModelParams,
} from '@material-ui/data-grid';

import { certificationListApi } from 'CertificationList/service/certificationList.api';
import { MassEnrollmentDialog, MassEnrollmentDialogState } from 'MassEnrollment/component/MassEnrollmentDialog';

import { useReportGridColumns } from 'Reports/hooks';
import { useQuery } from 'react-query';
import {
  ReportQuery,
  ReportQueryResponseFlat,
  ReportSortQuery,
} from 'Reports/model';
import { useToasts } from 'components/ToastProvider';
import { ReportExportFileType } from 'Reports/service/reports.api';
import { ReportPageTypes } from '../model/ReportPageTypes';
import { AdvancedFiltersScope } from './AdvancedFiltersScope';
import { FiltersScope } from './FiltersScope';
import {
  useFilterScopesQuery,
  useReportColumnDefinitionsQuery,
} from '../query/report.query';
import { FilterChangeValues, ReportingFilters } from './@types';
import { ReportDataGrid } from './ReportDataGrid';
import { ReportSettingsPanel } from './Settings';
import { GroupByDialog } from './ReportDataGrid/GroupByDialog';
import { RoundButton } from '../../components';


const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    filterIcon: {
      marginRight: theme.spacing(2),
    },
    resultsTitle: {
      margin: theme.spacing(4, 0, 2, 0),
    },
  }),
  {
    name: 'ReportBasePage',
  }
);

export interface ReportBasePageProps {
  reportType: ReportPageTypes;
  reportApi: (query: ReportQuery) => Promise<ReportQueryResponseFlat>;
  reportToFileApi: (
    query: ReportQuery,
    exportFileType: ReportExportFileType
  ) => Promise<unknown>;
}

export const ReportBasePage = (props: ReportBasePageProps) => {
  const { reportType, reportApi, reportToFileApi } = props;
  const {
    isLoading: filterScopesLoading,
    data: filterScopes,
  } = useFilterScopesQuery(reportType);
  const [
    exportingReport,
    setExportReport,
  ] = useState<ReportExportFileType | null>(null);
  const [groupBy, setGroupBy] = useState<string | null>(null);
  const [sort, setSort] = useState<ReportSortQuery>({});
  const { showSuccess, showError, showInfo } = useToasts();
  const [customColumns, setCustomColumns] = useState<string[] | null>(null);
  const [filters, setFilters] = useState<ReportingFilters>({});
  const [pageNumber, setPageNumber] = useState(0);
  const [pageNumberLoaded, setPageNumberLoaded] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const {
    isLoading: columnDefinitionsLoading,
    data: columnDefinitions,
  } = useReportColumnDefinitionsQuery(reportType);
  const [gridColDef, allColumns] = useReportGridColumns(
    columnDefinitions ?? [],
    customColumns,
    reportType
  );
  const {
    isLoading: reportLoading,
    data: report,
    refetch: getReport,
  } = useQuery(
    [reportType, pageNumber, pageSize],
    () =>
      reportApi({
        filters,
        grouping: {},
        groupings: [],
        sort,
        columns: allColumns,
        offset: pageNumber,
        limit: pageSize,
      }).then((x) => {
        setPageNumberLoaded(pageNumber);
        return x;
      }),
    {
      staleTime: 0,
      keepPreviousData: false,
      refetchOnMount: false,
      enabled:
        !columnDefinitionsLoading &&
        !filterScopesLoading &&
        gridColDef.length > 0,
    }
  );
  useEffect(() => {
    if (
      !reportLoading &&
      !columnDefinitionsLoading &&
      !filterScopesLoading &&
      gridColDef.length > 0
    ) {
      getReport();
    }
  }, [pageNumber, pageSize, sort, gridColDef]);
  const classes = useStyles(props);
  const title = useMemo(() => {
    switch (reportType) {
      case ReportPageTypes.StatusOfCertifications:
        return 'Status of Certifications';
      case ReportPageTypes.UncompletedRequirements:
        return 'Uncompleted Requirements';
      case ReportPageTypes.CertificationsByPerson:
        return 'Certifications By Person';
      case ReportPageTypes.KpiReports:
        return 'KPI Reports';
      default:
        return 'unknown';
    }
  }, []);
  const handleSort = (p: GridSortModelParams) => {
    const sortSettings = p?.sortModel[0];
    if (sortSettings?.field !== "undefined") {
      setSort({
        column: sortSettings?.field,
        direction: sortSettings.sort === 'asc',
      });
    } else {
      setSort({});
    }
  };
  const handleFilterChange = (key: string, value: FilterChangeValues) => {
    setFilters((prev) => {
      const next = { ...prev };
      if (value) {
        next[key] = value;
      } else {
        delete next[key];
      }
      return next;
    });
  };

  const handleExportReport = async (reportExportType: ReportExportFileType) => {
    try {
      setExportReport(reportExportType);
      await reportToFileApi(
        {
          filters,
          grouping: {},
          groupings: [],
          sort,
          columns:
            customColumns ??
            gridColDef.filter((x) => !x.hide).map((x) => x.field),
          offset: 0,
          limit: 50000,
        },
        reportExportType
      );
      showSuccess('Report file was exported');
    } catch (error) {
      showError("Report file wasn't exported");
    } finally {
      setExportReport(null);
    }
  };

  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {

    const emails = report?.data.map((r) => r.BQ4IBQQPDgwOCQgGBAgDBQ as string).filter((value, index, self) => self.indexOf(value) === index) ?? [];
    setSelectedProfiles(emails);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const doSend = (data: MassEnrollmentDialogState)=>{
    const ids = report?.data.map((r) => r.id as number).filter((value, index, self) => self.indexOf(value) === index) ?? [];
    const dto = {
      planIds: ids,

      notificationSubject: data?.subject,
      notificationMessage: data?.message,
      additionalRecipients: data?.recipients.split(",").map((r) => r.trim()).filter((r) => r),
    };
    certificationListApi.sendReminder(dto);
    showInfo('Remind action queued');
  };

  function UserGreeting() {
    if (reportType != ReportPageTypes.StatusOfCertifications)
      return (<></>);

    return (
      <>
        <RoundButton
          color="secondary"
          variant="text" type='button'
          disabled={Boolean(reportLoading || exportingReport)}
          onClick={openDialog}
        >
          SEND REMINDER
        </RoundButton>
      </>);
  };

  return (
    <>
      <Content
        title={title}
        loading={filterScopesLoading || columnDefinitionsLoading}
        renderActions={() => (
          <>
            <UserGreeting />
            {/* <RoundButton
            disabled={Boolean(exportingReport)}
            onClick={() => handleExportReport('pdf')}
          >
            Export Pdf
          </RoundButton> */}
            <RoundButton
              disabled={Boolean(reportLoading || exportingReport)}
              onClick={() => handleExportReport('excel')}
            >
              Export Excel
            </RoundButton>
          </>
        )}
        mutating={Boolean(exportingReport)}
        mutatingLabel={
          exportingReport === 'pdf'
            ? 'Export report to pdf...'
            : 'Export report to excel...'
        }
      >
        <ReportSettingsPanel
          chosenFilters={filters}
          reportType={reportType}
          columnDefinitions={columnDefinitions ?? []}
          onFiltersRestore={(filters) => {
            setFilters({ ...(filters as ReportingFilters) });
          }}
          onColumnsRestore={(columns) => {
            setCustomColumns(columns);
          }}
        />
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <FilterAltIcon className={classes.filterIcon} />
            <Typography variant="h6">Filters</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={3}>
              {filterScopes?.map((s) => (
                <FiltersScope
                  key={s.id}
                  filterScope={s}
                  columnsDefinition={columnDefinitions ?? []}
                  onFilterChange={handleFilterChange}
                  filters={filters}
                  reportType={reportType}
                />
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <FilterAltIcon className={classes.filterIcon} />
            <Typography variant="h6">Advanced filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AdvancedFiltersScope
              columnsDefinition={columnDefinitions ?? []}
              onFilterChange={handleFilterChange}
              filters={filters}
              reportType={reportType}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded elevation={0}>
          <AccordionActions>
            <Button
              color="primary"
              disabled={reportLoading}
              onClick={() => {
                setFilters({});
              }}
            >
              Clear
            </Button>
            <Button
              color="secondary"
              disabled={reportLoading}
              onClick={() => {
                setPageNumber(0);
                getReport();
              }}
            >
              Search
            </Button>
          </AccordionActions>
        </Accordion>

        <Typography className={classes.resultsTitle} variant="h5">
          Results
        </Typography>
        <ReportDataGrid
          loading={reportLoading}
          reportType={reportType}
          page={pageNumberLoaded}
          sort={sort}
          pageSize={pageSize}
          columns={gridColDef}
          report={report ?? { data: [], total: 0 }}
          onPageChange={(p: GridPageChangeParams) => {
            setPageNumber(p.page);
          }}
          onPageSizeChange={(p: GridPageChangeParams) => {
            setPageSize(p.pageSize);
          }}
          onSortModelChange={handleSort}
          onGroupByChange={(cid: string | null) => {
            setGroupBy(cid);
          }}
          columnsDefinitions={columnDefinitions ?? []}
          onVisibleColumnsChange={(columns, all) => {
            setCustomColumns(all ? [...allColumns] : columns);
          }}
        />
        <GroupByDialog
          groupByCid={groupBy}
          onClose={() => setGroupBy(null)}
          sort={sort ?? {}}
          filters={filters ?? []}
          columnsDefinitions={columnDefinitions ?? []}
          reportApi={reportApi}
          reportType={reportType}
          gridColDef={gridColDef}
        />
      </Content>
      <MassEnrollmentDialog
        isDialogOpen={isDialogOpen}
        selectedProfiles={selectedProfiles}
        onClose={closeDialog}
        onDoSend={doSend} 
        isReminder={true} />
    </>
  );
};
