import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon, { ListItemIconProps } from '@material-ui/core/ListItemIcon';
import ListItemText, {
  ListItemTextProps,
} from '@material-ui/core/ListItemText';

export const ListItemTextRoot = styled.div`
  display: flex;
`;

export const ListItemTextTitle = styled.span`
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledSecondaryText = styled(Typography)<TypographyProps>`
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export const StyledListItem = styled(ListItem)<
  ListItemProps & { $tinyLeftPadding: boolean }
>`
  && {
    ${({ theme, $tinyLeftPadding }) =>
      $tinyLeftPadding &&
      `
      padding-left: ${theme.spacing(0.5)}px;
    `}
    color: ${({ theme, selected }) =>
      selected ? theme.palette.secondary.main : theme.palette.grey[600]};
    background-color: ${({ theme, selected }) =>
      selected && theme.palette.shades?.red05_t} !important;
    border-right: ${({ theme, selected }) =>
      selected ? `3px solid ${theme.palette.secondary.main}` : 'none'};
  }
`;

export const StyledListItemText = styled(ListItemText)<ListItemTextProps>`
  word-break: break-all;
`;

export const StyledListItemIcon = styled(ListItemIcon)<ListItemIconProps>`
  ${({ theme }) => ({
    marginLeft: theme.spacing(1),
  })}
`;
