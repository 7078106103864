import { useQuery } from 'react-query';
import { PendingApprovals, PendingApprovalsQuery } from '../model';
import * as pendingApprovalApi from '../service/pendingApprovals.api';

export const pendingApprovalKey = 'pending-approval-key';
export const usePendingApprovalQuery = (
  query: Partial<PendingApprovalsQuery>
) =>
  useQuery<Partial<PendingApprovals>>(
    [pendingApprovalKey],
    () => pendingApprovalApi.getPendingApprovals(query),
    { enabled: false, useErrorBoundary: false }
  );
