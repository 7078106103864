import { FC, ReactNode, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';

import { Card } from 'components/Page';
import { Button, DataObjectView, SearchBar } from 'components';
import {
  archivedUsersColumns,
  managementColumns,
  UserTable,
} from 'MyProfile/component/UserTable';
import { getFullName, MyProfile } from 'MyProfile/model/MyProfile';
import { useUserHrgtQuery } from 'MyProfile/query/myProfile.query';
import { getPagination, Pagination, Sort } from 'model';
import {
  getDefaultSearchFilters,
  UserHrgtSearchFilters,
} from 'MyProfile/model/UserHrgtSearchFilters';
import { IsExternalType } from 'Dictionary/component';
import { TablePagination } from 'components/Table';
import { EditUserForm } from 'MyProfile/component/EditUserForm';
import { EditUserFormData } from 'MyProfile/component/EditUserForm/EditUserForm';
import { Language } from 'Dictionary/model/Language';
import { EmployeeJob, EmployeeJobFamily } from 'Dictionary/model/EmployeeJob';
import { formValidationSchema as externalFormValidationSchema } from 'MyProfile/component/EditExternalUserForm/EditExternalUserForm';
import { formValidationSchema as internalFormValidationSchema } from 'MyProfile/component/EditInternalUserForm/EditInternalUserForm';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import { DesktopSection, MobileSection } from 'components/MediaQuery';

export interface AddUsersProps {
  isExternalType: IsExternalType;
  selectedUsers: MyProfile[];
  setSelectedUsers: (users: MyProfile[]) => void;
}

const initialSort: Sort<MyProfile> = {
  sortExpression: 'lastName',
  sortDescending: false,
};

export const boostUser = (
  user: MyProfile,
  formData: EditUserFormData
): MyProfile => ({
  ...user,
  firstName: formData.firstName ?? user.firstName,
  lastName: formData.lastName ?? user.lastName,
  notes: formData.notes ?? user.notes,
  city: formData.city ?? user.city,
  state: formData.state ?? user.state,
  businessUnit: {
    id: formData.businessUnit.id as number,
    description: formData.businessUnit.name as string,
  },  
  manager: {
    ...user.manager,
    ...(formData.manager?.id && { geid: formData.manager.id as string }),
    ...(formData.manager?.group && { email: formData.manager.group as string }),
  },
  functionalManager: {
    ...user.functionalManager,
    ...(formData.functionalmanager?.id && { geid: formData.functionalmanager.id as string }),
    ...(formData.functionalmanager?.group && { email: formData.functionalmanager.group as string }),
  },
  employeeJobs: formData.employeeJobs as EmployeeJob[],
  employeeJobFamilies: formData.jobFamilies as EmployeeJobFamily[],
  preferredLanguages: formData.preferredLanguages as Language[],
  ...(formData.company
    ? {
        company: {
          id: formData.company.id as number,
          description: formData.company.name as string,
          location: '',
        },
      }
    : user.company),
  ...(formData.division
    ? {
        division: {
          id: formData.division.id as number,
          name: formData.division.name as string,          
        },
      }
    : user.division),
  ...(formData.pgUnit
    ? {
        pgUnit: {
          id: formData.pgUnit.id as number,
          name: formData.pgUnit.name as string,
          code: '',
        },
      }
    : user.pgUnit),
  ...(formData.country
    ? {
        country: {
          id: formData.country.id as number,
          name: formData.country.name as string,
          code: '',
        },
      }
    : user.country),
});

const pageSize = 10;

export const AddUsers: FC<AddUsersProps> = ({
  isExternalType,
  selectedUsers,
  setSelectedUsers,
}) => {
  const [personNameOrEmail, setPersonNameOrEmail] = useState('');
  const [userToEdit, setUserToEdit] = useState<MyProfile | null>(null);
  const [pagination, setPagination] = useState<Pagination>(
    getPagination(pageSize)
  );
  const [sort, setSort] = useState<Sort<MyProfile>>(
    initialSort
  );
  const [filters, setFilters] = useState<UserHrgtSearchFilters>(
    getDefaultSearchFilters(isExternalType)
  );
  const { data, isLoading, isFetching } = useUserHrgtQuery(
    pagination,
    sort,
    filters,
    {
      onSuccess: (newData) => setPagination(getPagination(pageSize, newData)),
    }
  );
  const handleOpenDialog = (user: MyProfile): void => setUserToEdit(user);
  const handleCloseDialog = (): void => setUserToEdit(null);
  const renderEditUserDialog = (): ReactNode => (
    <Dialog
      open={!!userToEdit}
      onClose={handleCloseDialog}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>Provide user information</DialogTitle>
      <DialogContent>
        {userToEdit && (
          <EditUserForm
            user={userToEdit}
            countryId={userToEdit.country?.id}
            loading={false}
            isExternalType={isExternalType}
            validationSchema={
              isExternalType === IsExternalType.External
                ? externalFormValidationSchema
                : internalFormValidationSchema
            }
            onSubmit={(values) =>
              setSelectedUsers([
                ...selectedUsers,
                boostUser(userToEdit, values),
              ])
            }
            renderActions={(submitForm, disableSubmit) => (
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  onClick={async () => {
                    await submitForm();
                    handleCloseDialog();
                  }}
                  disabled={disableSubmit}
                >
                  Add to the list
                </Button>
              </DialogActions>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
  const renderHrgtActions = (row: MyProfile): ReactNode =>
    isUserSelected(row) ? (
      <IconButton disabled>
        <CheckIcon />
      </IconButton>
    ) : (
      <>
        <IconButton onClick={() => handleOpenDialog(row)}>
          <AddIcon />
        </IconButton>
      </>
    );
  const renderSelectedActions = (row: MyProfile): ReactNode => (
    <IconButton
      onClick={() =>
        setSelectedUsers(selectedUsers.filter((user) => user.geid !== row.geid))
      }
    >
      <RemoveIcon />
    </IconButton>
  );
  const isUserSelected = (user: MyProfile): boolean =>
    selectedUsers.some(({ geid }) => geid === user.geid);
  const isExternalTitle =
    isExternalType === IsExternalType.Abb ? 'ABB' : 'External';

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Card header="Selected users">
            <MobileSection>
              <DataObjectList
                items={selectedUsers}
                renderDataObjectView={(item) => (
                  <DataObjectView
                    title={`${getFullName(item)} (${item.email})`}
                    item={item}
                    dataSetMaps={mapToDataSetMap(managementColumns, [
                      'firstName',
                    ])}
                    renderActions={() => renderSelectedActions(item)}
                  />
                )}
                noDataMessage={`Select ${isExternalTitle} users`}
              />
            </MobileSection>
            <DesktopSection>
              <UserTable
                columns={managementColumns}
                rows={selectedUsers}
                rowSelector={(row) => row.geid}
                renderRowActions={renderSelectedActions}
                noDataMessage={`Select ${isExternalTitle} users`}
              />
            </DesktopSection>
          </Card>
        </Grid>
        <Grid xs={12} item>
          <Card header={`Look for ${isExternalTitle} users`}>
            <SearchBar
              placeholder="Search by person name or email and hit enter..."
              searchText={personNameOrEmail}
              onClearText={() => {
                setPersonNameOrEmail('');
                setFilters(getDefaultSearchFilters(isExternalType));
              }}
              onChange={(text) => setPersonNameOrEmail(text)}
              onEnter={() =>
                setFilters((prevState) => ({
                  ...prevState,
                  personNameOrEmail,
                }))
              }
            />
            <MobileSection>
              <DataObjectList
                loading={isLoading || isFetching}
                items={data?.result ?? []}
                renderDataObjectView={(item) => (
                  <DataObjectView
                    title={`${getFullName(item)} (${item.email})`}
                    item={item}
                    dataSetMaps={mapToDataSetMap(archivedUsersColumns, [
                      'firstName',
                    ])}
                    renderActions={() => renderHrgtActions(item)}
                  />
                )}
              />
            </MobileSection>
            <DesktopSection>
              <UserTable
                columns={managementColumns}
                rows={data?.result ?? []}
                loading={isLoading || isFetching}
                renderRowActions={renderHrgtActions}
                onSortRequest={(direction, sortExpression) =>
                  setSort({
                    sortExpression,
                    sortDescending: direction === 'desc',
                  })
                }
                defaultSort={initialSort}
                enableSort
              />
            </DesktopSection>
            {!isFetching && (
              <TablePagination
                pagination={pagination}
                rowsPerPageOptions={[]}
                onChangePage={(event, currentPageIndex) =>
                  setPagination((prevPagination) => ({
                    ...prevPagination,
                    currentPageIndex,
                  }))
                }
              />
            )}
          </Card>
        </Grid>
      </Grid>
      {renderEditUserDialog()}
    </>
  );
};
