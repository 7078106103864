import { FC, useState } from 'react';
import { TextField, Typography, useTheme } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';

import { Button } from 'components';
import { ApiError } from 'model';
import { requirementApi } from 'Requirement/service/requirement.api';
import { useToasts } from 'components/ToastProvider';
import { certificationRequirementCommentsKey } from 'CertificationPlansPage/query/certificationPlansQuery';
import { useStyles } from './CertificationRequirementCommentForm.style';

export interface CertificationRequirementCommentFormProps {
  requirementId: number;
  onCancel: () => void;
  onAdded: ()=> void
}

export const CertificationRequirementCommentForm: FC<
  CertificationRequirementCommentFormProps
> = ({
  requirementId,
  onCancel,
  onAdded
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useToasts();
  const [comment, setComment] = useState('');
  const { mutate: addComment, isLoading: isAddCommentLoading } = useMutation<
    unknown,
    ApiError,
    { id: number; comment: string }
  >((params) => requirementApi.addComment(params.id, params.comment), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        certificationRequirementCommentsKey,
        requirementId,
      ]);
      setComment('');
      showSuccess('Add comment to requirement action success');
      onAdded()
    },
    onError: (error) => showError(error.message),
  });

  return (
    <>
    <Typography className={classes.title} style={{ fontWeight: theme.typography.fontWeightBold }}>
      Comments
    </Typography>
    <form
      className={classes.commentHeader}
      onSubmit={(ev) => {
        ev.preventDefault();
        addComment({ id: requirementId, comment });
      }}
    >
      <TextField
        className={classes.commentField}
        label="Provide a comment"
        value={comment}
        variant="outlined"
        fullWidth={false}
        disabled={isAddCommentLoading}
        onChange={(event) => setComment(event.target.value)}
      />
      <Button type="submit" color="secondary" disabled={isAddCommentLoading}>
        Add
      </Button>
    </form>
    </>
  );
};
