import { makeStyles } from '@material-ui/core';
import { useAutocomplete } from '@material-ui/lab';
import { BusinessUnit } from 'Dictionary/model/BusinessUnit';
import { useActiveBusinessUnitsQuery } from 'Dictionary/query/dictionaryQuery';
import { useEffect } from 'react';
import { AbbTheme } from 'theme/createAbbTheme';
import { SearchBarBase } from '../../components/SearchBar';

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    root: {
      marginBottom: theme.spacing(4),
    },
    list: {
      borderTop: `1px solid ${theme.palette.divider}`,
      width: '100%',
      margin: 0,
      padding: 0,
      zIndex: 1,
      top: 42,
      left: 0,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
      maxHeight: '60vh',
      boxShadow: theme.shadows[3],
      '& li': {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        ...theme.typography.body1,
      },
      '& li[data-focus="true"]': {
        backgroundColor: theme.palette.grey[100],
        cursor: 'pointer',
      },
      '& li:active': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  }),
  {
    name: 'BusinessUnitSearchBar',
  }
);

export interface BusinessUnitSearchBarProps {
  disabled?:boolean;
  onBuChange: (businessUnit:BusinessUnit) => void;
}

export const BusinessUnitSearchBar = (props: BusinessUnitSearchBarProps) => {
  const { disabled=false, onBuChange } = props;
  const classes = useStyles(props);
  const { data, isLoading, refetch } = useActiveBusinessUnitsQuery(0);
  useEffect(() => {
    refetch();
  }, []);
  const {
    getRootProps,
    getInputProps,
    popupOpen,
    groupedOptions,
    getListboxProps,
    getOptionProps,
  } = useAutocomplete({
    options: data ?? [],
    getOptionLabel: (option) => option.description,
    getOptionSelected: (option, value) => option.id === value.id,
    onChange: (e, bu) => {
      bu && onBuChange(bu);
    },
  });
  return (
    <SearchBarBase
      className={classes.root}
      margin="dense"
      disabled={disabled}
      loading={isLoading}
      InputBaseProps={{
        placeholder: 'Select Division…',
        inputProps: {
          ...getInputProps(),
        },
      }}
      {...getRootProps()}
    >
      {popupOpen && groupedOptions.length > 0 ? (
        <ul className={classes.list} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li key={option.id} {...getOptionProps({ option, index })}>
              {option.description}
            </li>
          ))}
        </ul>
      ) : null}
    </SearchBarBase>
  );
};
