import { FC } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { MyProfile } from 'MyProfile/model/MyProfile';
import { myProfileApi } from 'MyProfile/service/myProfile.api';
import { ApiError } from 'model/ApiError';
import { EditUserForm } from 'MyProfile/component/EditUserForm';
import { useToasts } from 'components/ToastProvider';
import {
  EditUserFormData,
  mapEditUserDto,
} from 'MyProfile/component/EditUserForm/EditUserForm';
import { queryClient } from 'service';
import { userKey } from 'MyProfile/query/myProfile.query';

export interface EditInternalUserFormProps {
  user: MyProfile;
}

export const formValidationSchema = Yup.object().shape({
  manager: Yup.object().typeError('Select Manager'),
  businessUnit: Yup.object().typeError('Select Division'),
  preferredLanguages: Yup.array().min(1,'Select at least one language'),
  company: Yup.object().required().typeError('Select company'),  
  employeeJobs: Yup.array().min(1, 'Select at least one Employee job'),
});

export const EditInternalUserForm: FC<EditInternalUserFormProps> = ({
  user,
}) => {
  const { showError, showSuccess } = useToasts();
  const { mutate, isLoading } = useMutation<
    unknown,
    ApiError,
    EditUserFormData
  >((formData) => myProfileApi.updateUser(user.id, mapEditUserDto(formData)), {
    onSuccess: () => {
      showSuccess('User profile has been updated');
      queryClient.invalidateQueries(userKey);
    },
    onError: (error) => showError(error.message),
  });

  return (
    <EditUserForm
      user={user}
      countryId={user.country.id}
      loading={isLoading}
      validationSchema={formValidationSchema}
      onSubmit={async (values, { resetForm }) => {
        try {
          await mutate(values);
          resetForm({ values });
        } catch (e) {
          resetForm();
        }
      }}
    />
  );
};
