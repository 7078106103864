import Grid, { GridSize } from "@material-ui/core/Grid";
import { Children, PropsWithChildren } from "react";

interface Props {
    direction?: "row" | "column"
}

export const Stack = (p: PropsWithChildren<Props>) => {
    const items = Children.count(p.children)

    return (
        <div style={{ flexGrow: 1, display: "flex", flexDirection: p.direction ?? "column", gap: "24px" }}>
            {Children.map(p.children, ((child, index) =>
            (
                <>{child}</>
            ))
            )}

        </div>
    )
}

export const FluidForEditors = (p: PropsWithChildren<{ inRow?: number, noSpacing?: boolean }>) => {
    const itemSize: GridSize = Math.max(3, Math.round(12 / (p.inRow ?? 2))) as GridSize

    return (
        <Grid container spacing={p.noSpacing === true ? undefined : 3}>
            {Children.map(p.children, ((child, index) =>
            (
                <Grid item xs={12} lg={itemSize}>
                    {child}
                </Grid>
            ))
            )}

        </Grid>
    )
}
