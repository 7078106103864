import {
    IconButton,
    Button, Typography, Card, Stack,
    DataTable, UsedIcons, FluidForEditors,
    ActionsRoot
} from 'components';
import { Yup, Formik, FormikTextField, DictionaryFieldByCode, FieldArray } from 'components/FormikField';
import { useState } from 'react';
import { usePCSContext } from 'Router/PCSContext';

import * as Model from './model';


type TRootModel = Model.CertificateListDefinitionDTO
type TItemModel = Model.CertificateListRequirementDTO

export function RequirementsFormPart() {
    const [show, setShow] = useState(false);
    const togleShow = () => setShow(prev => !prev)

    return (
        <Stack direction='column'>

            <Card header='Requirements'>
                <RequirementsList />
            </Card>

            {!show ?
                <ActionsRoot>
                    <Button color="secondary" onClick={togleShow}>
                        Add Requirement
                    </Button>
                </ActionsRoot>
                :
                <Card header='Add new requirement'>
                    <RequirementEditor onEnd={togleShow} />
                </Card>}
        </Stack>);
}

function RequirementsList() {
    const pcsContext = usePCSContext()!;

    return <FieldArray name='requirements'>
        {arrayHelpers => {
            const items = arrayHelpers.form.values[arrayHelpers.name] as TItemModel[]
            function doDelete(item: TItemModel) {
                arrayHelpers.remove(items.indexOf(item));
            }

            function onDelete(item: TItemModel) {
                pcsContext.showQuestion({
                    onConfirmed: () => doDelete(item),
                    confirmTitle: "Do you want to delete requirement?",
                    confirmDescription: "Deleting requirement removes all alternatives including it",
                    confirmButton: "DELETE"
                });
            }
            return (
                <DataTable
                    rows={items.sort((a, b) => (a.code).localeCompare(b.code))}
                    rowSelector={x => items.indexOf(x)}
                    columns={[
                        { field: "code", headerName: "Code", width: 60 },
                        {
                            field: "description", headerName: "Description", width: 400, renderField: (r) => (<>
                                {r.description}<br />
                                <Typography variant="caption" style={{ fontStyle: 'italic', marginTop: 8 }}>
                                    {r.extendedDescription}
                                </Typography></>)
                        },
                        { field: 'approverType', headerName: 'Approver', renderField: (r) => r.approverType.display },
                        { field: 'expiration', headerName: 'Expiration', renderField: (r) => r.expiration ? `${r.expiration} month(s)` : 'Never' },
                        { field: 'renewal', headerName: 'Renewal', renderField: (r) => r.renewal ? `${r.renewal} month(s)` : 'Never' },
                    ]}
                    noDataMessage="Currently there are no requirements set up. Click “Add requirement” button to add one."
                    renderCollapseRow={(item, collapseRow) => (
                        <Card>
                            <RequirementEditor
                                item={item}
                                onEnd={collapseRow}
                            />
                        </Card>)}

                    renderRowActions={(item, collapseRow) => (
                        <>
                            <IconButton onClick={collapseRow}>
                                <UsedIcons.Edit />
                            </IconButton>
                            <IconButton onClick={() => onDelete(item)}>
                                <UsedIcons.Delete />
                            </IconButton>
                        </>)}
                    hideCollapseColumn />)
        }}
    </FieldArray>
}

function RequirementEditor(p: {
    item?: TItemModel | undefined;
    onEnd: () => void;
}) {
    const fieldName: keyof TRootModel = "requirements";
    const data: Partial<TItemModel> = p.item ?? {
        id: 0,
        renewal: 0,
        expiration: 0
    };

    return <FieldArray name={fieldName}>
        {arrayHelpers => {
            const { form } = arrayHelpers
            const isNew = data.id === 0
            const cancel = p.onEnd
            const submit = (value: Partial<TItemModel>) => {
                const items = arrayHelpers.form.values[fieldName] as Partial<TItemModel>[];
                const currentIndex = items.indexOf(data);
                currentIndex == -1 ? arrayHelpers.push(value) : arrayHelpers.replace(currentIndex, value);
                p.onEnd();
            }

            return (
                <Formik
                    initialValues={data}
                    validationSchema={Yup.object().shape({
                        code: Yup.string().label("Code").min(1).max(3).required(),
                        description: Yup.string().label("Title").required(),
                        approverType: Yup.object().label("Approver").required(),
                        approverGroup: Yup.object().nullable().when(
                            'approverType', {
                            is: (approverType: DictionaryItemByCodeDTO | undefined) => (approverType?.code === "1"),
                            then: Yup.object().required()
                        }),
                        expiration: Yup.number().label("Expiration").min(0).required(),
                        renewal: Yup.number().label("Renewal").min(0).required(),
                    })}
                    onSubmit={submit}
                >
                    {formikProps => (<>
                        <FluidForEditors>
                            <FormikTextField required name="code" label="Code" />
                            <FormikTextField required name="description" label="Title" />
                        </FluidForEditors>
                        <FormikTextField name="extendedDescription" label="Description" multiline rows={4} />
                        <FluidForEditors>
                            <FormikTextField name="expiration" label="Expiration (Months)" type="number" helperText='month(s) (Leave zero for unexpiring requirement)'/>
                            <FormikTextField name="renewal" label="Renewal (Months)" type="number" helperText='month(s) before expiration'/>
                            <DictionaryFieldByCode required name="approverType" label="Approver" query={{ key: 'RequirementApprover' }} dependandFieldName='rule'/>
                            <DictionaryFieldByCode
                                required={formikProps.values.approverType?.code === "1"}
                                disabled={formikProps.values.approverType?.code !== "1"}
                                name="approverGroup" label="Approver Group"
                                query={{ key: 'RequirementApproverGroup', division: form.values.division }}
                                helperText='Select "Approver Group" in the Approver field' />
                            <DictionaryFieldByCode 
                                name="rule" 
                                label="Rule" 
                                query={{ key: 'RequirementRule' }} 
                                helperText='Select "Approved by Rules" in Approver field' 
                                required={formikProps.values.approverType?.code === "6"}
                                disabled={formikProps.values.approverType?.code !== "6"}/>
                            <>
                                <DictionaryFieldByCode 
                                    hidden={formikProps.values.rule?.code != "TRAINING"} 
                                    required name="training" label="MyLearning Course" 
                                    query={({ key: 'RequirementTraining' })} 
                                    helperText='Select "Approved by Rules" in Approver field' />
                                <DictionaryFieldByCode
                                    hidden={formikProps.values.rule?.code != "CERTIFICATION"}
                                    required name="certification" label="Certification"
                                    query={({
                                        key: 'RequirementCertification',
                                        division: form.values.division
                                    })} 
                                    helperText='Select "Approved by Rules" in Approver field' />
                            </>
                        </FluidForEditors>

                        <ActionsRoot>
                            <Button onClick={cancel}>
                                Cancel
                            </Button>
                            <Button color="secondary" onClick={formikProps.submitForm}>
                                {isNew ? "Add" : "Save"}
                            </Button>
                        </ActionsRoot>
                    </>
                    )}
                </Formik>)
        }}</FieldArray>
}
