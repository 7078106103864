import { TaskStatus } from '../../Dictionary/model/TaskStatus';
import { AbbColor, StatusChip } from '../../components/StatusChip';

export interface TaskStatusChipProps {
  taskStatus: TaskStatus;
}

export const TaskStatusChip = (props: TaskStatusChipProps) => {
  const { taskStatus } = props;
  let chipProps: { label: string; color: AbbColor } = {
    label: 'All',
    color: 'grey',
  };
  if (taskStatus === TaskStatus.Open) {
    chipProps = { label: 'Open', color: 'blue' };
  } else if (taskStatus === TaskStatus.Close) {
    chipProps = { label: 'Closed', color: 'green' };
  }
  return <StatusChip style={{ marginLeft: 0 }} {...chipProps} />;
};
