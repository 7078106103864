import { FC, ReactNode, useState } from 'react';
import ExportIcon from '@material-ui/icons/VerticalAlignBottom';
import { makeStyles } from "@material-ui/core/styles";
import { catalogDetailsRoute } from 'route';
import { useMutation } from 'react-query';

import { getPagination, PaginatedList, Pagination, Sort } from 'model';
import { Button, DataObjectView } from 'components';
import { TablePagination } from 'components/Table';
import { useCatalogQuery } from 'Catalog/query/catalogQuery';
import {
  CatalogSearchFiltersForm,
  CatalogSearchFiltersFormProps,
  initialValues,
  mapCatalogSearchFilters,
} from 'Catalog/component/CatalogSearchFiltersForm/CatalogSearchFiltersForm';
import { CatalogSearchFilters } from 'Catalog/model/CatalogSearchFilters';
import { CertificationPlansDetails } from 'CertificationPlansPage/model/CertificationPlansDetails';
import { catalogApi } from 'Catalog/service/catalog.api';
import { Loader } from 'components/Loader/Loader';
import { useToasts } from 'components/ToastProvider';
import {
  CatalogCertificateTable,
  CatalogCertificateTableProps,
} from 'Catalog/component/CatalogCertificateTable';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';

const initialSort: Sort<CertificationPlansDetails> = {
  sortExpression: 'title',
  sortDescending: false,
};

export interface SearchCertificatesProps {
  initialPageSize: number;
  enableExcelExport?: boolean;
  DataTableProps: Omit<CatalogCertificateTableProps, 'rows'>;
  SearchFiltersFormProps?: Omit<
    CatalogSearchFiltersFormProps,
    'onFiltersSubmit' | 'renderPageActions'
  >;
  children: (
    renderSearchFilters: () => ReactNode,
    renderTable: () => ReactNode,
    data?: PaginatedList<CertificationPlansDetails>
  ) => ReactNode;
  mobileView?: boolean;
}

export const SearchCertificates: FC<SearchCertificatesProps> = ({
  initialPageSize,
  DataTableProps,
  SearchFiltersFormProps = {},
  enableExcelExport = true,
  mobileView = true,
  children,
}) => {
  const { showSuccess, showError } = useToasts();
  const handleOpenDetails = (certificationId: number) => () =>
    window.open(
      `${window.location.origin}${catalogDetailsRoute.createRoute({
        certificationId: String(certificationId),
      })}`,
      '_blank'
    );

  const [pageSize, setPageSize] = useState(initialPageSize);
  const [pagination, setPagination] = useState<Pagination>(
    getPagination(pageSize)
  );
  const [sort, setSort] = useState<Sort<CertificationPlansDetails>>(
    initialSort
  );
  const [filters, setFilters] = useState<Partial<CatalogSearchFilters>>(
    mapCatalogSearchFilters(initialValues)
  );
  const { isFetching, data } = useCatalogQuery(pagination, sort, filters, {
    onSuccess: (newData) => setPagination(getPagination(pageSize, newData)),
  });
  const {
    mutate: exportToExcel,
    isLoading: isExportToExcelLoading,
  } = useMutation(() => catalogApi.exportToExcel(sort, filters), {
    onSuccess: () => showSuccess('Export to excel success'),
    onError: () =>
      showError(
        'Export to excel failed. Too many records on search. Must be less than 10000.'
      ),
  });

  const renderSearchFilters = () => (
    <CatalogSearchFiltersForm
      renderPageActions={() =>
        enableExcelExport && (
          <Button
            onClick={() => exportToExcel()}
            disabled={isExportToExcelLoading}
          >
            {isExportToExcelLoading ? (
              <Loader size={24} />
            ) : (
              <>
                <ExportIcon /> &nbsp;Export to excel
              </>
            )}
          </Button>
        )
      }
      onFiltersSubmit={(catalogSearchFilters) => {
        setFilters(catalogSearchFilters);
        setPagination(getPagination(pageSize));
      }}
      {...SearchFiltersFormProps}
    />
  );

  const renderCertificateTable = () => (
    <CatalogCertificateTable
      rows={data?.result ?? []}
      loading={isFetching}
      onSortRequest={(direction, sortExpression) =>
        setSort({
          sortExpression,
          sortDescending: direction === 'desc',
        })
      }
      defaultSort={initialSort}
      enableSort
      {...DataTableProps}
    />
  );

  const useStyles = makeStyles({
    selectIcon: {
      position: "relative"
    }
  });
  const classes = useStyles();

  const renderTable = () => (
    <>
      {mobileView ? (
        <>
          <DesktopSection>{renderCertificateTable()}</DesktopSection>
          <MobileSection>
            <DataObjectList
              loading={isFetching}
              items={data?.result ?? []}
              renderDataObjectView={(item) => (
                <DataObjectView
                  title={item.title}
                  item={item}
                  dataSetMaps={mapToDataSetMap(DataTableProps.columns)}
                  renderActions={() => (
                    <Button
                      color="secondary"
                      onClick={handleOpenDetails(item.id)}
                    >
                      Details
                    </Button>
                  )}
                />
              )}
              noDataMessage={DataTableProps.noDataMessage}
            />
          </MobileSection>
        </>
      ) : (
        renderCertificateTable()
      )}
      {!isFetching && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          onChangeRowsPerPage={(event) => {
            setPageSize(parseInt(event.target.value, 10));
            setPagination(getPagination(parseInt(event.target.value, 10)));
          }}
          classes={{ selectIcon: classes.selectIcon }}
          pagination={pagination}
          onChangePage={(event, currentPageIndex) =>
            setPagination((prevPagination) => ({
              ...prevPagination,
              currentPageIndex,
            }))
          }
        />
      )}
    </>
  );

  return <>{children(renderSearchFilters, renderTable, data)}</>;
};
