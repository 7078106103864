import { Typography, Card, FluidForEditors, DictionaryField } from 'components';
import { API } from './model';

export function AvailabilityCardPart() {
    return (
        <Card header='Availability'>
            <FluidForEditors>
                <DictionaryField name="forCountries" label="Country/Territory" multiple query={API.ICertificateListContract.dictionaryQuery({ key: 'Country' })} />
                <DictionaryField name="forDivisions" label="Division" multiple query={API.ICertificateListContract.dictionaryQuery({ key: 'Division' })} />
                <DictionaryField name="forChannels" label="Channel type" multiple query={API.ICertificateListContract.dictionaryQuery({ key: 'Channel' })} />
                <DictionaryField name="forUserType" label="ABB / External" query={API.ICertificateListContract.dictionaryQuery({ key: 'UserType' })} />
                <Typography variant="caption">If filters are not set up, everyone can enroll for the Certification</Typography>
            </FluidForEditors>
        </Card>);
}
