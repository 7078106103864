import { BusinessUnit } from 'Dictionary/model/BusinessUnit';
import { Signer } from 'Print/model/Signer';
import { PrintCertificate } from 'Print/model/PrintCertificate';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';

export interface Print {
  businessUnit: BusinessUnit;
  nCertificate: number;
  signers: Signer[];
  certificates: PrintCertificate[];
  firstSignerDisabled: boolean;
}

export const getSigners = (
  print: Print,
  defaultSigner: 'isDefaultFirst' | 'isDefaultSecond',
  signers: Signer[] = []
): Signer[] => [
  ...print.signers.filter((signer) => signer[defaultSigner]),
  ...signers
];

type GetSignersFunction = (print: Print) => AutocompleteFieldItem[];
export const getFirstSignersAutocompleteFields: GetSignersFunction = (print) =>
  getSigners(print, 'isDefaultFirst').map((signer) => ({
    id: signer.userId,
    name: signer.userName,
    group: '',
  }));

export const getSecondSignersAutocompleteFields: GetSignersFunction = (print) =>
  getSigners(print, 'isDefaultSecond').map((signer) => ({
    id: signer.userId,
    name: signer.userName,
    group: '',
  }));
