import React, { FC } from 'react';

import { useCompaniesQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'Dictionary/model/Company';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface CompanySelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {
  name: string;
  disabled?: boolean;
}

export const CompanySelectField: FC<CompanySelectFieldProps> = ({
  name,
  disabled = false,
  ...otherProps
}) => {
  const { data = [], isLoading, refetch } = useCompaniesQuery();

  return (
    <FormikAutocompleteField
      name={name}
      label="Company"
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading}
      onOpen={() => refetch()}
      required
      {...otherProps}
    />
  );
};
