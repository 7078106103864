import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

export const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
`;

export const NameTypography = styled(Typography)<TypographyProps>`
  text-transform: none;
  && {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`;

export const EmailTypography = styled(Typography)<TypographyProps>`
  text-transform: none;
  color: ${({ theme }) => theme.palette.grey[700]};
`;
