import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

export const RequirementsBar = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 2)};
  border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
`;

export const DescriptionTypography = styled(Typography)<TypographyProps>`
  && {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`;

export const DescriptionCaptionTypography = styled(Typography)<TypographyProps>`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
`;
