import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useMyProfileQuery } from 'MyProfile/query/myProfile.query';
import { Anchor } from 'components/Page/Anchor/Anchor';
import { myRoleMatchToRoles, RoleType } from 'MyProfile/enum/RoleType';
import { getRoles } from 'MyProfile/model/MyProfile';
import { List, NavListItem, ListItem } from '../../List';
import { NavListItemType } from '../../List/NavListItem/NavListItem';
import { Root } from './DrawerList.style';
import {
  relatedAppsList,
  managementList,
  certificationsList,
} from './menuList';

export type Props = {};

export const DrawerList: FC<Props> = () => {
  const { isLoading, data } = useMyProfileQuery();
  const myRoles: RoleType[] = data ? getRoles(data) : [];
  const managementRoles: RoleType[] = managementList
    .map((item) => item.roles as RoleType[])
    .flat();

  if (isLoading) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <Root>
      {process.env.NODE_ENV === 'development' && (
      <List<NavListItemType>
      header="Development"
      items={[
        {
          primaryText: 'Playground',
          path: "/development",
        }]}
      renderItem={(item) => (
        <Anchor href={item.path ?? ''}>
          <ListItem {...item} leftPadding="default" iconAsText button />
        </Anchor>
      )}
    />
    )}
      <List<NavListItemType>
        header="Certifications"
        items={certificationsList}
        renderItem={(item) => <NavListItem {...item} button />}
      />
      {myRoleMatchToRoles(myRoles, managementRoles) && (
        <List<NavListItemType>
          header="Management"
          items={managementList}
          renderItem={(item) => <NavListItem {...item} button />}
        />
      )}
      <List<NavListItemType>
        header="Related Apps"
        items={relatedAppsList}
        renderItem={(item) => (
          <Anchor href={item.path ?? ''}>
            <ListItem {...item} leftPadding="default" iconAsText button />
          </Anchor>
        )}
      />
    </Root>
  );
};
