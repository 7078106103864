import { makeStyles } from '@material-ui/core';
import { GridPanelProps } from '@material-ui/data-grid';
import clsx from 'clsx';
import { AbbTheme } from 'theme/createAbbTheme';

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    root: {
      display: 'none',
      width:'100%',
      maxWidth: 600,
      zIndex: 100,
      position: "absolute",
      '&$open': {
        display: 'block',
      },
    },
    open: {},
  }),
  { name: 'GridPanel' }
);

export const GridPanel = (props: GridPanelProps) => {
  const { open, children, className, ...other } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(className, classes.root, open && classes.open)}
      {...other}
    >
      {children}
    </div>
  );
};
