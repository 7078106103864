import { Pagination } from 'model/Pagination';

export interface PaginatedList<Element = {}> extends Pagination {
  result: Element[];
}

export const getPagination = (
  pageSize: number,
  paginatedList?: PaginatedList
): Pagination => ({
  pageSize,
  total: paginatedList?.total ?? 0,
  currentPageIndex: paginatedList?.currentPageIndex ?? 0,
});
