import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as route from 'route';
import {
    DetailsAppShell,
    useToasts,
    RoundButton,
    Content,
    EditorHelper,
    EditorBase
} from 'components'
import { Formik } from 'formik';
import { PCSContext } from 'Router/PCSContext';

import * as Model from './model';
import Editor from './Editor';

export interface FromRouteParams {
    itemId: number;
}

type DTOModel = Model.TrainingSetDTO
type TDataModel = DTOModel
type TQueryModel = Model.TrainingSetFindQueryDTO
const ContractAPI = Model.API.ITrainingSetContract


export default function EditorPage() {
    const { itemId } = useParams<{ itemId: string }>();
    const history = useHistory();
    const feedback = useToasts();
    const pcsContext = useContext(PCSContext)

    const [state, setState] = useState<{
        item: TDataModel
    } | undefined>()

    const itemIdParsed = parseInt(itemId, 10)

    const settings: DetailsPageSettings<TDataModel, TQueryModel> = {
        contract: ContractAPI,
        pageSettings: {
            title: (item: DTOModel) => item.id > 0 ? item.name : "Define new Training Set",
            subtitle: (item: DTOModel) => "Training sets list",
            details: {
                routeToNew: (itemId: number) => route.managTrainingSetDetailsRoute.createRoute({ itemId })
            },
        },
        newItem: () => ({
            id: 0,
            isActive: true,
            createdOn: new Date().toISOString(),
            editedOn: new Date().toISOString(),
            certificates: [],
            forDivision: [],
            forCountry: [],
            forFunctionalJob: [],
            forUserType: { id: 0, code: "", display: "Any" },
            name: "New name",
            automaticRenewal: false,
            reagisterAllUsers: false,
        }),
        // handlers: {
        //     doUpdate: (row: TDataModel) => {
        //         ContractAPI
        //             .update(row)
        //             .then(r => {
        //                 const result = r as { itemID: string | number }
        //                 showSuccess('Item added ...page will refresh')
        //                 const route = settings.pageSettings.details.routeToNew(result.itemID as number)
        //                 history.replace(route)
        //                 window.location.reload()
        //             })
        //     }
        // }
    }

    function loadDataBase() {
        if (itemIdParsed === 0)
            setState(s => ({ item: settings.newItem() as TDataModel }))
        else
            settings.contract
                .get(itemIdParsed)
                .then(x => setState(s => ({ item: x })))
    }
    useEffect(loadDataBase, []);

    const doUpdate = settings.handlers?.doUpdate ?? ((row: TDataModel) => {
        settings.contract
            .update(row)
            .then(e => {
                feedback.showSuccess('Item updated');
                if ((!!e) && typeof e === "object" && e != null && "usersEnrolled" in e) {
                    //@ts-ignore eslint-disable-next-line 
                    if (typeof e.usersEnrolled === "number")
                        //@ts-ignore eslint-disable-next-line 
                        feedback.showInfo(`Enrolled ${e.usersEnrolled} users`)
                }
            })
            .then(() => loadDataBase())
            .catch(e => feedback.showError(e.message ? `Cannot update item. Additional message: ${e.message}` : "Cannot update item."))
    })

    function doDelete(row: TDataModel) {
        settings.contract
            .delete(row)
            .then(r => {
                feedback.showSuccess('Item removed')
                history.goBack()
            })
            .catch(e => feedback.showError(e.message ? `Cannot delete item. Additional message: ${e.message}` : "Cannot delete item."))
    }

    function doAdd(row: TDataModel) {
        settings.contract
            .add(row)
            .then(r => {
                feedback.showSuccess('Item added ...page will refresh')
                const route = settings.pageSettings.details.routeToNew(r.itemID as number)
                history.replace(route)
                window.location.reload()
            })
    }

    const onCommit = (item: TDataModel) => {
        const isNew = (itemIdParsed === 0)
        isNew ? doAdd(item) : doUpdate(item);
    };

    const onDelete = (item: TDataModel) => {
        pcsContext!.showQuestion({
            onConfirmed: () => doDelete(item),
            confirmDescription: "Do you want to delete selected item?"
        })
    }

    function renderActions(helper: EditorHelper<TDataModel>) {
        return (<>

            {helper.values.id == 0 ? <>
                <RoundButton color="secondary" variant="contained"
                    onClick={() => helper.submitForm()}
                >
                    CREATE TRAINING SET
                </RoundButton>
            </> : <>
                <RoundButton color="primary"
                    onClick={() => onDelete(helper.values)}
                >
                    DELETE TRAINING SET
                </RoundButton>
                <RoundButton color="secondary" variant="contained"
                    onClick={() => helper.submitForm()}
                >
                    SAVE CHANGES
                </RoundButton></>
            }
        </>)
    }

    const toEdit = state?.item


    return (<>
        <DetailsAppShell
            title={toEdit ? settings.pageSettings.title(toEdit) : ""}
            subtitle={toEdit ? settings.pageSettings.subtitle(toEdit) : ""}
            loading={!state}
            defaultGoBackPath=".."
            logout={pcsContext?.onLogout}
        >
            <Formik
                initialValues={(toEdit ?? settings.newItem()) as TDataModel}
                key={toEdit?.id}
                onSubmit={values => onCommit(values)}
            >
                {formikProps => {
                    const helper = EditorBase.getHelper<TDataModel>(formikProps, () => { }, onCommit)
                    return <>

                        <Content
                            loading={!state}
                            title={(itemIdParsed === 0) ? "Define new Training set" : "Edit Training set"}
                            renderActions={() => renderActions(helper)}>
                            <Editor helper={helper} />
                        </Content>
                    </>
                }}
            </Formik>
        </DetailsAppShell>
    </>)
}
