import { useState } from 'react';
import { ActionsRoot, Button, Card, DictionaryFieldByCode, Stack } from 'components';
import { CertificationListForSelectionPart } from './CertificationListForSelectionPart';
import { CertificateLookupForField } from './CertificateLookupForField';


export function PrerequisitesCardPart() {

    const [show, setShow] = useState(false);
    const togleShow = () => setShow(prev => !prev)

    return (
        <Stack direction='column'>
            <Card header="Prerequisite enrollment rule">
                <DictionaryFieldByCode name="behavior" label="If this Certificate has Prerequisites" query={{ key: 'Behavior' }} />
            </Card>

            <Card header="Prerequisites">
                <CertificateLookupForField name='prerequisites'
                    noDataMessage={<Stack>
                        <span>Currently there are no prerequisite set up. Click “Add prerequisite” button to add one.</span>
                    </Stack>} />
            </Card>
            {show ?
                <Card header='Add existing certificates as prerequisites'>
                    <CertificationListForSelectionPart name='prerequisites' />
                </Card> :
                <ActionsRoot>
                    <Button color="secondary" onClick={togleShow}>ADD PREREQUISITE</Button>
                </ActionsRoot>}

        </Stack>);
}
