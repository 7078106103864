import { apiClient } from 'service';
import { ApiError } from 'model/ApiError';
import { PlanRequirements } from 'Requirement/model/PlanRequirements';
import {
  RejectRequirementPlanActionParams,
  RequirementPlanActionParams,
} from 'Requirement/model/RequirementPlanActionParams';

const domainPath = '/requirements';

const getPlanRequirements = async (
  certificatePlanId: number
): Promise<PlanRequirements> => {
  try {
    const response = await apiClient.get(
      `/certification-plans/${certificatePlanId}/requirements`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const addComment = async (id: number, comment: string): Promise<unknown> => {
  try {
    const response = await apiClient.post(`${domainPath}/${id}/comments`, {
      comment,
    });
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const submit = async (
  params: RequirementPlanActionParams
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `/certification/${params.certificateId}/requirements/submit`,
      params.requirementIds
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const renew = async (params: RequirementPlanActionParams): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${params.certificateId}/renew`,
      params.requirementIds
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const approve = async (
  params: RequirementPlanActionParams
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${params.certificateId}/approve`,
      params.requirementIds
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const reject = async (
  params: RejectRequirementPlanActionParams
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${params.certificateId}/reject`,
      {
        rejectReason: params.rejectReason,
        requirementIds: params.requirementIds,
      }
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const reset = async (params: RequirementPlanActionParams): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${params.certificateId}/reset`,
      params.requirementIds
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

const resetToApproval = async (
  params: RequirementPlanActionParams
): Promise<unknown> => {
  try {
    const response = await apiClient.post(
      `${domainPath}/${params.certificateId}/reset-to-approval`,
      params.requirementIds
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export interface UpdateRequirementsDatesDto  {
  requirementsIds: number[];
  submitDate: string | null;
  expirationDate: string | null;
  approvedDate: string | null;
}
const updateDates = async (
  updateDto: UpdateRequirementsDatesDto,
  certificatePlanId: number
): Promise<unknown> => {
  try {
    const response = await apiClient.patch(
      `${domainPath}/${certificatePlanId}/update-dates`,
      updateDto
    );
    return response.data;
  } catch (e) {
    throw new ApiError(e);
  }
};

export const requirementApi = {
  getPlanRequirements,
  addComment,
  submit,
  renew,
  approve,
  reset,
  reject,
  resetToApproval,
  updateDates,
};
