import { Box, Checkbox, TextField, Typography } from '@material-ui/core';
import { useState } from 'react';

import { myProfileApi } from 'MyProfile/service/myProfile.api';

import Icons from '../Trivial/UsedIcons';
import { Button } from '../Button';
import { ChipButtonList } from '../Trivial/ChipButtonList';


export interface ListFiltersSettings {
    defaultFilter?: string
    filters?: FilterSetting[]
}

export interface FilterSetting {
    title: string
    filter: unknown
}

export const userFilterLogic = {

    add: (settings: ListFiltersSettings, filterValues: unknown) => {
        if (settings.filters == undefined) // eslint-disable-next-line
            settings.filters = []

        const newItem: FilterSetting = { title: "New filter", filter: filterValues }
        settings.filters.push(newItem)
        return newItem
    },

    delete: (settings: ListFiltersSettings, filter: FilterSetting) => {
        if (settings.filters == undefined) return;

        const idx = settings.filters.indexOf(filter)
        settings.filters.splice(idx, 1)
        if (settings.defaultFilter == filter.title) // eslint-disable-next-line
            settings.defaultFilter = undefined
    },

    persist: (settingKey: string, settings: ListFiltersSettings) => {
        myProfileApi.saveSettings(settingKey, JSON.stringify(settings));
    },

    getDefault: (settings: ListFiltersSettings) => {
        if ((settings.defaultFilter ?? "") == "") return undefined
        return settings.filters?.find(e => e.title == settings.defaultFilter)
    },
    setDefault: (settings: ListFiltersSettings, filter: FilterSetting | undefined) => { // eslint-disable-next-line
        settings.defaultFilter = filter?.title
    },

    load: (settingKey: string) =>
        myProfileApi.getSettings<ListFiltersSettings>(settingKey)
}

export interface UserFilerListProps {
    items: ListFiltersSettings
    current?: FilterSetting
    savingCurrent: boolean

    onSelected: React.Dispatch<FilterSetting>
    onDeleted?: React.Dispatch<FilterSetting>
};

export function UserFilterList(props: UserFilerListProps) {
    const { items, ...other } = props;
    if (!props.items || props.savingCurrent) return (<></>)
    return (
        <>
            <ChipButtonList items={items.filters} {...other}
                formatIcon={i => i == userFilterLogic.getDefault(props.items) ? <Icons.DefaultFilter /> : <></>} />
        </>)
}

export interface UserFilerSaverProps {
    current?: FilterSetting
    dirty: boolean
    onSaved: React.Dispatch<FilterSetting>
    onEdit: React.Dispatch<FilterSetting | undefined>
    onCancel: React.DispatchWithoutAction
    savingCurrent: boolean

};

export function UserFilterSaver(props: UserFilerSaverProps) {
    if (props.dirty && (!props.savingCurrent || props.current == undefined)) {
        return (
            <Button onClick={() => props.onEdit(undefined)}>SAVE SETTINGS</Button>
        )
    }

    if (!props.dirty && props.current && !props.savingCurrent) {
        return (
            <Button onClick={() => props.onEdit(props.current)}>EDIT SETTINGS</Button>
        )
    }

    if (!props.current) return (<></>)
    return (
        <>
            <Button onClick={() => props.onSaved(props.current!)}>SAVE</Button>
            <Button onClick={() => props.onCancel()}>CANCEL</Button>
        </>)

}

export function UserFilterNameEditor(props: {
    items: ListFiltersSettings
    current: FilterSetting
    savingCurrent: boolean
}) {

    const [state, setState] = useState({ newTitle: "", isDefault: false })
    if (!props.current || !props.savingCurrent) return (<></>)

    const edit = props.current
    const seti = props.items
    return (
        <>
            <Box flexDirection='row' >
                <TextField
                    label="Setting name"
                    error={props.items.filters?.find((e => e.title == state.newTitle && e != props.current)) != null}
                    defaultValue={edit!.title}
                    required={true}
                    onChange={e => { setState({ ...state, newTitle: e.currentTarget.value }); edit!.title = e.currentTarget.value; }}
                />
                <Typography variant="body1">
                    <Checkbox
                        defaultChecked={userFilterLogic.getDefault(props.items) == props.current}
                        onChange={e => userFilterLogic.setDefault(props.items, e.target.checked ? props.current : undefined)}
                    />
                    Set this setting as default
                </Typography>
            </Box>
        </>)
}
