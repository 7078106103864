import { FC } from 'react';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from '../../../components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { AutocompleteFieldItem } from '../../../components/Field/AutocompleteField/AutocompleteField';
import { ObjectType } from '../../model/TaskType';

export interface TaskTypeSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options' | 'label'> {}

export const getTaskTypeItems = (): AutocompleteFieldItem[] => [
  { id: ObjectType.All, name: 'All' },
  { id: ObjectType.CertificateList, name: 'Certificates to publish' },
  { id: ObjectType.CertificationPlan, name: 'Certification plans' },
  { id: ObjectType.CPRequirement, name: 'Requirements' },  
  { id: ObjectType.CPEnrollmentRequest, name: 'Enrollment requests' },
  { id: ObjectType.Questionnaire, name: 'Questionnaire' },
  { id: ObjectType.User, name: 'Users to remove' },
  //{ id: ObjectType.UserUpdate, name: 'User update' },
];

export const TaskTypeSelectField: FC<TaskTypeSelectFieldProps> = (props) => {
  const items = getTaskTypeItems();
  return (
    <FormikAutocompleteField label="Task type" options={items} {...props} />
  );
};
