import { FC, ReactElement, useState, Dispatch, DispatchWithoutAction } from 'react';

import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { ModalContent } from 'components/Page';
import { Typography } from 'components/Trivial/external';

export const useModal = (
  children: (closeModal: () => void) => ReactElement
) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const ModalComponent: FC = () =>
    open ? (
      <Modal open={open} onClose={handleClose}>
        {children(handleClose)}
      </Modal>
    ) : null;

  return {
    Modal: ModalComponent,
    openModal: handleOpen,
    closeModal: handleClose,
  };
};

export function useConfirmationModal<T>(
  p: {
    onConfirmed: Dispatch<T>
    confirmTitle?: string
    confirmDescription?: string
    confirmButton?: string
  }
) {
  const [open, setOpen] = useState<T | undefined>(undefined);
  const handleOpen = (item: T) => setOpen(item);
  const handleClose = () => setOpen(undefined);

  const confirmTitle = p.confirmTitle ?? "Delete selected item?"
  const confirmDescription = p.confirmDescription ?? "Do you want to delete it?"
  const confirmButton = p.confirmButton ?? "Delete"

  const ModalComponent: FC = () =>
    open ? (
      <Modal open={!!open} onClose={handleClose} >
        <div>
          <ModalContent
            header={confirmTitle}
            renderActions={() => (<>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={() => { p.onConfirmed(open); handleClose() }}>
                {confirmButton}
              </Button>
            </>)}
          >
            <Typography style={{ minWidth: 400 }} variant="body1">
              {confirmDescription}
            </Typography>


          </ModalContent>
        </div>
      </Modal>
    ) : <></>;

  return {
    Modal: ModalComponent,
    openModal: handleOpen,
    closeModal: handleClose,
  };
};


export interface ModalQuetionProps {
  onConfirmed?: DispatchWithoutAction
  confirmTitle?: string
  confirmDescription?: string
  cancelButton?: string
  confirmButton?: string
}

interface ModalState extends ModalQuetionProps {
  opened: boolean

}


export function useConfirmationModal2() {
  const [state, setState] = useState<ModalState>({ opened: false });

  function showQuestion(p: ModalQuetionProps) {
    setState(c => ({ ...p, opened: true }));
  }
  const handleClose = () => setState(c => ({ opened: false }));

  const ModalComponent: FC = () =>
    state.opened ? (
      <Modal open={!!state.opened} onClose={handleClose} >
        <div>
          <ModalContent
            header={state.confirmTitle ?? ""}
            renderActions={() => (<>
              <Button onClick={handleClose} color="primary">
                {state.cancelButton ?? "Cancel"}
              </Button>
              <Button
                color="secondary"
                onClick={() => { state.onConfirmed && state.onConfirmed(); handleClose() }}>
                {state.confirmButton ?? "Yes"}
              </Button>
            </>)}
          >
            <Typography style={{ minWidth: 400 }} variant="body1">
              {state.confirmDescription}
            </Typography>
          </ModalContent>
        </div>
      </Modal>
    ) : <></>;

  return {
    Modal: ModalComponent,
    closeModal: handleClose,
    showQuestion
  };
};