import { useQuery } from 'react-query';

import { CertificationPlansDetails } from '../model/CertificationPlansDetails';
import { CertificationPlanRequirementComment } from '../model/CertificationPlanRequirementComment';
import { certificationPlanApi } from '../service/certificationPlans.api';

export const certificationPlansKey = 'certification-plans-details';
export const useCertificationPlansQuery = (certificatePlanId: number) =>
  useQuery<CertificationPlansDetails>(
    [certificationPlansKey, certificatePlanId],
    () => certificationPlanApi.getCertificationPlan(certificatePlanId)
  );

export const certificationRequirementCommentsKey =
  'certification-requirement-comments';
export const useCertificationRequirementCommentsQuery = (
  requirementId: number
) =>
  useQuery<CertificationPlanRequirementComment[]>(
    [certificationRequirementCommentsKey, requirementId],
    () =>
      certificationPlanApi.getCertificationRequirementComments(requirementId)
  );
