import { IsExternalType } from 'Dictionary/component';

export interface UserHrgtSearchFilters {
  personNameOrEmail: string;
  isExternal: boolean | null;
}

export const getDefaultSearchFilters = (
  isExternalType: IsExternalType
): UserHrgtSearchFilters => {
  if (isExternalType === IsExternalType.External) {
    return {
      personNameOrEmail: '',
      isExternal: true,
    };
  }
  if (isExternalType === IsExternalType.Abb) {
    return {
      personNameOrEmail: '',
      isExternal: false,
    };
  }
  return {
    personNameOrEmail: '',
    isExternal: null,
  };
};
