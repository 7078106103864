import { useContext } from 'react';
import {
  useGridSlotComponentProps,
  GridPanelWrapper,
} from '@material-ui/data-grid';
import {
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  IconButton,
  Button,
  Accordion,
  AccordionDetails,
  makeStyles,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
  Close,
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons';
import { AbbTheme } from 'theme/createAbbTheme';
import { GridContext } from './GridContext';

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    root: {
      zIndex: 100,
    },
    topMenu: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.divider}`,
      alignItems: 'center',
      margin: theme.spacing(0.5),
      minHeight: 52,
    },
    scroll: {
      padding: theme.spacing(0, 0, 1),
    },
    list: {
      width: '100%',
      padding: 0,
    },
  }),
  {
    name: 'ColumnsPanel',
  }
);

export function ColumnsPanel(props: unknown) {
  const classes = useStyles();
  const { apiRef } = useGridSlotComponentProps();
  const { reportType } = useContext(GridContext);
  const api = apiRef.current;
  const handleShowAllColumns = () => {
    const columns = api.getAllColumns();
    api.updateColumns(columns.map((c) => ({ ...c, hide: false })));
  };
  const handleSaveAllColumns = () => {
    const columns = api.getAllColumns().map((x) => ({
      field: x.field,
      hide: x.hide,
    }));
    localStorage.setItem(`report.${reportType}`, JSON.stringify(columns));
  };
  const handleClose = () => api.hideFilterPanel();
  return (
    <GridPanelWrapper className={classes.root} {...props}>
      <Accordion defaultExpanded>
        <div className={classes.topMenu}>
          <Button onClick={handleShowAllColumns}>Show all</Button>
          <Button onClick={handleSaveAllColumns} color="secondary">
            Save
          </Button>
          <span style={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <AccordionDetails className={classes.scroll}>
          <List className={classes.list} dense>
            {api.getAllColumns().map((c, index, { length }) => (
              <ListItem key={c.field}>
                <ListItemIcon>
                  <IconButton
                    onClick={() => {
                      api.updateColumn({ field: c.field, hide: !c.hide });
                    }}
                  >
                    {c.hide ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </ListItemIcon>
                <ListItemText>{c.headerName}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    size="small"
                    onClick={() => {
                      api.setColumnIndex(
                        c.field,
                        length === index ? 0 : index + 1
                      );
                    }}
                  >
                    <ExpandMore />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      api.setColumnIndex(
                        c.field,
                        index === 0 ? length : index - 1
                      );
                    }}
                  >
                    <ExpandLess />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </GridPanelWrapper>
  );
}
