import { ReactNode } from 'react';
import { IconButton } from '@material-ui/core';
import { Done, Close } from '@material-ui/icons';
import { StatusChip } from 'components/StatusChip';
import { useToasts } from 'components/ToastProvider';
import { DataObjectView } from 'components';
import {
  DataObjectList,
  mapToDataSetMap,
} from 'components/DataObjectView/DataObjectList';
import {
  approvePendingApprovals,
  rejectPendingApprovals,
} from 'PendingApprovalsPage/service/pendingApprovals.api';
import { useMutation } from 'react-query';
import { formatDate } from 'utils';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import {
  DataTable,
  DataTableColumn,
} from '../../components/DataTable/DataTable';
import { PendingApprovalCertificate } from '../model/PendingApprovals';
import { TaskStatusChip } from './TaskStatusChip';

export interface UserReleatedTableProps {
  loading: boolean;
  rows: PendingApprovalCertificate[];
  onApprove: (mutating: boolean) => void;
  onApproveDone: (taskId: number) => void;
  onReject: (mutating: boolean) => void;
  onRejectDone: (taskId: number) => void;
}

// eslint-disable-next-line max-len
const userUserReleatedColumns: DataTableColumn<PendingApprovalCertificate>[] = [
  {
    field: 'title',
    headerName: 'Change needed',
    notSortable: true,
    renderField: ({ title, status }) => (
      <>
        <TaskStatusChip taskStatus={status} /> 
      <div dangerouslySetInnerHTML={{ __html: title ?? '' }} />
      </>
    ),
    width: 400,
  },
  {
    field: 'type',
    headerName: 'Task type',
    width: 140,
    renderField: ({ text }) => <StatusChip label={text} />,
  },
  {
    field: 'companyName',
    headerName: 'Company',
  },
  {
    field: 'approver',
    headerName: 'Approver',
  },
  {
    field: 'submitter',
    headerName: 'Submitter',
  },
  {
    field: 'createDate',
    headerName: 'Create date',
    renderField: ({ createDate }) =>
      formatDate(createDate),
  }
];

const noDataMessage = "No user related pending approvals to display";
const approvePendingApprovalKey = 'user-releated-mass-approve';
const rejectPendingApprovalKey = 'user-releated-reject';

export const UserReleatedTable = (props: UserReleatedTableProps) => {
  const {
    loading,
    rows,
    onApprove,
    onApproveDone,
    onReject,
    onRejectDone,
  } = props;
  const { showSuccess, showError } = useToasts();
  const { mutate: approve } = useMutation(
    [approvePendingApprovalKey],
    approvePendingApprovals,
    {
      useErrorBoundary: false,
      onSuccess: () => {
        onApprove(false);
        onApproveDone(0);
        showSuccess('Tasks were approved');
      },
      onError: () => {
        onApprove(false);
        showError('Cannot mass approve tasks');
      },
      onMutate: () => onApprove(true),
    }
  );
  const { mutate: reject } = useMutation(
    [rejectPendingApprovalKey],
    rejectPendingApprovals,
    {
      useErrorBoundary: false,
      onSuccess: () => {
        onReject(false);
        onRejectDone(0);
        showSuccess('Task was rejected');
      },
      onError: () => {
        onReject(false);
        showError('Cannot reject task');
      },
      onMutate: () => onReject(true),
    }
  );
  const renderRowActions = (row: PendingApprovalCertificate): ReactNode => (
    <>
      <IconButton onClick={() => approve([row.taskId])}>
        <Done />
      </IconButton>
      <IconButton onClick={() => reject(row.taskId)}>
        <Close />
      </IconButton>
    </>
  );
  return (
    <>
      <MobileSection>
        <DataObjectList
          items={rows}
          renderDataObjectView={(item) => (
            <DataObjectView
              item={item}
              dataSetMaps={mapToDataSetMap(userUserReleatedColumns)}
              renderActions={() => renderRowActions(item)}
            />
          )}
          noDataMessage={noDataMessage}
        />
      </MobileSection>
      <DesktopSection>
        <DataTable
          columns={userUserReleatedColumns}
          loading={loading}
          rows={rows}
          rowSelector={(row) => row.taskId}
          showRowActionsOnHover={true}
          renderRowActions={renderRowActions}
          noDataMessage={noDataMessage}
          enableSort
          onSortRequest={(direction, fieldName) => 
            rows.sort((a,b) => {
                if((a[fieldName] > b[fieldName]))
                  return direction === "asc" ? 1 : -1;
                if((a[fieldName] < b[fieldName]))
                  return direction === "asc" ? -1 : 1;
                return 0;
              })
          }
        />
      </DesktopSection>
    </>
  );
};
