import { useState, FC, MouseEvent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MyProfileIcon from '@material-ui/icons/Person';
import LogOutIcon from '@material-ui/icons/PowerSettingsNew';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MUIMenu from '@material-ui/core/Menu';

import * as routes from 'route';
import { Button } from 'components/Button';
import { DesktopSection, MobileSection } from 'components/MediaQuery';
import { Link } from 'components/Link/Link.style';
import { UserAvatar } from 'MyProfile/component/UserAvatar';
import {
  StyledDivider,
  anchorOriginStyles,
  transformOriginStyles,
} from './Menu.style';

export interface MenuProps {
  logout: () => void;
}

export const Menu: FC<MenuProps> = ({ logout }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSetAnchorEl = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    handleClose();
    logout();
  };

  return (
    <>
      <MobileSection>
        <IconButton
          color="primary"
          component="span"
          onClick={handleSetAnchorEl}
        >
          <UserAvatar />
        </IconButton>
      </MobileSection>

      <DesktopSection>
        <Button onClick={handleSetAnchorEl} style={{ height: '100%' }}>
          <UserAvatar showDetails />
          <ExpandMore color="primary" />
        </Button>
      </DesktopSection>

      <MUIMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={anchorOriginStyles}
        transformOrigin={transformOriginStyles}
        onClose={handleClose}
      >
        <UserAvatar showDetails />
        <StyledDivider variant="fullWidth" />
        <Link to={routes.myProfileRoute.path}>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <MyProfileIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </MenuItem>
        </Link>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogOutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </MenuItem>
      </MUIMenu>
    </>
  );
};
