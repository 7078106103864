import { SearchFiltersForm, SearchFiltersFormProps } from 'components';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';
import { ObjectType } from 'Dictionary/model/TaskType';
import {
  getTaskStatusItems,
  ActiveBusinessUnitSelectField,
  TaskTypeSelectField,
  TaskStatusSelectField,
  BusinessUnitSelectField,
  DivisionSelectField,
  PGUnitSelectField,
  CompanyWithFiltersField,
  CountriesSelectField,
  EmployeeJobSelectField,
  EmployeeJobFamilySelectField,
  ApproverSelectField,
  CertificateFunctionSelectField,
  WorkTypeSelectField,
  ScopeSelectField,
  ScopeDetailsField,
  mapScopeDetailsFilterParams,
  getTaskTypeItems,
  LevelSelectField,
  DateRangeSelectField
} from 'Dictionary/component';
import { FormikTextField } from 'components/FormikField';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { TaskStatus } from 'Dictionary/model/TaskStatus';
import { DateRangeOption } from 'Dictionary/component/DateRangeSelectField/DateRangeSelectField';
import { mapCompanyFilterParams } from 'Dictionary/component/CompanyWithFiltersField/CompanyWithFiltersField';
import {
  IsExternalSelectField,
  IsExternalType,
} from 'Dictionary/component/IsExternalSelectField/IsExternalSelectField';
import { CertificationPlanScopeType } from 'enums/CertificationPlanScopeType';
import { CertificationPlanLevelType } from 'enums/CertificationPlanLevelType';
import { useMyProfileQuery } from 'MyProfile/query/myProfile.query';
import { MyProfile } from 'MyProfile/model/MyProfile';
import { useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';
import { pendingApprovalKey } from 'PendingApprovalsPage/query/pendingApprovals.query';
import { formatTemplate } from '../../utils';
import {
  PendingApprovalsQuery,
  PendingApprovalsFormData,
  getPendingApprovalsQuery,
} from '../model';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: theme.spacing(2, 0),
    },
  }),
  {
    name: 'SubHeaderTitle',
  }
);

const SubHeaderTitle = (props: { title: string }) => {
  const classes = useStyles(props);
  return (
    <Typography className={classes.root} variant="h6">
      {props.title}
    </Typography>
  );
};

export interface PendingApprovalsSearchBarProps
  extends Pick<
    SearchFiltersFormProps<PendingApprovalsFormData>,
    'renderPageActions'
  > {
  onSubmit: (query: PendingApprovalsQuery) => void;
}

export const getInitialValues: (
  profile: MyProfile | null
) => PendingApprovalsFormData = (profile) => ({
  impersonateUserName: profile
    ? {
        id: profile?.email ?? '',
        name: `${profile.firstName} ${profile.lastName} ${
          profile.geid ? `(${profile.geid})` : ''
        }`,
      }
    : {},
  searchText: '',
  taskType: getTaskTypeItems().find((x) => x.id === ObjectType.All) ?? {},
  taskStatus: getTaskStatusItems().find((x) => x.id === TaskStatus.Open) ?? {},
  createdDateAfter: null,
  createdDateBefore: null,  
  peopleBusinessUnit: { id: 0, name: 'All Divisions' },
  peopleDivision: { id: 0, name: 'All Business Areas' },
  peopleProductGroups: [],
  peopleCountries: [],
  peopleJobList: [],
  peopleJobFamilyList: [],
  peopleCompanies: [],
  isExternalPeople: { id: IsExternalType.All, name: 'All users' },
  businessUnit: { id: 0, name: 'All Divisions' },
  division: { id: 0, name: 'All Business Areas' },
  productGroups: [],
  scope: { id: CertificationPlanScopeType.AllScopes, name: 'All scopes' },
  isExternal: { id: IsExternalType.All, name: 'All users' },
  levels: [
    { id: CertificationPlanLevelType.Basic, name: 'Basic' },
    { id: CertificationPlanLevelType.Expert, name: 'Expert' },
    { id: CertificationPlanLevelType.General, name: 'General' },
    { id: CertificationPlanLevelType.Master, name: 'Master' },
    { id: CertificationPlanLevelType.Trainer, name: 'Trainer' }
  ],
  certificateFunction: [],
  workType: [],
  scopeDetails: [],
  certificateTitle: '',
  dateRange: { id: DateRangeOption.Custom, name: 'Custom' }
});

export const PendingApprovalsSearchBar = (
  props: PendingApprovalsSearchBarProps
) => {
  const { onSubmit, ...other } = props;
  const fetchingKeys = useIsFetching(pendingApprovalKey);
  const [createDateAfter, setCreateDateAfter] = useState<Dayjs | null>(null);
  const [createDateBefore, setCreateDateBefore] = useState<Dayjs | null>(null);
  const [customDateRangeSelected, setCustomDateRangeSelected] = useState<boolean>(true);
  const { data } = useMyProfileQuery();
  const initialValues = useMemo(() => getInitialValues(data ?? null), [data]);
  const handleOnFiltersSubmit: SearchFiltersFormProps<PendingApprovalsFormData>['onSubmit'] = (
    values
  ) => {
    onSubmit(getPendingApprovalsQuery(values));
  };
  return (
    <SearchFiltersForm
      loading={!!fetchingKeys}
      placeholder="Search by person name or expand the filter on the right"
      initialValues={initialValues}
      onSubmit={handleOnFiltersSubmit}
      {...other}
    >
      {({ values, setFieldValue }) => {
      
      const handleOnPeopleDivisionChange = () => {
        setFieldValue('peopleBusinessUnit', initialValues.peopleBusinessUnit);
        setFieldValue('peopleProductGroups', initialValues.peopleProductGroups);
      }
      const handleOnPeopleBusinessUnitChange = () => {
        setFieldValue('peopleProductGroups', initialValues.peopleProductGroups);
        setFieldValue('peopleCompanies', initialValues.peopleCompanies);
      };
      const handleOnDivisionChange = () => {
        setFieldValue('businessUnit', initialValues.businessUnit);
        setFieldValue('scopeDetails', initialValues.scopeDetails);
        setFieldValue('productGroups', initialValues.productGroups);
      }
      const handleOnBusinessUnitChange = () => {
        setFieldValue('scopeDetails', initialValues.scopeDetails);
        setFieldValue('productGroups', initialValues.productGroups);
      };
      const handleCreateDateAfterChange = (date: Dayjs | null) => {
        setCreateDateAfter(date);
        setFieldValue('createdDateAfter',  date && date.isValid() ? dayjs(date).toISOString() : null);
      };
      const handleCreateDateBeforeChange = (date: Dayjs | null) => {
        setCreateDateBefore(date);
        setFieldValue('createdDateBefore',  date && date.isValid() ? dayjs(date).toISOString() : null);
      };
      const handleOnDateRangeChange = (range: DateRangeOption) => {
        setCustomDateRangeSelected(range === DateRangeOption.Custom);  
        const today = dayjs();      
        switch(range){
          case DateRangeOption.Custom:
            handleCreateDateAfterChange(null);
            handleCreateDateBeforeChange(null);
            break; 
          case DateRangeOption.Today:            
            handleCreateDateAfterChange(today);
            handleCreateDateBeforeChange(today);
            break; 
          case DateRangeOption.Yesterday:
            handleCreateDateAfterChange(today.subtract(1, 'day'));
            handleCreateDateBeforeChange(today.subtract(1, 'day'));
            break;
          case DateRangeOption.Last7Days:
            handleCreateDateAfterChange(today.subtract(7, 'day'));
            handleCreateDateBeforeChange(today);
            break; 
          case DateRangeOption.CurrentMonth:
            handleCreateDateAfterChange(today.date(1));
            handleCreateDateBeforeChange(today);
            break; 
          case DateRangeOption.PreviousMonth:
            handleCreateDateAfterChange(today.subtract(1, 'month').startOf('month'));
            handleCreateDateBeforeChange(today.subtract(1, 'month').endOf('month'));
            break; 
          case DateRangeOption.Last90Days:
            handleCreateDateAfterChange(today.subtract(90, 'day'));
            handleCreateDateBeforeChange(today);
            break;  
        }
      }
        
      return (
        <>
          <Grid spacing={3} container>
            <Grid item xs={12} md={6} lg={4}>
              <ApproverSelectField
                name="impersonateUserName"
                required={false}
                disableClearable
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TaskStatusSelectField name="taskStatus" disableClearable />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>      
              <TaskTypeSelectField name="taskType" disableClearable />          
            </Grid>
          </Grid>
          <SubHeaderTitle title="User filters" />
          <Grid spacing={3} container>
            <Grid item xs={12} md={6} lg={4}>
              <DivisionSelectField name="peopleDivision" 
                defaultOptions={[initialValues.peopleDivision]}   
                onFieldChange={handleOnPeopleDivisionChange}               
              />
              <BusinessUnitSelectField
                name="peopleBusinessUnit"
                disableClearable
                divisionID={Number(values.peopleDivision?.id ?? 0)}
                onFieldChange={handleOnPeopleBusinessUnitChange}
              />
              <PGUnitSelectField 
                label="Product group"
                name="peopleProductGroups" 
                businessUnitID={Number(values.peopleBusinessUnit.id)}
                disabled={
                  !values.peopleBusinessUnit.id
                }
                multiple={true}
                helperText="Depends on selected Division" 
              />  
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CountriesSelectField
                name="peopleCountries"
                label="Country/Territory"
                required={false}
                multiple
                disableClearable
              />
              <CompanyWithFiltersField
                name="peopleCompanies"
                label="Company"
                required={false}
                filterParams={mapCompanyFilterParams(
                  values.peopleBusinessUnit,
                  values.peopleCountries,
                  null
                )}
                multiple={true}
                disableClearable
                helperText="Depends on selected Division and Country"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EmployeeJobFamilySelectField
                name="peopleJobFamilyList"
                required={false}
                disableClearable
              />
              <EmployeeJobSelectField
                name="peopleJobList"
                required={false}
                disableClearable
              />
              <IsExternalSelectField
                name="isExternalPeople"
                disableClearable
                required={false}
              />
            </Grid>
          </Grid>
          <SubHeaderTitle title="Certificate filters" />
          <Grid spacing={3} container>
            <Grid item xs={12} md={12} lg={12}>
              <FormikTextField
                name="certificateTitle"
                label="Certificate title"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DivisionSelectField name="division" 
                defaultOptions={[initialValues.division]}  
                onFieldChange={handleOnDivisionChange}
              />
              <ActiveBusinessUnitSelectField
                name="businessUnit"
                label="Division"
                required={false}
                onFieldChange={handleOnBusinessUnitChange}
                disableClearable
                divisionID={Number(values.division?.id ?? 0)}
              />
              <PGUnitSelectField 
                label="Product group"
                name="productGroups" 
                businessUnitID={Number(values.businessUnit.id)}
                disabled={
                  !values.businessUnit.id
                }
                multiple={true}
                helperText="Depends on selected Division" 
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <IsExternalSelectField
                name="isExternal"
                disableClearable
                required={false}
              />
              <ScopeSelectField
                name="scope"
                onFieldChange={() =>
                  setFieldValue('scopeDetails', initialValues.scopeDetails)
                }
                required={false}
                disableClearable
              />
              <ScopeDetailsField
                label="Scope details"
                name="scopeDetails"
                filterParams={mapScopeDetailsFilterParams(
                  values.businessUnit,
                  values.scope
                )}
                helperText="Depends on selected Division and Scope"
              />              
            </Grid>
            <Grid item xs={12} md={6} lg={4}>              
              <CertificateFunctionSelectField name="certificateFunction" />
              <WorkTypeSelectField name="workType" />
              <LevelSelectField name="levels" />
            </Grid>
          </Grid>
          <SubHeaderTitle title="Date filters" />
          <Grid spacing={3} container>
            <Grid item xs={12} md={6} lg={4}>
              <DateRangeSelectField name="dateRange" 
                onFieldChange={(value) => handleOnDateRangeChange((value as AutocompleteFieldItem).id as DateRangeOption)}                 
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <KeyboardDatePicker
                value={createDateAfter}
                onChange={handleCreateDateAfterChange}
                variant="inline"
                inputVariant="filled"
                label="Create date start"
                format={formatTemplate}
                placeholder='yyyy/mm/dd'
                disabled={!customDateRangeSelected}
                autoOk
              /> 
            </Grid>
            <Grid item xs={12} md={6} lg={4}>      
              <KeyboardDatePicker
                value={createDateBefore}
                onChange={handleCreateDateBeforeChange}
                variant="inline"
                inputVariant="filled"
                label="Create date end"
                format={formatTemplate}
                placeholder='yyyy/mm/dd'
                disabled={!customDateRangeSelected}
                autoOk
              />     
            </Grid>
          </Grid>
        </>
      )
      }}
    </SearchFiltersForm>
  );
};

export default PendingApprovalsSearchBar;
