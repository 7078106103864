import { FC } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, {
  AutocompleteProps as MUIAutocompleteProps,
} from '@material-ui/lab/Autocomplete';
import { InputProps } from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelperIcon from '@material-ui/icons/Info';

import { HelperTextRoot, HelperTypography } from './Autocomplete.style';

export interface AutocompleteFieldItem {
  id?: string | number;
  name?: string;
  group?: string;
}

interface AutocompleteProps
  extends Omit<
    MUIAutocompleteProps<AutocompleteFieldItem, false | true, true, undefined>,
    'renderInput'
  > {
  error?: boolean;
}

export interface AutocompleteFieldProps extends AutocompleteProps {
  label: string;
  helperText?: string;
  required?: boolean;
  InputProps?: InputProps;
}

export const AutocompleteField: FC<AutocompleteFieldProps> = ({
  label,
  helperText,
  error = false,
  required = false,
  InputProps = {},
  ...autocompleteFieldProps
}) => {
  const renderHelperText = () =>
    !!helperText && (
      <HelperTextRoot>
        <HelperIcon color={error ? 'secondary' : 'primary'} fontSize="small" />
        <HelperTypography variant="caption">{helperText}</HelperTypography>
      </HelperTextRoot>
    );

  return (
    <FormControl fullWidth>
      <Autocomplete
        {...autocompleteFieldProps}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label={label}
            error={error}
            helperText={renderHelperText()}
            InputLabelProps={{ required }}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <>
                  {autocompleteFieldProps.loading ? (
                    <CircularProgress color="secondary" size={16} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        getOptionLabel={(option) => option?.name ?? ''}
        getOptionSelected={(option, value) => option?.id === value?.id}
        noOptionsText="No data found"
      />
    </FormControl>
  );
};
