import React, { FC } from 'react';

import { usePreferredLanguagesQuery } from 'Dictionary/query/dictionaryQuery';
import { mapToAutocompleteFieldItems } from 'Dictionary/model/Language';
import {
  FormikAutocompleteField,
  FormikAutocompleteFieldProps,
} from 'components/FormikField/FormikAutocompleteField/FormikAutocompleteField';

export interface PreferredLanguagesSelectFieldProps
  extends Omit<FormikAutocompleteFieldProps, 'options'> {}

// eslint-disable-next-line max-len
export const PreferredLanguagesSelectField: FC<PreferredLanguagesSelectFieldProps> = ({
  multiple = true,
  disabled = false,
  ...otherProps
}) => {
  const { data, isLoading, refetch } = usePreferredLanguagesQuery();

  return (
    <FormikAutocompleteField
      multiple={multiple}
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data)}
      loading={isLoading}
      onOpen={() => refetch()}
      required
      {...otherProps}
    />
  );
};
