import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

export const HelperTypography = styled(Typography)<TypographyProps>`
  padding: ${({ theme }) => theme.spacing(0, 0, 0, 0.5)};
`;

export const HelperTextRoot = styled.span`
  display: flex;
  padding: ${({ theme }) => theme.spacing(0, 0.5)};
`;
