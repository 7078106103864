import CertificationsIcon from '@material-ui/icons/AssignmentTurnedIn';
import CatalogIcon from '@material-ui/icons/Explore';
import HelpAndSupportIcon from '@material-ui/icons/Help';
import ReportingIcon from '@material-ui/icons/Assessment';
import PendingApprovalsIcon from '@material-ui/icons/NotificationsActive';
import MassEnrollmentIcon from '@material-ui/icons/AssignmentInd';
import UserManagementIcon from '@material-ui/icons/SupervisedUserCircle';
import AdministrationIcon from '@material-ui/icons/Settings';
import PrintIcon from '@material-ui/icons/Print';
import MyLearningIcon from '@material-ui/icons/OpenInNew';

import { RoleType } from 'MyProfile/enum/RoleType';
import { NavListItemType } from 'components/List/NavListItem/NavListItem';
import * as routes from 'route';
import { NEW_ADMIN } from 'featureToggles';

export const createLegacyUrl = (pathName: string): string =>
  process.env.REACT_APP_OLD_APP_ORGIN + pathName;

export const certificationsList: NavListItemType[] = [
  {
    primaryText: 'My certifications',
    path: routes.myCertificationsRoute.path,
    Icon: CertificationsIcon,
  },
  {
    primaryText: 'Catalog',
    path: routes.catalogRoute.path,
    Icon: CatalogIcon,
  },
  {
    primaryText: 'Help and support',
    path: routes.helpAndSupportRoute.path,
    Icon: HelpAndSupportIcon,
  },
];

export const managementList: NavListItemType[] = [
  {
    primaryText: 'Reporting',
    Icon: ReportingIcon,
    roles: routes.pcsReportingRoute.role,
    collapseListItems: [
      {
        primaryText: 'Status of certifications',
        path: routes.certificationsStatusRoute.path,
      },
      {
        primaryText: 'Certifications by person',
        path: routes.certificationsByPersonRoute.path,
      },
      {
        primaryText: 'Uncompleted requirements',
        path: routes.uncompletedRequirementsRoute.path,
      },
    ],
  },
  {
    primaryText: 'Pending approvals',
    path: routes.pendingApprovalsRoute.path,
    roles: routes.pendingApprovalsRoute.role,
    Icon: PendingApprovalsIcon,
  },
  {
    primaryText: 'Mass enrollment',
    roles: routes.massEnrollmentRoute.role,
    Icon: MassEnrollmentIcon,
    collapseListItems: [
      {
        primaryText: 'Manual enrollment',
        path: routes.massEnrollmentRoute.path
      },
      {
        primaryText: 'Bulk upload',
        path: routes.bulkUploadRoute.path,
      },
    ]
  },
  {
    primaryText: 'User management',
    Icon: UserManagementIcon,
    roles: [
      RoleType.Administrator,
      RoleType.HierarchicalManager,
      RoleType.ABBTrainingManager,
      RoleType.ChannelPartnerManager,
      RoleType.Supervisor,
      RoleType.PartnerSupervisor,
    ],
    collapseListItems: [
      {
        primaryText: 'Internal users',
        path: routes.internalUsersRoute.path,
        roles: routes.internalUsersRoute.role,
      },
      {
        primaryText: 'External users',
        path: routes.externalUsersRoute.path,
        roles: routes.externalUsersRoute.role,
      },
      {
        primaryText: 'Approval groups',
        path: routes.approvalGroupsRoute.path,
        roles: routes.approvalGroupsRoute.role,
      },
      {
        primaryText: 'Archived users',
        path: routes.archivedUsersRoute.path,
        roles: routes.archivedUsersRoute.role,
      },
    ],
  },
  {
    primaryText: 'Administration',
    Icon: AdministrationIcon,
    roles: [
      RoleType.Administrator,
      RoleType.CertificationAuthority,
      RoleType.CertificateBuilder,
      RoleType.Supervisor
    ],
    collapseListItems: [
      ...(!NEW_ADMIN) ? [] : [
        {
          primaryText: 'Manage certificates',
          path: routes.manageCertificatesRoute.path,
          roles: !NEW_ADMIN ? [RoleType.Developer] : routes.manageCertificatesRoute.role,
        },
        {
          primaryText: 'Manage companies',
          path: routes.manageCompaniesRoute.path,
          roles: !NEW_ADMIN ? [RoleType.Developer] : routes.manageCompaniesRoute.role,
        },
        {
          primaryText: 'Manage Training sets',
          path: routes.trainingSetsRoute.path,
          roles: !NEW_ADMIN ? [RoleType.Developer] : routes.trainingSetsRoute.role,
        },
        {
          primaryText: 'Manage Scope Details',
          path: routes.scopeKeywordsRoute.path,
          roles: !NEW_ADMIN ? [RoleType.Developer] : routes.scopeKeywordsRoute.role,
        },

        {
          primaryText: 'Print settings',
          Icon: PrintIcon,
          roles: !NEW_ADMIN ? [RoleType.Developer] : routes.printingCertificatesRoute.role,
          collapseListItems: [
  
            {
              primaryText: 'Standard Texts',
              path: routes.printingStandardRoute.path,
              roles: !NEW_ADMIN ? [RoleType.Developer] : routes.printingStandardRoute.role,
            },
            {
              primaryText: 'Headers',
              path: routes.printingHeadersRoute.path,
              roles: !NEW_ADMIN ? [RoleType.Developer] : routes.printingHeadersRoute.role,
            },
            {
              primaryText: 'Digital Signatures',
              path: routes.digitalSignatureRoute.path,
              roles: !NEW_ADMIN ? [RoleType.Developer] : routes.digitalSignatureRoute.role,
            },
          ],
        }
      ],
        

      {
        primaryText: 'Manage certificates',
        path: createLegacyUrl('/Pages/Certificates/Manage/Certificates.aspx'),
        roles: routes.manageCertificatesRoute.role,
        externalLink: true,
      },
      // {
      //   primaryText: 'Add companies',
      //   path: createLegacyUrl('/Pages/Companies/AddCompany.aspx'),
      //   roles: routes.manageCompaniesRoute.role,
      //   externalLink: true,
      // },
      {
        primaryText: 'Manage companies',
        path: createLegacyUrl('/Pages/Companies/ManageCompanies.aspx'),
        roles: routes.manageCompaniesRoute.role,
        externalLink: true,
      },
      {
        primaryText: 'Manage Training sets',
        path: createLegacyUrl(
          '/Pages/Certificates/Manage/ManageTrainingSets.aspx'
        ),
        roles: routes.trainingSetsRoute.role,
        externalLink: true,
      },
      // {
      //   primaryText: 'Define Training sets',
      //   path: createLegacyUrl(
      //     '/Pages/Certificates/Manage/DefineTrainingSet.aspx'
      //   ),
      //   roles: routes.trainingSetsRoute.role,
      //   externalLink: true,
      // },
      {
        primaryText: 'Manage Scope keywords',
        path: createLegacyUrl('/Pages/BusinessUnits/ScopeDetail.aspx'),
        roles: routes.scopeKeywordsRoute.role,
        externalLink: true,
      },
      {
        primaryText: 'Manage old enrollments',
        path: createLegacyUrl(
          '/Pages/Certificates/Enroll/ReplaceCertification.aspx'
        ),
        roles: routes.oldEnrollmentsRoute.role,
        externalLink: true,
      },
      {
        primaryText: 'Print settings',
        Icon: PrintIcon,
        roles: routes.printingCertificatesRoute.role,
        collapseListItems: [
          {
            primaryText: 'Standard Texts',
            path: createLegacyUrl(
              '/Pages/Certificates/Manage/PrintingStandards.aspx'
            ),
            roles: routes.printingCertificatesRoute.role,
            externalLink: true,
          },
          {
            primaryText: 'Capability Headers',
            path: createLegacyUrl(
              '/Pages/Certificates/Manage/CapabilityHeader.aspx'
            ),
            roles: routes.printingCertificatesRoute.role,
            externalLink: true,
          },
          {
            primaryText: 'Group Headers',
            path: createLegacyUrl(
              '/Pages/Certificates/Manage/GroupHeaders.aspx '
            ),
            roles: routes.printingCertificatesRoute.role,
            externalLink: true,
          },
          {
            primaryText: 'CL Identiifcators',
            path: createLegacyUrl(
              '/Pages/Certificates/Manage/CLIdentificator.aspx'
            ),
            roles: routes.printingCertificatesRoute.role,
            externalLink: true,
          },
          {
            primaryText: 'Digital Signatures',
            path: createLegacyUrl(
              '/Pages/Certificates/Manage/Signatures.aspx '
            ),
            roles: routes.printingCertificatesRoute.role,
            externalLink: true,
          },
        ],
      },
    ],
  },
];

export const relatedAppsList: NavListItemType[] = [
  { primaryText: 'MyLearning', Icon: MyLearningIcon, path: 'https://mylearning.abb.com/' },
];
