import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';

import { DesktopSection, MobileSection } from 'components/MediaQuery';
import { AbbLogo } from 'components/AbbLogo/AbbLogo';
import { useToasts } from 'components/ToastProvider';
import { Menu } from './Menu/Menu';
import { DrawerList } from './DrawerList/DrawerList';
import {
  Grow,
  LogoWrapper,
  StyledTypography,
  StyledDrawer,
  Root,
  ContentRoot,
} from './AppShell.style';
import { AppShellBaseProps } from './@types';

export const AppShell: FC<AppShellBaseProps> = ({
  logout,
  userNeedToUpdateProfile,
  children,
}) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const userCanNavigate = !userNeedToUpdateProfile;
  const { closeAll } = useToasts();
  const history = useHistory();

    useEffect(() =>
        history.listen(() => {
            closeAll();
        })
        , [history]);

  return (
    <Root>
      <AppBar position="fixed" color="default">
        <Toolbar>
          {userCanNavigate && (
            <MobileSection>
              <IconButton
                color="primary"
                component="span"
                onClick={() => setMobileDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </MobileSection>
          )}
          <LogoWrapper>
            <AbbLogo variant="regular" />
          </LogoWrapper>
          <StyledTypography variant="h6" noWrap>
            PCS2
          </StyledTypography>
          <Grow />
          <Menu logout={logout} />
        </Toolbar>
      </AppBar>

      {userCanNavigate && (
        <>
          <MobileSection>
            <Drawer
              open={mobileDrawerOpen}
              onClose={() => setMobileDrawerOpen(false)}
            >
              <DrawerList />
            </Drawer>
          </MobileSection>
          <DesktopSection>
            <StyledDrawer variant="permanent">
              <DrawerList />
            </StyledDrawer>
          </DesktopSection>
        </>
      )}

      <ContentRoot>{children}</ContentRoot>
    </Root>
  );
};

export default AppShell;
