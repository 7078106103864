import styled from 'styled-components';
import { Form } from 'formik';

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(2, 0, 0)};
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;
