import { FC, useEffect, useState } from 'react';

import { getPagination, PaginatedList, Sort } from 'model';
import { MyProfile } from 'MyProfile/model/MyProfile';
import { useUserHrgtQuery } from 'MyProfile/query/myProfile.query';
import { FormikAutocompleteField } from '../../../components/FormikField/FormikAutocompleteField/FormikAutocompleteField';
import { mapToAutocompleteFieldItems } from './utils';

export interface HrgtUserSelectFieldProps {
  name: string;
  initialValue?: string;
  disabled?: boolean;
}

const defaultSort: Sort<MyProfile> = {
  sortExpression: 'lastName',
  sortDescending: false,
};

const searchTimeout = 1000;
const minLenOfSearchValue = 3;


export const HrgtUserSelectField: FC<HrgtUserSelectFieldProps> = ({
  name,
  initialValue,
  disabled = false
}) => {
  const [value, setValue] = useState('');
  const { data = [], isLoading, isFetching, refetch } = useUserHrgtQuery(
    getPagination(20),
    defaultSort,
    {
      isExternal: false,
      personNameOrEmail: value,
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (initialValue !== value && value.length >= minLenOfSearchValue) {
        const timer = setTimeout(() => refetch(), searchTimeout);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [value]);

  return (
    <FormikAutocompleteField
      name={name}
      label="User"
      helperText="Type Firstname Lastname (at least 3 letters)"
      disabled={disabled}
      options={mapToAutocompleteFieldItems(data as PaginatedList<MyProfile>)}
      loading={isLoading || isFetching}
      onInputChange={(event, eventValue) => setValue(eventValue)}
      required
      disableClearable
    />
  );
};
