import React from 'react';
import { AvatarProps } from '@material-ui/core/Avatar';

import { StyledAvatar, StyledInitials, Size } from './Avatar.style';

export type Props = AvatarProps & {
  fullName: string;
  size?: Size;
};

const getNameInitials = (fullName: string) =>
  fullName
    .split(' ')
    .map((name) => name.charAt(0).toUpperCase())
    .concat('');

export const Avatar: React.FC<Props> = ({
  size = 'regular',
  fullName,
  ...avatarProps
}) => (
  <StyledAvatar size={size} src={avatarProps.src} alt={fullName}>
    {!avatarProps.src && (
      <StyledInitials size={size}>{getNameInitials(fullName)}</StyledInitials>
    )}
  </StyledAvatar>
);
