import { AccordionActions, Grid, Button } from '@material-ui/core';
import { Form, Formik, FormikConfig } from 'formik';
import { FormikTextField } from 'components/FormikField';
import { ActiveBusinessUnitSelectField } from 'Dictionary/component';
import { AutocompleteFieldItem } from 'components/Field/AutocompleteField/AutocompleteField';
import { Card } from '../../components/Page/Card/Card';

export interface ApprovalGroupFormData {
  businessUnit: AutocompleteFieldItem;
  description: string;
}

export interface ApprovalGroupEditProps
  extends Omit<FormikConfig<ApprovalGroupFormData>, 'onReset'> {
  title: string;
  actionLabel?: string;
  className?: string;
  onClose: () => void;
}

export const ApprovalGroupEdit = (props: ApprovalGroupEditProps) => {
  const {
    title,
    actionLabel = 'Add group',
    className,
    onClose,
    ...other
  } = props;
  const formikProps = { ...other, onReset: () => {} };
  return (
    <Formik {...formikProps}>
      {(formikProps) => (
        <Form>
          <Card className={className} header={title} elevation={0}>
            <Grid container spacing={3}>
              <Grid item xl={3} lg={4} md={5} sm={12}>
                <ActiveBusinessUnitSelectField
                  defaultOptions={[
                    formikProps?.initialValues?.businessUnit ?? {},
                  ]}
                  label="Division"
                  name="businessUnit"
                  multiple={false}
                  required
                />
              </Grid>
              <Grid item xl={9} lg={8} md={7} sm={12}>
                <FormikTextField
                  name="description"
                  label="Description"
                  required
                />
              </Grid>
            </Grid>
            <AccordionActions style={{ padding: 0 }}>
              <Button onClick={() => onClose()}>Cancel</Button>
              <Button
                type="submit"
                color="secondary"
                disabled={!formikProps.isValid}
              >
                {actionLabel}
              </Button>
            </AccordionActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
