import { makeStyles, Typography } from '@material-ui/core';
import {
  DataTable,
  DataTableColumn,
  DataTableProps,
} from 'components/DataTable/DataTable';
import { Recommended } from '../model/CertificationPlansDetails';
import { AbbTheme } from '../../theme/createAbbTheme';

const columns: DataTableColumn<Recommended>[] = [
  { field: 'title', headerName: 'Title' },
  {
    field: 'level',
    headerName: 'Level',
    renderField: ({ level }) => level.description,
  },
  {
    field: 'provider',
    headerName: 'Provider',
    renderField: ({ provider }) => provider.description,
  },
];

const useStyles = makeStyles(
  (theme: AbbTheme) => ({
    title: {},
  }),
  {
    name: 'RecommendedCertificatesTable',
  }
);

export interface RecommendedCertificatesTableProps {
  recommendedCertificates: Recommended[];
  DataTableProps?: Omit<DataTableProps<Recommended>, 'rows' | 'columns'>;
  title: string;
}

export const RecommendedCertificatesTable = (
  props: RecommendedCertificatesTableProps
) => {
  const { title, recommendedCertificates, DataTableProps } = props;
  const classes = useStyles();
  return Array.isArray(recommendedCertificates) &&
    recommendedCertificates.length ? (
    <>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>

      <DataTable
        columns={columns}
        rows={recommendedCertificates}
        rowSelector={(row) => row.id}
        {...DataTableProps}
      />
    </>
  ) : null;
};

export default RecommendedCertificatesTable;
