import { useEffect, useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { PendingApprovalsQuery } from '../model';
import { TabsView } from '../../components/TabsView';
import { usePendingApprovalQuery } from '../query/pendingApprovals.query';
import { CertificateReleatedTable } from './CertificateReleatedTable';
import { UserReleatedTable } from './UserReleatedTable';

export interface PendingApprovalsTabsProps {
  query: PendingApprovalsQuery;
  onMutating: (mutating: boolean) => void;
  tabIndex: number;
  onChange: (tabIndex: number)=>void
}

export const PendingApprovalsTabs = (props: PendingApprovalsTabsProps) => {
  const { query, onMutating, tabIndex, onChange } = props;
  
  const {
    data = { certificateRelated: [], totalCount: 0, userRelated: [] },
    isLoading,
    isFetching,
    refetch,
  } = usePendingApprovalQuery(query);
  useEffect(() => {
    refetch();
   }, [query]
  );

  const labels = useMemo(
    () => [
      `Certificate related (${data?.certificateRelated?.length ?? 0})`,
      `User related (${data?.userRelated?.length ?? 0})`,
    ],
    [data]
  );
  return isLoading || isFetching ? (
    <Skeleton width={320} height={32} />
  ) : (
    <TabsView
      labels={labels}
      animateHeight={false}
      tabIndex={tabIndex}
      onChange={onChange}
    >
      <CertificateReleatedTable
        loading={isFetching}
        rows={data?.certificateRelated ?? []}
        onApprove={(mutation) => onMutating(mutation)}
        onApproveDone={() => refetch()}
      />
      <UserReleatedTable
        loading={isFetching}
        rows={data?.userRelated ?? []}
        onApprove={(mutation) => onMutating(mutation)}
        onApproveDone={() => refetch()}
        onReject={(mutation) => onMutating(mutation)}
        onRejectDone={() => refetch()}
      />
    </TabsView>
  );
};
