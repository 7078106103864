import { ReactNode, Fragment } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Typography, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { NoDataMessageWrapper } from 'components/DataTable/DataTable.style';
import { DataTableColumn } from 'components/DataTable/DataTable';
import { DataObjectMap } from 'components/DataObjectView/DataObjectView';
import {
  inputButtonStyles,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledAccordionActions,
} from 'components/DataObjectView/DataObjectView.style';

export function mapToDataSetMap<Model>(
  dataTableColumns: DataTableColumn<Model>[],
  omitFields: (keyof Model)[] = []
): DataObjectMap<Model>[] {
  return dataTableColumns
    .filter((column) => !!column.field)
    .filter((column) => !omitFields.includes(column.field as keyof Model))
    .map((column) => ({
      field: column.field as keyof Model,
      headerName: column.headerName,
      ...(column.renderField && { renderField: column.renderField }),
    }));
}

export interface DataObjectListProps<O> {
  items: O[];
  renderDataObjectView: (object: O) => ReactNode;
  loading?: boolean;
  noDataMessage?: string | JSX.Element;
  renderDetails?: (object: O) => ReactNode;
  renderActions?: (object: O) => ReactNode;
}

export function DataObjectList<O>(props: DataObjectListProps<O>) {
  if (props.loading) {
    return <Skeleton width={320} height={32} />;
  }
  if (props.noDataMessage && !props.items.length) {
    return (
      <NoDataMessageWrapper>
        <Typography variant="body2">{props.noDataMessage}</Typography>
      </NoDataMessageWrapper>
    );
  }
  return (
    <>
      {props.items.map((item, i) =>
        props.renderDetails ? (
          <StyledAccordion key={i} TransitionProps={{ unmountOnExit: true }}>
            <StyledAccordionSummary
              IconButtonProps={{
                style: inputButtonStyles,
              }}
              expandIcon={<ExpandMoreIcon fontSize="large" />}
            >
              {props.renderDataObjectView(item)}
            </StyledAccordionSummary>
            {props.renderActions && (
              <StyledAccordionActions>
                {props.renderActions(item)}
              </StyledAccordionActions>
            )}
            <Divider />
            <StyledAccordionDetails>
              {props.renderDetails(item)}
            </StyledAccordionDetails>
          </StyledAccordion>
        ) : (
          <Fragment key={i}>{props.renderDataObjectView(item)}</Fragment>
        )
      )}
    </>
  );
}
