import { FC } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { AppShellBaseProps, DetailsAppShell } from 'components/AppShell';
import { CatalogDetailsParams, catalogDetailsRoute, catalogRoute } from 'route';
import { Content } from 'components/Page';
import { useMutating } from 'hooks';
import {
  certificationListColumns,
  PrerequisitesTable,
} from 'CertificationPlansPage/components/PrerequisitesTable';
import { RecommendedCertificatesTable } from 'CertificationPlansPage/components/RecommendedCertificatesTable';
import {
  CertificationPlanActions,
  mutatingFilterKeys,
} from 'CertificationPlansPage/components/CertificationPlanActions';
import { HistoryTable } from 'CertificationPlansPage/components/HistoryTable';
import { AlternativesCertificatesTable } from 'CertificationPlansPage/components/AlternativesCertificatesTable';
import { UserDetailsDataObjectView } from 'CertificationList/component';
import {
  useCertificationListDetailsQuery,
  useCertificationListHistoryQuery,
  useCertificationListRequirementsQuery,
} from 'CertificationList/query/certificationListQuery';
import {
  catalogDetailsColumns,
  CertificationPlansRequirementsTable,
} from 'Requirement/component/PlanRequirementsTable/CertificationPlansRequirementsTable';
import { RoundButton } from 'components';
import { CertificationPlanWorkFlowActions } from 'enums/CertificationPlanWorkFlowActions';
import {
  catalogDataSetMap,
  getCatalogDataSetMap,
} from 'CertificationList/component/UserDetailsDataObjectView/UserDetailsDataObjectView';

export interface CatalogDetailsPageProps extends AppShellBaseProps {}

export const CatalogDetailsPage: FC<CatalogDetailsPageProps> = ({ logout }) => {
  const { certificationId } = useParams<CatalogDetailsParams>();
  const history = useHistory();
  const handleOpenNewerRelease = (certificationId: number) =>
    history.push(
      catalogDetailsRoute.createRoute({
        certificationId: String(certificationId),
      })
    );
  const { isLoading, data, refetch } = useCertificationListDetailsQuery(
    Number(certificationId)
  );
  const {
    isLoading: isHistoryLoading,
    data: historyData,
  } = useCertificationListHistoryQuery(Number(certificationId));
  const {
    isLoading: requirementsLoading,
    data: requirementsData,
  } = useCertificationListRequirementsQuery(Number(certificationId));
  const { data: certificationList } = useCertificationListDetailsQuery(
    Number(certificationId)
  );
  const isMutating = useMutating([...mutatingFilterKeys]);
  const renderGoToNewVersionButton = () =>
    (data?.userActions ?? []).includes(
      CertificationPlanWorkFlowActions.NewerReleaseAvailable
    ) &&
    data?.newVersionCertificateListId && (
      <Tooltip title="This certificate is no longer available. New version can be found by clicking here">
        <div>
          <RoundButton
            onClick={() =>
              handleOpenNewerRelease(data.newVersionCertificateListId)
            }
          >
            Go to new version
          </RoundButton>
        </div>
      </Tooltip>
    );

  return (
    <DetailsAppShell
      title={data?.title ?? ''}
      subtitle="Certificate preview"
      loading={isLoading}
      logout={logout}
      defaultGoBackPath={catalogRoute.path}
    >
      <Content
        loading={isLoading}
        mutating={isMutating}
        mutatingLabel="Applying action..."
        title={data?.title ?? ''}
        subtitle={data?.purpose ?? ''}
        renderActions={() => (
          <>
            {renderGoToNewVersionButton()}
            <CertificationPlanActions
              certificationPlanId={Number(certificationId)}
              userCertificationPlan={data?.userCertificationPlan}
              userEnrollmentRequest={data?.userEnrollmentRequest}
              workflowActions={data?.userActions}
              onActionSuccess={refetch}
            />
          </>
        )}
      >
        <Grid container spacing={3}>
          {data && (
            <Grid xs={12} item>
              <UserDetailsDataObjectView
                title="Details"
                item={certificationList}
                dataSetMap={
                  certificationList &&
                  getCatalogDataSetMap(certificationList, catalogDataSetMap)
                }
              />
            </Grid>
          )}
          {!!data?.prerequisites.length && (
            <Grid xs={12} item>
              <PrerequisitesTable
                prerequisites={data?.prerequisites ?? []}
                isCatalog = {true}
                DataTableProps={{ columns: certificationListColumns }}
              />
            </Grid>
          )}          
          <Grid xs={12} item>
            <CertificationPlansRequirementsTable
              certificateId={Number(certificationId)}
              displayRowActions
              requirementsCount={requirementsData?.requirementsCount}
              DataTableProps={{
                columns: catalogDetailsColumns,
                rows: requirementsData?.requirements ?? [],
                loading: requirementsLoading,
                noDataMessage: 'No requirements to display',
                renderRowActions: undefined,
                headerRowAlwaysOnTop: false,
              }}
            />
          </Grid>
          <Grid xs={12} item>
            <AlternativesCertificatesTable
              alternativesCertificates={data?.alternatives ?? []}
            />
          </Grid>
          <Grid xs={12} item>
            <RecommendedCertificatesTable
              recommendedCertificates={data?.recommended ?? []}
              title="Recommended Certificates"
            />
          </Grid>
          <Grid xs={12} item>
            <RecommendedCertificatesTable
              recommendedCertificates={data?.automaticEnrollment ?? []}
              title="Certificates automatically enrolled after completion"
            />
          </Grid>
          {!!data?.prerequisitesTo.length && (
            <Grid xs={12} item>
              <PrerequisitesTable
                title="Prerequisites to"
                isCatalog = {true}
                prerequisites={data?.prerequisitesTo ?? []}
                DataTableProps={{ columns: certificationListColumns }}
              />
            </Grid>
          )}
          <Grid xs={12} item>
            <HistoryTable
              DataTableProps={{
                loading: isHistoryLoading,
                rows: historyData ?? [],
                noDataMessage: '',
              }}
            />
          </Grid>
        </Grid>
      </Content>
    </DetailsAppShell>
  );
};
