import { ChipButton } from "./ChipButton"

interface ItemRequiredField {
    title: string,
}

export interface ChipButtonListProps<T> {
    items?: T[]
    current?: T
    onSelected: React.Dispatch<T>
    onDeleted?: React.Dispatch<T>

    formatIcon?: (item: T) => React.ReactElement

};

export function ChipButtonList<T extends ItemRequiredField>(props: ChipButtonListProps<T>) {

    if (!props.items) return (<></>)
    return (
        <>
            {props.items.map((item, idx) =>
            (<ChipButton
                key={idx}
                color={item === props.current ? "secondary" : "primary"}
                onClick={() => props.onSelected(item)}
                onDelete={() => props.onDeleted && props.onDeleted(item)}
                icon={props.formatIcon ? props.formatIcon(item) : undefined}
                label={item.title}
            />)


            )}
        </>)
}