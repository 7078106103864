import * as MUI from "@material-ui/icons"

const UsedIcons = {
    DefaultFilter: MUI.Star,
    Edit: MUI.Edit,
    Delete: MUI.Delete,
    Close: MUI.Close,
    Add: MUI.Add,
    List: MUI.List,
    ExpandMore: MUI.ExpandMore,
    ExpandLess: MUI.ExpandLess,
    NavigateNext: MUI.NavigateNext,
    NavigateBefore: MUI.NavigateBefore,
    Settings: MUI.Settings,
    HelperIcon : MUI.Info,
    Menu: MUI.MoreVert,
}

export default UsedIcons