import { useMemo } from 'react';
import { TextFieldProps } from '@material-ui/core';
import { ColumnDefinition, ColumnType } from 'Reports/model';

export enum FieldType {
  text,
  dateTime,
  select,
  unknown,
}

const getFieldType = (colType: ColumnType): FieldType => {
  const { date, search, country, select } = colType;
  switch (true) {
    case !date && !search && !country && !select:
      return FieldType.text;
    case date:
      return FieldType.dateTime;
    case Boolean(select):
      return FieldType.select;
    default:
      return FieldType.unknown;
  }
};

export const useColumnDefinition = (
  columnDefinition: ColumnDefinition
): [TextFieldProps, FieldType] => {
  const results = useMemo(() => {
    const field: TextFieldProps = {
      helperText: columnDefinition.tooltip ?? ' ',
      label: columnDefinition.name,
    };
    return [field, getFieldType(columnDefinition.type)];
  }, [columnDefinition]);
  return [results[0] as TextFieldProps, results[1] as FieldType];
};
