import { FC } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { MyProfile } from 'MyProfile/model/MyProfile';
import { myProfileApi } from 'MyProfile/service/myProfile.api';
import { ApiError } from 'model/ApiError';
import { EditUserForm } from 'MyProfile/component/EditUserForm';
import { useToasts } from 'components/ToastProvider';
import { EditUserFormData } from 'MyProfile/component/EditUserForm/EditUserForm';
import { queryClient } from 'service';
import { userKey } from 'MyProfile/query/myProfile.query';
import { IsExternalType } from 'Dictionary/component';
import { EditExternalUserDto } from 'MyProfile/model/EditExternalUserDto';

export interface EditInternalUserFormProps {
  user: MyProfile;
}

export const formValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Enter user first name'),
  lastName: Yup.string().required('Enter user last name'),
  country: Yup.object().required().typeError('Select country'),
  company: Yup.object().required().typeError('Select company'),  
  manager: Yup.object().required().typeError('Select Manager'),   
  preferredLanguages: Yup.array().min(1,'Select at least one language'),
  employeeJobs: Yup.array().min(1, 'Select at least one Employee job'),
});

export const mapEditUserDto = (
  user: MyProfile,
  profileInformationForm: EditUserFormData
): EditExternalUserDto => ({
  firstName: profileInformationForm.firstName,
  lastName: profileInformationForm.lastName,
  email: user.email,
  notes: profileInformationForm.notes,
  city: profileInformationForm.city,
  state: profileInformationForm.state,
  trainingManagerEmail: (profileInformationForm.manager?.group ?? '').toString(),
  trainingManagerGeid: (profileInformationForm.manager?.id ?? '').toString(),
  companyId: Number(profileInformationForm.company?.id),
  divisionId: Number(profileInformationForm.division?.id),
  businessUnitId: Number(profileInformationForm.businessUnit?.id),
  pgUnitId: Number(profileInformationForm.pgUnit?.id),
  employeeJobsIds: profileInformationForm.employeeJobs?.map((job) =>
    Number(job?.id)
  ),
  employeeJobFamiliesIds: profileInformationForm.jobFamilies?.map((job) =>
    Number(job?.id)
  ),
  preferredLanguagesIds: profileInformationForm.preferredLanguages.map(
    (language) => Number(language?.id)
  ),
  countryId: (profileInformationForm.country?.id as number) ?? null,
});

export const EditExternalUserForm: FC<EditInternalUserFormProps> = ({
  user,
}) => {
  const { showError, showSuccess } = useToasts();
  const { mutate, isLoading } = useMutation<
    unknown,
    ApiError,
    EditUserFormData
  >(
    (formData) =>
      myProfileApi.updateExternalUser(user.id, mapEditUserDto(user, formData)),
    {
      onSuccess: () => {
        showSuccess('User profile has been updated');
        queryClient.invalidateQueries(userKey);
      },
      onError: (error) => showError(error.message),
    }
  );

  return (
    <EditUserForm
      user={user}
      loading={isLoading}
      validationSchema={formValidationSchema}
      isExternalType={IsExternalType.External}
      onSubmit={async (values, { resetForm }) => {
        try {
          await mutate(values);
          resetForm({ values });
        } catch (e) {
          resetForm();
        }
      }}
    />
  );
};
