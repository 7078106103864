import { PageBase } from 'components/Page/PageBase'

import * as API from 'Dictionary/service/dictionary.api'

import EditorPart from './Editor'
import FiltersPart from './Filters'
import * as Model from './model'

type QueryModel = Model.DigitalSignatureFindQuery
type DTOModel = Model.DigitalSignatureDto

const ContractAPI = API.dictionaryApi.digitalSignature

const defaultQuery: QueryModel = { currentPageIndex: 0, pageSize: 50, division: undefined, businessArea: undefined }
const defaultNewItem: DTOModel = { id: 0, divisions: [] } as unknown as DTOModel
const getRowId = (row: DTOModel) => row.id

export function Page() {

  const getNewItem = (): DTOModel => ({ ...defaultNewItem })
  return PageBase<DTOModel, QueryModel>({
    defaultQuery: { ...defaultQuery },
    getIdFor: getRowId,
    contract: ContractAPI,
    pageSettings: {
      title: "Digital Signature",
      subtitle: "Here you can find the Digital Signature or define the Authorized Signers and upload their digital signature for the Certificate's Print.",
      addTitle: "Add new signature",
      editTitle: "Edit signer",
      newItem: {
        actionCaption: "ADD SIGNATURE",
        inPageAsRowView: true
      },
      deleteItem: {
        confirmTitle: "Delete Signature",
        confirmDescription: "Delete Signature can't be restored.",
        confirmButton: "DELETE"
      }
    },
    getRowView: (row, cancelHandler, updateHandler) => (<EditorPart item={row ?? getNewItem()} onCancel={cancelHandler} onCommit={updateHandler} />),
    getSearchFiltersForm: (onQuery) => (<FiltersPart defaultQuery={defaultQuery} onQuery={onQuery} />),
    columns: (
      [
        {
          field: 'signer',
          headerName: 'Signer',
          renderField: (r) => `${r.signer.lastName}, ${r.signer.firstName} (${r.signer.email})`
        },
        {
          field: 'divisions',
          headerName: 'Division',
          renderField: (r) => r.divisions!.map(x => x.description).join(", ")
        },
        {
          field: 'labelSignature',
          headerName: '	Signature Label'
        },
        {
          field: 'id',
          headerName: 'Digital Signature',
          renderField: (r) => (
            <img id="image"
              alt="Signature"
              style={{ display: "block", maxWidth: 200, maxHeight: 95, width: "auto", height: "auto" }}
              src={`data:image;base64,${r.signature}`} />
          )
        },
      ]
    )
  })
};