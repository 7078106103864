import { Dispatch } from 'react'
import { Form, FormikForApp } from 'components/FormikField';

import * as Model from './model'

type QueryModel = Model.FindQuery

export default function SearchForm(p: {
  defaultQuery: QueryModel
  onQuery: Dispatch<QueryModel>
}) {

  return (
    <FormikForApp validate={p.onQuery} initialValues={p.defaultQuery} onSubmit={p.onQuery}>
      {(p) => (
        <Form>
          <div style={{ width: "25rem" }}>
            {p.DictionaryFieldByCode({ name: "division", query: { key: "Division" } })}
          </div>
        </Form>
      )}
    </FormikForApp>)
}
